import terminalStyles from "./InputStyleTerminal.module.css";
import webStyles from "./InputStyle.module.css";

import { isTerminal } from "../../../../constants";

export const InputTerminal = (props) => {
    const {
        onChange,
        label,
        placeholder,
        trailingIcon,
        type,
        errorView,
        name,
        inputRef,
    } = props;
    const style = isTerminal ? terminalStyles : webStyles;

    return (
        <div className={style.containerInput}>
            <label>{label}</label>
            <div
                className={`${style.inputWrapper} ${
                    errorView
                        ? style.inputWrapperError
                        : style.inputWrapperDefault
                }`}>
                <input
                    ref={inputRef}
                    name={name}
                    className={style.input}
                    placeholder={placeholder}
                    onChange={onChange}
                    type={type}
                    {...props}
                />
                {trailingIcon}
            </div>
            {errorView && (
                <span className={style.errorView}>{errorView}</span>
            )}
        </div>
    );
};
