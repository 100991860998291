export const footerLinks = [
    {
        id:"link-1",
        title:"Портал ОЦО",
        path:"http://ssc.alrosa.ru/",
    },
    {
        id:"link-2",
        title:"Корпоративный сайт",
        path:"https://www.alrosa.ru/",
    },
    {
        id:"link-3",
        title:"Поддержка",
        path:"https://helpdesk.alrosa.ru/portal",
    },
    // {
    //     id:'link-4',
    //     title:'Обратная связь',
    //     path:'#'
    // },
    {
        id:"link-5",
        title:"Условия использования",
        path:"#",
        blank: true,
    },
    // {
    //     id:'link-6',
    //     title:'Политика конфиденциальности',
    //     path:'#'
    // }
];
