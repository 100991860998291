import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import DropdownMenuTest from "../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";
import CommentInput from "../../../../../common/Inputs/CommentInput/CommentInput";
import NDFLApplication from "../../../../../../frontend.classes/References/NDFL.application";
import { isTerminal } from "../../../../../../constants";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

isTerminal ? import("./NdflTerminal.scss") : import("./Ndfl.scss");

const NDFL = ({ typeId, setCurrentRequest, currentRequest }) => {
    const dispatch = useDispatch();

    function selectDropdown(event, element, stateProperty) {
        if (stateProperty === "year") {
            const temp = {
                ...currentRequest,
                data: {
                    ...currentRequest.data,
                    year: element.name,
                },
                isValid: {
                    ...currentRequest.isValid,
                    year: true,
                },
            };
            setCurrentRequest(temp);
        } else if (stateProperty === "stamp") {
            const temp = {
                ...currentRequest,
                data: {
                    ...currentRequest.data,
                    stamp: element.id,
                },
                isValid: {
                    ...currentRequest.isValid,
                    stamp: true,
                },
            };
            setCurrentRequest(temp);
        }
    }

    function commentHandler(e) {
        const temp = {
            ...currentRequest,
            data: {
                ...currentRequest.data,
                comment: e.target.value,
            },
        };
        setCurrentRequest(temp);
    }

    useEffect(() => {
        if (typeId === 0) {
            const temp = { ...currentRequest, typeId: typeId };
            setCurrentRequest(new NDFLApplication({
                typeId: typeId,
                data: {
                    comment: "",
                    year: null,
                    stamp: null,
                },
                isValid: {
                    typeId: true,
                    year: true,
                    stamp: true,
                },
            }));
        }
    }, []);


    return (
        <section className={"ndfl"}>
            <DropdownMenuTest
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Выберите период<span className="required_text"> *</span></>}
                listOfOptions={[{ name: "2024", id: 0 }, { name: "2023", id: 1 }, { name: "2022", id: 2 }]}
                stateProperty={"year"}
                isValidKey={currentRequest && currentRequest.isValid && currentRequest.isValid.year}
                selectOptionClick={selectDropdown} />
            <DropdownMenu
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Выберите печать<span className="required_text"> *</span></>}
                listOfOptions={[{ name: "без печати", id: 0 }, { name: "с печатью", id: 1 }]}
                stateProperty={"stamp"}
                isValidKey={currentRequest && currentRequest.isValid && currentRequest.isValid.stamp}
                selectOptionClick={selectDropdown} />
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.data && currentRequest.data.comment}
                stateProperty={"comment"}
                changeHandler={commentHandler}
                label={"Комментарий"}
                parentStyle={"unplanned-vacation"}
            />
        </section>
    );
};

export default NDFL;
