import HelperService from "./Helper.service";

import moment from "moment";

import "moment/locale/ru";

class DateService {

    static getStartOfYear () {
        return moment().startOf("year").toDate();
    }
    static getStartOfMonth (date) {
        return moment(date).startOf("month").toDate();
    }
    static getStartOfWeek () {
        return moment().startOf("week").toDate();
    }
    static getStartOfWeekClear () {
        return moment().startOf("week").format("YYYY-MM-DD");
    }
    static getDay (date, adder = 0) {
        return moment(date)
            .locale("ru")
            .add(adder, "d");
    }
    static getDayClear (date, adder = 0) {
        return moment(date)
            .locale("ru")
            .add(adder, "d")
            .format("YYYY-MM-DD");
    }
    static getDayNumber (date, adder = 0) {
        return moment(date)
            .locale("ru")
            .add(adder, "d")
            .format("D");
    }
    static getDayName (date, format, adder = 0) {
        return moment(this.getStartOfWeek())
            .locale("ru")
            .add(adder, "d")
            .format(format);
    }
    static getDayNameMonth (date, format, adder = 0) {
        return moment(this.getStartOfMonth(date))
            .locale("ru")
            .add(adder, "d")
            .format(format);
    }
    static getMonthNames () {
        return new Array(12).fill(null).map((e, i) => {
            return HelperService.capitalizeSentence(
                moment().startOf("year").add(i, "month").format("MMMM")
            );
        });
    }
    static getCurrentDateFormat (date,format) {
        return moment(date).format(format);
    }
    static numberOfWeeks (date) {
        const now = moment(date);
        const firstWeekDays = 7 - now.startOf("month").isoWeekday() + 1;
        let rows = 1;
        let rest = now.daysInMonth() - firstWeekDays;
        const middleRows = Math.floor(rest/7);
        rows = rows + middleRows;
        rest = rest - (middleRows * 7);
        if (rest > 0) {
            rows = rows + 1;
        }
        return rows;
    }
}

export default DateService;
