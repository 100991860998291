import {tableHeadPrefix} from "../../components/common/Holidays/HolidaysTable/table.data";

import HelperService from "../Helper.service";

import TooltipText from "../../components/common/TooltipText/TooltipText";

import {v4 as uuidv4} from "uuid";
import moment from "moment";

class TableQuartHelper {
    static getHeaderRow (period) {
        // PERIOD PROP "FROM" SHOULD BE START OF A QUARTER
        const start = moment(period.from).month();
        // MONTHS
        const months = new Array(3).fill(null).map((e, i) => {
            return {
                id: uuidv4(),
                data: HelperService
                    .capitalizeSentence(
                        moment().month(start + i).format("MMMM")
                    ),
                cssKey: "__table-header-quart",
                colspan: 4,
            };
        });
        return tableHeadPrefix.concat(months);
    }
    static getFilledRow (data, parentStyle, section, setTooltip) {
        const output = [];
        data && data.forEach((rawGantDataElement, rawGantDataIndex) => {
            const weekNumber = rawGantDataElement.leaves.map(period => {
                return {
                    displayData: {
                        from: moment(period.from).format("DD.MM"),
                        to: moment(period.to).format("DD.MM"),
                        days: period.days,
                    },
                    cells: TableQuartHelper.cellRange(
                        TableQuartHelper.cellPositionCalculate(section.from, period.from),
                        TableQuartHelper.cellPositionCalculate(section.from, period.to)
                    ),
                };
            });
            output.push(
                <tr key={uuidv4()}>
                    <td className={`${parentStyle}__body__department`}>
                        {rawGantDataElement.struct}
                    </td>
                    <td className={`${parentStyle}__body__worker`}>
                        {rawGantDataElement.name}
                    </td>
                    <td className={`${parentStyle}__vacation-days`}>
                        {rawGantDataElement.totalDays}
                    </td>
                    {rawGantDataElement.leaves.length
                        ? new Array(12).fill(null).map((week, weekIndex) => {
                            const start = moment(section.from).add(weekIndex + 2, "week").week();
                            return (
                                <td
                                    id={uuidv4()}
                                    key={uuidv4()}
                                    className={`${parentStyle}__body__week`}>
                                    {
                                        weekNumber
                                            .filter(e => {return e.cells.includes(weekIndex + 1);}).length
                                            ?
                                            <div
                                                id={uuidv4()}
                                                className={
                                                // moment(section.to).month() > moment().month()
                                                    start > moment().week()
                                                        ? "week-regular"
                                                        : "week-past"
                                                }
                                                onMouseLeave={(e) => {
                                                    setTooltip(null);
                                                }}
                                                onMouseEnter={(e) => {
                                                    const elem = document.getElementById(e.target.id);
                                                    if (elem) {
                                                        const top = Number(elem.getBoundingClientRect().y);
                                                        const left = Number(elem.getBoundingClientRect().x);
                                                        setTooltip(this.tooltipComponentQuart(
                                                            weekNumber,
                                                            Math.ceil(top),
                                                            Math.ceil(left),
                                                            weekIndex + 1
                                                        ));
                                                    }
                                                }}
                                            >
                                            </div>
                                            : null
                                    }
                                </td>
                            );})
                        : new Array(12).fill(null).map((week, weekIndex) => {
                            return (
                                <td key={uuidv4()}>
                                </td>
                            );
                        })
                    }
                </tr>
            );
        });
        return output;
    }
    static getPeriod (section) {
        const startOfYear = moment().startOf("year").format("YYYY-MM-DD");

        const dates = {
            firstQuart: {
                from: startOfYear,
                to: moment(moment(startOfYear).add(2, "month")).endOf("month").format("YYYY-MM-DD"),
            },
            secondQuart: {
                from: moment(moment(startOfYear).add(3, "month")).startOf("month").format("YYYY-MM-DD"),
                to: moment(moment(startOfYear).add(5, "month")).endOf("month").format("YYYY-MM-DD"),
            },
            thirdQuart: {
                from: moment(moment(startOfYear).add(6, "month")).startOf("month").format("YYYY-MM-DD"),
                to: moment(moment(startOfYear).add(8, "month")).endOf("month").format("YYYY-MM-DD"),
            },
            fourthQuart: {
                from: moment(moment(startOfYear).add(9, "month")).startOf("month").format("YYYY-MM-DD"),
                to: moment(moment(startOfYear).add(11, "month")).endOf("month").format("YYYY-MM-DD"),
            },
        };
        switch (section) {
        case "firstQuart": return dates.firstQuart;
        case "secondQuart": return dates.secondQuart;
        case "thirdQuart": return dates.thirdQuart;
        case "fourthQuart": return dates.fourthQuart;
        default: return dates.firstQuart;
        }
    }
    static cellPositionCalculate (startDate, comparisonDate) {

        const month = Math.ceil(moment(comparisonDate).date() / 7) > 4
            ? moment(comparisonDate).diff(startDate, "month") + 1
            : moment(comparisonDate).diff(startDate, "month");
        const week = Math.ceil(moment(comparisonDate).date() / 7) > 4
            ? 1
            : Math.ceil(moment(comparisonDate).date() / 7);
        const result = month * 4 + week;
        // if (moment(comparisonDate).date() / 7 > 5) return result - 1
        return result;
    }
    static cellRange (start, end) {
        const result = [];
        for (let i = start; i <= end; i += 1) {
            result.push(i);
        }
        return result;
    }
    static tooltipComponentQuart (weekNumber, top, left, weekIndex) {
        const tooltipData = weekNumber.filter(e => {
            return e.cells.includes(weekIndex);
        });
        return (
            <div
                key={uuidv4()}
                className={"week-regular__pop-up"}
                style={
                    {
                        top: top,
                        left: left,
                        display: "block",
                    }
                }>

                <ul key={uuidv4()}
                    className={"week-regular__pop-up__list"}>
                    {
                        tooltipData.map(e => {
                            return (
                                <TooltipText data={e.displayData} parentStyle={"week-regular"}/>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default TableQuartHelper;