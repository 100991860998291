import { VALIDATION_CONTROL_CURRENT_REQUEST } from "../../../enums/Request/currentRequestControls.types";

export const validationControlCurrentRequest = (key, value, flag = "string", index) => {
    switch (flag) {
    case "string":
        return {
            type: VALIDATION_CONTROL_CURRENT_REQUEST,
            payload: { key, value, flag },
        };
    case "object":
        return {
            type: VALIDATION_CONTROL_CURRENT_REQUEST,
            payload: { object: key.object, innerKey: key.innerKey, value, flag },
        };
    // case "array":
    //     return VALIDATION_CONTROL_CURRENT_REQUEST,
    //       // payload: {key,  }
    default:
        return {
            type: VALIDATION_CONTROL_CURRENT_REQUEST,
            payload: { key, value, flag },
        };
    }
};
