import { SELECT_WORKER_FROM_EMPLOYEE_LIST } from "../../../../enums/Request/request.types";

export const selectWorkerFromEmployeeListAction = (workerIndex, pid) => {
    return {
        type: SELECT_WORKER_FROM_EMPLOYEE_LIST,
        payload: {
            pid,
            index: workerIndex,
        },
    };
};