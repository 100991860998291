import { asyncPayrollAction } from "../../../../../redux/actions/Payroll/Thunk/payroll.thunk";
import MultiRoute from "../../../../common/MultiRoute/MultiRoute";
import Header from "../../../../layout/Header/Header";
import Footer from "../../../../layout/Footer/Footer";
import { ChangeMonths } from "../../../../common/componentsTerminal/ChangeMonths/ChangeMonths";
import { ScrollButton } from "../../../../common/componentsTerminal/ScrollButton/ScrollButton";
import { isTerminal } from "../../../../../constants";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";

isTerminal ? import("./PayrollTerminal.scss") : import("./PayrollStyle.scss");

const currentDate = moment();
const maxDate = moment();

export const PayrollPage = () => {
    const dispatch = useDispatch();
    const { payroll, isLoading, error } = useSelector(state => state.payroll);
    const iframeRef = useRef(null);

    const onChange = (date) => {
        const { months, years } = date.toObject();
        dispatch(asyncPayrollAction(months * years + months));
    };

    useEffect(() => {
        onChange(currentDate);
    }, [dispatch]);

    return (
        <>
            <Header />
            <div className="buttons-month__wrapper">
                <MultiRoute
                    subHeader="Вознаграждение"
                    breadCrumbs
                    styleParent={"reward__header"}
                />
                <ChangeMonths
                    date={currentDate}
                    maxDate={maxDate}
                    onChange={onChange}
                    disabled={isLoading}
                />
            </div>
            <div className="payroll__wrapper">
                {isLoading && <div className="loader__container"><ClipLoader size={72}/></div>}
                {payroll && !isLoading && !error && <div className="reward">
                    <iframe className="reward__iframe" srcDoc={payroll} ref={iframeRef} />
                    {isTerminal && <ScrollButton propsClassName=".reward__iframe" isIframe iframeRef={iframeRef} />}
                </div>}
                {!isLoading && !payroll && !error && <div className="payroll__default">Расчетного листа не существует</div>}
                {error && !isLoading && <div className="payroll__default">Что-то пошло не так обратитесь в службу поддержки</div>}
            </div>
            <Footer />
        </>
    );
};
