import { $api } from "../../../services/request.config";

export const openFileBlobThunkAction = (document) => async (dispatch) => {
    try {
        const { leaveRequestUid, id} = document;
        const file = await $api(`v1/leaverequest/file/${leaveRequestUid}/${id}`, {responseType: "blob"});
        const blobLink = URL.createObjectURL(new Blob([file.data], {type: `${file.headers["content-type"]}`}));
        window.open(blobLink, "_blank");
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR WHILE OPENING FILE BLOB:", error);
    }
};