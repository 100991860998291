import blankList from "../../../assets/icons/NavBlockIcons/blank-list.svg";
import calendar from "../../../assets/icons/NavBlockIcons/calendar.svg";
import blankNote from "../../../assets/icons/NavBlockIcons/blank-note.svg";
import users from "../../../assets/icons/NavBlockIcons/users.svg";

export const data = [
    {
        title: "Структура подразделения",
        description:
            "Описание структуры подразделения, подчиненность, положения, документы подразделения, штатное расписание",
        icon: blankList,
        path: "/divisions/structure",
    },
    {
        title: "Рабочее время сотрудников",
        description:
            "Отпуска, командировки, служебные поездки, вахты и другие данные по рабочему времени сотрудников",
        icon: calendar,
        path: "/divisions/work-time",
    },
    {
        title: "Прием и увольнение сотрудников",
        description:
            "Информация и управление приемом новых сотрудников, увольнением сотрудников и прочее",
        icon: blankNote,
        path: "#",
    },
    {
        title: "Здоровье сотрудников",
        description:
            "Медицинский осмотр, вакцинация, диспансеризация и другие данные по здоровью сотрудников",
        icon: users,
        path: "#",
    },
    {
        title: "Вознаграждение сотрудников",
        description:
            "Заработная плата, премирование, пособия, надбавки и другая информация о вознаграждении",
        icon: calendar,
        path: "#",
    },
    {
        title: "Обучение сотрудников",
        description:
            "Информация по обучению сотрудников, повышению квалификации и другие учебные материалы",
        icon: blankNote,
        path: "#",
    },
    {
        title: "Дашборд",
        description:
            "Дашборд руководителя подразделения для внутреннего анализа состояния",
        icon: users,
        path: "#",
    },
];
