import {
    getFioInformationThunkAction,
} from "../../../../../redux/actions/User/profile/GeneralData/thunk/getFioInformationThunkAction";
import BasicLine from "../../../../common/BasicLine/BasicLine";
import { isTerminal } from "../../../../../constants";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

isTerminal ? import("./FioContactsTerminal.scss") : import("./FioContacts.scss");


const FioContacts = () => {
    const dispatch = useDispatch();

    const fioContacts = useSelector(state => state.personalData.fioContacts);
    const [info, setInfo] = useState(fioContacts);

    useEffect(() => {
        dispatch(getFioInformationThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInfo(fioContacts);
    }, [fioContacts]);

    return (
        <div className='fio'>
            {info && info.map((el, i) => {
                return (
                    <BasicLine
                        key={i}
                        type={el.type}
                        description={el.description}
                        styleParent={"fio"}
                    />
                );
            })}
        </div>
    );
};

export default FioContacts;
