import triangle from "../Triangle.svg";

import RequestsHelper from "../../../../../frontend.services/RequestServices/Requests.helper";
import { isTerminal } from "../../../../../constants";
import {
    datepickerSelectCurrentRequest,
} from "../../../../../redux/actions/Requests/currentRequestControls/datepickerSelect.currentRequest";
import calendarIcon from "../../../../../assets/icons/Claendar/calendar.svg";
import { DatepickerCustomField } from "../../../DatepickerCustomField/DatepickerCustomField";

import React, { memo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";


import { useDispatch, useSelector } from "react-redux";

isTerminal ? import("./ControlledDatePickerTerminal.scss") : import("./ControlledDatePicker.scss");

const ControlledDatePicker = (
    {
        parentStyle,
        index,
        field,
        label,
        date,
        period,
        datesForRange,

    },
) => {
    const datePickerRef = useRef();
    const dispatch = useDispatch();

    const currentRequest = useSelector(state => state.requests.currentRequest);

    const [selectedDate] = useState(date ? date : null);
    const [arrow, setArrow] = useState(false);
    const handleCalendarClose = () => setArrow(false);
    const handleCalendarOpen = () => setArrow(true);

    function changeHandler(date) {
        dispatch(datepickerSelectCurrentRequest({
            date: moment(date).format("YYYY-MM-DD"),
            index,
            field,
        }));
    }


    return (
        <>
            <div className={
                currentRequest && currentRequest.isValid.validDate
                    ? "valid-date"
                    : "invalid-date"
            }>
                <div className={`${parentStyle}-date-period__label`}>
                    {label}
                </div>
                <DatePicker
                    customInput={<DatepickerCustomField />}
                    minDate={datesForRange && datesForRange.minFrom
                        ? datesForRange.minFrom
                        : RequestsHelper.datepickerMinDateChecker(field, period)
                    }
                    maxDate={
                        RequestsHelper.datepickerMaxDateChecker(field, period)
                    }
                    dateFormat="dd.MM.yyyy"
                    excludeDateIntervals={
                        currentRequest.periods ?
                            currentRequest.periods
                                .filter((e, i) => e.dateFrom && e.dateTo && i !== index)
                                .map(e => {
                                    return [{ start: new Date(moment(e.dateFrom)), end: new Date(moment(e.dateTo)) }];
                                })
                                .flat()
                            : null
                    }
                    locale={"ru"}
                    selected={selectedDate ? new Date(selectedDate) : null}
                    className={
                        `${parentStyle}-date-period__input${
                            currentRequest && currentRequest.isValid.validDate
                                ? "-regular"
                                : "-error"}`
                    }
                    onChange={changeHandler}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                    ref={datePickerRef}
                />
                {
                    isTerminal
                        ? (<img onClick={datePickerRef.current?.onInputClick} className={`${parentStyle}-date-period__calendar`} src={calendarIcon} />)
                        : (<img className={`${parentStyle}-date-period__${arrow ? "arrow-down" : "arrow"}`} src={triangle} alt=">" />)
                }
            </div>
            {
                currentRequest && !currentRequest.isValid.validDate
                    ? <div className={`${parentStyle}-date-period__error`}>
                        Проверьте правильность заполнения
                    </div>
                    : null
            }
        </>

    );
};

export default memo(ControlledDatePicker);
