import NavMenuDocuments from "../../common/Documents/NavMenuDocuments/NavMenuDocuments";
import AllDocuments from "../../common/Documents/AllDocuments/AllDocuments";
import EmploymentDocuments from "../../common/Documents/EmploymentDocuments/EmploymentDocuments";
import References from "../../common/Documents/References/References";
import Approvals from "../../common/Documents/Approvals/Approvals";
import Applications from "../../common/Documents/Applications/Applications";
import {
    getUserRequestsThunkAction,
} from "../../../redux/actions/Requests/GetUserRequests/thunk/getUserRequests.thunk.action";

import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import {
    getUserHrDocsThunkAction,
} from "../../../redux/actions/Requests/Hr-documents/thunk/getUserHrDocs.thunk.action";
import {
    getLeaderRequestsThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getLeaderRequests.thunk.action";
import {
    getAllUserReferencesThunkAction,
} from "../../../redux/actions/Requests/References/thunk/getAllUserReferences.thunk.action";
import ModalBanOverlay from "../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import ErrorModal from "../../common/Modal/ModalConditions/Profile/ErrorModal/ErrorModal";
import { Modal } from "../../common/Modal/Modal";
import WorkerSignType from "../../common/Modal/ModalConditions/UserSignType/WorkerSignType/WorkerSignType";
import { ScrollButton } from "../../common/componentsTerminal/ScrollButton/ScrollButton";
import { getAllDocumentsThunkAction } from "../../../redux/actions/Documents/AllDocuments/getAllDocuments.thunk.action";
import {
    getStructureTopThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getStructureTop.thunk.action";

import {
    getLatesUnepCertificateThunkAction,
} from "../../../redux/actions/User/profile/ElectronicSignature/thunk/getLatesUnepCertificate.thunk.action";
import { isTerminal } from "../../../constants";

import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";

isTerminal ? import ("./DocumentsTerminal.scss"): import ("./Documents.css");

const Documents = () => {
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.requests);
    const allDocuments = useSelector((state) => state.documents.allDocuments);
    const isSubstitute = useSelector((state) => state.documents.isSubstitute);

    const [toggleState, setToggleState] = useState(
        localStorage.getItem("hasActualDocuments") === "true" ? 2 : 4,
    );
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const [currentRows, setCurrentRows] = useState(5);
    const currentRowsHandler = (id) => {
        setCurrentRows(id);
    };

    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("");

    const [signType, setSignType] = useState(false);

    function closeSignType() {
        setSignType(false);
    }

    useEffect(() => {
        dispatch(getStructureTopThunkAction());
        dispatch(
            getLatesUnepCertificateThunkAction({
                setErrorModal: setErrorModal,
                setErrDescription: setErrDescription,
            }),
        );
        // dispatch(
        //     getWorkSignTypeThunkAction(
        //         {
        //             setErrorModal: setSignType,
        //             setErrDescription: setErrDescription,
        //         },
        //         1,
        //     ),
        // );
        dispatch(
            getUserRequestsThunkAction({
                setErrorModal: setErrorModal,
                setErrDescription: setErrDescription,
            }),
        );
        dispatch(
            getUserHrDocsThunkAction({
                setErrDescription: setErrDescription,
                setErrorModal: setErrorModal,
            }),
        );
        dispatch(
            getAllUserReferencesThunkAction({
                // setErrorModal:setErrorModal,
                // setErrDescription:setErrDescription
            }),
        );
        dispatch(
            getLeaderRequestsThunkAction({
                // setErrorModal:setErrorModal,
                // setErrDescription:setErrDescription
            }),
        );
        dispatch(getAllDocumentsThunkAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            {!requests.userRequests ||
      !requests.hrDocuments ||
      !requests.leaderRequests ||
      !requests.userReferences ||
      !allDocuments ? (
            // !requests.userRequests || !requests.hrDocuments || !requests.leaderRequests  || !requests.userReferences
            //TODO
                    <div
                        style={{
                            width: isTerminal ? "1080px" : "1160px",
                            margin: "20vh auto auto auto",
                            textAlign: "center",
                            minHeight: "20vh",
                        }}
                        className={isTerminal && "docs-main-wrapper"}
                    >
                        <ClipLoader color="#6DCDF6" />
                    </div>
                ) : (
                    <div className="docs-main-container">
                        <NavMenuDocuments
                            toggleState={toggleState}
                            toggleTab={toggleTab}
                        />
                        <div className="content-tabs">
                            <div
                                className={
                                    toggleState === 1 ? "active-content" : "content"
                                }
                            >
                                <AllDocuments
                                    allDocuments={allDocuments}
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                />
                            </div>
                            <div
                                className={
                                    toggleState === 2 ? "active-content" : "content"
                                }
                            >
                                <EmploymentDocuments
                                    hrDocuments={requests.hrDocuments}
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                />
                            </div>
                            <div
                                className={
                                    toggleState === 3 ? "active-content" : "content"
                                }
                            >
                                <References
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                    userReferences={requests.userReferences}
                                />
                            </div>
                            <div
                                className={
                                    toggleState === 4 ? "active-content" : "content"
                                }
                            >
                                <Applications
                                    // arr={}
                                    userRequests={requests.userRequests}
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                />
                            </div>
                            <div
                                className={
                                    toggleState === 5 ? "active-content" : "content"
                                }
                            >
                                {(isSubstitute ||
                localStorage.getItem("isLeader") ===
                "true") && (
                                    <Approvals
                                        requests={
                                            requests.leaderRequests
                                                ? requests.leaderRequests
                                                : null
                                        }
                                        currentRows={currentRows}
                                        currentRowsHandler={currentRowsHandler}
                                        rowsPerPage={currentRows}
                                    />
                                )}
                            </div>
                            <Outlet />
                        </div>
                    </div>
                )}
            <Footer />
            <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                <ErrorModal
                    description={errDescription}
                    setError={setErrorModal}
                    route={"/documents"}
                    signType={true}
                />
            </ModalBanOverlay>
            <Modal shown={signType} close={closeSignType}>
                <WorkerSignType
                    description={errDescription}
                    setErrorModal={setErrorModal}
                    close={closeSignType}
                    signType={true}
                />
            </Modal>
        </>
    );
};

export default React.memo(Documents);
