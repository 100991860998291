import { $api } from "../../../../../../redux/services/request.config";

import Breadcrumbs from "../../../../../common/Breadcrumbs/Breadcrumbs";
import Footer from "../../../../../layout/Footer/Footer";
import Header from "../../../../../layout/Header/Header";

import { useEffect, useState } from "react";

import "./AssessmentWorkingConditions.scss";

const AssessmentWorkingConditions = () => {

    const [isDataAvailable, setIsDataAvailable] = useState([]);

    useEffect(() => {
        $api("v1/is/sewc/classes")
            .then(res => {
                setIsDataAvailable(res.data.data.table);
            })
            // eslint-disable-next-line no-console
            .catch(err => console.log("ERROR: ", err));

    }, []);

    return (
        <>
            <Header />
            <Breadcrumbs />
            <div className="assessment-working-conditions">
                <div className="sub-header" >
                    Оценка условий труда по вредным (опасным) факторам
                </div>
                {
                    isDataAvailable.length
                        ? <div className="assessment-working-conditions__table">
                            <div className="assessment-working-conditions__table__header">
                                <div className="assessment-working-conditions__table__header__first">
                                    Наименование факторов производственной среды и трудового процесса
                                </div>
                                {/* <div className="assessment-working-conditions__table__header__second">
                                    класс (подкласс) условий труда
                                </div>
                                <div className="assessment-working-conditions__table__header__third">
                                    эффективность СИЗ, +/- / не оценивалась
                                </div>
                                <div className="assessment-working-conditions__table__header__fourth">
                                    класс (подкласс) условий труда при эффективном использовании сиз
                                </div> */}
                            </div>
                            <div className="assessment-working-conditions__table__body">
                                
                                {
                                    isDataAvailable.map(factor => {
                                        return (
                                            <div key={factor.id} className="assessment-working-conditions__table__row">
                                                <div className="assessment-working-conditions__table__row__first">
                                                    {factor.name}
                                                </div>
                                                {/* <div className="assessment-working-conditions__table__row__second">
                                                    {factor.value}
                                                </div>
                                                <div className="assessment-working-conditions__table__row__third">
                                                    {factor.efficiencySIZ}
                                                </div>
                                                <div className="assessment-working-conditions__table__row__fourth">
                                                    {factor.effectiveClassSIZ}
                                                </div> */}
                                            </div>
                                        );
                                    })
                                } 
                            
                                
                            </div>
                        </div>
                        : <div className="assessment-working-conditions__lack-of-info">Информация отсутствует</div>
                }
            </div>
            <Footer />
        </>
    );
};
 
export default AssessmentWorkingConditions;