import { createPortal } from "react-dom";
import { Children, cloneElement } from "react";

const ICDropdown = (
    {
        changeHandler,
        dropdownProps,
        children,
        field,
        setState,
        setStateModel,
    }
) => {

    const {
        isComponentVisible,
        setPopperElement,
        styles,
        attributes,
        portalRef,
        forceClose,
    } = dropdownProps;


    return isComponentVisible ? (
        createPortal(
            <div
                className={"input-composition-default__popper"}
                ref={setPopperElement}
                style={{...styles.popper}}
                {...attributes.popper}>
                <div
                    ref={portalRef}>
                    {
                        Children &&
                        Children.map(children, (child) => {
                            return cloneElement(child, {
                                forceClose,
                                setStateModel,
                                setState,
                                field, // this is for key in object that should fill up
                                changeHandler,
                            });
                        })
                    }
                </div>
            </div>  
            , document.querySelector("#popup"))
    ) : null;
};

export default ICDropdown;