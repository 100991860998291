import HeaderHolidaysApplication from "./HeaderHolidaysApplication/HeaderHolidaysApplication";
import HolidaysApplicationRow from "./HolidaysApplicationRow/HolidaysApplicationRow";
import HolidaysApplicationNoContent from "./HolidatsApplicationNoContent/HolidaysApplicationNoContent";

import { useSelector } from "react-redux";
import { memo, useState } from "react";
import {v4 as uuidv4} from "uuid";

import "./HolidaysApplication.scss";

const HolidaysApplications = (
    {
        multipleSelection,
        selectClickHandler,
        setModal,
    }
) => {
    // const content = [];
    const pendingUserRequests = useSelector(
        (state) => state.employees.pendingApplications,
    );
    // const [paginatedPendingRequests, {paging, perPage}] = UsePagination(pendingUserRequests && pendingUserRequests.applications);
    // console.log("PENDING REQUESTS: ", pendingUserRequests);
    // console.log("PAGINATED ITEMS: ", paginatedPendingRequests);

    const [noContent, setNoContent] = useState(true);


    return (
        <>
            {pendingUserRequests && !pendingUserRequests.applications.length ? (
                <>
                    {noContent ? (
                        <HolidaysApplicationNoContent
                            setNoContent={setNoContent}
                            noContent={noContent}
                        />
                    ) : (
                        <div className="holidays-application-wrapper__no-content-false"></div>
                    )}
                </>
            ) : (
                <table className="holidays-application-table">
                    {pendingUserRequests && (
                        <HeaderHolidaysApplication
                            multiSelect={pendingUserRequests && pendingUserRequests.multiSelect}
                            multipleSelection={multipleSelection}
                            days={pendingUserRequests && pendingUserRequests.dailyPercent}
                        />
                    )}
                    <tbody>
                        {
                            pendingUserRequests &&
                            pendingUserRequests.applications.map(
                                (application, applicationIndex) => {
                                    return (
                                        <HolidaysApplicationRow
                                            setModal={setModal}
                                            key={uuidv4()}
                                            selectClickHandler={selectClickHandler}
                                            pendingUserRequests={
                                                pendingUserRequests
                                            }
                                            application={application}
                                            applicationIndex={
                                                applicationIndex
                                            }
                                        />
                                    );
                                },
                            )
                        }
                    </tbody>
                </table>
            )}
        </>
    );
};

export default memo(HolidaysApplications);
