import $api from "../../../../../services/axiosService";
import { getFioInformatioAction } from "../getFioInformatioAction";

export const getFioInformationThunkAction = () => async (dispatch) => {
    try {
        const responseInfo = await $api.get("/v1.0/c/u/0");
        const fio = {
            type: "ФИО",
            // eslint-disable-next-line max-len
            description: `${responseInfo.data.data.personal.lastNameRu ? responseInfo.data.data.personal.lastNameRu : ""} ${responseInfo.data.data.personal.firstNameRu ? responseInfo.data.data.personal.firstNameRu : ""} ${responseInfo.data.data.personal.secondNameRu ? responseInfo.data.data.personal.secondNameRu : ""}`,
        };
        const responseEmail = await $api.get("/v1.0/c/u/9");
        const mail = {
            type: "Личная эл. почта",
            description: responseEmail.data.data.email,
        };
        const responseNumber = await $api.get("/v1.0/c/u/10");
        const phone = {
            type: "Личный телефон",
            description: responseNumber.data.data.phoneNumber ? `+${responseNumber.data.data.phoneNumber}` : " ",
        };
        return dispatch(getFioInformatioAction([fio, mail, phone]));
    } catch (e) {
        // console.log(e.response)
    }
};
