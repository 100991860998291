import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

import {
    isWorkerSubstituteThunkAction,
} from "../../../../redux/actions/Documents/Leader/thunk/isWorkerSubstitute.thunk.action";

import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";

import { usefulLinksThunkAction } from "../../../../redux/actions/User/profile/UsefulLinks/usefulLinks.thunk.action";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import "./UsefulLinks.scss";

const UsefulLinks = () => {
    const dispatch = useDispatch();
    const isSubstitute = useSelector((state) => state.documents.isSubstitute);
    const usefulLinks = useSelector((state) => state.personalData.usefulLinks);
    useEffect(() => {
        dispatch(isWorkerSubstituteThunkAction());
        dispatch(usefulLinksThunkAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Header />
            <Breadcrumbs />
            <section className={"useful-links"}>
                <h1 className={"sub-header"}>
          Полезные ссылки
                </h1>
                <div className={"hr-services"}>
                    {
                        usefulLinks && usefulLinks.map((element, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`hr-services__item${element.path !== "#" ? "" : "-disabled"}`}
                                    onClick={() => window.open(element.url)}
                                >
                                    <div
                                        className={`hr-services__block-head${
                                            element.url !== "#" ? "" : "-disabled"
                                        }`}
                                    >
                                        <div>{element.title}</div>
                                    </div>
                                    <div
                                        className={`hr-services__description${
                                            element.url !== "#" ? "" : "-disabled"
                                        }`}
                                    >
                                        {element.description}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </section>
            <Footer />
        </>

    );
};

export default UsefulLinks;
