import {useSelector} from "react-redux";

const useHelperDescription = () => {

    const currentRequest = useSelector(state => state.requests.currentRequest);
    const reasonTypes = useSelector(state => state.requests.reasons);


    let helper = "";

    if (Array.isArray(reasonTypes)) {
        const temp = reasonTypes.find(e => {
            if (currentRequest && currentRequest.leaveReasonId) {
                return e.id === currentRequest.leaveReasonId;
            }
            return [];
        });
        if (temp && temp.helperText) {
            helper = temp.helperText;
        }
    }
    return helper;
};

export default useHelperDescription;
