// import triangle from "../../../../../assets/documents/triangle.svg";

export const tableHeaderData = [
    {
        id:"unep-head-1",
        title:"дата выдачи",
        icon:"",
        width:"180px",
    },
    {
        id:"unep-head-2",
        title:"текущий статус",
        icon:"",
        width:"176px",
    },
    {
        id:"unep-head-3",
        title:"срок действия ЭЦП",
        icon:"",
        width:"264px",
    },
    {
        id:"unep-head-4",
        title:"ключ ЭЦП",
        width:"407px",
        icon:"",
    },
];
