import $api from "../../../services/axiosService";

export const passwordValidationAction = (
    {
        password,
        // modal,
        // route,
        uid,
        setCorrectPassword,
        setSentStatus,
        setRequestNumber,
        setRequestTheme,
        setModalError,
        setOnValidation,
    },
) =>
    async () => {
        try {
            const response = await $api.post("v1/auth/verify/password", { password: password.password });
            if (response.status === 204) {
                setOnValidation(true);
                setCorrectPassword(true);
                const sign = await $api.post(`v1/leaverequest/${uid}/sign`, { uid });
                if (sign.data.isSuccess) {
                    const fullRequest = await $api(`v1/leaverequest/${uid}`);
                    setRequestNumber(fullRequest.data.item.orderNumber);
                    setRequestTheme(fullRequest.data.item.leaveTypeId);
                }

                setSentStatus(true);
                setOnValidation(false);
            }
        } catch (error) {
            setOnValidation(false);
            if (error.response.status === 500) {
                setSentStatus(false);
                setModalError(prev => {
                    return { ...prev, sign: true, status: true };
                });
            }
            if (error.response.data.type === "verify-password-bad-request") {
                setCorrectPassword(false);
            }
            if (error.response.status !== 204 && error.response.status !== 400) {
                setCorrectPassword(false);
            }
        }

    };
