import PickedDatesCalendar from "../PickedDatesCalendar/PickedDatesCalendar";

import React, {forwardRef, useState} from "react";
import DatePicker from "react-datepicker";

// import "../../common/DatepickerStyle/DatepickerStyle.scss";

const ExampleCustomInput = forwardRef(({inputStyle, value, onClick }, ref) => (
    <div className={inputStyle} onClick={onClick} ref={ref}>
        Добавить замещающего
    </div>

));

const TwoMonthsCalendar = (
    {
        data,
        setVisible,
        inputStyle,
        pid,
        setModal,
    }
) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    function acceptDates (e) {
        setModal({
            body: "add",
            status: true,
            subject: data.name,
            from: startDate,
            to: endDate,
            pid,
        });
        setVisible(false);
        return true;
    }

    function clearDates (e) {
        setStartDate(null);
        setEndDate(null);
        return true;
    }


    return (
        <>
            <div
                className="two-months-calendar">
                <DatePicker
                    fixedHeight
                    popperClassName={"popper-styles"}
                    shouldCloseOnSelect={false}
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    locale={"ru"}
                    monthsShown={2}
                    selectsRange
                    customInput={<ExampleCustomInput inputStyle={inputStyle}/>}
                >
                    <PickedDatesCalendar
                        startDate={startDate}
                        endDate={endDate}
                        acceptDates={acceptDates}
                        clearDates={clearDates}
                    />
                </DatePicker>

            </div>

        </>


    );
};

export default TwoMonthsCalendar;
