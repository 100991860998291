import {breadcrumbsRoutes} from "./breadcrumbs.routes";
import { breadcrumbsRoutesTerminal } from "./breadcrumbsTerminal.routes";

import { isTerminal } from "../../../constants";

import useBreadcrumbs from "use-react-router-breadcrumbs";
import {NavLink, useLocation} from "react-router-dom";

isTerminal ? import("./BreadcrumbsTerminal.scss") : import("./Breadcrumbs.scss");
const routers = isTerminal ? breadcrumbsRoutesTerminal : breadcrumbsRoutes;
const Breadcrumbs = ({className}) => {
    const breadcrumbs = useBreadcrumbs(routers);
    const location = useLocation();

    return (
        <ul className="breadcrumbs">
            {breadcrumbs.map(({match, breadcrumb}) => (
                <li key={match.pathname}>
                    <NavLink to={ match.pathname  }
                        className={() => (location.pathname === match.pathname ? "active" : "inactive")}>
                        { breadcrumb}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

export default Breadcrumbs;
