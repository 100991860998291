import {
    submitImportantTaskThunkAction,
} from "../../redux/actions/Requests/LeaderRequests/thunk/submitImportantTask.thunk.action";
import {
    validationControlCurrentRequest,
} from "../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    addWorkersToAmmountAction,
} from "../../redux/actions/Requests/RequestsFromLeader/ImportanyTask/addWorkersToAmmount.action";

import { inputValidationInArray } from "../../redux/actions/Requests/currentRequestControls/inputValidationInArray";

import { element } from "prop-types";

export default class ImportantTasksService {
    constructor() {
    }

    static async filterWorkers(currentRequest, dispatch, setWorkersState, workersState) {
        const temp = workersState.filter(element => Boolean(element.pid) === true && Boolean(Number(element.amount)) === true);
        dispatch(addWorkersToAmmountAction(temp));
        setWorkersState(temp);
        const request = {
            ...currentRequest,
            workers: temp,
        };
        return request;
    }

    static save(currentRequest, dispatch, setWorkersState, workersState) {
        // const temp = workersState.filter(element => Boolean(element.pid) === true && Boolean(Number(element.amount)) === true);
        // dispatch(addWorkersToAmmountAction(temp));
        // setWorkersState(temp);
        // if (currentRequest.workers.length !== 0) {
        if (currentRequest.structId !== null && currentRequest.assetId !== null) {
            return true;
        } else {
            if (currentRequest.structId === null) {
                dispatch(validationControlCurrentRequest("structId", false));
            }
            if (currentRequest.assetId === null) {
                dispatch(validationControlCurrentRequest("assetId", false));
            }
            return false;
        }
        // }
    }

    static validation(currentRequest, dispatch) {
        const keysToCheck = ["dateFrom", "dateTo", "description", "goal", "assetId", "structId", "fundingSource"];
        if (currentRequest.fundingSource === 1) {
            keysToCheck.push("functionMarkerId");
        }
        currentRequest.workers &&
        currentRequest.workers.forEach((element, index) => {
            if (!element.pid) {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "pidValid",
                        bool: true,
                        indexArray: index,
                        arrayField: "workers",
                    }),
                );
            }
            if (!element.amount) {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "amountValid",
                        bool: true,
                        indexArray: index,
                        arrayField: "workers",
                    }),
                );
            }
        });
        return (
            this.keyChecker(
                keysToCheck,
                currentRequest,
                dispatch,
            )
        );
    }

    static keyChecker(arrayOfKeys, currentRequest, dispatch) {
        let result = 0;
        arrayOfKeys.forEach((e) => {
            if (
                !Array.isArray(e) &&
                (currentRequest[`${e}`] === null ||
                    currentRequest[`${e}`] === undefined)
            ) {
                result += 1;
                dispatch(validationControlCurrentRequest(e, false));
            }
        });
        return !result;
    }

}
