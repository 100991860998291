export const MATERNITY_VALIDATE_URL = "v1/request/leave/maternity/validate";
export const MATERNITY_SAVE_DRAFT = "v1/request/leave/maternity";
export const MATERNITY_UPDATE_DRAFT = "v1/request/leave/maternity";

export const STUDY_LEAVE_VALIDATE_URL = "v1/request/leave/study/validate";
export const STUDY_LEAVE_SAVE_DRAFT = "v1/request/leave/study";
export const STUDY_LEAVE_UPDATE_DRAFT = "v1/request/leave/study";

export const DISMISSAL_REVOCATION_VALIDATE_URL =
  "v1/request/dismissal/rev/validate";
export const DISMISSAL_REVOCATION_SAVE_DRAFT = "v1/request/dismissal/rev";
export const DISMISSAL_REVOCATION_UPDATE_DRAFT = "v1/request/dismissal/rev";

export const UNPAID_PRACTICE_VALIDATE_URL = "v1/managers/requests/internships/validate";
export const UNPAID_PRACTICE_SAVE_URL = "v1/managers/requests/internships";
export const UNPAID_PRACTICE_UPDATE_URL = "v1/managers/requests/internships";

export const PARENTAL_LEAVE_VALIDATE_URL = "v1/requests/leaves/parental/validate";
export const PARENTAL_LEAVE_SAVE_URL = "v1/requests/leaves/parental";
// export const PARENTAL_LEAVE__UPDATE_URL =
export const IMPORTANT_TASK_VALIDATE_URL = "v1/managers/requests/specialtasks/validate";
export const IMPORTANT_TASK_SAVE_URL = "v1/managers/requests/specialtasks";
export const IMPORTANT_TASK_UPDATE_URL = "v1/managers/requests/specialtasks";
