import { useInputCompositionContext } from "../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";

import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";

registerLocale("ru", ru);

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="input-composition-default-datepicker__datepicker_select" onClick={onClick} ref={ref}>
        {value}
    </div>
));

const CustomDisabledInput = forwardRef(({ value, onClick }, ref) => (
    <div className="input-composition-default-datepicker__datepicker_select">
        {/*{value}*/}
    </div>
));

const ICDatepickerSelect = (
    {
        checkbox,
        changeHandler,
        minDate,
        maxDate,
        field,
    }
) => {

    const [stateModel, setStateModel, isError] = useInputCompositionContext();

    const [checkboxState, setCheckboxState] = useState(false);

    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [startDate, setStartDate] = useState(null);

    function handleCalendarClose () {
        setIsOpenPopup(false);
    }

    function handleCalendarOpen () {
        setIsOpenPopup(true);
    }

    function onChangeCheckboxHandler (event) {
        setCheckboxState(event.target.checked);
        if (event.target.checked) {
            setStartDate(null);
            setStateModel(prev => {
                if (prev[field]) {
                    const temp = prev;
                    delete temp[field];
                    return temp;
                } else {
                    return prev;
                }
            });
        }
    }

    // console.log(" STATE: ", field, ": ", stateModel["to"]);
    // console.log(" MAX DATE: ", field, ": ", maxDate);

    // console.log(" MIN DATE: ", field, ": ", minDate);

    return (
        <div style={
            isError &&
            isError[field]
                ? {display: "flex", gap: "10px", borderBottom: "1px solid red", maxWidth: "290px", position: "relative"}
                : {display: "flex", gap: "10px", borderBottom: "1px solid #EEEEEE", maxWidth: "290px", position: "relative"}}>
            <span className={
                isOpenPopup
                    ? "input-composition-default__arrow-up-datepicker"
                    : "input-composition-default-datepicker__arrow-down-datepicker"
            }
            ></span>
            <DatePicker
                minDate={minDate ? new Date(minDate) : null}
                maxDate={maxDate && stateModel["to"] ? new Date(maxDate) : null}
                selected={startDate}
                locale={"ru"}
                dateFormat="dd.MM.yyyy"
                onChange={(date) => {
                    changeHandler(false, false, date);
                    setStartDate(date);
                }}
                customInput={
                    checkbox && checkboxState
                        ? <CustomDisabledInput />
                        : <CustomInput/>
                }
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={handleCalendarOpen}/>
            {
                checkbox
                    ? <input
                        style={
                            {
                                width: "20px",
                                display: "block",
                                position: "absolute",
                                top: "16px",
                                left: "270px",
                            }
                        }
                        type="checkbox"
                        checked={checkboxState}
                        onChange={onChangeCheckboxHandler}
                    />
                    : null
            }
        </div>
    );
};

export default ICDatepickerSelect;