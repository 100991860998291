import { resetPasswordWrongAction } from "../resetPasswordAction";
import { userSuccessRegisterAction } from "../userRegisterAction";
import { setStateToResetPasswordAction } from "../resetStatetePasswordAction";
import { $api } from "../../../services/request.config";

export const resetPasswordThunkAction =
    (input, resetPassword) => async (dispatch) => {
        try {
            // const response = await axios.post(`${process.env.API_PROD_URL}/v1.0/auth/verify/socNumber `,{socNumber:input.id})
            const response = await $api.post("/v1.0/auth/verify/socNumber", {
                socNumber: input.id,
            });
            // const response = await axios.post('https://po-test.alrosa.ru/api/v1.0/auth/verify/socNumber ',{socNumber:input.id})

            // console.log(response)
            // switch (response.data.succeeded) {
            //     case 200:
            if (response) {
                localStorage.setItem("userId", response.data.userId);
                localStorage.setItem("resetPassword", resetPassword);
                if (localStorage.getItem("resetPassword")) {
                    dispatch(setStateToResetPasswordAction());
                    dispatch(userSuccessRegisterAction(response.data));
                }
                //
            }
            // default: return response.status
            // }
            // dispatch(resetPasswordAction(response))
        } catch (e) {
            // console.log(e.response)
            dispatch(resetPasswordWrongAction(e.response.data));
        }
    };
//046-534-693 08
