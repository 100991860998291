import { $api } from "../../../../services/request.config";
import { getListOfChildrenAction } from "../getListOfChildren.action";

export const getListOfChildrenThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/requests/leaves/parental/children");
        const mapedArray = response.data.map((el) => {
            return {
                ...el,
                name: `${el.lastName ? el.lastName : ""} ${el.firstName ? el.firstName : ""}`,
                id: el.externalId,
            };
        });
        const temp = [...mapedArray, { name: "Информация о ребенке ранее не предоставлялась в ОЦО" }];
        dispatch(getListOfChildrenAction(temp));
    } catch (error) {
        throw new Error(error.response);
    }
};
