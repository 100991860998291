import { getAdditionalInformationAction } from "../getAdditionalInformationAction";
import $api from "../../../../../services/axiosService";

export const getAdditionalInformationThunkAction = () => async (dispatch) => {
    try {
        const isFinanciallyResponsible = await $api("v1/u/p/frps");
        const responsible = {
            type: "Материально ответственное лицо",
            description:
                isFinanciallyResponsible &&
                isFinanciallyResponsible.data &&
                isFinanciallyResponsible.data.isFinanciallyResponsible
                    ? "Да"
                    : "Нет",
        };

        const workStatus = await $api("v1/u/c/work/status"); // статус работника
        const status = {
            type: "Статус работника (пенсионер, иностранный работник)",
            description:
                (workStatus.data.foreigner && "Иностранный работник") ||
                (workStatus.data.pensioner && "Пенсионер") ||
                ((!workStatus.data.foreigner || !workStatus.data.pensioner) &&
                    "Отсутствует"),
        };
        const workCharacter = await $api("v1/u/p/work/type");
        const characters = {
            type: "Характер работы (вахтовый метод, разъездной характер, работа в полевых условиях)",
            description:
                workCharacter.data.isFieldWork === false &&
                workCharacter.data.isItinerantWork === false &&
                workCharacter.data.isShiftWork === false
                    ? "Отсутствует"
                    : `${
                        workCharacter.data.isFieldWork === true
                            ? "Работа в полевых условиях"
                            : ""
                    } ${
                        workCharacter.data.isItinerantWork === true
                            ? "Разъездной характер работы"
                            : ""
                    } ${
                        workCharacter.data.isShiftWork === true
                            ? " Вахтовый метод"
                            : ""
                    }`,
        };
        const typeWork = await $api("v1/u/p/app/type");
        const types = {
            type: "Специальные типы должности (дефицитная профессия, подземные работы)",
            description:
                typeWork.data.length === 0
                    ? "Отсутствует"
                    : typeWork.data.split(","),
        };

        dispatch(
            getAdditionalInformationAction([
                responsible,
                status,
                characters,
                types,
            ]),
        );
    } catch (error) {
        // throw new Error(error.response);
    }
};
