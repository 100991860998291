import {
    getAdditionalInformationThunkAction,
} from "../../../../../redux/actions/User/profile/ServiceData/thunk/getAdditionalInformationThunkAction";
import BasicLine from "../../../../common/BasicLine/BasicLine";

import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

const AdditionalInformation = () => {
    const dispatch = useDispatch();

    const additionalInformation = useSelector(state => state.personalData.additionalInformation);
    const [additional,setAdditional]=useState(additionalInformation);

    useEffect(()=>{
        dispatch(getAdditionalInformationThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        setAdditional(additionalInformation);
    },[additionalInformation]);
    return (
        <div className='fio'>
            {additional&&additional.map((el,index)=> {
                return (
                    <div
                        key={index}
                    >
                        <BasicLine
                            type={el.type}
                            description = {el.description}
                            styleParent={"fio"}
                        />
                    </div>
                );})}
        </div>
    );
};

export default AdditionalInformation;
