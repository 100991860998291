import triangle from "../../../../assets/documents/triangle.svg";
import { isTerminal } from "../../../../constants";

export const referencesData = [
    {
        id:"head-1",
        title:"тип",
        icon:triangle,
        width:isTerminal ? "111px" : "66px",
    },
    {
        id:"head-2",
        title:"номер заявки",
        icon:"",
        width:isTerminal ? "137px" : "163px",
    },
    {
        id:"head-3",
        title:"наименование",
        icon:"",
        width:isTerminal ? "237px" : "391px",
    },
    {
        id:"head-4",
        title:"статус",
        icon:"",
        width:isTerminal ? "211px" : "150px",
    },
    {
        id:"head-5",
        title:"файл",
        icon:"",
        width:isTerminal ? "191px" : "218px",
    },

];
