export const breadcrumbsRoutesTerminal = [
    //MAIN_PAGE
    // {}
    { path: "/", breadcrumb: "" },
    { path: "/main", breadcrumb: "Главная" },
    { path: "/profile", breadcrumb: "Профиль" },
    { path: "/knowledge-base", breadcrumb: "База знаний" },
    { path: "/help", breadcrumb: "Помощь" },
    { path: "/divisions", breadcrumb: "Мое поздразделение" },

    //HR_SERVICES
    { path: "/main/hr-services", breadcrumb: "Кадровый сервис" },
    { path: "/main/hr-services/work-time", breadcrumb: "Рабочее время" },
    { path: "/main/hr-services/layoff", breadcrumb: "Увольнение" },
    {
        path: "/main/hr-services/work-time/requests",
        breadcrumb: "Заявки по рабочему времени",
    },
    {
        path: "/main/hr-services/layoff/request",
        breadcrumb: "Заявка на увольнение",
    },
    {
        path: "/main/hr-services/work-time/holidays",
        breadcrumb: "Отпуска и дни отдыха",
    },
    {
        path: "/main/hr-services/work-time/work-schedule",
        breadcrumb: "График работы",
    },
    {
        path: "/main/hr-services/reward",
        breadcrumb: "Вознаграждения",
    },
    {
        path: "/main/hr-services/reward/payroll",
        breadcrumb: "Расчетный лист",
    },
    //MAIN_REFERENCES
    {
        path: "/main/references",
        breadcrumb: "Заказ справок",
    },
    {
        path: "/main/references/salary",
        breadcrumb: "Заказ справок о заработной плате",
    },
    //MAIN__USEFUL-LINKS->
    {
        path: "/main/useful-links",
        breadcrumb: "Полезные ссылки",
    },
    //PROFILE
    { path: "/profile/personal-data", breadcrumb: "Персональные данные" },
    { path: "/profile/service-data", breadcrumb: "Служебные данные" },
    { path: "/profile/personal-documents", breadcrumb: "Документы" },
    {
        path: "/profile/evaluation-information",
        breadcrumb: "Информация об оценке",
    },
    { path: "/profile/eds-release", breadcrumb: "Электронная подпись" },
    {
        path: "profile/eds-release/unep",
        breadcrumb: "Усиленная неквалифицированная электронная подпись",
    },
    { path: "profile/eds-release/unep/release", breadcrumb: "Получить УНЭП" },
];

