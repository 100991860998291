import $api from "../../../../services/axiosService";
import {getAllUserReferencesAction} from "../getAllUserReferences.action";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";

export const getAllUserReferencesThunkAction = (props) => async (dispatch) => {
    try {
        const response = await $api("v1/itsm/hr/get/all");
        if (response.status === 200) {
            const data = await RequestServices.getHrReferencesWithFile(response.data.data);
            return dispatch (getAllUserReferencesAction(data));
        }
    } catch (e) {
        props.setErrDescription("Что-то пошло не так, повторите попытку позже.");
        props.setErrorModal(true);
        // console.log('USER_REFERENCES_ERROR: ', e.response)
    }
};
