import {$api} from "../../../../services/request.config";
import {getRescheduleDaysAction} from "../getRescheduleDays.action";

export const getRescheduleDaysThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/leaverequest/restdays/rescheduling");
        dispatch(getRescheduleDaysAction(response.data));
    } catch (error) {
        // console.log("ERROR WHILE GETTING DAYS FOR RESCHEDULE: ", error.response)
    }
};
