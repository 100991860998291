import {
    repeadSmsCodeForSignRequestThunkAction,
} from "../../../../../redux/actions/Requests/Layoff/thunk/repeadSmsCodeForSignRequest.thunk.action";

import React, { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import { useDispatch, useSelector } from "react-redux";

import "./ConfirmSignModal.scss";

const ConfirmSignModal = (
    {
        styleParent,
        input,
        setInput,
        errorMessage,
        setErrorMessage,
    }
) => {
    const dispatch = useDispatch();
    const createSignRequest = useSelector(
        (state) => state.requests.createSignRequest,
    );
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const [active, setActive] = useState(false);
    const [counter, setCounter] = useState(60);

    function repeatSmsCode() {
        dispatch(
            repeadSmsCodeForSignRequestThunkAction(
                currentRequest.validatedData.uid,
            ),
        );
    }

    function counterHandler() {
        setCounter(60);
        setActive(false);
        setInput("");
        repeatSmsCode();
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (counter) {
                setCounter(counter - 1);
            }
        }, 1020);

        if (counter <= 0) {
            setActive(true);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [counter, setActive, setCounter]);

    return (
        <div className={`${styleParent}`}>
            {!errorMessage ? (
                <div className={`${styleParent}__message`}>
                    <p>Введите код из СМС отправленный на номер</p>
                    <p className={`${styleParent}__bold`}>
                        +7 (***) ***{" "}
                        {
                            createSignRequest &&
                            createSignRequest.phoneLastNumbers
                        }
                    </p>
                </div>
            ) : (
                <label className={`${styleParent}__label-invalid`}>
                    {errorMessage}
                </label>
            )}
            <div onClick={() => setErrorMessage("")}>
                <VerificationInput
                    value={input}
                    onChange={setInput}
                    autoFocus={true}
                    placeholder={""}
                    removeDefaultStyles
                    classNames={{
                        container: "character__container",
                        character: !errorMessage
                            ? "character"
                            : "character-invalid",
                        characterInactive: "character--inactive",
                        characterSelected: "character--selected",
                    }}
                />
            </div>
            <div className={`${styleParent}__refresh`}>
                <p>
                    Повторная отправка СМС через
                    {counter >= 0 ? (
                        <>
                            <span className={`${styleParent}__support`}>
                                {" "}
                                {counter}{" "}
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="colored-text"> 0 </span>
                        </>
                    )}
                    секунд
                </p>
                <span
                    onClick={active ? counterHandler : null}
                    className={active ? "blueText" : "colored-text"}
                >
                    Отправить повторно
                </span>
            </div>
        </div>
    );
};

export default ConfirmSignModal;
