import { isTerminal } from "../../../../constants";
import UButton from "../UButton/UButton";

import React from "react";
import {useNavigate} from "react-router-dom";

isTerminal ? import("./BackButtonTerminal.scss") : import("./BackButton.scss");

const BackButton = (props) => {
    const navigate = useNavigate();

    function backHandler(e) {
        e.preventDefault();
        navigate(props.route);
    }

    return (
        <>
            <UButton onClick={backHandler} className="ui-back-button">
                назад
            </UButton>
        </>

    );
};

export default BackButton;