

export const links = [
    {
        id: 1,
        path: "?section=lastYear",
        title: "Прошлый год",
        search: "lastYear",
        // component: <HolidaysTableYear
        //     tableStyle={'hp-table-year'}
        //     section={'lastYear'}/>
    },
    {
        id: 2,
        path: "?section=currentYear",
        title: "Текущий год",
        search: "currentYear",
        // component: <HolidaysTableYear
        //     tableStyle={'hp-table-year'}
        //     section={'currentYear'}/>
    },
    {
        id: 3,
        path: "?section=currentWeek",
        title: "Текущая неделя",
        search: "currentWeek",
        // component: <HolidaysTableWeek
        //     tableStyle={"hp-table-week"}/>
    },
    {
        id: 4,
        path: "?section=firstQuart",
        title: "I квартал",
        search: "firstQuart",
        // component: <HolidaysTableQuart
        //     tableStyle={"hp-table-quart"}
        //     section={'firstQuart'}/>
    },
    {
        id: 5,
        path: "?section=secondQuart",
        title: "II квартал",
        search: "secondQuart",
        // component: <HolidaysTableQuart
        //     tableStyle={"hp-table-quart"}
        //     section={'secondQuart'}/>
    },
    {
        id: 6,
        path: "?section=thirdQuart",
        title: "III квартал",
        search: "thirdQuart",
        // component: <HolidaysTableQuart
        //     tableStyle={"hp-table-quart"}
        //     section={'thirdQuart'}/>
    },
    {
        id: 7,
        path: "?section=fourthQuart",
        title: "IV квартал",
        search: "fourthQuart",
        // component: <HolidaysTableQuart
        //     tableStyle={"hp-table-quart"}
        //     section={'fourthQuart'}/>
    },
];

export const representationLinks = [
    {
        id: "1rep",
        // path: 'repres=Gant',
        path: "section=currentYear&repres=Gant",
        title: "Диаграмма Ганта",
        search: "Gant",
    },
    {
        id: "2rep",
        // path: 'repres=split',
        path: "section=directEmployees&repres=split",
        title: "Пересечения отпусков",
        search: "split",
    },
];

export const workerLinks = [
    {
        id: 1,
        path: "?section=directEmployees",
        title: "Непосредственные подчиненные",
        search: "directEmployees",
    },
    // {
    //     id: 2,
    //     path: '?section=employees',
    //     title: 'Все сотрудники',
    //     search: 'employees'
    // },
];