import triangle from "../../../../assets/documents/triangle.svg";
import { isTerminal } from "../../../../constants"; 

export const emploumentData = [

    {
        id:"hr-head-1",
        title:"тип",
        icon:triangle,
        width:isTerminal ? "111px" : "113px",
    },
    {
        id:"hr-head-2",
        title:"наименование",
        icon:"",
        width:isTerminal ? "230px" : "396px",
    },
    {
        id:"hr-head-3",
        title:"ознакомиться до",
        icon:"",
        width: isTerminal ? "210px" : "181px",
    },
    {
        id:"hr-head-4",
        title:"",
        icon:"",
        width:isTerminal ? "0" : "154px",
    },
    {
        id:"hr-head-5",
        title:"документ",
        icon:"",
        width: isTerminal ? "191px" : "192px",
    },
    {
        id:"hr-head-6",
        title:"статус",
        icon:"",
        width:isTerminal ? "138px" : "160px",
    },

];
