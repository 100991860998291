import { $api } from "../../../../services/request.config";
import { getEducationalProgramsAction } from "../getEducationalPrograms.action";

export const getEducationalProgramsThunkAction = () => async (dispatch) => {
    try {
        const response = await $api(
            "v1/request/leave/study/educational-programs",
        );
        dispatch(getEducationalProgramsAction(response.data));
    } catch (error) {
        throw new Error(error.data);
    }
};
