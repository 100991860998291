import { data } from "./profile.data";

import {
    getFioInformationThunkAction,
} from "../../../redux/actions/User/profile/GeneralData/thunk/getFioInformationThunkAction";

import MultiRoute from "../../common/MultiRoute/MultiRoute";
import {
    getAdditionalInformationThunkAction,
} from "../../../redux/actions/User/profile/ServiceData/thunk/getAdditionalInformationThunkAction";
import {
    getDocumentsInformationThunkAction,
} from "../../../redux/actions/User/profile/GeneralData/thunk/getDocumentsInformationThunkAction";
import {
    getExpirienceInformationThunkAction,
} from "../../../redux/actions/User/profile/ServiceData/thunk/getExpirienceInformationThunkAction";
import {
    getGeneralDataThunkAction,
} from "../../../redux/actions/User/profile/ServiceData/thunk/getGeneralDataThunkAction";
import {
    getRelativesInformationThunkAction,
} from "../../../redux/actions/User/profile/GeneralData/thunk/getRelativesInformationThunkAction";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import { isTerminal } from "../../../constants";

import { useDispatch } from "react-redux";
import React, { useEffect } from "react";

const style = isTerminal ? {height: "100%"} : { marginTop: "30px", minHeight: "40vh" }

const Profile = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFioInformationThunkAction());
        dispatch(getAdditionalInformationThunkAction());
        dispatch(getDocumentsInformationThunkAction());
        dispatch(getExpirienceInformationThunkAction());
        dispatch(getGeneralDataThunkAction());
        dispatch(getRelativesInformationThunkAction());
    }
    , []);

    return (
        <>
            <Header />
            <div style={style}>
                <MultiRoute
                    // subHeader={"О работнике"}
                    data={data}
                    styleParent={"hr-services"}
                />
            </div>
            <Footer />
        </>
    );
};

export default Profile;
