
class FrontendFormValidationService {
    constructor(state, isError, setIsError, rules) {
        this.state = state;
        this.isError = isError;
        this.setIsError = setIsError;
        this.rules = rules;
    }
    validate () {
        let isValid = true;
        for (const rulesKey in this.rules) {
            // eslint-disable-next-line no-loop-func
            this.rules[rulesKey].forEach(rulesKeyword => {
                switch (rulesKeyword) {
                case "notEmpty":
                    if (this.isEmpty(rulesKey)) {
                        return true;
                    } else {
                        isValid = false;
                        break;
                    }
                case "earlierThan":
                    return true;
                default: return true;
                }
            });
        }
        return isValid;
    }
    isEmpty (key, message) {
        if (this.state[key]) {
            return true;
        } else {
            this.setIsError(prev => {
                return {
                    ...prev,
                    [key]: message ? message : "Поле не должно быть пустым",
                };
            });
            return false;
        }
    }
    earlierThan (key, compareKey) {
        return true;
    }
}

export default FrontendFormValidationService;