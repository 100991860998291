import { isTerminal } from "../../../../constants";

import UButton from "../UButton/UButton";

import React from "react";

isTerminal ? import("./OKButtonTerminal.scss") : import("./OKButton.scss");

const OkButton = (
    {
        handler,
        onValidation,
        title,
    },
) => {
    return (
        <>
            <UButton
                disabled={onValidation}
                loading={onValidation}
                onClick={handler}
            >{title}
            </UButton>
        </>
    );
};

export default OkButton;