import { $api } from "../../../../services/request.config";
import { getFunctionMarkerAction } from "../getFunctionMarker.action";

export const getFunctionMarkerThunkAction = () => async (dispatch) => {
    try {
        // const response = await $api("v1/managers/requests/specialtasks/function-markers");
        // console.log("RESPONSE: ", response.data);
        const functionMarker = [
            {
                id: 1,
                name: "ФЭБ",
            },
            {
                id: 2,
                name: "УП",
            },
            {
                id: 3,
                name: "ТОиР",
            },
            {
                id: 4,
                name: "Безопасность",
            },
        ];
        dispatch(getFunctionMarkerAction(functionMarker));

    } catch (error) {
        // eslint-disable-next-line
        console.log("ERROR WHILE GETTING FUNCTION MARKER: ", error.response);
    }
};