import NDFL from "./Requests/NDFL/NDFL";

import Header from "../../../layout/Header/Header";
import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import DropdownMenu from "../../../common/Menu/DropdownMenu/DropdownMenu";
import Footer from "../../../layout/Footer/Footer";
import EmptyBody from "../../../common/EmptyBody/EmptyBody";
import NDFLApplication from "../../../../frontend.classes/References/NDFL.application";

import { isTerminal } from "../../../../constants";
import { ScrollButton } from "../../../common/componentsTerminal/ScrollButton/ScrollButton";

import { sendReferenceThunkAction } from "../../../../redux/actions/References/Salary/sendReference.thunk.action";

import ModalBanOverlay from "../../../common/Modal/ModalBanOverlay/ModalBanOverlay";

import ErrorModal from "../../../common/Modal/ModalConditions/Profile/ErrorModal/ErrorModal";
import RequestVerification
    from "../../../common/Modal/ModalConditions/Requests/RequestVerification/RequestVerification";

import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./SalaryPage.scss";

const SalaryPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [typesListLoader, setTypesListLoader] = useState(false);
    const [requestOption, setRequestOption] = useState(null);
    const [currentRequest, setCurrentRequest] = useState({
        // data: {
        //     comment: null,
        //     year: null,
        //     stamp: null,
        // },
        typeId: requestOption ? requestOption : null,
    });
    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("");
    const [modalSentStatus, setModalSentStatus] = useState(false);

    function selectOptionClick(event, element, stateProperty) {
        setRequestOption(element.id);
    }

    function backHandler(e) {
        navigate("/main/references");
    }

    function sendHandler() {
        const validatedData = NDFLApplication.sendToValidation(currentRequest, setCurrentRequest);
        if (validatedData === true) {
            setModalSentStatus(true);
            dispatch(sendReferenceThunkAction({
                currentRequest: currentRequest,
                stateLoader: setSendButtonLoading,
                closeModal: setModalSentStatus,
                setErrorModal: setErrorModal,
                setErrDescription: setErrDescription,
            }));
        } else {
            // setCurrentRequest(validatedData.temp);
        }
    }

    return (
        <>
            <Header />
            <Breadcrumbs />
            {isTerminal && <ScrollButton />}
            <DropdownMenu
                optionsLoader={typesListLoader}
                styleName={"types"}
                label={"Справки о заработной плате"}
                initialMenuMessage={"Выберите категорию"}
                listOfOptions={[{ name: "2-НДФЛ", id: 0 }]}
                stateProperty={"leaveTypeId"}
                currentRequest={currentRequest}
                selectOptionClick={selectOptionClick} />
            {
                requestOption === 0 &&
                <NDFL
                    typeId={requestOption}
                    currentRequest={currentRequest}
                    setCurrentRequest={setCurrentRequest}
                />
            }
            {
                requestOption === 0 ?
                    // ? <RequestButtons
                    //     // disabled={validation(currentRequest)}
                    //     sendHandler={sendHandler}
                    //     references={true}
                    //     backHandler={backHandler} />
                    <div className="wt-request-buttons">
                        <button
                            onClick={backHandler}
                            className="wt-request-buttons__default">
                            {isTerminal ? "Назад" : "назад"}
                        </button>
                        {/*<div className="wt-request-buttons__two-buttons">*/}

                        <button
                            onClick={sendHandler}
                            className="wt-request-buttons__default">
                            {isTerminal ? "Направить заявку" : "направить заявку"}
                        </button>
                        {/*</div>*/}
                    </div>
                    : <EmptyBody backHandler={backHandler} />
            }
            <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                <ErrorModal
                    description={errDescription}
                    setError={setErrorModal}
                    route={"/main/references"}
                />
            </ModalBanOverlay>

            <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                <RequestVerification
                    // onValidation={onValidation}
                    // setOnValidation={setOnValidation}
                    // setModalError={setModalError}
                    sendButtonLoading={true}
                    route={"/main/hr-services/work-time"}
                    setModalSentStatus={setModalSentStatus
                    } />
            </ModalBanOverlay>
            <Footer />
        </>
    );
};

export default SalaryPage;
