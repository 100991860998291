import stylesTerminal from "./ForgotPasswordTerminal.module.css";

import { resetPasswordThunkAction } from "../../../../redux/actions/Auth/Thunk/resetPasswordThunkAction";
import { resetStateNullAction } from "../../../../redux/actions/Auth/resetPasswordAction";
import UButton from "../../Button/UButton/UButton";
import { isTerminal } from "../../../../constants";
import { InputTerminal } from "../../componentsTerminal/Input/InputTerminal";
import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";

import { useNavigate } from "react-router-dom";
import React, { useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ForgotPasswordModal.scss";



const ForgotPasswordModal = ({ close }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputSnils = useRef();

    const wrongRequestResetPassword = useSelector(
        (state) => state.wrongRequestResetPassword,
    );
    // const resetPassword = useSelector(state => state.resetPassword);
    const user = useSelector((state) => state.user);

    const [input, setInput] = useState({ id: "" });
    // const [modalTextShown,setModalTextShown] = useState(false);

    const handleChangeSnils = (e) => {
        const cardValue = inputSnils.current?.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        inputSnils.current.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}${`${
                  cardValue[3] ? `-${cardValue[3]}` : ""
              }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ""}`}`;
        const numbers = inputSnils.current?.value.replace(/(\D)/g, "");
        setInput({ id: numbers });
    };

    const submitHandler = useCallback((e)=> {
        e.preventDefault();
        dispatch(resetPasswordThunkAction(input, true));
        if (user) {
            // setModalTextShown(true);
            navigate("/authentication");
        }
    }, [dispatch, user]);


    if (!isTerminal) {
        return (
            <>
                <div className="forgot-modal">
                    <h4
                        className={
                            wrongRequestResetPassword
                                ? "forgot-modal__header-invalid"
                                : "forgot-modal__header"
                        }>
                        {wrongRequestResetPassword
                            ? wrongRequestResetPassword
                            : "Забыли пароль?"}
                    </h4>
                    <input
                        ref={inputSnils}
                        type="tel"
                        maxLength={16}
                        onClick={() => dispatch(resetStateNullAction())}
                        className={
                            wrongRequestResetPassword
                                ? "forgot-modal__input-invalid"
                                : "forgot-modal__input"
                        }
                        onChange={handleChangeSnils}
                        placeholder={"Введите СНИЛС"}
                        name="id"
                    />
                    <div>
                        Мы отправим вам код с восстановлением пароля <br />
                        на указанный ранее телефонный номер
                    </div>
                    <div className="forgot-modal__buttons">
                        <UButton onClick={close}>отмена</UButton>
                        <UButton onClick={submitHandler}>сбросить пароль</UButton>
                    </div>
                </div>
            </>
        );
    }

    if (isTerminal) {
        return (
            <>
                <div className={stylesTerminal.container}>
                    <h4 className={stylesTerminal.text}>
                        {wrongRequestResetPassword
                            ? wrongRequestResetPassword
                            : "Забыли пароль?"}
                        </h4>
                    <InputTerminal
                        name="id"
                        label="Снилс"
                        placeholder="Введите СНИЛС"
                        inputRef={inputSnils}
                        onChange={handleChangeSnils}
                    />
                    <span className={stylesTerminal.text}>
                        Мы отправим вам код с восстановлением пароля<br />
                        на указанный ранее телефонный номер
                    </span>
                    <div className={stylesTerminal.groupButton}>
                        <ButtonTerminal
                            label="Отмена"
                            variant="outlined"
                            onClick={close}
                        />
                        <ButtonTerminal
                            label="Сбросить пароль"
                            onClick={submitHandler}
                        />
                    </div>
                </div>
            </>
        );
    }
};

export default ForgotPasswordModal;
