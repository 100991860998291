import BackButton from "../../../../Button/BackButton/BackButton";

import React from "react";
import "./ErrorModal.scss";


function ErrorModal(props) {
    function closeModal () {
        document.body.style.overflow = "unset";
        props.setError(false);
    }
    return (
        <div className={"error500-unep"}>
            <div>
                {props.description ? props.description :"Что-то пошло не так, повторите попытку позже"}
            </div>
            <div onClick={closeModal}>
                <BackButton
                    route={props.route}
                />
            </div>
        </div>
    );
}

export default ErrorModal;
