export const data = [
    {
        title: "Отпуска и дни отдыха",
        description: "Оформление и информация об отпусках и днях отдыха",
        path: "/main/hr-services/work-time/holidays",
    },
    {
        title: "График работы",
        description: "Оформление и информация о графике работы",
        path: "/main/hr-services/work-time/work-schedule",
    },
    {
        title: "Вахты",
        description: "Оформление и информация о вахтовом графике работы",
        path: "#",
    },
    {
        title: "Другое",
        description: "Оформление и информация иных данных о рабочем времени",
        path: "#",
    },
    {
        title: "Заявки по рабочему времени и отпускам",
        description: "Заявки на отпуск, изменение рабочего времени и графика работы",
        path: "/main/hr-services/work-time/requests",
        // path: "#",
        // secured: true,
    },
    {
        title: "СОУТ",
        description: "Специальные условия оценки труда",
        path: "/main/hr-services/work-time/sout",
    },
];
