export const mode = process.env.REACT_APP_BUILD_MODE;

export const urlOnUse = () => {
    switch (mode) {
    case "PROD":
        return process.env.REACT_APP_API_PROD_URL;
    case "STAGE":
        return process.env.REACT_APP_API_STAGE_URL;
    case "TEST":
        return process.env.REACT_APP_API_URL;
    default: // UNSTABLE
        return process.env.REACT_APP_API_UNSTABLE_URL;
    }
};

export const rawHeaders = {
    "Content-Type" : "application/json",
    "Consumer" : "1",
};