import {$api} from "../../../../../services/request.config";
import {getDelegationsInProgressAction} from "../getDelegationsInProgress.action";

export const getDelegationsInProgressThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v2/manager/delegations");
        dispatch(getDelegationsInProgressAction(response.data));
    } catch (error) {
        // console.log("ERROR WHILE GETTING DELEGATIONS IS PROGRESS: ", error.response);
    }
};