import {unepData} from "./unep.data";

import Header from "../../../layout/Header/Header";

import Footer from "../../../layout/Footer/Footer";
import MultiRoute from "../../../common/MultiRoute/MultiRoute";

import React from "react";


const EDSRelease = () => {
    return (
        <>
            <Header/>
            <MultiRoute
                data={unepData}
                styleParent={"hr-services"}
                breadCrumbs={true}
                subHeader={"электронная подпись"}/>
            <Footer/>
        </>
    );
};

export default EDSRelease;
