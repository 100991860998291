import { $api } from "../../../../services/request.config";
import { getEmployeesLeavesAction } from "../getEmployeesLeaves.action";
import { getEmployeesPendingApplicationsAction } from "../../getEmployeesPendingApplications.action";
import { clearEmployeesLeavesAction } from "../clearEmployeesLeaves.action";
import { getSearchableEmployeesAction } from "../getSearchableEmployees.action";
import { clearSearchableEmployeesAction } from "../clearSearchableEmployees.action";
import { getFilterSelectedPidsAction } from "../getFilterSelectedPids.action";

export const getEmployeesWithApplicationsThunkAction = ({from, to, subordinatePids}) => async (dispatch) => {
    try {
        dispatch(clearSearchableEmployeesAction());
        dispatch(clearEmployeesLeavesAction());
        dispatch(getEmployeesPendingApplicationsAction([]));

        if (subordinatePids && subordinatePids.length) {
            const responseDefault = await $api.post("v1/manager/subordinates/leaves/overlapping/filters", { from, to });
            const responseSub = await $api.post("v1/manager/subordinates/leaves/overlapping/filters", {
                from,
                to,
                subordinatePids,
            });
            if (responseSub.data.leaves) {
                dispatch(getEmployeesLeavesAction(responseSub.data.leaves));
                dispatch(getSearchableEmployeesAction(responseDefault.data.leaves.items));
            }
            if (responseSub.data.requests) {
                dispatch(getEmployeesPendingApplicationsAction({ requests: responseSub.data.requests }));
            }
            dispatch(getFilterSelectedPidsAction(subordinatePids));
        } else {
            const response = await $api.post("v1/manager/subordinates/leaves/overlapping/filters", {from, to});
            if (response.data.leaves) {
                dispatch(getEmployeesLeavesAction(response.data.leaves));
                dispatch(getSearchableEmployeesAction(response.data.leaves.items));
            }
            if (response.data.requests) {
                dispatch(getEmployeesPendingApplicationsAction({ requests: response.data.requests }));
            }
        }

    } catch (error) {
        dispatch(clearSearchableEmployeesAction());
        dispatch(clearEmployeesLeavesAction());
        dispatch(getEmployeesPendingApplicationsAction([]));
        // eslint-disable-next-line no-console
        console.log("ERROR WHILE GETTING EMPLOYEES WITH REQUESTS: ", error);
    }
};