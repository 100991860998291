import moment from "moment";

class ApplicationHelper {
    static periodMapper (periods, length) {
        const map = new Array(length).fill(null).map(e => 0);
        let isClippedAtBegin = false;
        let isClippedAtEnd = false;
        periods.forEach(period => {
            if (period.map) {
                if (period.map.isClippedAtBegin) isClippedAtBegin = true;
                if (period.map.isClippedAtEnd) isClippedAtEnd = true;
                if (period.map.index || period.map.index === 0) {
                    const from = period.map.index;
                    period.map.map.forEach((mapElement, mapElementIndex) => {
                        map.splice(from + mapElementIndex, 1, mapElement);
                    });
                }
            }
        });
        return {map, isClippedAtBegin, isClippedAtEnd};
    }

    static periodToListMapper (periods) {
        const result = [];

        periods.forEach(period => {
            result.push(`
            ${moment(period.from).format("DD.MM.YYYY")} 
            -
            ${moment(period.to).format("DD.MM.YYYY")}
            (${period.days} дней)`);
        });

        return result;
    }

    static isClipped (index, periods, length) {
        let temp = false;
        if (index === 0) {
            periods.forEach(period => {
                if (period.map) {
                    if (period.map.clippedAtBegin) temp = true;
                }
            });
            return temp;
        } else if (index === length - 1) {
            periods.forEach(period => {
                if (period.map) {
                    if (period.map.clippedAtEnd) temp = true;
                }
            });
            return temp;
        } else {
            return false;
        }
    }
}

export default ApplicationHelper;