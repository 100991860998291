import OkButton from "../../../../Button/OKButton/OKButton";
import { isTerminal } from "../../../../../../constants";

import {useNavigate} from "react-router-dom";

isTerminal ? import("./SaveSuccessModalTerminal.scss") : import("./SaveSuccessModal.scss");

const SaveSuccessModal = (props) => {

    const navigate = useNavigate();

    function buttonHandler(e) {
        props.close(false);
        navigate(props.route);
    }

    return (
        <div className="save-success-modal">
            <div className="save-success-modal__content">
                <div className="save-success-modal__head">
                    данные сохранены!
                </div>
                <div className="save-success-modal__description">
                    Можете продолжить заполнение заявки или
                    вернуться к ее заполнению позднее в разделе «Документы»
                </div>
            </div>
            <div
                className="save-success-modal__button">
                <OkButton handler={buttonHandler} title={"ок"}/>
            </div>
        </div>
    );
};

export default SaveSuccessModal;