import ModalSupport from "../../common/Auth/ModalSupport/ModalSupport";
import {Modal} from "../../common/Modal/Modal";
import {userRegisterThunkAction} from "../../../redux/actions/Auth/Thunk/userRegisterThunkAction";
import RegForm from "../../common/Auth/RegForm/RegForm";
import { isTerminal } from "../../../constants";

import {useNavigate} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import "../LoginPage/LoginPage.scss";
import "./RegPageTerminal.scss";

const RegisterPage = () => {
    const inputSnils = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [input, setInput] = useState({socNumber:"",password:"",confirmPassword:""});
    const [validPassword, setValidPassword] = useState(true);
    const [invalidLength, setInvalidLength] = useState(false);
    const [validSnils, setvalidSnils] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownRepeat, setPasswordShownRepeat] = useState(false);
    const [checked, setChecked] = useState(false);
    // const [modalShownSudgestions, toggleModalSudgestions] = useState(false);//modalSudgestens
    const [modalShownSupport, toggleModalSupport] = useState(false);//modalSupport
    const [openEye, setOpenEye] = useState(false);
    const [openEyeRepeat, setOpenEyeRepeat] = useState(false);
    const user = useSelector(state => state.user);

    const togglePasswordVisiblity = () => {
        setOpenEye(!openEye);
        setPasswordShown(!passwordShown);
    };
    const togglePasswordVisiblityRepeat = () => {
        setOpenEyeRepeat(!openEyeRepeat);
        setPasswordShownRepeat(!passwordShownRepeat);
    };


    const checkSnils = (e) =>{
        const validSnils = inputSnils.current.value
            .match(/^\d{3}-?\d{3}-?\d{3}-? ?\d{2}$/gm);
        if(!validSnils  &&  inputSnils.current?.value.length !== 11){
            setvalidSnils(false);
        }

    };

    const checkValidPassword = (e) =>{
        if(input.password !== input.confirmPassword ){
            setValidPassword(false);
        }
    };


    const checkPassword = (e) =>{
        if(input.password?.length < 8)  {
            setInvalidLength(true);
        }
    };

    const inputHandler = (e) =>{
        setInput(prev => ( {...prev, [e.target.name]: e.target.value}));
    };
    const handleChangeSnils = (e) => {
        const cardValue = inputSnils.current.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        inputSnils.current.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ""
            }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ""}`}`;
        const numbers = inputSnils.current?.value.replace(/(\D)/g, "");
        setInput(prev => ( {...prev, [e.target.name]: numbers}));

    };
    const submitHandler = (e)=>{
        e.preventDefault();
        setValidPassword(true);
        setInvalidLength(false);
        setvalidSnils(true);

        if(input.socNumber.length === 11 && input.password.length>7 && input.password === input.confirmPassword ){
            dispatch(userRegisterThunkAction(input));//санка реги на токен
            // setInput({socNumber:'',password:'',confirmPassword:''})
            // navigate('/authentication')
            if(user){
                navigate("/authentication");
            }
        } else{
            checkSnils(e);
            checkValidPassword(e);
            checkPassword(e);
        }
    };

    function clickHandlerSupport(e) {
        toggleModalSupport(!modalShownSupport);
    }
    function closeHandlerSupport(e){
        toggleModalSupport(false);
    }

    useEffect(()=>{
        if(user){
            navigate("/authentication");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (

        <div className={isTerminal ? "login-container-terminal" : "login-container"}>
            <div className={isTerminal ? "login-wrapper-terminal" : "login-wrapper"}>
                <RegForm
                    validPassword={validPassword}
                    setValidPassword={setValidPassword}
                    invalidLength={invalidLength}
                    setInvalidLength={setInvalidLength}
                    validSnils={validSnils}
                    setvalidSnils={setvalidSnils}
                    input={input}
                    setInput={setInput}
                    inputHandler={inputHandler}
                    passwordShown={passwordShown}
                    setPasswordShown={setPasswordShown}
                    togglePasswordVisiblity={togglePasswordVisiblity}
                    passwordShownRepeat={passwordShownRepeat}
                    setPasswordShownRepeat={setPasswordShownRepeat}
                    togglePasswordVisiblityRepeat={togglePasswordVisiblityRepeat}
                    checked={checked}
                    setChecked={setChecked}
                    checkSnils={checkSnils}
                    checkValidPassword={checkValidPassword}
                    checkPassword={checkPassword}
                    inputSnils={inputSnils}
                    handleChangeSnils={handleChangeSnils}
                    submitHandler={submitHandler}
                    clickHandlerSupport={clickHandlerSupport}
                    openEye={openEye}
                    openEyeRepeat={openEyeRepeat}
                />
            </div>
            { !isTerminal && <div className={"login-img-auth"}></div> }
            { isTerminal && <img style={{height: "100vh"}} src={require("../../../assets/authPhoto/photo.png")} alt="" /> }
            <Modal
                showCloseButton={!isTerminal}
                shown={modalShownSupport}
                close={closeHandlerSupport}
            >
                <ModalSupport close = {closeHandlerSupport}/>
            </Modal>
        </div>

    );
};

export default RegisterPage;
