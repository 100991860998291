import { isTerminal } from "../../../../constants";
import MultiWidget from "../../MultiWidget/MultiWidget";
import {userWidgetsThunkAction} from "../../../../redux/actions/User/profile/Widgets/Thunk/userWidgetsThunkAction";

import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

isTerminal ? import("./InformationWidgetsPartTerminal.scss") : import("./InformationWidgetsPart.scss");


const InformationWidgetsPart = (
    {
        handleClick,
        clickHandlerWidgets,
    }
) => {

    const dispatch = useDispatch();

    const widgets = useSelector((state) => state.personalData.widgets);

    const [fio]= useState(JSON.parse(localStorage.getItem("fio")));
    const [appointment] = useState(localStorage.getItem("appointment"));

    useEffect(()=>{
        dispatch(userWidgetsThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return (
        <div className='information-part'>
            <div className='information-part__name'>
                { fio ?
                    <span className='information-part__name__fio'>
                        {
                            fio.lastNameRu
                        }
                        <br/>
                        {
                            fio.firstNameRu
                        }
                        {" "}
                        {
                            fio.secondNameRu
                        }
                    </span>
                    :
                    <span style={{minWidth:"343px"}}>
                        <br/>
                    </span>

                }
                {
                    appointment
                        ? <p className='information-part__name__appointment'>
                            {appointment}
                        </p>
                        : <p >

                        </p>
                }

            </div>
            {
                widgets &&  <MultiWidget
                    data={widgets}
                    styleParent = {"information-part__widgets"}/>
            }
        </div>
    );
};

export default memo(InformationWidgetsPart);
