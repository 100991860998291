
export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";
export const  SET_DOCUMENTS_TYPES = "SET_DOCUMENTS_TYPES";
export const IS_WORKER_SUBSTITUTE = "IS_WORKER_SUBSTITUTE";
export const GET_REASONS_FOR_REJECT = "GET_REASONS_FOR_REJECT";
export const DROPDOWN_SELECT = "DROPDOWN_SELECT";
// export const SET_EMPLOYMENT_DOCUMENTS = 'SET_EMPLOYMENT_DOCUMENTS';
// export const SET_REFERENCES_DOCUMENTS = 'SET_REFERENCES_DOCUMENTS';
// export const SET_MY_APPLICATION = 'SET_MY_APPLICATION';
// export const SET_APPROVALS = 'SET_APPROVALS'
