export const resetPasswordWrongAction = (data) =>{
    return{
        type: "BAD_REQUEST",
        payload:data,
    };
};
export const resetStateNullAction = ()=>{
    return{
        type:"RETURN_TO_NULL",
        payload:null,
    };
};

