import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import plural from "plural-ru";

import "./LogoutModalWindowTerminal.scss";

export const LogoutModalWindowTerminal = ({
    open,
    timeout,
    handleStillHere,
    getRemainingTime,
    start,
    pause,
}) => {
    const [remaining, setRemaining] = useState(timeout);
    
    const { pathname } = useLocation();

    useEffect(() => {
        pathname === "/start" || pathname === "/" ? pause() : start();
    }, [pathname]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime, remaining]);

    return open && (
        <div className="warning-container">
            <div className="warning">
                <p>
                    Сеанс закончится через {remaining} {plural(remaining, "секунду", "секунды", "секунд")}.
                    <br />
                    Хотите продолжить?
                </p>
                <button className="warning__button" onClick={handleStillHere}>Продолжить</button>
            </div>
        </div>
    );
};