
// function onRemoved(cookie) {
//     // console.log(`Removed: ${cookie}`);
// }
//
// function onError(error) {
//     // console.log(`Error removing cookie: ${error}`);
// }

function deleteCookies() {
    const Cookies = document.cookie.split(";");
    for (let i = 0; i < Cookies.length; i++) {
        document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
    }
    return true;
}
export const removeCookiesThunkAction = () => async(dispatch) => {
    try {
        deleteCookies();
    } catch (e) {

    }
};
