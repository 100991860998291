import BasicApplication from "./Basic.application";
// import ExtraVacationDaysApplication from "./ExtraVacationDays.application";
//
// import {MATERNITY_VALIDATE_URL} from "../../redux/enums/Request/applicationUrls";

class MaternityLeaveApplication extends BasicApplication {
    // #validateUrl
    constructor(options) {
        super(options);
        this.leaveReasonId ="";
        this.disabilityFrom = options.disabilityFrom;
        this.disabilityTo = options.disabilityTo;
        this.disabilityDocumentName = options.disabilityDocumentName;
        this.disabilityDocumentId = options.disabilityDocumentId;
        this.dateFrom = options.dateFrom;
        this.dateTo = options.dateTo;
        this.isValid = {
            disabilityFrom:true,
            disabilityTo: true,
            // disabilityDocumentId: true,
            validDate: true,
            dateFrom: true,
            dateTo: true,
            disabilityDocumentName: true,
        };
    }
    //  basePublicStaticMethod() {
    //     return this.#validateUrl
    // }
}

export default MaternityLeaveApplication;
