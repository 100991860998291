import $api from "../../../services/axiosService";
import { setCurrentRequestAction } from "../CurrentRequest/setCurrentRequest.action";
import { clearCurrentRequestAction } from "../CurrentRequest/clearCurrentRequest.action";

export const saveDraftThunkAction =
    ({ requestState, setRequestState }) =>
        async (dispatch) => {
            try {
                const response = await $api.post("v1/leaverequest", requestState);
                if (response.data.isSuccess) {
                    const request = await $api(
                        `v1/leaverequest/${response.data.uid}`,
                    );
                    const { item } = request.data;
                    dispatch(setCurrentRequestAction(item));
                    setRequestState((prev) => {
                        return { ...prev, uid: item.uid };
                    });
                } else {
                    dispatch(clearCurrentRequestAction());
                }
            } catch (error) {}
        };
