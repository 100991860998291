import DropdownMenu from "../../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import {
    getListOfChildrenThunkAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/thunk/getListOfChildren.thunk.action";

import DatePopupSelector from "../../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";

import {
    addInfoOfChildAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/controls/addInfoOfChild.action";

import SingleUploaderNewVersion from "../../../../../../../common/SingleUploaderNewVersion/SingleUploaderNewVersion";
import RequestInputInArray from "../../../../../../../common/Inputs/RequestInputInArray/RequestInputInArray";

import {
    validationControlCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";

import {
    addParentTabNumberAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/controls/addParentTabNumber.action";

import {
    getOrderOfChildThunkAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/thunk/getOrderOfChild.thunk.action";

import {
    getGenderForChildThunkAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/thunk/getGenderForChild.thunk.action";

import {
    calendarDatePickCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import moment from "moment";

const SelectedChild = ({
    index,
    // currentRequest,
    element,
    parentStyle,
    visible,
}) => {

    const dispatch = useDispatch();
    const currentRequest = useSelector((state => state.requests.currentRequest));
    const children = useSelector(state => state.requests.children);
    const order = useSelector(state => state.requests.order);
    const gender = useSelector(state => state.requests.gender);


    function selectDropdown(event, element, stateProperty) {
        if (stateProperty === "child") {
            dispatch(addInfoOfChildAction({
                child: element,
                childIndex: index,
                childField: stateProperty,
            }));
        }
        dispatch(dropdownSelectionCurrentRequest({
            event,
            element,
            stateProperty,
            indexOfArray: index,
            arrField: "children",
        }));
    }


    function inputHandler(input, index, field) {
        const value = input;
        dispatch(addParentTabNumberAction({
            parentInput: value,
            indexOfParent: index,
            inputfield: field,
            isParent: false,
        }));


    }

    function changeDateHandler(field, date) {
        dispatch(validationControlCurrentRequest(field, true));
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(addParentTabNumberAction({
            inputfield: field, date: value,
            isParent: false,
            indexOfParent: index,
            parentInput: value,
        }));

        dispatch(validationControlCurrentRequest("dateTo", true));
        dispatch(calendarDatePickCurrentRequest({ field: "dateTo", date: moment(value).add(36, "months") }));
    }

    useEffect(() => {
        dispatch(getListOfChildrenThunkAction());
        dispatch(getOrderOfChildThunkAction());
        dispatch(getGenderForChildThunkAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return visible && (

        <div className={`${parentStyle}-general-information`}>

            <>
                <DropdownMenu
                    currentRequest={currentRequest}
                    styleName={"reason"}
                    label={"Выбор ребенка *"}
                    listOfOptions={children}
                    stateProperty={"child"}
                    //       isValidKey={
                    //           currentRequest &&
                    // currentRequest.children &&
                    // currentRequest.children[index] &&
                    // currentRequest.children[index].name
                    //               ? currentRequest.children[index].name
                    //               : false
                    //       }
                    selectOptionClick={selectDropdown}
                />
                <DatePopupSelector
                    field={"birthDate"}
                    label={"Дата рождения ребенка *"}
                    errorMessage={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].birthDateValid
                            ? "Обязательное поле"
                            : false
                    }
                    changeHandler={changeDateHandler}
                    propsDate={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].birthDate
                            ? new Date(currentRequest.children[index].birthDate)
                            : null
                    }
                    minDate={moment().subtract(36, "months")}
                    maxDate={new Date()}
                />

                <RequestInputInArray
                    uploader={true}
                    value={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].lastName
                    }
                    // ref={inputRef}
                    basicInput={true}
                    handler={inputHandler}
                    maxLength={20}
                    stateProperty={"lastName"}
                    field={"lastName"}
                    index={index}
                    // changeHandler={searchParent}
                    label={"Фамилия ребенка *"}
                    errorMessage={
                        element && !element.lastNameValid
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"unpaid-practice"}
                    letter={20}
                />
                <RequestInputInArray
                    uploader={true}
                    value={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].firstName
                    }
                    // ref={inputRef}
                    basicInput={true}
                    handler={inputHandler}
                    maxLength={15}
                    stateProperty={"firstName"}
                    field={"firstName"}
                    index={index}
                    // changeHandler={searchParent}
                    label={"Имя ребенка *"}
                    errorMessage={
                        element && !element.firstNameValid
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"unpaid-practice"}
                    letter={15}
                />
                <RequestInputInArray
                    uploader={true}
                    value={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].middleName
                    }
                    // ref={inputRef}
                    basicInput={true}
                    handler={inputHandler}
                    maxLength={15}
                    stateProperty={"middleName"}
                    field={"middleName"}
                    index={index}
                    // changeHandler={searchParent}
                    label={"Отчество ребенка "}
                    errorMessage={
                        element && !element.middleNameValid
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"unpaid-practice"}
                    letter={15}
                />


                <DropdownMenu
                    currentRequest={currentRequest && currentRequest.children && currentRequest.children[index]}
                    styleName={"reason"}
                    label={"Пол ребенка *"}
                    listOfOptions={gender}
                    stateProperty={"genderId"}
                    isValidKey={
                        element && element.genderIdValid
                    }
                    selectOptionClick={selectDropdown}
                />


                <RequestInputInArray
                    uploader={true}
                    value={currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].birthCertificateNumber}
                    // ref={inputRef}
                    basicInput={true}
                    handler={inputHandler}
                    maxLength={300}
                    stateProperty={"birthCertificateNumber"}
                    field={"birthCertificateNumber"}
                    index={index}
                    // changeHandler={searchParent}
                    label={"Серия, номер свидетельства о рождении  *"}
                    errorMessage={
                        element && !element.birthCertificateNumberValid
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"unpaid-practice"}
                    letter={300}
                />


                <DatePopupSelector
                    field={"birthCertificateDate"}
                    label={"Дата выдачи свидетельства о рождении *"}
                    errorMessage={
                        element && !element.birthCertificateDateValid
                            ? ""
                            : "Обязательное поле"
                    }
                    changeHandler={changeDateHandler}
                    propsDate={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].birthCertificateDate
                            ? new Date(currentRequest.children[index].birthCertificateDate)
                            : null
                    }
                    minDate={currentRequest &&
          currentRequest.children &&
          currentRequest.children[index] &&
          currentRequest.children[index].birthDate
                        ? new Date(currentRequest.children[index].birthDate)
                        : new Date()}
                    maxDate={new Date()}
                />
                <SingleUploaderNewVersion
                    label={"Копия свидетельства о рождении ребенка, на которого требуется назначить пособие *"}
                    index={index}
                    i={0}
                    subTypeNumber={6}
                    field={"children"}
                    errorMessage={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].attachments &&
            currentRequest.children[index].attachments[0] &&
            currentRequest.children[index].attachments[0].attachValidation
                            ? "Пожалуйста, прикрепите документ"
                            : ""
                    }
                />
                <RequestInputInArray
                    uploader={true}
                    value={
                        currentRequest &&
            currentRequest.children &&
            currentRequest.children[index] &&
            currentRequest.children[index].snils
                    }
                    // ref={inputRef}
                    basicInput={true}
                    handler={inputHandler}
                    maxLength={11}
                    stateProperty={"snils"}
                    field={"snils"}
                    index={index}
                    // changeHandler={inputHandler}
                    label={"СНИЛС ребенка *"}
                    errorMessage={
                        element && !element.snilsValid
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"unpaid-practice"}
                    letter={11}
                />
                {/*}*/}
                <DropdownMenu
                    currentRequest={currentRequest && currentRequest.children && currentRequest.children[index]}
                    styleName={"reason"}
                    label={"Очередность ребенка у матери *"}
                    listOfOptions={order}
                    stateProperty={"orderId"}
                    isValidKey={
                        element && element.orderIdValid
                    }
                    selectOptionClick={selectDropdown}
                />
            </>

        </div>
    );
};

export default SelectedChild;
