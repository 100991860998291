import { releaseUnepData } from "../components/pages/Profile/EDSRelease/Unep/UnepRelease/releaseUnep.data";

// import $api from "../redux/services/axiosService";

class ElectronicSignatureService {

    static async formingUnep(data, isForeigner) {
        const temp = [];
        for (let i = 0; i < data.length; i++) {
            temp.push({
                title: releaseUnepData[i].title,
                description: data[i],
            });
        }
        if (isForeigner === true) {
            const foreignerArray = temp.filter((el) => el.title !== "ИНН" &&
            el.title !== "СНИЛС" &&
            el.title !== "Паспортные данные  (код подразделения)");

            return foreignerArray;
        }
        return temp;
    }

    // static async  certificateRequestInfo () {
    //     const inn = await $api.get('/v1.0/c/u/3')
    //
    //     const objForBack = {
    //         "inn": "344812345678",
    //         "snilsNumber": "12345678901",
    //         "lastname": "Панченко",
    //         "firstname": "Дмитрий",
    //         "middlename": "Петрович",
    //         "email": "PanchenkoDP@alrosa.ru",
    //         "phone": "9060123456",
    //         "birthDate": "1980-02-21T00:00:00Z",
    //         "series": "1234",
    //         "number": "123456",
    //         "issueDate": "30.03.2012",
    //         "issueOrganizationId": "342123"
    //     }
    // }
}

export default ElectronicSignatureService;
