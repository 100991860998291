import ApplicationHelperService from "../HolidaysApplications/applicationHelper.service";

import arrowLeft from "../HolidaysApplications/assets/prevMonthArrow.svg";
import arrowRight from "../HolidaysApplications/assets/nextMonthArrow.svg";

import EmployeesService from "../../../../frontend.services/Employees/Employees.service";

import { UsePagination } from "../../../../hooks/usePagination/UsePagination";

import { useSelector } from "react-redux";
import {v4 as uuidv4} from "uuid";

const HolidaysEmployeesLeaves = () => {

    let dailyPercent;
    // leaves,
    // pageLeaves;

    const employeesLeaves = useSelector(state => state.employees.employeesLeaves);
    const [employeeLeaves, { paging, perPage }] = UsePagination(employeesLeaves ? employeesLeaves.leaves : []);

    if (employeesLeaves) {
        // leaves = employeesLeaves.leaves;
        dailyPercent = employeesLeaves.dailyPercent;
    }

    return (
        <>
            <table className="holidays-employees-table">
                <thead>
                    <tr className="holidays-employees-table__row">
                        <td className="holidays-employees-table__row__title" colSpan={2}>
                            <div
                                className="holidays-employees-table__title"
                                style={{marginLeft:"20px"}}>
                            Отпуска по графику
                            </div>
                        </td>
                        <td>
                            {/*JOB TITLE*/}
                        </td>
                        {dailyPercent && dailyPercent.map(day => {
                            return (
                                <td className={`holidays-employees-table__day-cell${day >= 19 ? "-over" : ""}`}
                                    key={uuidv4()}>
                                    {day > 1
                                        ? (
                                            <div className="holidays-employees-table__day">
                                                {day}
                                            </div>
                                        )
                                        : (
                                            <div
                                                className="holidays-employees-table__day"
                                                style={{color:"#FFFFFF"}}>
                                                {day}
                                            </div>
                                        )}

                                </td>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {/*{pageLeaves && pageLeaves.map((employee) => {*/}
                    {employeeLeaves && employeeLeaves.map((employee) => {

                        return (
                            <tr
                                className="holidays-employees-table-body__row"
                                key={uuidv4()}>
                                <td>
                                    <div className="holidays-employees-table-body__name">
                                        {employee.name}
                                    </div>
                                </td>
                                <td>
                                    <div className="holidays-employees-table-body__job-title">
                                        {employee.jobTitle}
                                    </div>
                                </td>
                                {!employee.periods.length ? (
                                    dailyPercent && dailyPercent.map((emptyDayCell) => {
                                        return (
                                            <td
                                                className="holidays-employees-table-body__day-regular"
                                                key={uuidv4()}>
                                                <div></div>
                                            </td>
                                        );
                                    })
                                )
                                    : ApplicationHelperService
                                        .periodMapper(employee.periods, dailyPercent.length)
                                        .map
                                        .map((day, employeeDayIndex, array) => {
                                            return (
                                                <td
                                                    className={`holidays-application-body__${
                                                        EmployeesService.getStyleBitwise(day)
                                                    }
                                      `}
                                                    key={uuidv4()}
                                                >
                                                    <div>
                                                        {employeeDayIndex === 0 &&
                                                        ApplicationHelperService.isClipped(
                                                            employeeDayIndex,
                                                            employee.periods,
                                                            dailyPercent.length,
                                                        ) && (
                                                            <img
                                                                src={arrowLeft}
                                                                alt="left"
                                                            />
                                                        )}
                                                        {employeeDayIndex === array.length - 1 &&
                                                        ApplicationHelperService.isClipped(
                                                            employeeDayIndex,
                                                            employee.periods,
                                                            dailyPercent.length,
                                                        ) && (
                                                            <img
                                                                src={arrowRight}
                                                                alt="right"
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination-control">
                {perPage}
                {paging}
            </div>
        </>
    );
};

export default HolidaysEmployeesLeaves;