import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import DatePopupSelector from "../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";
import StudyLeaveApplication from "../../../../../../../frontend.classes/Requests/StudyLeave.application";
import DropdownMenuTest from "../../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import SinglePairDatesForStudyLeave from "../../../../../../common/Inputs/SinglePairDatesForStudyLeave/SinglePairDatesForStudyLeave";

import { clearAttachmentsAction } from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import { emptyCurrentRequest } from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    calendarDatePickCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";
import {
    getEducationalProgramsThunkAction,
} from "../../../../../../../redux/actions/Requests/StudyLeave/thunk/getEducationalPrograms.thunk.action";
import { getCourseNumbersThunkAction } from "../../../../../../../redux/actions/Requests/StudyLeave/thunk/getCourseNumbers.thunk.action";
import {
    dropdownSelectionStudyLeave,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelectionStudyLeave.currentRecuest";
import { isTerminal } from "../../../../../../../constants";
import { ScrollButton } from "../../../../../../common/componentsTerminal/ScrollButton/ScrollButton";

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";


isTerminal ? import("./StudyLeaveTerminal.scss") : import("./StudyLeave.scss");

function StudyLeave({ setRequestStatus, requestStatus, requestOption }) {

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const dispatch = useDispatch();

    const currentRequest = useSelector((state) => state.requests.currentRequest);
    const educationalPrograms = useSelector((state) => state.requests.educationalPrograms);
    const reasons = useSelector((state) => state.requests.reasons);
    const courseNumbers = useSelector((state) => state.requests.courseNumbers);

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectDropdown(event, element, stateProperty) {
        dispatch(
            dropdownSelectionStudyLeave({
                event,
                el: element,
                stateName: stateProperty,
            }),
        );
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    function inputHandler(e, field) {
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field,
                value: e.target.value,
            }),
        );
        dispatch(validationControlCurrentRequest(field, true));
    }

    function changeDateHandler(field, date) {
        dispatch(validationControlCurrentRequest(field, true));
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(calendarDatePickCurrentRequest({ field: field, date: value }));
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        dispatch(getEducationalProgramsThunkAction());
        dispatch(getCourseNumbersThunkAction());
        if (!queryId) {
            dispatch(
                emptyCurrentRequest(
                    new StudyLeaveApplication({
                        leaveTypeId: 5,
                        institutionName: null,
                        educationProgramId: null,
                        courseNumberId: null,
                        specialtyName: null,
                        certificateCallNumber: null,
                        certificateCallDate: null,
                        dateTo: null,
                        dateFrom: null,
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return (
        <section className={"study-leave"}>
            {isTerminal && <ScrollButton />}
            <DropdownMenu
                helper={
                    "Причина отпуска должна совпадать с причиной, указанной в справке-вызове от учебного заведения"
                }
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Выберите причину отпуска<span className="required_text"> *</span></>}
                listOfOptions={reasons}
                stateProperty={"leaveReasonId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.leaveReasonId
                        ? currentRequest.isValid.leaveReasonId
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            <CommentInput
                value={currentRequest && currentRequest.institutionName}
                basicInput={true}
                maxLength={255}
                helper={
                    "Название учебного заведения должно совпадать с указанным в справке-вызове"
                }
                stateProperty={"institutionName"}
                changeHandler={(e) => inputHandler(e, "institutionName")}
                label={<>Название учебного заведения<span className="required_text"> *</span></>}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.institutionName
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"extra-vacation-days"}
            />
            <DropdownMenuTest
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Программа образования<span className="required_text"> *</span></>}
                listOfOptions={educationalPrograms}
                stateProperty={"educationProgramId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.educationProgramId
                        ? currentRequest.isValid.educationProgramId
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            <DropdownMenu
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Номер курса<span className="required_text"> *</span></>}
                listOfOptions={courseNumbers}
                stateProperty={"courseNumberId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.courseNumberId
                        ? currentRequest.isValid.courseNumberId
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            <CommentInput
                value={currentRequest && currentRequest.specialtyName}
                basicInput={true}
                maxLength={255}
                stateProperty={"specialtyName"}
                helper={
                    "Название специальности должно совпадать с указанным в справке-вызове"
                }
                changeHandler={(e) => inputHandler(e, "specialtyName")}
                label={<>Название специальности<span className="required_text"> *</span></>}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.specialtyName
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"extra-vacation-days"}
            />
            <CommentInput
                value={currentRequest && currentRequest.certificateCallNumber}
                basicInput={true}
                maxLength={255}
                stateProperty={"certificateCallNumber"}
                changeHandler={(e) => inputHandler(e, "certificateCallNumber")}
                label={<>Номер справки-вызова от учебного заведения<span className="required_text"> *</span></>}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.certificateCallNumber
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"extra-vacation-days"}
            />
            <DatePopupSelector
                field={"certificateCallDate"}
                label={<>Дата выдачи справки-вызова от учебного заведения<span className="required_text"> *</span></>}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.certificateCallDate
                        ? ""
                        : "Обязательное поле"
                }
                changeHandler={changeDateHandler}
                propsDate={
                    currentRequest && currentRequest.certificateCallDate
                        ? new Date(currentRequest.certificateCallDate)
                        : null
                }
                minDate={new Date(moment().subtract(12, "months"))}
                maxDate={new Date(moment().subtract(1, "days"))}
            />
            <SinglePairDatesForStudyLeave />
            {isTerminal && (
                <div
                    style={{
                        display: "flex",
                        width: "1160px",
                        margin: "auto",
                        gap: "20px",
                    }}
                ></div>
            )}
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                stateProperty={"employeeComment"}
                changeHandler={commentHandler}
                label={"Комментарий"}
                parentStyle={"unplanned-vacation"}
            />
            <CustomUploader
                accept={
                    ".png, .jpg, .jpeg, .pdf, .docx, .zip, .rar, .sig, .sgn"
                }
            />
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
                blockingMessages={true}
            />
            {/*</div>*/}
            <div className={"extra-vacation-days__notification"}>
                Необходимо приложить справку-вызов от учебного заведения (если
                документ подписан электронной подписью, то приложите все
                сопутствующие документы к справке одним архивом (например, файл
                электронной подписи формата sig, sgn)). <br />
                Обращаем внимание: в случае предоставления скан-образа, заявка
                не будет исполнена без предоставления в ОЦО оригинала(-ов)
                подтверждающих документов.
            </div>
        </section>
    );
}

export default StudyLeave;
