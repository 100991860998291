import $api from "../../../../../services/axiosService";
import {getDocumentsInformationAction} from "../GetDocumentsInformationAction";

import moment from "moment";

export const getDocumentsInformationThunkAction = ()=>async(dispatch)=>{
    const data=[];
    try{

        const nationCitizenship = await $api.get("/v1.0/c/u/13");
        const citizenship = {
            type:"Гражданство",
            description:
                nationCitizenship &&
                nationCitizenship.data &&
                nationCitizenship.data.data &&
                nationCitizenship.data.data.nationCitizenship &&
                nationCitizenship.data.data.nationCitizenship.citizenship
                    ? nationCitizenship.data.data.nationCitizenship.citizenship
                    : " ",
        };
        data.push(citizenship);

        const inn = await $api.get("/v1.0/c/u/3");
        const userInn = {
            type: "ИНН",
            description: inn && inn.data &&   inn.data.data && inn.data.data.inn ? inn.data.data.inn : " ",
        };
        data.push(userInn);

        const birthTime = await $api.get("/v1.0/c/u/1");
        const userBirthTime = {
            type:"Дата рождения",
            description:
                birthTime &&
                birthTime.data &&
                birthTime.data.data &&
                birthTime.data.data.birthDate
                    ? moment(birthTime.data.data.birthDate).format("DD.MM.YYYY")
                    : " ",
        };
        data.push(userBirthTime);

        const snils = await $api.get("/v1.0/c/u/4");
        const socNumber ={
            type:"СНИЛС",
            description:
                snils &&
                snils.data &&
                snils.data.data &&
                snils.data.data.socNumber
                    ?  snils.data.data.socNumber
                        .replace(
                            /(\d{3})\.?-?-?(\d{3})\.?-?-?(\d{3})\.? ?(\d{2})/g,
                            "$1-$2-$3 $4"
                        )
                    : " ",

        };
        data.push(socNumber);

        const passport = await $api.get("/v1.0/c/u/5");
        const usersPassport = {
            type:"Паспортные данные",
            description:
            `Cерия и номер: ${passport.data.data.passport.seria} ${passport.data.data.passport.number} <br/>
             Выдан: ${passport.data.data.passport.grant} <br/>
             Дата выдачи: ${moment(passport.data.data.passport.date).format("DD.MM.YYYY")}
            `,
        };
        data.push(usersPassport);

        const military = await $api.get("/v1/u/c/military");//военный билет
        const militaryTemp = `Cерия и номер: ${military.data.series+military.data.number} <br/>
                              Дата выдачи: ${moment(military.data.date).format("DD.MM.YYYY")}`;
        const userMilitary={
            type:"Военный билет",
            description:`
            ${!military.data.series? " Отсутствует ": militaryTemp}`,
        };
        military.data.series&&data.push(userMilitary);
        const jobHistory = await $api.get("/v1.0/c/u/8");//трудовая
        const userJobHistory={
            type:"Трудовая книжка",
            description:
                `Cерия и номер: ${jobHistory.data.data.jobHistory.seria} ${jobHistory.data.data.jobHistory.number}`,
        };
        data.push(userJobHistory);

        const place  = await $api.get("/v1/c/u/14");
        const birthPlace = {
            type:"Место рождения",
            description:`${place.data.data.birthPlace}`,
            //'поселок Первомайский, Усть-Каменогорского района Волгоградской области Российской Федерации'
        };
        data.push(birthPlace);

        const addresses = await $api.get("/v1.0/c/u/11");
        const addressTemp = {
            type:"Адрес регистрации, Фактический адрес проживания",
            description:
                `${addresses.data.data.addresses
                    .map(el=>`${el.countryName}, ${el.address} </br>`). join("")}`,
        };
        data.push(addressTemp);

        const education = await $api.get("/v1/c/education");
        const descr = education.data.data.map((el,index) => {
            return `${index + 1}. ${el.educationTypeName ? el.educationTypeName : ""} ${el.firmName ? el.firmName : ""}
                <br/>
                    ${el.specName ? el.specName + ","  : " "} ${el.qualifyName ? el.qualifyName : ""} 
                    Дата выдачи: ${el.dateEnd ? moment(el.dateEnd).format("DD.MM.YYYY") : ""} 
                <br/>`;
        });
        const educationTemp = {
            type:"Образование (ВУЗ/СУЗ, диплом, специальность, квалификация, дата окончания)",
            description:descr.join(" "),
        };
        data.push(educationTemp);
        const migration = await $api.get("/v1/u/c/migration");//миграционный учет
        const migrationTemp = {
            type:"Данные о миграционном учете (для иностранных работников)",
            description:
                `${
                    !migration.data.series 
                        ? "Отсутствует" 
                        : migration.data.series + " №" + migration.data.number +
                            `(дата окончания вида на жительство - 
                            ${moment(migration.data.endDate).format("DD.MM.YYYY")})
                            `}`,
        };

        migration.data.series&&data.push(migrationTemp);
        dispatch(getDocumentsInformationAction(data));
    }catch (e) {
        // console.log(e.response)
    }

};

