import $api from "../../../services/axiosService";
import {isLeaderAction} from "../isLeader.action";
export const isLeaderThunkAction = () => async(dispatch) => {
    try {
        const response = await $api("v1/u/p/leader");
        localStorage.setItem("isLeader",response.data.isLeader);
        dispatch(isLeaderAction(response.data.isLeader));
    } catch (e) {
        // console.log(e.response)
    }
};
