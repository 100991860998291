import HelperService from "../Helper.service";

import { HolidaysEmployeeFilter } from "../../components/common/Holidays/HolidaysEmployeeFilter/HolidaysEmployeeFilter";

import moment from "moment";
import {v4} from "uuid";

class TableCrossMonthHelper {
    static getRow (data, parentStyle, period, selectClickHandler) {
        return data.map
            ? (
                <tr className={`${parentStyle}__row`}
                    key={v4()}>
                    <td
                        key={v4()}
                        className={`${parentStyle}__body__checkbox`}>
                        <input type="checkbox"
                            name={"checkbox"}
                            id={data.uid}
                            onChange={(e)=>{
                                selectClickHandler(e);
                            }}
                            {...{checkbox: "check"}}
                            checked={data.checked}>
                        </input>
                    </td>
                    <td key={v4()}
                        className={`${parentStyle}__body__worker-accept`}>
                        {data.name}
                    </td>
                    <td key={v4()}
                        className={`${parentStyle}__body__jobTitle-accept`}>
                        {data.jobTitle}
                    </td>
                    {this.getCellStyleFromMap(data.map, `${parentStyle}`)}
                </tr>
            )
            : (
                <tr className={`${parentStyle}__row`}
                    key={v4()}>
                    <td key={v4()}
                        colSpan={2} className={`${parentStyle}__body__worker`}>
                        {data.name}
                    </td>
                    <td key={v4()}
                        className={`${parentStyle}__body__jobTitle`}>
                        {data.jobTitle}
                    </td>
                    {this.getDaysOfPeriod(period, data, parentStyle)}
                </tr>
            );
    }

    // TODO GET RID FROM METHOD, CONDITION AND PROPS
    static getHeaderDatesRow (
        headers,
        data,
        parentStyle,
        {
            startOfMonth,
            endOfMonth,
        }
    ) {
        return (
            <tr key={v4()}>
                {
                    headers.map(e => {
                        if (e.id === "01tableHead") {
                            return (
                                <td colSpan={e.colspan}
                                    key={v4()}
                                    className={`${parentStyle}__head${e.cssKey}`}>
                                    <div>
                                        <HolidaysEmployeeFilter startOfMonth={startOfMonth} endOfMonth={endOfMonth} />
                                    </div>
                                </td>
                            );
                        } else {
                            return (
                                <td colSpan={e.colspan}
                                    key={v4()}
                                    className={`${parentStyle}__head${e.cssKey}`}>
                                    <div>
                                        {e.data}
                                    </div>
                                </td>
                            );
                        }

                    })
                }
                {
                    data.map(e => {
                        return (
                            <td
                                key={v4()}
                                className={`${parentStyle}__head${e.cssKey}`}>
                                <div>
                                    {e.data.upperValue && e.data.upperValue}
                                    <br/>
                                    {e.data.lowerValue && e.data.lowerValue}
                                    {e.data.lowerValue
                                        ? null
                                        : e.data ? e : null}
                                </div>
                            </td>
                        );
                    })
                }
            </tr>
        );
    }
    static getHeaderRow (headers, data, parentStyle, period) {
        return (
            <tr>
                {
                    headers.map(e => {
                        return (
                            <td className={`${parentStyle}__head__sub-header-regular`}
                                colSpan={e.colspan}
                                key={v4()}>
                                <div>
                                    {e.data}
                                </div>
                            </td>);
                    })
                }
                {
                    data
                        ? data.map(e => {
                            return (
                                <td className={`${parentStyle}__head__sub-header${
                                    e > 18
                                        ? "-over"
                                        : e > 0 
                                            ? "-cross" 
                                            : "-regular"
                                }`}
                                key={v4()}>
                                    <div>{e}</div>
                                </td>
                            );
                        })
                        : new Array(moment(period).daysInMonth()).fill(null).map(e => {
                            return (
                            // TODO LOADER TABLE CELL EFFECT
                                <td className={`${parentStyle}__head__sub-header-regular`}
                                    key={v4()}>
                                    <div></div>
                                </td>
                            );
                        }
                        )
                }
            </tr>
        );
    }
    static getHeaderAcceptanceRow (headers, data, parentStyle, multipleSelectionHandler, checkbox = false) {
        return (
            <tr key={v4()}>
                {
                    headers.map(e => {
                        return e.input
                            ? (
                                <td
                                    key={v4()}
                                    className={`${parentStyle}__head${e.cssKey}`}>
                                    <input type={e.input}
                                        id={"checkbox-controller"}
                                        checked={checkbox}
                                        onChange={multipleSelectionHandler}>
                                    </input>
                                </td>
                            )
                            : (
                                <td
                                    key={v4()}
                                    className={`${parentStyle}__head${e.cssKey}`}>
                                    <div>
                                        {e.data}
                                    </div>
                                </td>
                            );
                    })
                }
                {
                    data.map(e => {
                        return (
                            <td
                                key={v4()}
                                className={`${parentStyle}__head__${
                                    e > 18 
                                        ? "sub-header-over" 
                                        : e > 0 
                                            ? "sub-header-cross" 
                                            : e === 0 
                                                ? "sub-header-none" 
                                                :"sub-header-regular"
                                }`}>
                                <div>
                                    {e}
                                </div>
                            </td>
                        );
                    })
                }
            </tr>
        );
    }
    static getDaysOfPeriod (period, data, parentStyle) {
        return period
            ? new Array(moment(period.dateFormat).daysInMonth())
                .fill(null)
                .map((e,dayIndex) => {
                    return (
                        <td key={v4()}
                            className={`${parentStyle}__body__${this.getCellStyle(dayIndex + 1, data, period)}`}>
                        </td>
                    );
                })
            : new Array(moment(new Date()).daysInMonth())
                .fill(null)
                .map((e,dayIndex) => {
                    return (
                        <td key={v4()}
                            className={`${parentStyle}__body__regular-cell`}>
                        </td>
                    );
                });
    }
    static getCellStyle (day, data, period) {
        const {planned, cross, over} = data;
        if (HelperService.isContainsInPeriod(planned, period)
            || HelperService.isContainsInPeriod(cross, period)
            || HelperService.isContainsInPeriod(over, period)) {
            // TODO CHECK CORRECT PERIOD
            // TODO CHECK PAST PERIODS
            if (HelperService.setCellStyleCrossTable(over, day) ) {
                return "over-cell";
            } else if (HelperService.setCellStyleCrossTable(cross, day)) {
                return "cross-cell";
            } else if (HelperService.setCellStyleCrossTable(planned, day)){
                return "planned-cell";
            } else {
                return "regular-cell";
            }

        } else {
            return "regular-cell";
        }
    }
    static getCellStyleFromMap (data, parentStyle) {
        return data.map(e => {
            return (
                <td key={v4()}
                    className={`${parentStyle}__body__${
                    // e === 0 
                    //     ? 'regular-cell' 
                    //     : e === 1 
                    //         ? 'past-cell' 
                    //         : e === 2 
                    //             ? 'planned-cell' 
                    //             : e === 3 
                    //                 ? 'cross-cell' 
                    //                 : e === 4 
                    //                     ? 'over-cell'
                    //                     : 'regular-cell'
                        e === 0
                            ? "regular-cell"
                            : e === 1
                                ? "planned-cell"
                                : e === 2
                                    ? "cross-cell"
                                    : e === 3
                                        ? "over-cell"
                                        : "regular-cell"
                    }`}>
                </td>
            );
        });
    }
}

export default TableCrossMonthHelper;