import {
    // addUserWidgetsAction,
    getUserWidgetsAction,
} from "../userWidgetsAction";
import $api from "../../../../../services/axiosService";
// import ExperienceControlService from "../../../../../../frontend.services/Profile/ExperienceControlService";

import moment from "moment";

export const userWidgetsThunkAction = () => async (dispatch) => {
    try {
        const widgets = [];

        const division = await $api.get("/v1.0/c/u/12");
        const tabelNumber = {
            title: "Табельный номер",
            description: division.data.data.appointment[0].tabNumber
                ? `${division.data.data.appointment[0].tabNumber}`
                : "---",
        };
        widgets.push(tabelNumber);
        const upcomingLeaves = await $api("v1/p/po/leaves/upcoming");
        const upcomingEvent = {
            title: "Предстоящий отпуск",
            description: upcomingLeaves.data.from
                ? `${moment(upcomingLeaves.data.from).format("DD.MM.YYYY")} 
                - ${moment(upcomingLeaves.data.to).format("DD.MM.YYYY")} `
                : "Отсутствует",
        };
        widgets.push(upcomingEvent);

        dispatch(getUserWidgetsAction(widgets));
    } catch (e) {
        throw new Error("ERROR WHILE GETTING USER WIDGETS");
    }
};
