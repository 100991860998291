import { isWorkerSubstituteThunkAction } from "../../../../redux/actions/Documents/Leader/thunk/isWorkerSubstitute.thunk.action";
import { isTerminal } from "../../../../constants";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

isTerminal ? import("./NavMenuDocumentsTerminal.scss") : import("./NavMenuDocuments.css");

const NavMenuDocuments = (
    {
        toggleState,
        toggleTab,
    }
) => {

    const dispatch = useDispatch();
    const isSubstitute = useSelector((state) => state.documents.isSubstitute);
    // const [leader, setLeader] = useState(localStorage.getItem("isLeader"));

    function handler3000(index, type) {
        toggleTab(index);
    }

    function allDocumentsHandler() {
        toggleTab(1);
    }

    useEffect(() => {
        dispatch(isWorkerSubstituteThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubstitute]);

    return (
        <div className="documents-menu-container">
            <div
                onClick={() => allDocumentsHandler()}
                className={
                    toggleState === 1
                        ? "documents-menu-item"
                        : "documents-menu-item-active"
                }
            >
                Все документы
            </div>
            <div
                onClick={() => handler3000(2, "соглашение")}
                className={
                    toggleState === 2
                        ? "documents-menu-item"
                        : "documents-menu-item-active"
                }
            >
                Кадровые документы
            </div>
            <div
                onClick={() => handler3000(3, "Справка")}
                className={
                    toggleState === 3
                        ? "documents-menu-item"
                        : "documents-menu-item-active"
                }
            >
                Справки
            </div>

            <div
                onClick={() => toggleTab(4)}
                className={
                    toggleState === 4
                        ? "documents-menu-item"
                        : "documents-menu-item-active"
                }
            >
                {
                    localStorage.getItem("isLeader") === "true"
                        ? "Мои заявки"
                        : "Заявки"
                }
            </div>
            {
                (isSubstitute || localStorage.getItem("isLeader") === "true") &&
                (<div
                    onClick={() => toggleTab(5)}
                    className={
                        toggleState === 5
                            ? "documents-menu-item"
                            : "documents-menu-item-active"
                    }
                >
                    Согласование
                </div>)
            }
        </div>
    );
};

export default NavMenuDocuments;
