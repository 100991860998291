

export const divisions = [
    {
        title: "Положения и документы",
        description: "Положения и документы подразделения",
        // icon: blankList,
        path: "#",
    },
    {
        title: "Штатное замещение",
        description: "Штатное расписание подразделения",
        // icon: blankList,
        path: "#",
    },
    {
        title: "Список сотрудников",
        description: "Список сотрудников подразделения",
        // icon: blankList,
        path: "/divisions/structure/employee-list",
    },
    {
        title: "Делегирование полномочий",
        description: "Передача полномочий руководителя на сотрудника",
        // icon: blankList,
        path: "/divisions/structure/delegation",
    },
];
