import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";

import React from "react";
import "./ForbiddenPage.scss";

const ForbiddenPage = () => {
    return (
        <div>
            <Header />
            <div className={"container-forbidden"}>
                <p className={"container-forbidden__paragraph"}>
                    ДОСТУП <span style={{color: "red"}}>ЗАПРЕЩЁН</span>
                </p>
                <p className={"container-forbidden__paragraph container-forbidden__paragraph__closures"}>
                    ФУНКЦИОНАЛЬНОСТЬ ДОСТУПНА ТОЛЬКО ВНУТРИ СЕТИ КСПД
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default ForbiddenPage;