import React from "react";
import {v4} from "uuid";

const TableHead = ({children, parentStyle}) => {
    return (
        <thead key={v4()} className={`${parentStyle}__head`}>
            {children}
        </thead>
    );
};

export default TableHead;