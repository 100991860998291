import DateService from "../Date.service";

import moment from "moment";

import "moment/locale/ru";

class TableService {
    constructor() {
        this.attribute = {
            regular: "regular",
            split: "split",
            vacation: "vacation",
        };
    }
    // this method created only for holiday table to put after metadata of a table
    static getCurrentHeaderWeekdays () {
        return Array(7).fill(null).map((e, i) => {
            return {
                id: `${i + 4}tableHead`,
                cssKey: "__day",
                data: {
                    upperValue: DateService.getDayName(DateService.getStartOfWeek(), "dd", i),
                    lowerValue: DateService.getDayNumber(DateService.getStartOfWeek(), i),
                },
            };
        });
    }
    static getCurrentWorkerWeekdays (uid, from, to) {
        return Array(7).fill(null).map((e, i) => {
            let attribute = "";
            if (
                moment(from).isBefore(DateService.getDay(DateService.getStartOfWeek(), i + 1).toDate())
                &&
                moment(to).isAfter(DateService.getDay(DateService.getStartOfWeek(), i - 1).toDate())
            ) {
                attribute = "vacation";
            } else {
                attribute = "regular";
            }
            const temp = {
                id: `${uid}`,
                cssKey: "__day",
                dataAttribute: attribute,
            };
            return temp;
        });
    }

    static getCurrentWorkerWeekdaysMod (uid, planned, cross) {
        const temp = new Array(7).fill(null).map((element, index) => {
            let attribute = "";
            for (let i = 0; i < planned.length; i += 1) {
                if (
                    moment(planned[i].from).isSameOrBefore(DateService.getDayClear(DateService.getStartOfWeek(), index))
                    &&
                    moment(planned[i].to).isSameOrAfter(DateService.getDayClear(DateService.getStartOfWeek(), index))
                ) {
                    for (let i = 0; i < cross.length; i += 1) {
                        if (
                            moment(cross[i].from).isSameOrBefore(DateService.getDayClear(DateService.getStartOfWeek(), index))
                            &&
                            moment(cross[i].to).isSameOrAfter(DateService.getDayClear(DateService.getStartOfWeek(), index))
                        ) {
                            attribute = "split";
                        }
                        else {
                            attribute = "vacation";
                        }
                    }
                }
                else {
                    if (attribute === "vacation" || attribute === "split") {
                        element ? attribute = element.attribute : attribute = "regular";
                    } else {
                        attribute = "regular";
                    }
                }
            }

            return {
                ...element,
                date:DateService.getDayClear(DateService.getStartOfWeek(), index),
                cssKey: "__day",
                dataAttribute: attribute,
            };
        });
        return temp;
    }
    static getCurrentWorkerWeekdaysModYear (uid, planned, cross) {
        let attribute = "";
        return new Array(360).fill(null).map((element, index) => {
            planned.forEach((pe,pi) => {
                if (
                    moment(pe.from).isSameOrBefore(DateService.getDayClear(DateService.getStartOfYear(), index))
                    &&
                    moment(pe.to).isSameOrAfter(DateService.getDayClear(DateService.getStartOfYear(), index))
                ) {
                    cross.forEach((ce, ci) => {

                        if (
                            moment(ce.from).isSameOrBefore(DateService.getDayClear(pe.from, index))
                            &&
                            moment(ce.to).isSameOrAfter(DateService.getDayClear(pe.to, index))
                        ) {
                            attribute = "split";
                        }
                        else {
                            attribute = "vacation";
                        }
                    });
                }
                else {
                    attribute = "regular";
                }
            });
            return {
                ...element,
                date:DateService.getDayClear(DateService.getStartOfYear(), index),
                cssKey: "__day",
                dataAttribute: attribute,
            };
        });
    }
    // this method created only for holiday table to put after metadata of a table
    static getWeekWithPrefix (prefix) {
        return prefix.concat(this.getCurrentHeaderWeekdays().map((e,i) => {
            // mapping to change in last two days objects css class to ...__weekend
            if (i > 4) {
                return {
                    ...e, cssKey: "__weekend",
                };
            } else {
                return e;
            }
        }));
    }

    static getWeekWorkerWithPrefix (prefix, uid, from, to) {
        return prefix.concat(this.getCurrentWorkerWeekdays(uid,from,to).map((e, i) => {
            if (i > 4) {
                return {
                    ...e, cssKey: "__weekend",
                };
            } else {
                return e;
            }
        }));
    }
    static getWeekWorkerWithPrefixMod (prefix, uid, planned, cross) {
        return prefix.concat(this.getCurrentWorkerWeekdaysMod(uid,planned,cross).map((e, i) => {
            if (i > 4) {
                return {
                    ...e, cssKey: "__weekend",
                };
            } else {
                return e;
            }
        }));
    }
    static getWeekWorkerWithPrefixModYear (prefix, uid, planned, cross) {
        return prefix.concat(this.getCurrentWorkerWeekdaysModYear(uid,planned,cross));
    }

    static getMonthDaysHeader (date, prefix) {
        return prefix.concat(
            new Array(moment(date).daysInMonth()).fill(null).map((e,i) => {
                const dayName = DateService.getDayNameMonth(date, "dd", i);
                if (dayName !== "сб" &&  dayName !== "вс") {
                    return {
                        id: `${i + 2}tableHead`,
                        cssKey: "__day",
                        data: {
                            upperValue: DateService.getDayNameMonth(date, "dd", i),
                            lowerValue: i + 1,
                        },
                    };
                } else {
                    return {
                        id: `${i + 9}tableHead`,
                        cssKey: "__weekend",
                        data: {
                            upperValue: DateService.getDayNameMonth(date, "dd", i),
                            lowerValue: i + 1,
                        },
                    };
                }
            })
        );
    }static getMonthDaysHeaderMod (date) {
        return new Array(moment(date).daysInMonth()).fill(null).map((e,i) => {
            const dayName = DateService.getDayNameMonth(date, "dd", i);
            if (dayName !== "сб" &&  dayName !== "вс") {
                return {
                    id: `${i + 2}tableHead`,
                    cssKey: "__day",
                    data: {
                        upperValue: DateService.getDayNameMonth(date, "dd", i),
                        lowerValue: i + 1,
                    },
                };
            } else {
                return {
                    id: `${i + 9}tableHead`,
                    cssKey: "__weekend",
                    data: {
                        upperValue: DateService.getDayNameMonth(date, "dd", i),
                        lowerValue: i + 1,
                    },
                };
            }
        });
    }
}

export default TableService;
