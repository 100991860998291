import DateService from "../../../../frontend.services/Date.service";

export const tableHeadYear = [
    {
        id: "1tableHead",
        cssKey: "__department-year",
        data: {
            upperValue: "структурное",
            lowerValue: "подразделение",
        },
    },
    {
        id: "2tableHead",
        cssKey: "__worker-year",
        data: "Сотрудник",
    },
    {
        id: "3tableHead",
        cssKey: "__vacation-days-year",
        data: {
            upperValue: "дни",
            lowerValue: "отпуска",
        },
    },
];

export const TableHeadYearMonths = () => {
    return DateService.getMonthNames().map((e, i) => {
        return {
            id: `tableHeadMonth-${i + 1}`,
            cssKey: "__table-header-month",
            data: e,
        };
    });
};
