import triangle from "../../../../assets/documents/triangle.svg";
import { isTerminal } from "../../../../constants";

export const allDocumentsData = [
    {
        id:"head-1",
        title:"тип",
        icon:triangle,
        width: isTerminal ? "111px": "98px",
    },
    {
        id:"head-2",
        title:"наименование",
        icon:"",
        width: isTerminal ? "203px": "368px",
    },
    {
        id:"head-3",
        title:"ознакомиться до",
        icon:"",
        width: isTerminal ? "174px": "181px",
    },
    {
        id:"head-4",
        title:"подписать до",
        icon:"",
        width: isTerminal ? "140px" : "164px",
    },
    {
        id:"head-5",
        title:"документ",
        icon:"",
        width:isTerminal ? "144px" : "192px",
    },
    {
        id:"head-6",
        title:"статус",
        icon:"",
        width:isTerminal ? "108px" : "177px",
    },
];
