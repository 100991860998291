//profile -> general information
export const GET_FIO = "GET_FIO";
export const GET_RELATIVES = "GET_RELATIVES";
export const GET_GENERAL_DATA = "GET_GENERAL_DATA";
export const GET_USER_ADDRESSES = "GET_USER_ADDRESSES";
export const GET_USER_PASSPORT = "GET_USER_PASSPORT";
//profile -> service information
export const GET_ADDITIONAL_INFO = "GET_ADDITIONAL_INFO";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_EXPERIENCE = "GET_EXPERIENCE";
//profile -> digital sign unep
export const RELEASE_UNEP = "RELEASE_UNEP";
export const APPROVE_RELEASE_UNEP = "APPROVE_RELEASE_UNEP";
export const GET_LATEST_UNEP_CERTIFICATE = "GET_LATEST_UNEP_CERTIFICATE";
export const GET_WORK_SIGN_TYPE = "GET_WORK_SIGN_TYPE";
