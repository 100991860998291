class NDFLApplication {
    constructor(options) {
        this.typeId = options.typeId;
        this.data = {
            comment: options.data
                ? options.data.comment
                : null,
            year: options.data
                ? options.data.year
                : null,
            stamp: options.data
                ? options.data.stamp
                : null,
        };
        this.isValid = {
            typeId: true,
            year: true,
            stamp: true,
        };
    }

    static sendToValidation(currentRequest, setCurrentRequest) {
        if (currentRequest && currentRequest.data && currentRequest.data.stamp !== null && currentRequest.data.year) {
            return true;

        } else {
            const keysToCheck = ["stamp", "year"];
            for (let i = 0; i < keysToCheck.length; i += 1) {
                if (currentRequest.data[keysToCheck[i]] === null || currentRequest.data[keysToCheck[i]] === undefined) {
                    setCurrentRequest((prev) => {
                        return {
                            ...prev,
                            isValid: {
                                ...prev.isValid,
                                [`${keysToCheck[i]}`]: false,
                            },
                        };
                    });
                }
            }
            return false;
            // let temp;
            // if (currentRequest.data.stamp === null && currentRequest.data.stamp === undefined) {
            //     temp = {
            //         ...currentRequest,
            //         isValid: {
            //             ...currentRequest.isValid,
            //             stamp: false,
            //         },
            //     };
            // }
            // console.log("STAMP: ", temp);
            // if (!currentRequest.data.year) {
            //     temp = {
            //         ...currentRequest,
            //         isValid: {
            //             ...currentRequest.isValid,
            //             year: false,
            //         },
            //     };
            //     // request = temp;
            // }
            // console.log("year:: ", temp);

            // return {
            //     valid: false,
            //     temp,
            // };
        }
    }
}

export default NDFLApplication;
