export const statusData = [
    {
        id:1,
        type:"Draft",
        name:"Черновик",
    },
    {
        id:2,
        type:"SentToApproval",
        name:"На согласовании",
    },
    {
        id:3,
        type:"RequireConfirmation",
        name:"На согласовании",
    },
    {
        id:4,
        type:"SentToConfirmation",
        name:"На согласовании",
    },
    {
        id:5,
        type:"Confirmed",
        name:"Согласована",
    },
    {
        id:6,
        type:"NewPackage",
        name:"На исполнении",
    },
    {
        id:7,
        type:"PendingSign",
        name:"Сформирован кадровый документ",
    },
    {
        id:8,
        type:"Signing",
        name:"Сформирован кадровый документ",
    },
    {
        id:9,
        type:"Cancelling",
        name:"Отозвана",
    },
    {
        id:10,
        type:"Complete",
        name:"Исполнена",
    },
    {
        id:11,
        type:"Aborted",
        name:"Отозвана",
    },
    {
        id:12,
        type:"Rejected",
        name:"Отклонена",
    },
];
//
