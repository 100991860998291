import OKButton from "../../../../Button/OKButton/OKButton";

import "./WarningModal.scss";

function WarningModal(props) {
    return (
        <div className={"warning"}>
            <div>
                {
                    Array.isArray(props.description)
                        ? props.description.map((el,i) => {
                            return (
                                <div key ={i}>
                                    {el.message}
                                </div>
                            );
                        })
                        : <div>
                            {props.description}
                        </div>
                }
            </div>
            <OKButton
                title = {"Oк"}
                handler={props.close}
            />
        </div>
    );
}

export default WarningModal;
