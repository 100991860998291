import Header from "../../../../../layout/Header/Header";
import Footer from "../../../../../layout/Footer/Footer";
import Breadcrumbs from "../../../../../common/Breadcrumbs/Breadcrumbs";
import BasicLine from "../../../../../common/BasicLine/BasicLine";
import BackButton from "../../../../../common/Button/BackButton/BackButton";
import UButton from "../../../../../common/Button/UButton/UButton";

import { Modal } from "../../../../../common/Modal/Modal";
import VerifyingIdentity from "../../../../../common/Modal/ModalConditions/Profile/VerifyingIdentity/VerifyingIdentity";

import {
    releaseUnepThunkAction,
} from "../../../../../../redux/actions/User/profile/ElectronicSignature/thunk/releaseUnep.thunk.action";
import {
    createCertificateRequestInfoThunkAction,
} from "../../../../../../redux/actions/User/profile/ElectronicSignature/thunk/createCertificateRequestInfo.thunk.action";
import {
    createCertificateWhithEsiaThunkAction,
} from "../../../../../../redux/actions/User/profile/ElectronicSignature/thunk/createCertificateWhithEsia.thunk.action";
import {
    createCertificateWhithSmsCodeThunkAction,
} from "../../../../../../redux/actions/User/profile/ElectronicSignature/thunk/createCertificateWhithSmsCode.thunk.action";
import { isTerminal } from "../../../../../../constants";
import { ScrollButton } from "../../../../../common/componentsTerminal/ScrollButton/ScrollButton";
import { BackArrowButton } from "../../../../../common/componentsTerminal/BackArrowButton/BackArrowButton";

import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

isTerminal ? import("./UnepReleaseTerminal.scss") : import("./UnepRelease.scss");


const UnepRelease = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(releaseUnepThunkAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    const personalData = useSelector(state => state.personalData);

    const [modalVerifyingIdentity, toggleModalVerifyingIdentity] = useState(false);
    const [loader, setLoader] = useState(true);
    const [phoneToggle, setPhoneToggle] = useState(false);
    const [description, setDescription] = useState(false);


    function clickHandlerVerifying() {
        dispatch(createCertificateRequestInfoThunkAction(personalData.electronicSignature.requestInfo, {
            setLoader: setLoader,
            setDescription: setDescription,
            navigate: navigate,
        }));

        toggleModalVerifyingIdentity(!modalVerifyingIdentity);
    }

    function closeHandlerVerifying() {
        toggleModalVerifyingIdentity(false);
        setDescription(false);
        setPhoneToggle(false);
    }

    function approveIdentityWith(num) {
        setLoader(true);

        num === 1
            ? dispatch(createCertificateWhithEsiaThunkAction({
                setLoader: setLoader,
                setDescription: setDescription,
            },
            personalData?.unepIssueId.issueId,
            personalData.electronicSignature.requestInfo,
            ))
            : dispatch(createCertificateWhithSmsCodeThunkAction({
                setLoader: setLoader,
                setDescription: setDescription,
                setPhoneToggle: setPhoneToggle,
            },
            personalData?.unepIssueId.issueId,
            ));
    }

    return (
        <>
            <Header />
            <Breadcrumbs />
            <section className={"unep-release"}>
                {!personalData.electronicSignature ?
                    <div className={"unep-release__loader"}>
                        <ClipLoader color="#6DCDF6" />
                    </div>
                    :
                    (<>
                        <div className={"unep-release__header"}>
                            <div className={"unep-release__header__h1"}>
                                {isTerminal && <BackArrowButton />}
                формирование унэп
                            </div>
                            <span className={"unep-release__header__description"}>
                            Проверьте заполненные ниже данные, и если все верно, нажмите “Продолжить”
                            </span>
                            <span className={"unep-release__header__description"}>
                                {!isTerminal && ("В случае обнаружения ошибок в заполненных данных, обратитесь в опер. отдел ОЦО для корректировки персональных данных")}
                                {isTerminal && "В случае обнаружения ошибок в заполненных данных обратитесь в ОЦО."}
                                {/*В случае обнаружения ошибок в заполненных данных
                                , оформите, пожалуйста, <a className={'unep-link'}>
                                заявку на изменение</a> персональных данных.*/}
                            </span>
                        </div>
                        <div className={"unep-release__form"}>
                            {isTerminal && <ScrollButton />}
                            {
                                personalData.electronicSignature.requestData &&
                                personalData.electronicSignature.requestData.map((el, index) => {
                                    return (
                                        <BasicLine
                                            key={index}
                                            type={<>{el.title} <span className="required_text"> *</span></>}
                                            description={el.description}
                                            styleParent={"unep-release__form"}
                                        />
                                    );
                                })
                            }
                        </div>
                        <div className={"unep-release__container"}>
                            <BackButton
                                route={"/profile/eds-release/unep"}
                            />
                            <UButton onClick={() => clickHandlerVerifying()}>продолжить</UButton>
                        </div>
                    </>)
                }
            </section>
            <Modal
                shown={modalVerifyingIdentity}
                close={closeHandlerVerifying}
            >
                <VerifyingIdentity
                    isForeigner={personalData &&
          personalData.electronicSignature &&
          personalData.electronicSignature.requestInfo &&
          personalData.electronicSignature.requestInfo.isForeigner ? personalData.electronicSignature.requestInfo.isForeigner : false}
                    close={closeHandlerVerifying}
                    loader={loader}
                    setLoader={setLoader}
                    description={description}
                    setDescription={setDescription}
                    approveIdentityWith={approveIdentityWith}
                    phoneToogle={phoneToggle}
                    setPhoneToggle={setPhoneToggle}
                    issueId={personalData?.unepIssueId}
                />
            </Modal>
            <Footer />
        </>
    );
};

export default UnepRelease;
