import { data } from "./workTimePage.data";
import { terminalData } from "./workTimePageTerminal.data";

import Header from "../../../layout/Header/Header";

import Footer from "../../../layout/Footer/Footer";
import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import { isTerminal } from "../../../../constants";

import { unsetUserRequestAction } from "../../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";

import { useDispatch } from "react-redux";

import React, { useEffect } from "react";

const WorkTimePage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(unsetUserRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <MultiRoute
                breadCrumbs={true}
                subHeader={"Рабочее время"}
                styleParent={"work-time"}
                data={isTerminal ? terminalData : data}
            />
            <Footer />
        </>
    );
};

export default WorkTimePage;
