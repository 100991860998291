import BasicApplication from "./Basic.application";

import {
    validationControlCurrentRequest,
} from "../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import { inputValidationInArray } from "../../redux/actions/Requests/currentRequestControls/inputValidationInArray";
import { attachmentsValidation } from "../../redux/actions/Requests/currentRequestControls/attachmentsValidation";

class UnpaidPracticeApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.category = 3;
        this.internshipTypeId = options.internshipTypeId;
        this.students = options.students;
        this.contactEmail = options.contactEmail;
        this.contactPhone = options.contactPhone;
        this.contactPerson = options.contactPerson;
        this.courseNumber = options.courseNumber;
        this.chair = options.chair;
        this.faculty = options.faculty;
        this.university = options.university;
        this.workplacePreparationNeeded = options.workplacePreparationNeeded;
        this.headPid = options.headPid;
        this.structId = options.structId;
        this.dateTo = options.dateTo;
        this.dateFrom = options.dateFrom;
        this.employeeComment = options.employeeComment;
        this.isValid = {
            students: [],
            contactEmail: true,
            contactPhone: true,
            contactPerson: true,
            courseNumber: true,
            chair: true,
            faculty: true,
            university: true,
            workplacePreparationNeeded: true,
            headPid: true,
            structId: true,
            internshipTypeId: true,
            dateTo: true,
            dateFrom: true,
            validDate: true,
        };
    }

    static async filterStudents(currentRequest) {
        const temp = [...currentRequest.students.map(el => {
            return {
                ...el,
                attachments: el.attachments.filter(el => el !== null),
            };

        })];
        return {
            ...currentRequest,
            students: temp,
        };
    }

    static saveOrUploadValidation(currentRequest, dispatch) {
        if (
            currentRequest.internshipTypeId &&
            currentRequest.dateTo &&
            currentRequest.dateFrom) {
            return true;
        } else {
            if (!currentRequest.internshipTypeId) {
                dispatch(validationControlCurrentRequest("internshipTypeId", false));
            }
            if (!currentRequest.dateTo) {
                dispatch(validationControlCurrentRequest("dateTo", false));
            }
            if (!currentRequest.dateFrom) {
                dispatch(validationControlCurrentRequest("dateFrom", false));
            }
            return false;
        }
    }

    static sendToValidation(currentRequest, dispatch) {
        const periodsCheck = true;
        const keysToCheck = {
            // eslint-disable-next-line max-len
            1: ["dateFrom", "dateTo", "internshipTypeId", "contactEmail", "contactPhone", "contactPerson", "courseNumber", "chair", "faculty",
                "university", "workplacePreparationNeeded", "headPid", "structId"],

        };

        currentRequest.students &&
        currentRequest.students.forEach((element, index) => {
            if (!element.fullName) {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "fullNameValid",
                        bool: true,
                        indexArray: index,
                        arrayField: "students",
                    }),
                );
            }
            if (
                currentRequest.students &&
                currentRequest.students[index].attachments &&
                currentRequest.students[index].attachments.length === 0
            ) {
                for (let i = 0; i <= 3; i += 1) {
                    dispatch(attachmentsValidation({
                        arrIndex: index,
                        attachIndex: i,
                        falsyAttach: true,
                        arrayName: "students",
                    }));
                }
            }
            if (
                currentRequest.students &&
                currentRequest.students[index].attachments &&
                currentRequest.students[index].attachments.length !== 0
            ) {
                element.attachments && element.attachments.forEach((attach, indexAttach) => {
                    if (!attach.uid && indexAttach !== 4) {
                        dispatch(attachmentsValidation({
                            arrIndex: index,
                            attachIndex: indexAttach,
                            falsyAttach: true,
                            arrayName: "students",
                        }));
                    }
                });
            }
        });


        return (
            this.keyChecker(
                keysToCheck[1],
                currentRequest,
                dispatch,
            ) && periodsCheck
        );
    }

    static keyChecker(arrayOfKeys, currentRequest, dispatch) {
        let result = 0;
        arrayOfKeys.forEach((e) => {
            if (
                !Array.isArray(e) &&
                (currentRequest[`${e}`] === null ||
                    currentRequest[`${e}`] === undefined || currentRequest[`${e}`] === "" || currentRequest["courseNumber"] === 0)
            ) {
                result += 1;
                dispatch(validationControlCurrentRequest(e, false));
            } else if (Array.isArray(e)) {
                const key = [...e].shift();
                for (const bloodKey in currentRequest[key]) {
                    if (
                        currentRequest[key][bloodKey] === null ||
                        currentRequest[key][bloodKey] === undefined
                    ) {
                        result += 1;
                        dispatch(
                            validationControlCurrentRequest(
                                { object: key, innerKey: bloodKey },
                                false,
                                "object",
                            ),
                        );
                    }
                }
            }
        });
        return !result;
    }
}

export default UnpaidPracticeApplication;
