import { $api } from "../../../../../services/request.config";
import { getUserAddressesAction } from "../getUserAddresses.action";
import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../Requests/currentRequestControls/addBasicInputHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../Requests/currentRequestControls/validationControl.currentRequest";


export const getUserAddressesThunkAction = () => async (dispatch) => {
    try {
        const response = await $api.get("/v1.0/c/u/11");
        if (response.status === 200) {
            const addresses = {};
            const registration = response.data.data.addresses.find(element => element.addressTypeId === 1);
            addresses.registrationAddress = registration && registration.countryName && registration.address
                ? `${registration.countryName}, ${registration.address}`
                : "Данные отсутствуют";
            const actual = response.data.data.addresses.find(element => element.addressTypeId === 2);
            addresses.actualAddress = actual && actual.countryName && actual.address
                ? `${actual.countryName}, ${actual.address}`
                : "Данные отсутствуют";
            if (addresses.registrationAddress === addresses.actualAddress) {
                addresses.same = "Совпадает с адресом регистрации";
            }
            dispatch(
                addBasicInputHandlerCurrentRequest({
                    field: "registrationAddress",
                    value: addresses.registrationAddress,
                }),
            );
            dispatch(validationControlCurrentRequest("registrationAddress", true));
            dispatch(
                addBasicInputHandlerCurrentRequest({
                    field: "actualAddress",
                    value: addresses.actualAddress,
                }),
            );
            dispatch(validationControlCurrentRequest("actualAddress", true));
            return dispatch(getUserAddressesAction(addresses));
        }
    } catch (error) {
        dispatch(getUserAddressesAction({
            actualAddress: "Получение данных завершилось ошибкой",
            registrationAddress: "Получение данных завершилось ошибкой",
        }));
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field: "registrationAddress",
                value: "",
            }),
        );
        dispatch(validationControlCurrentRequest("registrationAddress", false));
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field: "actualAddress",
                value: "",
            }),
        );
        dispatch(validationControlCurrentRequest("actualAddress", false));

        throw new Error(error.response);
    }
};
