import React from "react";

const HeaderHolidaysApplication = (
    {
        days,
        multipleSelection,
        multiSelect,
    }
) => {
    return (
        <thead>
            <tr className="holidays-application-table__row">
                {/*colspan = 2 is for selector in applications [selector][application]*/}
                <td className="holidays-application-table__row__title"
                    colSpan={2}>
                    <div className="holidays-application-table__selector">
                        <div className="holidays-application-table__checkbox">
                            <input
                                checked={multiSelect}
                                onChange={multipleSelection}
                                type="checkbox"/>
                        </div>
                        <div className="holidays-application-table__title">
                        Согласование отпусков
                        </div>
                    </div>
                </td>
                <td className="holidays-application-table__row__percent">
                    <div className="holidays-application-table__percent">
                    рассчетный %
                    </div>
                </td>
                {days && days.map((day, index) => {
                    return (
                        <td className={`holidays-application-table__day-cell${day >= 19 ? "-over" : ""}`}
                            key={index}>
                            <div className="holidays-application-table__day">{day}</div>
                        </td>);
                })}
            </tr>
        </thead>
    );
};

export default HeaderHolidaysApplication;