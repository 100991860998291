import {
    PAYROLL_REQUEST,
    PAYROLL_SUCCESS,
    PAYROLL_FAILURE,
} from "../../enums/Payroll/payroll";

const initialState = {
    isLoading: false,
    payroll: null,
    error: null,
};

export const payrollReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case PAYROLL_REQUEST: {
        return {
            ...state,
            isLoading: true,
        };
    }
    case PAYROLL_SUCCESS: {
        return {
            ...state,
            isLoading: false,
            payroll: payload,
        };
    }
    case PAYROLL_FAILURE: {
        return {
            ...state,
            isLoading: false,
            error: payload,
        };
    }
    default: return state;
    }
};
