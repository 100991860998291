import React from "react";
import {Markup} from "interweave";
import "./BasicLine.scss";
function BasicLine(props) {
    return (
        <div  className={`${props.styleParent}__item`}>
            <label className={`${props.styleParent}__item__label`}>
                {props.type}
            </label>
            <div className={`${props.styleParent}__item__description`}>
                <Markup content={props.description}/>
            </div>
        </div>
    );
}

export default BasicLine;
