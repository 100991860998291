import { $api } from "./request.config";

import axios from "axios";

let isRefreshing = false;
let failedQueue = [];


const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


$api.interceptors.request.use((config) => {

    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

    return config;

}, (error) => {

    // console.log("interceptor error:", error)

    return Promise.reject(error);

});


$api.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        const originalRequest = err.config;

        if (err.response.status === 401 && !originalRequest._retry) {

            // const idleTimeout = setTimeout(() => {
            //     window.location.assign('/start')
            //     localStorage.clear()
            // }, 1000)
            console.log(7777777777);
            if (isRefreshing) {
                console.log(666666666);

                // idleTimeout()

                return new Promise(function(resolve, reject) {
                    if (err.response.request.responseURL.includes("v1/auth/refresh")) {
                        console.log(err.response.request, err.response.status);
                        const idleTimeout = setTimeout(() => {
                            window.location.assign("/start");
                            localStorage.clear();
                        }, 1000);

                    } else {
                        failedQueue.push({ resolve, reject });
                    }
                })
                    .then(token => {
                        console.log(555555555);

                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        console.log("REGECT");
                        return Promise.reject(err);
                    });
            }
            console.log(444444444);

            originalRequest._retry = true;
            isRefreshing = true;
            console.log(33333333);

            return new Promise(function(resolve, reject) {
                const id = localStorage.getItem("userId");
                const authToken = localStorage.getItem("token");
                const refreshToken = localStorage.getItem("refreshToken");
                // const signType = $api
                //     .get("v1/sections-access/general")
                //     .catch((error) => localStorage.setItem(
                //         "restricted",
                //         "false",
                //     ));
                // console.log(signType);
                $api
                    .post("v1/auth/refresh", {
                        refreshToken, id, authToken, consumer: 1,
                    })
                    .then(({ data }) => {
                        axios.defaults.headers.common["Authorization"] = "Bearer " + data.token;
                        originalRequest.headers["Authorization"] = "Bearer " + data.token;
                        processQueue(null, data.token);
                        localStorage.setItem("refreshToken", data.refreshToken);
                        localStorage.setItem("token", data.token);
                        resolve(axios(originalRequest));
                        console.log(987654321);
                        // clearTimeout(idleTimeout)
                    })
                    .catch(err => {
                        console.log(123456789);
                        processQueue(err, null);
                        reject(err);
                        // const idleTimeout = setTimeout(() => {
                        //     window.location.assign("/star");
                        //     localStorage.clear();
                        // }, 1000);
                    })
                    .then(() => {
                        console.log(123456789);
                        isRefreshing = false;
                    });


            });
        }
        return Promise.reject(err);
    },
);

export default $api;
