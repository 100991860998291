// import { getUserHrDocsThunkAction } from "./getUserHrDocs.thunk.action";

import $api from "../../../../services/axiosService";

export let status;

export const checkStatus = async (id, props, documentId) => {
    // console.log("CHECK STATUS PROPS: ", props)
    try {
        setTimeout(async () => {
            const resp = await new Promise((resolve, reject) => {
                const data = $api(
                    `v2/directum/document/signing/sign/task/${id}`,
                );
                resolve(data);
                return resp;
            });
            // eslint-disable-next-line default-case
            switch (resp.data.status) {
            case 3:
                status = resp.data.message;
                return props.setMarkDocuments((prev) => {
                    const temp = { ...prev };
                    temp[`${documentId}`] = "Не удалось подписать документ";
                    return temp;
                });
            case 1:
                return checkStatus(id, props, documentId);
            case 0:
                return checkStatus(id, props, documentId);
            case 2:
                return props.setMarkDocuments((prev) => {
                    const temp = { ...prev };
                    temp[`${documentId}`] =
                            " Подписание успешно завершено ";
                    return temp;
                });
            case 4:
                // unep needed
                // console.log("SIGNED")
                // console.log(resp.data)
                return props.setSignByUnep(resp.data);
            case -1:
                status = resp.data.message;
                return props.setMarkDocuments((prev) => {
                    const temp = { ...prev };
                    temp[`${documentId}`] = "Не удалось подписать документ";
                    return temp;
                });
            default:
                return status;
            }
        }, 1000);
    } catch (e) {
        // console.log('signingSignHrDocumentThunkAction ERROR: ', e.response)
    }
};
export const signingSignHrDocumentThunkAction =
    (packageId, documentId, workerId, props) => async (dispatch) => {
        try {
            // console.log(props)
            // props.setLoader(true)
            const response = await $api.post(
                "v2/directum/document/signing/sign/task",
                {
                    packageId: packageId,
                    documentId: documentId,
                    workerId: workerId,
                },
            );
            if (response.status === 200) {
                // console.log("status200",response.status )
                await checkStatus(response.data.id, props, documentId);
                // dispatch(getUserHrDocsThunkAction())
            }
            // console.log('signingSignHrDocumentThunkAction WORK: ')
        } catch (e) {
            // console.log('signingSignHrDocumentThunkAction ERROR: ', e.response)
        }
    };
