import {saveDraftThunkAction} from "../../../../redux/actions/Requests/thunk/saveDraft.thunk.action";
import { isTerminal } from "../../../../constants";

import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

isTerminal ? import("./SaveButtonTerminal.scss") : import("./SaveButton.scss");

const SaveButton = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function saveHandler(e) {
        props.handler();
        dispatch(saveDraftThunkAction(props.requestData));
        props.close(false);
        navigate(props.route);
    }

    return (
        <>
            <button
                onClick={saveHandler}
                className="ui-save-button">
                сохранить
            </button>
        </>
    );
};

export default SaveButton;