import {$api} from "../../../../services/request.config";
import {layoffDismissalReasonsAction} from "../layoffDismissalReasons.action";

export const layoffDismissalReasonsThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/dismissal/motive");
        if (response.status) {
            const temp = response.data.map((el) => {
                return {
                    ...el,
                    checked: false,
                };
            });
            dispatch(layoffDismissalReasonsAction(temp));
        }
    }  catch (e) {
        // console.log('ERROR WHILE GETTING DISMISSAL REASONS', e?.response)
    }
};
