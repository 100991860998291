import {initialState} from "../../init/initialState";
import {GET_LEAVES_WIDGETS, GETWORKTIMEWIDGETS, RESET_LEAVES_WIDGETS} from "../../enums/HrServices/WorkTime/workTime";

export const workTimeReducer = (state=initialState, action)=>{
    switch (action.type){
    case GET_LEAVES_WIDGETS:
        return {...state, leaves:action.payload};
    case RESET_LEAVES_WIDGETS:
        return {...state, leaves:action.payload};
    case GETWORKTIMEWIDGETS:
        return {...state, workSchedule: action.payload};
    default:
        return state;
    }
};
