import {clearAttachmentsAction} from "../clearAttachments.action";
import {$api} from "../../../../services/request.config";

export const deleteAllAttachmentThunkAction = (data) => async(dispatch) => {
    try {
        if (data.length > 0) {
            for (let i = 0; i < data.length; i+=1 ){
                await $api.delete(`v1/leaverequest/${data[i].requestId}/upload/${data[i].attachId}`);
            }
        }
        dispatch(clearAttachmentsAction());
    } catch (e) {
        // console.log('ERROR_deleteAllAttachmentThunkAction: ', e?.response)
    }
};
