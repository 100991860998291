import React from "react";
import {v4 as uuidv4} from "uuid";

const TooltipText = ({data, parentStyle}) => {
    return (
        <li key={uuidv4()}>
            <span className={`${parentStyle}__pop-up__list__dates`}>
                {data.from} - {data.to}
            </span>
            <span className={`${parentStyle}__pop-up__list__days`}>
                ({data.days} {" "}
                {"дней"})
            </span>
        </li>
    );
};

export default TooltipText;