import redCross from "./images/redCross.svg";

import {
    deleteWorkerFromImportantTaskAction,
} from "../../../redux/actions/Requests/currentRequestControls/ImportantTask/deleteWorkerFromImportantTask.action";

import {
    addWorkerToImportantTaskAction,
} from "../../../redux/actions/Requests/currentRequestControls/ImportantTask/addWorkerToImportantTask.action";

import {
    selectWorkerFromEmployeeListAction,
} from "../../../redux/actions/Requests/currentRequestControls/ImportantTask/selectWorkerFromEmployeeList.action";

import {
    addAmountToWorkerImportantTaskAction,
} from "../../../redux/actions/Requests/currentRequestControls/ImportantTask/addAmountToWorkerImportantTask.action";

import { inputValidationInArray } from "../../../redux/actions/Requests/currentRequestControls/inputValidationInArray";

import {
    getEmployeesByStructIdThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getEmployeesByStructId.thunk.action";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useSearchParams } from "react-router-dom";

const ImportantTasksWorkers = (
    {
        worker,
        workerIndex,
        setWorkerState,
    },
) => {

    const [searchParams] = useSearchParams();
    const queryString = searchParams.get("id");

    const dispatch = useDispatch();

    const dropdownRef = useRef();

    const employeeList = useSelector(state => state.requests.employeeListByStructId);
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [inputState, setInputState] = useState("");
    const [curentWorker, setCurrentWorker] = useState(currentRequest && currentRequest.workers && currentRequest.workers[workerIndex] &&
  currentRequest.uid === queryString && currentRequest.workers[workerIndex].lastName ?
        `${currentRequest.workers[workerIndex].lastName} ${currentRequest.workers[workerIndex].firstName} ${currentRequest.workers[workerIndex].secondName}`
        : "");

    function toggleMenuHandler(e) {
        setIsMenuActive(!isMenuActive);
    }

    function clickOutside(e) {
        if (!dropdownRef?.current.contains(e.target)) {
            setIsMenuActive(false);
        }
    }

    function deleteWorkerHandler(index) {
        dispatch(deleteWorkerFromImportantTaskAction(index));
        setWorkerState((prev) => {
            const temp = [...prev];
            temp.splice(index, 1);
            return temp;
        });
    }

    function selectWorkerHandler(workerIndex, pid, selectedWorker) {
        setCurrentWorker("");
        setWorkerState((prev) => {
            const temp = [...prev];
            temp[workerIndex] = {
                pid,
                name: `${selectedWorker.lastName} ${selectedWorker.firstName} ${selectedWorker.secondName} `,
                amount: 0,
            };
            return temp;
        });
        dispatch(selectWorkerFromEmployeeListAction(workerIndex, pid));
        dispatch(
            inputValidationInArray({
                fieldElement: "pidValid",
                bool: false,
                indexArray: workerIndex,
                arrayField: "workers",
            }),
        );
    }

    function addAmountToWorker(workerIndex, amount) {
        dispatch(addAmountToWorkerImportantTaskAction(workerIndex, amount));
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, []);

    return (
        <>
            <div className={"important-tasks__workers"}>
                <div
                    className={currentRequest &&
          currentRequest.workers &&
          currentRequest.workers[workerIndex] &&
          currentRequest.workers[workerIndex].pidValid ? "important-tasks__workers__dd-container-error" : "important-tasks__workers__dd-container"}
                    ref={dropdownRef}>
                    <div style={{ fontSize: "12px", lineHeight: "19px" }}>
            ФИО сотрудника *
                    </div>
                    <div
                        onClick={toggleMenuHandler}
                        className={"important-tasks__workers__selected"}>
                        <div style={{ paddingRight: "25px" }}>
                            {
                                curentWorker ? curentWorker : worker.name
                            }
                        </div>

                        <span className={`important-tasks__workers__arrow${isMenuActive ? "-up" : "-down"}`}></span>
                    </div>
                    <div className={`important-tasks__workers__dropdown${isMenuActive ? "-active" : "-hidden"}`}>
                        {
                            employeeList
                                ? employeeList.map(e => {
                                    return <div
                                        onClick={() => {
                                            selectWorkerHandler(workerIndex, e.pid, e);
                                        }}
                                        className={"important-tasks__workers__list-item"}
                                        key={uuid()}
                                    >
                                        <div className={"important-tasks__workers__list-item__fio"}>
                                            {e.lastName}
                                            {" "}
                                            {e.firstName}
                                            {" "}
                                            {e.secondName}

                                        </div>
                                        <div className={"important-tasks__workers__list-item__position"}>
                                            {e.appointment}
                                        </div>
                                        <div className={"important-tasks__workers__list-item__struct"}>
                                            {e.structName}
                                        </div>
                                    </div>;
                                })
                                : <div>Выберите подразделение</div>
                        }
                    </div>
                </div>
                <div>
                    <div style={{ fontSize: "12px", lineHeight: "19px" }}>Сумма в руб. *</div>
                    <input
                        onBlur={(event) => {
                            setWorkerState(prev => {
                                const temp = [...prev];
                                temp[workerIndex].amount = Number(inputState);
                                dispatch(
                                    inputValidationInArray({
                                        fieldElement: "amountValid",
                                        bool: false,
                                        indexArray: workerIndex,
                                        arrayField: "workers",
                                    }),
                                );
                                return temp;
                            });
                            setInputState(inputState);
                        }}

                        onChange={(event) => {
                            setInputState(event.target.value.replace(/[a-z]/i, ""));
                        }}
                        value={currentRequest && currentRequest.workers && currentRequest.workers[workerIndex] && currentRequest.workers[workerIndex].amount ? currentRequest.workers[workerIndex].amount : inputState}
                        className={currentRequest && currentRequest.workers && currentRequest.workers[workerIndex] && currentRequest.workers[workerIndex].amountValid ? "important-tasks__workers__error" : "important-tasks__workers__input"}
                        type="text"
                    ></input>

                </div>
                {
                    workerIndex !== 0
                        ? <img
                            onClick={() => deleteWorkerHandler(workerIndex)}
                            className={"important-tasks__workers__delete-worker"}
                            src={redCross} alt="delete" />
                        : null
                }
            </div>
            {/*    {*/}
            {/*        currentRequest && currentRequest.workers && currentRequest.workers[workerIndex] && currentRequest.workers[workerIndex].amountValid ||*/}
            {/*currentRequest && currentRequest.workers && currentRequest.workers[workerIndex] && currentRequest.workers[workerIndex].pidValid ?*/}
            {/*            <div className="date-popup-selector__error-message">*/}
            {/*    Обязательные поля*/}
            {/*            </div> :*/}
            {/*            null*/}
            {/*    }*/}
        </>
    );
};

export default ImportantTasksWorkers;
