import BasicApplication from "./Basic.application";

class ShiftAdditionalRestDaysApplication extends BasicApplication {
    constructor(options) {
        super(options);
        // this.leaveRequestReasonDwhId = options.leaveRequestReasonDwhId
        this.leaveRequestDwhId = options.leaveRequestDwhId;
        this.dateFrom = options.dateFrom;
        this.dateTo = options.dateTo;
        this.periods = options.periods;
        this.isValid = {
            leaveRequestDwhId: true,
            leaveReasonId: true,
            dateFrom: true,
            dateTo: true,
            periods: true,
            validDate: true,
        };
    }
}

export default ShiftAdditionalRestDaysApplication;