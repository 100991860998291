import { isTerminal } from "../../../../../../../constants";
import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";
import DatePeriodControlled from "../../../../../../common/Inputs/DatePeriodControlled/DatePeriodControlled";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import {
    getRequestsForRescheduleThunkAction,
} from "../../../../../../../redux/actions/Requests/Postpone/thunk/getRequestsForReschedule.thunk.action";
import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import RequestsHelper from "../../../../../../../frontend.services/RequestServices/Requests.helper";
import {
    emptyCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import PostponeVacationApplication from "../../../../../../../frontend.classes/Requests/PostponeVacation.application";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import {clearAttachmentsAction} from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";

import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import React, {memo, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

isTerminal ? import("./PostponeVacationTerminal.scss") : import("./PostponeVacation.scss");


const PostponeVacation = (
    {
        setRequestStatus,
        requestStatus,
        requestOption,
        setRequestState,
    }
) => {

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const reasons = useSelector(state => state.requests.reasons);
    const schedule = useSelector(state => state.requests.requestsToReschedule);
    const currentRequest = useSelector(state => state.requests.currentRequest);

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectDropdown(event, element, stateProperty) {
        dispatch(dropdownSelectionCurrentRequest({event, element, stateProperty}));
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getRequestsForRescheduleThunkAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        if (!queryId) {
            dispatch(emptyCurrentRequest
            (new PostponeVacationApplication
            ({
                leaveTypeId: 3,
                periods: [{dateFrom: null, dateTo: null}],
            })
            )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return (
        <section className='postpone-vacation'>
            <DropdownMenu
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Выберите причину отпуска <span className="required_text"> *</span></>}
                listOfOptions={reasons}
                stateProperty={"leaveReasonId"}
                isValidKey={currentRequest
                    && currentRequest.isValid
                    && currentRequest.isValid.leaveReasonId
                    ? currentRequest.isValid.leaveReasonId
                    : false}
                selectOptionClick={selectDropdown}/>
            {currentRequest && currentRequest.leaveReasonId &&
                <DropdownMenu
                    currentRequest={currentRequest}
                    styleName={"reason-second"}
                    label={<>Выберите период отпуска для переноса <span className="required_text"> *</span></>}
                    listOfOptions={schedule
                        && schedule.length
                        ? RequestsHelper.postponeDropdownFabric(schedule)
                        : [{name: "нет отпусков для переноса"}]}
                    stateProperty={"leaveRequestDwhId"}
                    selectOptionClick={selectDropdown}
                    isValidKey={currentRequest.isValid.leaveRequestDwhId}/>
            }
            {
                currentRequest
                && currentRequest.leaveRequestDwhId
                && currentRequest.periods
                && currentRequest.periods.map(
                    (period, index) => {
                        return (
                            <DatePeriodControlled
                                setRequestState={setRequestState}
                                periodsLength={currentRequest.periods.length}
                                key={uuidv4()}
                                parentStyle={"postpone-vacation"}
                                period={period}
                                index={index}/>
                        );
                    })
            }
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                changeHandler={commentHandler}
                label={"Комментарий"}
                parentStyle={"postpone-vacation"}/>
            <CustomUploader />
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
                blockingMessages={true}
            />
        </section>
    );
};

export default memo(PostponeVacation);