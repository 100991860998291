import React from "react";

const Table = ({tableStyle, children}) => {
    return (
        <table className={tableStyle}>
            {children}
        </table>
    );
};

export default Table;