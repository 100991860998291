import React from "react";
import { ClipLoader } from "react-spinners";
import "./UButton.scss";

const UButton = ({ onClick, loading, disabled, children, className }) => {
    console.log("rerender button");
    if (loading) {
        return (
            <button className={["ui-u-button",className].join(" ")} onClick={onClick} disabled={disabled}>
                <ClipLoader size={24} />
            </button>);
    } else {
        return (
            <button className={["ui-u-button",className].join(" ")} onClick={onClick} disabled={disabled}>
                {children}
            </button>
        );
    }
};

export default React.memo(UButton);