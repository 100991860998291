import GeneralData from "./GeneralData/GeneralData";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import Experience from "./Expirience/Expirience";

import {
    getGeneralDataThunkAction,
} from "../../../../redux/actions/User/profile/ServiceData/thunk/getGeneralDataThunkAction";
import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import { isTerminal } from "../../../../constants";
import { BackArrowButton } from "../../../common/componentsTerminal/BackArrowButton/BackArrowButton";

import React, { useState } from "react";
import { useDispatch } from "react-redux";

isTerminal ? import("./ServiceDataTerminal.scss") : import("./ServiceData.scss");


const ServiceData = () => {
    const dispatch = useDispatch();
    const [toggleStateData, setToggleStateData] = useState(1);

    function handlerToResetData(index) {
        setToggleStateData(index);
    // dispatch(setTypesDocumentsAction(type))
    }

    function allDataHandler() {
        setToggleStateData(1);
        dispatch(getGeneralDataThunkAction());
    }

    return (
        <>
            <Header />
            <div className="main__container--personal-data">
                <Breadcrumbs />
                <div className="block-sub-header">
                    {!isTerminal && "Служебные данные"}
                    {isTerminal && (
                        <>
                            <BackArrowButton />
                            <span>Служебные данные</span>
                        </>
                    )}
                </div>
                <div className="data-service-bread-crumbs">
                    <div
                        onClick={() => allDataHandler()}
                        className={toggleStateData === 1 ? "data-menu-item" : "data-menu-item-active"}>
            Общие данные
                    </div>
                    <div
                        onClick={() => handlerToResetData(2, "соглашение")}
                        className={toggleStateData === 2 ? "data-menu-item" : "data-menu-item-active"}>
            Стаж

                    </div>
                    <div
                        onClick={() => handlerToResetData(3, "Справка")}
                        className={toggleStateData === 3 ? "data-menu-item" : "data-menu-item-active"}>
            Дополнительная информация

                    </div>
                </div>
                <div className="content-tabs">

                    <div className={toggleStateData === 1 ? "active-content" : "content"}>
                        <GeneralData />
                    </div>
                    <div className={toggleStateData === 2 ? "active-content" : "content"}>
                        <Experience />
                    </div>
                    <div className={toggleStateData === 3 ? "active-content" : "content"}>
                        <AdditionalInformation />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ServiceData;
