import $api from "../../../../services/axiosService";
import {getRequestsForRescheduleAction} from "../getRequestsForReschedule.action";

export const getRequestsForRescheduleThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/leaverequest/leaves/rescheduling");
        dispatch(getRequestsForRescheduleAction(response.data));
    } catch (error) {
        // console.log("ERROR WHILE GETTING REQUESTS FOR RESCHEDULE: ", error.response)
    }
};
