import stylesWeb from "./ModalSupport.module.css";
import stylesTerminal from "./ModalSupportTerminal.module.css";

import UButton from "../../Button/UButton/UButton";
import { isTerminal } from "../../../../constants";

import React from "react";

const styles = isTerminal ? stylesTerminal : stylesWeb;

const ModalSupport = ({close}) => {
    return (
        <div className='modal-support-container' >
            <div className={styles["modal-support-container"]}>
                <div className={styles["show-modal-text"]}>
                    <div className={styles["header-support"]}>Поддержка</div>
                    <div>По вопросам проблем с регистрацией в Личном кабинете
                        необходимо обратиться в контактный центр оперативного центра обслуживания
                        (ОЦО): 8-800-585-58-85</div>
                </div>
                <div className={styles["big-button-support-container"]}>
                    <UButton onClick={close}>отмена</UButton>
                </div>
            </div>
        </div>
    );
};

export default ModalSupport;
