export const links = [
    {
        id:1,
        path:"/main",
        title:"Главная",
        notification:"",
    },
    {
        id:2,
        path:"/profile",
        title:"Профиль",
        notification:"",
    },
    {
        id:3,
        path:"/documents",
        title:"Документы",
        notification:" ",
    },
    {
        id:4,
        path:"/knowledge-base",
        title:"База знаний",
        notification:"",
    },
    {
        id:5,
        path:"/help",
        title:"Помощь",
        notification:"",
    },
    //, {
//     path:'/divisions',
    //     title:'Мое подразделение',
    //     notification:''
    // }
];
