import {data} from "./directorDivisionsPart.data.js";

import MultiRoute from "../../common/MultiRoute/MultiRoute";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";

import React from "react";


const MyDivision = () => {
    return (
        <>
            <Header/>
            <section
                //TO DO
                style={{marginTop:"30px"}}>
                <MultiRoute
                    breadCrumbs={false}
                    styleParent={"divisions"}
                    data={data}
                />
            </section>
            <Footer/>
        </>
    );
};

export default MyDivision;
