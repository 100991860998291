import React from "react";
import {v4} from "uuid";

const TableBody = ({children}) => {
    return (
        <tbody key={v4()}>
            {children}
        </tbody>
    );
};

export default TableBody;