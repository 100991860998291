import UButton from "../../common/Button/UButton/UButton";
import Logo from "../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";

import {getHeaderInformationThunkAction} from "../../../redux/actions/Auth/Thunk/getHeaderInformation.thunk.action";

import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";

import "./StartPage.scss";

const StartPage = () => {
//     возвращает куки с указанным name,
// или undefined, если ничего не найдено
    const navigate = useNavigate();
    const dispatch = useDispatch();
    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
        // "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function  setCookieToLocalStorage () {
        if (document.cookie) {
            const refresh = getCookie("refresh_token");
            const token = getCookie("access_token");
            const userId = getCookie("UserId");

            if (refresh && token && userId) {
                localStorage.setItem("refreshToken", refresh);
                localStorage.setItem("token", token);
                localStorage.setItem("userId", userId);
                // await AuthService.getHeaderInformation()
                // if (localStorage.getItem('refreshToken') && localStorage.getItem('token') && localStorage.getItem('userId')) {
                //     navigate('/main')
                // }
            }
        }

    }

    function buttonHandler (event) {
        event.preventDefault();
        if (
            localStorage.getItem("refreshToken")
            && localStorage.getItem("token")
            && localStorage.getItem("userId")
        ) {
            dispatch(getHeaderInformationThunkAction());
            navigate("/main");
        } else {
            navigate("/login");
        }
    }

    useEffect(() => {
        setCookieToLocalStorage ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="start-page">
                <img className="start-page__logo" src={Logo} alt="logo"/>
                <span className="start-page__text">
                            личный кабинет <br/>
                            сотрудника
                </span>
                <div onClick={(event) => buttonHandler(event)}>
                    <UButton>Войти</UButton>
                </div>
            </div>
        </>
    );
};

export default StartPage;
