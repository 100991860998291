import {getEmployeesVacationRequestsAction} from "../getEmployeesVacationRequests.action";
import $api from "../../../services/axiosService";
// import {mockAccept} from "../../../../components/common/Holidays/HolidaysTable/mockAccept.data";

export const getEmployeesVacationRequestsThunkAction = (date) => async (dispatch) => {
    try {
        dispatch(getEmployeesVacationRequestsAction([]));
        const response = await $api(`v1/leaverequest/linemanager/requests/${date.from}/${date.to}/overlaps`);

        if (response.status === 200) {
            dispatch(getEmployeesVacationRequestsAction(response.data));
        } else {
            dispatch(getEmployeesVacationRequestsAction([]));
        }
    } catch (error) {
    }
};
