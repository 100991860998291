import { isTerminal } from "../../../../../../../constants";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";
import QuestionMarkHint from "../../../../../../common/QuestionMarkHint/QuestionMarkHint";
import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import WithoutPayApplication from "../../../../../../../frontend.classes/Requests/WithoutPay.application";
import SinglePairDates from "../../../../../../common/Inputs/SinglePairDates/SinglePairDates";
import {
    emptyCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import useHelperDescription from "../../../../../../../hooks/useHelperDescription/useHelperDescription";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import {clearAttachmentsAction} from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";

import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

isTerminal ? import("./WithoutPayDropdownTerminal.scss") : import("./WithoutPayDropdown.scss");




const LeaveWithoutPay = (
    {
        requestStatus,
        setRequestStatus,
        requestOption,
    }
) => {

    const dispatch = useDispatch();

    const currentRequest = useSelector(state => state.requests.currentRequest);
    const reasonTypes = useSelector(state => state.requests.reasons);

    const [notificationWindow, setNotificationWindow] = useState(false);

    const helper = useHelperDescription();

    function selectReasonClick(event, element, stateProperty) {
        dispatch(dropdownSelectionCurrentRequest({event, element, stateProperty}));
    }
    function commentHandler (e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    useEffect(() => {
        dispatch(clearAttachmentsAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        dispatch(emptyCurrentRequest(new WithoutPayApplication({leaveTypeId: 1})));
        setRequestStatus(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return (
        <div className="rws">
            <div className="rws__select">
                <DropdownMenu
                    styleName={"reason"}
                    label={<>Выберите причину отпуска<span className="required_text"> *</span></>}
                    listOfOptions={reasonTypes}
                    stateProperty={"leaveReasonId"}
                    currentRequest={currentRequest}
                    selectOptionClick={selectReasonClick}
                    isValidKey={
                        currentRequest && currentRequest.isValid &&
                        currentRequest.isValid.leaveReasonId
                            ? currentRequest.isValid.leaveReasonId
                            : false}
                />
                {
                    currentRequest && helper
                        ? isTerminal ? <span className="rws__helperText">{helper}</span> : <QuestionMarkHint content={helper}/>
                        : null
                }
            </div>
            <SinglePairDates />
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                changeHandler={commentHandler}
                label={"Комментарий"}
                parentStyle={"postpone-vacation"}/>
            {!isTerminal && <CustomUploader />}
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
            />
        </div>
    );
};

export default memo(LeaveWithoutPay);
