import {$api} from "../../../../services/request.config";

export const documentSignigRejectThunkAction = (data, comment, input, props) => async (dispatch) => {
    try {
        props.setLoader(true);
        if (comment.isUserCommentRequired) {
            const response = await $api.post("v2/directum/document/signing/reject", {
                packageId: data.packageId,
                documentId: data.documentId,
                workerId: data.workerId,
                comment: input.text,
            });
            if(response.status === 204) {
                props.setLoader(false);
                props.setRequestStatus("сообщение об ошибке отправлено");
            }

        } else {
            const response = await $api.post("v2/directum/document/signing/reject", {
                packageId: data.packageId,
                documentId: data.documentId,
                workerId: data.workerId,
                comment: comment.comment,
            });
            if(response.status === 204) {
                props.setLoader(false);
                props.setRequestStatus("сообщение об ошибке отправлено");
            }
        }
    } catch (e) {
        props.setLoader(false);
        props.setRequestStatus("не удалось отправить сообщение об ошибке");
    }
};
