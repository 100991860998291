import $api from "../../../services/axiosService";
import { wrongAuthAunteficationAction } from "../aunteficationAction";
import { returnAuthAction } from "../../Auth/Thunk/checkAuthThunkAction";
import AuthService from "../../../services/AuthService";

export const authNumberThunkAction =
    (input, props, resetPassword = false) =>
        async (dispatch) => {
            try {
                const response = await $api.post("/v1.0/auth/code", input);

                if (response.status) {
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken,
                    );
                    localStorage.setItem("token", response.data.token);

                    if (!resetPassword) {
                        const temp = await AuthService.getHeaderInformation();

                        if (temp) {
                            return dispatch(returnAuthAction());
                        } else {
                            dispatch(
                                wrongAuthAunteficationAction(
                                    "Что-то пошло не так, повторите попытку позже",
                                ),
                            );
                        }
                    } else if (resetPassword) {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem(
                            "refreshToken",
                            response.data.refreshToken,
                        );
                        return dispatch(returnAuthAction());
                    // props.navigate('/reset-password')
                    }
                }
            } catch (e) {
                if (e.response) {
                    dispatch(wrongAuthAunteficationAction(e.response.data.detail));
                } else {
                    dispatch(
                        wrongAuthAunteficationAction(
                            "Что-то пошло не так, повторите попытку позже",
                        ),
                    );
                }
            }
        };
