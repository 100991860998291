
export const validationRules = {
    "substitutionType": ["notEmpty"]
    // [
    // {
    //     action: "notEmpty",
    //     options: {
    //         message: "chack chack",
    //     },
    // }]
    ,
    "substitutePid": ["notEmpty"],
    "delegationType": ["notEmpty"],
    "from": ["notEmpty", "earlierThan"],
};