import { $api } from "../../../../services/request.config";
import { getAllLeaveTypesAction } from "../../LeaveMetadata/getAllLeaveTypes.action";
import { mode } from "../../../../../app/mode";

export const getAvailableTypesForLeaderThunkAction = (option) => async (dispatch) => {
    try {
        const response = await $api("v1/requests/types",
            {
                params: {
                    category: `${option}`,
                },
            });

        if (response.status === 200) {
            if (response.data.length !== 0) {
                const temp = response.data.map((el) => {
                    return {
                        ...el,
                        path: `/main/hr-services/leader-requests/${el.id}`,
                        title: el.name,
                    };
                });
                // eslint-disable-next-line no-console
                let readyRequests;
                if (mode === "PROD") {
                    readyRequests = temp.filter(
                        (el) =>
                            el.id === 15 || el.id === 16,
                    );
                } else if (mode === "TEST" || mode === "UNSTABLE") {
                    readyRequests = temp.filter(
                        (el) =>
                            el.id === 15 || el.id === 16 || el.id === 19,
                    );
                }
                dispatch(getAllLeaveTypesAction(readyRequests));
            } else {
                dispatch(getAllLeaveTypesAction([]));
            }
        }
    } catch (error) {
        throw new Error(error?.response);
    }
};
