// noinspection JSXUnresolvedComponent
import { revocationValidationRules } from "./revocation.validation.rules";

import Header from "../../../../../layout/Header/Header";
import Breadcrumbs from "../../../../../common/Breadcrumbs/Breadcrumbs";
import Footer from "../../../../../layout/Footer/Footer";
import IC from "../../../../../common/compositions/InputComposition/IC";
import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import {
    getDelegationsInProgressThunkAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityRevocation/thunk/getDelegationsInProgress.thunk.action";
import {
    sendToRevokeDelegationThunkAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityRevocation/thunk/sendToRevokeDelegation.thunk.action";
import SimpleDropdown
    from "../../../../../common/compositions/InputComposition/dropdown.types/SimpleDropdown/SimpleDropdown";

import InputCompositionContext from "../../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import OkButton from "../../../../../common/Button/OKButton/OKButton";

import DelegationInProgressItem
    from "../../../../../common/compositions/InputComposition/dropdownItems.types/DelegationInProgressItem/DelegationInProgressItem";
import {
    clearErrorNotificationContentAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityDelegation/clearErrorNotificationContent.action";


import FrontendFormValidationService from "../../../../../../frontend.services/FrontendFormValidation.service";

import { useEffect, useState } from "react";

import "./AuthorityRevocation.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const AuthorityRevocation = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const delegationsInProgress = useSelector(state => state.myDivision.delegationsInProgress);
    const errorNotificationContent = useSelector(state => state.myDivision.errorNotificationContent);

    const [revocationState, setRevocationState] = useState({});
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [notification, setNotification] = useState(false);

    const context = [revocationState, setRevocationState, isError, setIsError];

    function backButtonHandler() {
        navigate("/divisions/structure/delegation");
    }

    function submitHandler() {
        const validation = new FrontendFormValidationService(revocationState, isError, setIsError, revocationValidationRules);
        if (validation.validate()) {
            dispatch(sendToRevokeDelegationThunkAction(
                revocationState,
                setIsLoading,
                setModalSuccess,
                setNotification,
            ));
        }
    }

    function closeNotificationHandler() {
        dispatch(clearErrorNotificationContentAction());
        setNotification(false);
    }

    useEffect(() => {
        dispatch(getDelegationsInProgressThunkAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

    }, [errorNotificationContent]);


    return (
        <>
            <Header />
            <Breadcrumbs />
            <main className={"revocation-transfer"}>
                <div className={"sub-header"}>
          Отзыв полномочий руководителя
                </div>
                <InputCompositionContext.Provider value={context}>

                    <IC
                        require
                        field={"substitutePid"}>
                        <IC.Label
                            value={"Выбор сотрудника, с которого необходимо снять замещение"}
                        />
                        <IC.Input />
                        <IC.Dropdown>
                            <SimpleDropdown
                                items={
                                    delegationsInProgress &&
                  delegationsInProgress.length
                                        ? delegationsInProgress
                                        : []
                                }
                            >
                                <DelegationInProgressItem />
                            </SimpleDropdown>
                        </IC.Dropdown>
                        <IC.Error />
                    </IC>

                    <div style={{ display: "flex", gap: "30px" }}>

                        <IC
                            require
                            field={"from"}
                            customStyle={"datepicker"}>
                            <IC.Label value={"Дата начала замещения"} />
                            {/*TODO PERIOD HIGHLIGHT*/}
                            <IC.DatepickerSelect
                                // minDate={
                                //     delegationsInProgress &&
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid) &&
                                //     new Date(
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid).from
                                //     )
                                // }
                                // maxDate={
                                //     delegationsInProgress &&
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid) &&
                                //     new Date(
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid).to
                                //     )
                                // }
                            />
                            <IC.Description value={"Отметьте справа, в случае бессрочного замещения"} />
                            <IC.Error />

                        </IC>

                        <IC
                            field={"to"}>
                            <IC.Label value={"Дата окончания замещения"} />
                            {/*TODO PERIOD HIGHLIGHT*/}
                            <IC.DatepickerSelect

                                // minDate={
                                //     delegationsInProgress &&
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid) &&
                                //     new Date(
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid).from
                                //     )
                                // }
                                // maxDate={
                                //     delegationsInProgress &&
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid) &&
                                //     new Date(
                                //     delegationsInProgress.find(delegation => delegation.id === revocationState.substitutePid).to
                                //     )
                                // }
                                checkbox={true} />
                            <IC.Error />
                        </IC>

                    </div>

                    <IC field={"comment"}>
                        <IC.Label value={"Комментарий"} />
                        <IC.Input />
                        <IC.Description value={"* - поля обязательные для заполнения"} />
                    </IC>

                </InputCompositionContext.Provider>


                <div style={{ width: "1160px", margin: "60px auto", display: "flex", justifyContent: "space-between" }}>
                    <OkButton
                        title={"назад"}
                        handler={backButtonHandler}
                    />
                    <OkButton
                        onValidation={isLoading}
                        title={"отозвать полномочия"}
                        handler={submitHandler}
                    />
                </div>

                {
                    notification &&
          errorNotificationContent &&
          errorNotificationContent.length
                        ? <div className={"delegation-transfer__notification"}>
                            <div onClick={closeNotificationHandler}
                                className={"delegation-transfer__notification__cross"}></div>
                            {
                                errorNotificationContent &&
                Array.isArray(errorNotificationContent)
                                    ? errorNotificationContent.map((e, i) => {
                                        return (<div key={i}><p>{e}</p> <br /></div>);
                                    })
                                    : <div>{errorNotificationContent}</div>}
                        </div>
                        : null
                }
            </main>
            <Footer />

            <ModalBanOverlay shown={modalSuccess}>
                <div className={"delegation-modal-status"}>
                    <div style={{ textAlign: "center" }} className={"delegation-modal-status__header sub-header"}>
            Делегирование успешно отозвано
                    </div>
                    <div className={"delegation-modal-status__buttons"}>
                        <OkButton title={"принять"} handler={backButtonHandler} />
                    </div>
                </div>
            </ModalBanOverlay>
        </>
    );
};

export default AuthorityRevocation;
