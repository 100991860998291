import {GETWORKTIMEWIDGETS, RESET_LEAVES_WIDGETS} from "../../enums/HrServices/WorkTime/workTime";

export const workScheduleAction = (data) => {
    return {
        type :GETWORKTIMEWIDGETS,
        payload : data,
    };
};
export const resetLeavesWidgetsAction = () => {
    return {
        type:RESET_LEAVES_WIDGETS,
        payload:null,
    };
};
