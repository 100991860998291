import {GET_CURRENT_HR_DOCS, VIEW_CURRENT_HR_DOCS} from "../../../enums/Request/request.types";

export const setCurrentHrDocsAction = (data)=>{
    // console.log(data)
    return {
        type:GET_CURRENT_HR_DOCS,
        payload:data,
    };
};
export const viewDocAction = (data)=>{
    return {
        type:VIEW_CURRENT_HR_DOCS,
    };
};
