import { divisions } from "./divisionStructure.data";

import Header from "../../../layout/Header/Header";

import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import Footer from "../../../layout/Footer/Footer";

import React from "react";


const DivisionStructure = () => {
    return (
        <>
            <Header/>
            <MultiRoute
                breadCrumbs={true}
                subHeader={"Структура подразделения"}
                styleParent={"work-time-workers"}
                data={divisions}
            />
            <Footer/>
        </>
    );
};

export default DivisionStructure;