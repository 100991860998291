import OkButton from "../../../Button/OKButton/OKButton";

import React from "react";
import {useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";

const ReplacementStatusModal = ({close}) => {

    const message = useSelector(state => state.myDivision.processingError);

    return (
        message ?
            <div className={"replacement-modal"}>
                <div className={"replacement-modal__header"}>
                    {message && message.head}
                </div>
                <div className={"replacement-modal__body"}>
                    {message && message.message }
                </div>
                <div className={"replacement-modal__buttons"} style={{justifyContent: "center"}}>
                    <OkButton
                        handler={close}
                        title={"К списку сотрудников"}/>
                </div>
            </div>
            : <ClipLoader size={"32px"} color={"#6DCDF6"}/>
    );
};

export default ReplacementStatusModal;