import { SET_CURRENT_REQUEST_FROM_CLASS } from "../../../enums/Request/currentRequestControls.types";
import UnpaidPracticeApplication from "../../../../frontend.classes/Requests/UnpaidPractice.application";
import ImportantTasksApplication from "../../../../frontend.classes/Requests/nonBasic/ImportantTasks.application";

const dict = {
    15: function(item) {
        return new UnpaidPracticeApplication(item);
    },
    16: function(item) {
        return new ImportantTasksApplication(item);
    },
};


export const setCurrentLeaderRequestFromClass = ({ currentRequest }) => {
    const { leaveTypeId } = currentRequest;
    return {
        type: SET_CURRENT_REQUEST_FROM_CLASS,
        payload: dict[leaveTypeId](currentRequest),
    };
};
