// import {$api} from "../../../services/request.config";
// import {mode} from "../../../../app/mode";
//
// import axios from "axios";

export const returnAuthAction = () => {
    return {
        type: "isAuth",
    };
};
export const noAuthAction = () => {
    return {
        type: "noAuth",
    };
};
// export const checkAuthThunkAction = () => async (dispatch) => {
//     try {
//         const id = localStorage.getItem('userId')
//         const authToken = localStorage.getItem('token')
//         const refreshToken = localStorage.getItem('refreshToken')
//
//         const response = await $api.post('/v1/auth/refresh',
//         // const response = await axios.post(`${process.env.API_PROD_URL}/v1/auth/refresh`,
//         // const response = await $api.post('/v1/auth/refresh', {id: id, authToken: authToken, refreshToken: refreshToken})
//         // const response = await axios.post(`https://po-test.alrosa.ru/api/v1/auth/refresh`,
//             {id: id, authToken: authToken, refreshToken: refreshToken}, {
//                 'Content-Type': 'Application/json',
//                 'Accept': 'Application/json',
//                 "Access-Control-Allow-Origin": mode === "PROD"
//                     ? process.env.REACT_APP_API_PROD_URL
//                     : process.env.REACT_APP_API_URL,
//                 // "Access-Control-Allow-Origin": "https://po-test.alrosa.ru/api",
//                 // withCredentials:true
//             }
//         )
//         if (response.status) {
//             localStorage.setItem('token', response.data.token)
//             localStorage.setItem('refreshToken', response.data.refreshToken)
//             dispatch(returnAuthAction())
//         }
//
//     } catch (error) {
//         console.log('ERROR WHILE CHECK AUTH:', error.response)
//     }
// }
