import UButton from "../../../../Button/UButton/UButton";

import { useNavigate } from "react-router-dom";

import "./WorkSignType.scss";

function WorkerSignType(props) {

    const navigate = useNavigate();

    return (
        <div className={"signType-modal"}>
            <span className={"signType-modal__text"}>{props.description}</span>
            {
                props.closeButton &&
                <UButton
                    onClick={
                        props.block
                            ? () => navigate(props.path)
                            : props.close
                    }
                    className={"signType-modal__button"}
                >ок</UButton>
            }

        </div>
    );
}

export default WorkerSignType;
