import {$api} from "../../../../services/request.config";
import {createSignRequestAction} from "../createSignRequest.action";

export const repeadSmsCodeForSignRequestThunkAction = (uid) => async (dispatch) => {
    try {
        const response = await $api.post(`v2/request/${uid}/create-sign-request`);
        if(response.data) {
            dispatch(createSignRequestAction(response.data));
        }
    } catch (e) {

    }
};
