import moment from "moment";

const PickedDatesCalendar = (
    {
        startDate,
        endDate,
        acceptDates,
        clearDates,
    }) => {


    return (
        <div
            className="two-months-calendar__picked-days">
            <div
                className={"tm-clear-button"}
                onClick={clearDates}>
                Очистить
            </div>
            <div className={"tm-periods"}>
                {startDate ? moment(startDate).format("DD.MM.YYYY") : "Дата начала"}
                {" "}
                -
                {" "}
                {endDate ? moment(endDate).format("DD.MM.YYYY") : "Дата окончания"}
                {" "}
            </div>
            {
                startDate && endDate
                    ?
                    <div
                        className={"tm-accept-button"}
                        onClick={acceptDates}>
                        Подтвердить
                    </div>
                    :
                    <div
                        style={{color: "grey"}}
                        className={"tm-accept-button"}>
                        Подтвердить
                    </div>
            }
        </div>
    );
};

export default PickedDatesCalendar;