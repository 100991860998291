export const GET_LEAVE_TYPES = "GET_LEAVE_TYPES";
export const GET_REASONS_TYPES = "GET_REASONS_TYPES";

export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const DELETE_ONE_REQUEST = "DELETE_ONE_REQUEST";

export const GET_CURRENT_REQUEST = "GET_CURRENT_REQUEST";
export const SET_VALIDATED_REQUEST = "SET_VALIDATED_REQUEST";
export const CLEAR_CURRENT_REQUEST = "CLEAR_CURRENT_REQUEST";
export const SET_CURRENT_REQUEST = "SET_CURRENT_REQUEST";
export const UNSET_CURRENT_REQUEST = "UNSET_CURRENT_REQUEST";

export const GET_REQUESTS_RESCHEDULE = "GET_REQUESTS_RESCHEDULE";

export const SET_CURRENT_REQUEST_CARD = "SET_CURRENT_REQUEST_CARD";
export const UNSET_CURRENT_REQUEST_CARD = "UNSET_CURRENT_REQUEST_CARD";

export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const GET_ATTACHMENTS_TO_REQUEST = "GET_ATTACHMENTS_TO_REQUEST";
export const ADD_SINGLE_ATTACHMENT = "ADD_SINGLE_ATTACHMENT";
export const DELETE_REQUEST_ATTACHMENT = "DELETE_REQUEST_ATTACHMENT";

export const GET_APPLICATION_FILE = "GET_APPLICATION_FILE";
export const DELETE_APPLICATION_FILE = "DELETE_APPLICATION_FILE";

export const GET_ALL_HR_DOCS = "GET_ALL_HR_DOCS";
export const GET_CURRENT_HR_DOCS = "GET_CURRENT_HR_DOCS";
export const UNSET_CURRENT_HR_DOCS = "UNSET_CURRENT_HR_DOCS";
export const VIEW_CURRENT_HR_DOCS = "VIEW_CURRENT_HR_DOCS";

export const GET_LEADER_REQUESTS = "GET_LEADER_REQUESTS";

export const GET_ALL_REFERENCES = "GET_ALL_REFERENCES";

export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";

//layoff
export const GET_DOCUMENT_OBTAINING = "GET_DOCUMENT_OBTAINING";
export const GET_DOCUMENT_REASONS = "GET_DOCUMENT_REASONS";
export const GET_LAYOFF_ADRESS = "GET_LAYOFF_ADRESS";
export const CREATE_SIGN_REQUEST = "CREATE_SIGN_REQUEST";

// shift days
export const GET_RESCHEDULE_SHIFT_DAYS = "GET_RESCHEDULE_SHIFT_DAYS";
export const CLEAR_SHIFT_DAYS = "CLEAR_SHIFT_DAYS";

//MaterinityLeave
export const GET_MATERINITY_DIS_DOCS = "GET_MATERINITY_DIS_DOC";

//StudyLeave
export const GET_EDUCATIONAL_PROGRAMS = "GET_EDUCATIONAL_PROGRAMS";
export const GET_COURSE_NUMBERS = "GET_COURSE_NUMBERS";
export const DROPDOWN_SELECT_STUDY_LEAVE_REQUEST =
  "DROPDOWN_SELECT_STUDY_LEAVE_REQUEST";

// PlannedRevocation
export const GET_PLANNED_REVOCATION = "GET_PLANNED_REVOCATION";
export const ADD_BLOOD_DONATION_INFORMATION = "ADD_BLOOD_DONATION_INFORMATION";
export const ADD_EMPTY_STRING = "ADD_EMPTY_STRING";

// unpaid Practice
export const GET_INTERNSHIPS_TYPES = "GET_INTERNSHIPS_TYPES";
export const GET_INTERNSHIPS_STRUCTS = "GET_INTERNSHIPS_STRUCTS";
export const GET_INTERNSHIPS_STRUCTS_EMPLOYEES =
  "GET_INTERNSHIPS_STRUCTS_EMPLOYEES";
export const DROPDOWN_SELECT_UNPAID_PRACTICE =
  "DROPDOWN_SELECT_UNPAID_PRACTICE";
export const ADD_STUDENT_HANDLER_CURRENT_REQUEST =
  "ADD_STUDENT_HANDLER_CURRENT_REQUEST";
export const DELETE_STUDENT_HANDLER_CURRENT_REQUEST =
  "DELETE_STUDENT_HANDLER_CURRENT_REQUEST";
export const BASIC_INPUT_STUDENTS_REQUEST = "BASIC_INPUT_STUDENTS_REQUEST";
export const NOT_VALID_INPUT = "NOT_VALID_INPUT";

// ParentalLeave
export const ADD_CHILDREN_TO_REQUEST = "ADD_CHILDREN_TO_REQUEST";
export const DELETE_CHILDREN_FROM_REQUEST = "DELETE_CHILDREN_FROM_REQUEST";
export const ADD_PARENT_TO_REQUEST = "ADD_PARENT_TO_REQUEST";
export const GET_CLAIMANT_STATUSES = "GET_CLAIMANT_STATUSES";
export const GET_GENDER_FOR_CHILD = "GET_GENDER_FOR_CHILD";
export const GET_ORDER_OF_CHILD = "GET_ORDER_OF_CHILD";
export const GET_PARENTAL_STATUS = "GET_PARENTAL_STATUS";
export const GET_RELATIVE_TYPES = "GET_RELATIVE_TYPES";
export const GET_PARENTAL_FULLNAME = "GET_PARENTAL_FULLNAME";
export const ADD_INPUT_TO_ARRAY_IN_REQUEST = "ADD_INPUT_TO_ARRAY_IN_REQUEST";
export const GET_LIST_OF_CHILDREN = "GET_LIST_OF_CHILDREN";
export const GET_INFO_OF_CHILD = "GET_INFO_OF_CHILD";

// IMPORTANT TASKS
export const ADD_WORKER_TO_IMPORTANT_TASK = "ADD_WORKER_TO_IMPORTANT_TASK";
export const DELETE_WORKER_FROM_IMPORTANT_TASK = "DELETE_WORKER_FROM_IMPORTANT_TASK";
export const SELECT_WORKER_FROM_EMPLOYEE_LIST = "SELECT_WORKER_FROM_EMPLOYEE_LIST";
export const ADD_AMOUNT_TO_WORKER_IMPORTANT_TASK = "ADD_AMOUNT_TO_WORKER_IMPORTANT_TASK";
export const ADD_WORKERS_WHITH_AMMOUNT = "ADD_WORKERS_WHITH_AMMOUNT";
