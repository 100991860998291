import { allDocumentsData } from "./allDocuments.data";

import { isTerminal } from "../../../../constants";

import { LeftArrow }  from "../../../../assets/icons/LeftArrow";
import { RightArrow }  from "../../../../assets/icons/RightArrow";

import { memo, useState } from "react";
import ReactPaginate from "react-paginate";


isTerminal ? import("./AllDocumentsTerminal.scss") : import("./AllDocuments.scss");

const AllDocuments = (
    {
        allDocuments,
        // currentRows,
        currentRowsHandler,
        rowsPerPage,
    },
) => {

    // const dispatch = useDispatch();
    const [modalShownReferences, toggleModalShownReferences] = useState(false);
    // const [description, setDescription] = useState("");
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + rowsPerPage;
    const currentItems = allDocuments && allDocuments.sort((a, b) => b.el.typeDate - a.el.typeDate).slice(itemOffset, endOffset);
    const pageCount = Math.ceil(allDocuments.length / rowsPerPage);

    function clickHandlerReferences() {
        toggleModalShownReferences(!modalShownReferences);
    }

    // function closeHandlerReferences(e) {
    //     toggleModalShownReferences(false);
    // }


    const handlePageClick = (event) => {
        const newOffset = (event.selected * rowsPerPage) % allDocuments.length;
        setItemOffset(newOffset);
    };

    return !allDocuments.length
        ? <thead>
            <tr className="table__row">
                {allDocumentsData && allDocumentsData.map((el) => {
                    return (
                        <th
                            key={el.id}
                            style={{ width: `${el.width}` }}
                            className="table__row__item"
                        >
                            {el.title}
                            {el.icon ?
                                <img
                                    src={el.icon}
                                    alt="triangle"
                                    className="header-triangle"
                                />
                                : null
                            }
                        </th>
                    );
                })
                }
            </tr>
        </thead>
        : (<div>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr className="table__row">
                            {
                                allDocumentsData &&
                allDocumentsData.map((el) => {
                    return (
                        <th
                            key={el.id}
                            style={{ width: `${el.width}` }}
                            className="table__row__item"
                        >
                            {el.title}
                            {
                                el.icon
                                    ? <img
                                        src={el.icon}
                                        alt="triangle"
                                        className="header-triangle" />
                                    : null
                            }
                        </th>
                    );
                })
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {currentItems && currentItems.map((el, i) => (
                            <tr
                                onClick={() => (clickHandlerReferences(el.workorderid))}
                                key={i}
                                className={"row-request-allDocuments"}
                            >
                                <td style={{ width: isTerminal ? "111px": "98px" }}>{el.el.typeText}</td>
                                <td style={{ width: isTerminal ? "203px": "368px" }}>{el.el.typeName}</td>
                                <td style={{ width: isTerminal ? "174px": "181px" }}>{el.el.typeViewTo}</td>
                                <td style={{ width: isTerminal ? "140px" : "164px" }}>{el.el.typeSignTo}</td>
                                <td
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ width: isTerminal ? "144px" : "192px" }}>
                                    {
                                        el.el.fileName
                                            ? <p
                                                className="request-link-allDocuments">{el.el.fileName}
                                            </p>
                                            : el.el.fileName
                                    }
                                </td>
                                <td style={{ width: isTerminal ? "108px" : "177px" }}>
                                    {el.el.typeStatus}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className={ isTerminal && "pagination-wrapper"}>
                    {!isTerminal && <div className="pagination-container">
                        <div className="pagination-container__rows">
                            <div
                                onClick={() => currentRowsHandler(8)}
                                className={rowsPerPage === 5 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                    5
                            </div>
                            <div
                                onClick={() => currentRowsHandler(10)}
                                className={rowsPerPage === 10 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                    10
                            </div>
                            <div
                                onClick={() => currentRowsHandler(20)}
                                className={rowsPerPage === 20 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>

                                    20
                            </div>

                            <div
                                onClick={() => currentRowsHandler(50)}
                                className={rowsPerPage === 50 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                    50
                            </div>
                        </div>

                    </div>}
                    <ReactPaginate
                        className={"pagination"}
                        breakLabel=".."
                        nextLabel={isTerminal ? <RightArrow /> : ">"}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        previousLabel={isTerminal ? <LeftArrow /> : "<"}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
        );
};

export default memo(AllDocuments);
