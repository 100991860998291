import { $api } from "../../../../services/request.config";
import { getClaimantStatusesAction } from "../getClaimantStatuses.action";

export const getClaimantStatusesThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/requests/leaves/parental/relatives");
        dispatch(getClaimantStatusesAction(response.data));
    } catch (error) {
        throw new Error(error.response);
    }
};
