import $api from "../../../../services/axiosService";
import {getGantRepresentationAction} from "../getGantRepresentation.action";
import {clearGantRepresentationAction} from "../clearGantRepresentation.action";

export const getGantRepresentationThunkAction = ({from, to}) => async (dispatch) => {
    try {
        dispatch(getGantRepresentationAction());
        const response = await $api(`v1/p/po/lead/s/leaves/${from}/${to}`);
        if (response.status === 200) {
            dispatch(getGantRepresentationAction(response.data));
        } else {
            dispatch(clearGantRepresentationAction());
        }
    } catch (error) {
        // console.log("ERROR WHILE GETTING GANT REPRESENTATION: ", error.response)
    }
};
