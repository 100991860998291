import { $api } from "../../../../../services/request.config";
import { getErrorNotificationContentAction } from "../getErrorNotificationContent.action";

export const sendDelegationEmployeeThunkAction =
    (
        delegationState,
        setIsLoading,
        setModalSuccess,
        setNotification,
    ) => async (dispatch) => {
        try {
            setIsLoading(true);
            const response = await $api.post("v2/manager/delegations", delegationState);
            if (response.status === 202) {
                setModalSuccess(true);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response.status === 422) {
                const { errors } = error.response.data;
                if (errors) {
                    const arrOfErrors = [];
                    for (const key in errors) {
                        errors[key].forEach(e => arrOfErrors.push(e));
                    }
                    dispatch(getErrorNotificationContentAction(arrOfErrors));
                    setNotification(true);
                }
            }
        }
    };