import {returnAuthAction} from "./checkAuthThunkAction";

import $api from "../../../services/axiosService";

import {setStateToNullResetPasswordAction} from "../resetStatetePasswordAction";
import {resetPasswordWrongAction} from "../resetPasswordAction";
import AuthService from "../../../services/AuthService";



export const newPasswordThunkAction = (input, props) => async(dispatch)=>{
    const {userId, password} = input;
    try{
        const response = await $api.post("/v1.0/auth/restore-password",{userId:Number(userId),password:password});
        switch (response.status) {
        case 200:
            localStorage.removeItem("resetPassword");
            const temp = await AuthService.getHeaderInformation();
            if (temp) {
                dispatch(returnAuthAction());
                props.navigate("/main");
                return dispatch(setStateToNullResetPasswordAction());
            } else {
                return dispatch(resetPasswordWrongAction("Что-то пошло не так, повторите попытку позже"));

            }
        default: return null;
        }

    }catch (e) {
        // console.log(e.response)
        dispatch(resetPasswordWrongAction(e.response.data));
    }
};
