import stylesTerminal from "./ButtonStyleTerminal.module.css";

export const ButtonTerminal = (props) => {
    const { 
        label, 
        variant = "container", 
        textAlign = "text-center", 
        children,
    } = props;

    return (
        <button className={`${stylesTerminal[variant]} ${stylesTerminal[textAlign]}`} {...props}>
            {label}
            {children}
        </button>
    );
};
