import moment from "moment";

class HelperService {
    static capitalizeSentence (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    // TODO REFACTOR
    static getLastNameWithInitials (fullName) {
        const split = fullName.split(" ");
        if (split.length > 2) {
            return `${split[0]} ${split[1][0]}. ${split[2][0]}.`;
        }
        return `${split[0]}`;
    }
    static isContainsInPeriod (arrOfRanges, period) {
        let temp = false;
        const {dateFormat} = period;
        const startOfMonth = moment(dateFormat).startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment(dateFormat).endOf("month").format("YYYY-MM-DD");
        if (arrOfRanges) {
            for (let i = 0; i < arrOfRanges.length; i += 1) {
                if (moment(arrOfRanges[i].dateFrom).isBetween(startOfMonth, endOfMonth)) {
                    temp = true;
                    break;
                }
                if (moment(arrOfRanges[i].dateTo).isBetween(startOfMonth, endOfMonth)) {
                    temp = true;
                    break;
                }
            }
        }
        return temp;
    }
    static wordSearch (string, searchValue) {
        const hasSpase = string.match(/\s/gmi);
        if (hasSpase && hasSpase.length) {
            const split = string.split(" ");
            split.forEach(word => {
                if (word.includes(searchValue)) {
                    // const start = word.indexOf(searchValue);
                    // const word.splice(start, start + searchValue.length)
                }
            });
        }
        return "test";
    }
    static getHighlightedText(text, highlight) {
        const multipleSearch = highlight.trim().split(" ");
        if (multipleSearch.length > 1) {
            
        }
        const parts = text.split(new RegExp(`(${highlight})`, "gi"));
        return <span> { parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: "bold" } : {} }>
                { part }
            </span>)
        } </span>;
    }
    static setCellStyleCrossTable (arrOfRanges, day) {
        if (arrOfRanges) {
            for (let i = 0; i < arrOfRanges.length; i += 1) {
                const before = moment(arrOfRanges[i].dateFrom).format("DD");
                const after = moment(arrOfRanges[i].dateTo).format("DD");
                if (before <= day && after >= day) {
                    return true;
                }
            }
        }
        return false;
    }
}

export default HelperService;