import {
    getExpirienceInformationThunkAction,
} from "../../../../../redux/actions/User/profile/ServiceData/thunk/getExpirienceInformationThunkAction";
import $api from "../../../../../redux/services/axiosService";
import BasicLine from "../../../../common/BasicLine/BasicLine";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import "./Expirience.scss";

const Experience = ({ workExp }) => {
    const dispatch = useDispatch();
    const experience = useSelector((state) => state.personalData.experience);
    // const [, setExperienceInfo] = useState(experience);
    //
    // const [, setPercentCompany] = useState(false);
    // const [, setPercentRks] = useState(false);
    //
    // const [current, setCurrent] = useState({});

    // function calc(current) {
    //     if (current.total) {
    //         const totalMonth = current.total.years * 12 + current.total.months;
    //         if (current.company) {
    //             const companyMonth =
    //               current.company.years * 12 + current.company.months;
    //             const companyPercents = (companyMonth * 100) / totalMonth;
    //             setPercentCompany(Math.floor(companyPercents));
    //             if (current.rks) {
    //                 const rksMonth =
    //                   current.rks.years * 12 + current.rks.months;
    //                 const rcsPercents =
    //                   (rksMonth / companyMonth) * companyPercents;
    //                 setPercentRks(Math.floor(rcsPercents));
    //             }
    //         } else if (current.rks) {
    //             const rksMonth = current.rks.years * 12 + current.rks.months;
    //             const rcsPercents = (rksMonth * 100) / totalMonth;
    //             setPercentRks(Math.floor(rcsPercents));
    //         }
    //     }
    // }

    useEffect(() => {
        dispatch(getExpirienceInformationThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="fio">
            {experience &&
            experience?.map((el, index) => {
                return (
                    <div key={index}>
                        <BasicLine
                            key={index}
                            type={el.type}
                            description={el.description}
                            styleParent={"fio"}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Experience;
