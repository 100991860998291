import {initialState} from "../../init/initialState";

export const resetPasswordReducer = (state = initialState,action)=>{
    const {type,payload} = action;
    switch (type) {
    case "BAD_REQUEST":
        return payload;
    case "RETURN_TO_NULL":
        return null;
    default:
        return state;
    }
};
