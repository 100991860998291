import bluePlus from "./images/bluePlus.svg";

import {
    getStructureHeadThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getStructureHead.thunk.action";
import {
    getStructureTopThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getStructureTop.thunk.action";
import {
    getFunctionMarkerThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getFunctionMarker.thunk.action";
import {
    clearStructureTopAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearStructureTop.action";
import {
    clearStructureTreeAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearStructureTree.action";
import {
    clearFunctionMarkersAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearFunctionMarkers.action";
import {
    setSelectedStructItemAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/setSelectedStructItem.action";
import {
    clearSelectedStructItemAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/clearSelectedStructItem.action";
import {
    getEmployeesByStructIdThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getEmployeesByStructId.thunk.action";
import {
    clearEmployeeListAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearEmployeeList.action";
import {
    addWorkerToImportantTaskAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/addWorkerToImportantTask.action";
import ImportantTasksService from "../../../../../../frontend.services/LeaderRequests/ImportantTasks.service";

import ImportantTasksWorkers from "../../../../../common/ImportantTasksWorkers/ImportantTasksWorkers";
import ImportantTasksDropdownTree from "../../../../../common/ImportantTasksDropdownTree/ImportantTasksDropdownTree";
import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import CustomUploader from "../../../../../common/CustomUploader/CustomUploader";
import RequestButtons from "../../../../../common/Button/RequestButtons/RequestButtons";
import CommentInput from "../../../../../common/Inputs/CommentInput/CommentInput";
import {
    selectDropdownMenuItemAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/selectDropdownMenuItem.action";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";

import ImportantTasksApplication from "../../../../../../frontend.classes/Requests/nonBasic/ImportantTasks.application";
import {
    emptyCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";

import {
    calendarDatePickCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    addWorkersToAmmountAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/ImportanyTask/addWorkersToAmmount.action";
import { clearAttachmentsAction } from "../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    requestValidationForLeaderRequestsThunkAction,
} from "../../../../../../redux/actions/Requests/RequestValidation/thunk/requestValidationForLeaderRequests.thunk.action";
import UnpaidPracticeApplication from "../../../../../../frontend.classes/Requests/UnpaidPractice.application";
import {
    saveDraftCurrentLeaderRequestThunk,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/thunk/saveDraftCurrentLeaderRequest.thunk.action";
import {
    updateCurrentLeaderRequestThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/thunk/updateCurrentLeaderRequest.thunk.action";
import NotificationWindowRequests from "../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import EmptyBody from "../../../../../common/EmptyBody/EmptyBody";
import { Modal } from "../../../../../common/Modal/Modal";
import BackHandlerModal from "../../../../../common/Modal/ModalConditions/Requests/BackHandlerModal/BackHandlerModal";
import SaveSuccessModal from "../../../../../common/Modal/ModalConditions/Requests/SaveSuccessModal/SaveSuccessModal";
import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import RequestVerification
    from "../../../../../common/Modal/ModalConditions/Requests/RequestVerification/RequestVerification";
import Error500Modal from "../../../../../common/Modal/ModalConditions/Requests/Error500Modal/Error500Modal";

import DatePopupSelector from "../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";
import ImportantTasksDescription from "../../../../../common/ImportantTasksDescription/ImportantTasksDescription";
import SingleUploaderNewVersion from "../../../../../common/SingleUploaderNewVersion/SingleUploaderNewVersion";

import {
    editStructureAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/ImportanyTask/editStructure.action";

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuid } from "uuid";

import "./ImportantTasks.scss";


const StructTreeItem = forwardRef((
    {
        item,
    },
    ref,
) => {

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [innerStructOpen, setInnerStructOpen] = useState(false);

    function selectHandler(item, event) {
        setInnerStructOpen(!innerStructOpen);
        if (document.getElementsByClassName("selected-menu-tree-struct").length) {
            document.getElementsByClassName("selected-menu-tree-struct")[0].classList.remove("selected-menu-tree-struct");
            document.getElementById("menu-tree-arrow").remove();
        }
        setSelectedItemId(item.id);
    }


    return (
        <div>
            <div
                onClick={(event) => {
                    selectHandler(item, event);
                    ref.current = item;
                }}
                className={`menu-tree-struct ${selectedItemId === item.id ? "selected-menu-tree-struct" : ""}`}
            >
                {
                    item.children.length
                        ? <span className={`menu-tree-struct__arrow${innerStructOpen ? "__open" : ""}`}></span>
                        : <span className={"menu-tree-struct__buffer"}></span>
                }
                {item.name}
                {
                    selectedItemId === item.id
                        ? <span id={"menu-tree-arrow"} className={"menu-tree-struct__selected-arrow"}></span>
                        : null
                }
            </div>
            {
                item.children.length
                    ? (
                        <div
                            className={`menu-tree-struct__sub-menu ${innerStructOpen ? "" : "sub-menu-shrunk"}`}
                        >
                            {
                                item.children.map(subItem => {
                                    return <StructTreeItem key={uuid()} item={subItem} ref={ref} />;
                                })
                            }
                        </div>

                    )
                    : null
            }
        </div>
    );
});
export const StructTreeSelector = (
    {
        tree,
        setIsMenuActive,
        setWorkersState,
    },
) => {

    const ref = useRef(null);

    const dispatch = useDispatch();

    function acceptHandler() {
        dispatch(editStructureAction());
        dispatch(setSelectedStructItemAction(ref.current));
        dispatch(getEmployeesByStructIdThunkAction(ref.current.id));
        setIsMenuActive(false);
        setWorkersState([{ pid: "", amount: "", name: "" }]);
    }

    function declineHandler() {
        setIsMenuActive(false);
    }


    return tree
        ? (
            <>
                <div className={"tree"}>
                    {
                        tree.map(node => <StructTreeItem
                            key={uuid()}
                            item={node}
                            ref={ref}
                        />)
                    }
                </div>
                <div className={"tree__buttons"}>
                    <div
                        className={"tree__buttons__decline"}
                        onClick={declineHandler}
                    >
                        ОТМЕНА
                    </div>
                    <div
                        className={"tree__buttons__accept"}
                        onClick={acceptHandler}
                    >
                        ПРИНЯТЬ
                    </div>
                </div>
            </>

        )
        : <div>loading</div>;
};


const ImportantTasks = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const [workersState, setWorkersState] = useState(queryId && currentRequest ? currentRequest.workers : [{
        pid: "",
        amount: 0,
    }]);
    const currentRequest = useSelector(state => state.requests.currentRequest);

    const structTree = useSelector(state => state.requests.structTree);
    const structTop = useSelector(state => state.requests.structTop);
    const functionMarkers = useSelector(state => state.requests.functionMarkers);
    const structItem = useSelector(state => state.requests.selectedStructItem);
    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [onValidation, setOnValidation] = useState(false);

    // const [typesListLoader, setTypesListLoader] = useState(false);
    //
    // const [, setRequestOption] = useState(null);

    // NOTIFICATION IN CONTROL PROCEDURES CONTENT
    const [requestStatus, setRequestStatus] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
    const [modalSentStatus, setModalSentStatus] = useState(false);
    const [notificationWindow, setNotificationWindow] = useState(false);

    const [isRunningSaveHandler, setIsRunningSaveHandler] = useState(false);
    const [isRunningSendHandler, setIsRunningSendHandler] = useState(false);


    const [modalError, setModalError] = useState({
        sign: false,
        validation: false,
        status: false,
    });
    const fundingSource = [
        {
            id: 0,
            name: "Фонд руководителя актива",
        },
        {
            id: 1,
            name: "Фонд руководителя функции",
        },
    ];

    function backHandler(e) {
        currentRequest && currentRequest.leaveReasonId
            ? setModalStatus(true)
            : navigate("/main/hr-services");
    }

    function selectDropdown(event, element, stateProperty) {
        dispatch(selectDropdownMenuItemAction(event, element, stateProperty));
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    function changeDateHandler(field, date) {
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(validationControlCurrentRequest(field, true));
        dispatch(calendarDatePickCurrentRequest({ field: field, date: value }));
    }

    function addWorkerHandler() {
        if (currentRequest && queryId) {
            setWorkersState(currentRequest.workers);
        }
        dispatch(addWorkerToImportantTaskAction());
        setWorkersState((prev) => {
            return [...prev, { pid: "", amount: "", name: "" }];
        });
    }

    async function saveHandler(e) {
        let result = false;
        const temp = await ImportantTasksService.filterWorkers(currentRequest, dispatch, setWorkersState, workersState);
        if (ImportantTasksService.save(temp, dispatch, setWorkersState, workersState)) {
            setModalSuccessStatus(true);
            setIsRunningSaveHandler(true);
            if (!currentRequest.uid) {
                result = dispatch(
                    saveDraftCurrentLeaderRequestThunk(
                        temp,
                        setModalSuccessStatus,
                        params.id,
                    ),
                );
            } else {
                result = dispatch(
                    updateCurrentLeaderRequestThunkAction(
                        temp,
                        setModalSuccessStatus,
                        params.id,
                    ),
                );
            }
        }

        if (result)
            result.then(()=>{
                setIsRunningSaveHandler(false);
            })
        else setIsRunningSaveHandler(false);


    }

    function submitHandler() {
        let result = false;
        dispatch(addWorkersToAmmountAction(workersState));
        if (ImportantTasksService.validation(currentRequest, dispatch)) {
            setModalSentStatus(true);
            setIsRunningSendHandler(true);
            result = dispatch(
                requestValidationForLeaderRequestsThunkAction({
                    currentRequest,
                    setRequestStatus,
                    stateLoader: setSendButtonLoading,
                    closeModal: setModalSentStatus,
                    setModalError,
                    option: Number(params.id),
                }),
            );
        }
        if (result)
            result.then(()=>{
                setIsRunningSendHandler(false);
            })
        else setIsRunningSendHandler(false);
    }

    useEffect(() => {
        dispatch(addWorkersToAmmountAction(workersState));
    }, [workersState]);


    useEffect(() => {
        dispatch(getStructureHeadThunkAction());
        dispatch(getStructureTopThunkAction());
        dispatch(getFunctionMarkerThunkAction());
        dispatch(clearAttachmentsAction());
        if (!queryId) {
            dispatch(emptyCurrentRequest(new ImportantTasksApplication({ workers: [{ pid: "", amount: 0 }] })));
        }

        return () => {
            dispatch(clearStructureTreeAction());
            dispatch(clearStructureTopAction());
            dispatch(clearFunctionMarkersAction());
            dispatch(clearSelectedStructItemAction());
            dispatch(clearEmployeeListAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);

        }
    }, [requestStatus]);

    return (
        <div>
            <div style={{ width: "1160px", margin: "0 auto", minHeight: "35vh" }}>
                {/*<Breadcrumbs />*/}
                <div className={"sub-header"} style={{ width: "767px", margin: "0 0 60px 0" }}>
                    Премирование за выполнение ОВЗ
                </div>
                <div className={"important-tasks"}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "1160px",
                            margin: "auto",
                            gap: "15px",
                        }}
                    >
                        <DatePopupSelector
                            field={"dateFrom"}
                            label={"Дата начала выполнения работ *"}
                            errorMessage={
                                currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.dateFrom
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={
                                currentRequest && currentRequest.dateFrom
                                    ? new Date(currentRequest.dateFrom)
                                    : null
                            }
                            minDate={new Date(moment().subtract(12, "months"))}
                            maxDate={currentRequest &&
                            currentRequest.dateTo ? currentRequest.dateTo === "Invalid date"
                                    ? new Date(moment().subtract(1, "days"))
                                    : new Date(currentRequest.dateTo)
                                : new Date(moment().subtract(1, "days"))
                            }
                        />
                        <DatePopupSelector
                            field={"dateTo"}
                            label={"Дата окончания выполнения работ *"}
                            errorMessage={
                                currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.dateTo
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={
                                currentRequest && currentRequest.dateTo ? new Date(currentRequest.dateTo) : null
                            }
                            minDate={currentRequest && currentRequest.dateFrom
                                ? currentRequest.dateFrom === "Invalid date"
                                    ? new Date(moment().subtract(12, "months"))
                                    : new Date(currentRequest.dateFrom)
                                : new Date(moment().subtract(12, "months"))}
                            maxDate={new Date(moment().subtract(1, "days"))}

                        />
                    </div>
                    <ImportantTasksDescription
                        value={currentRequest && currentRequest.description}
                        field={"description"}
                        message={"Опишите состав поставленного задания"}
                        textAreaLabel={"Описание задания *"} />

                    <ImportantTasksDescription
                        value={currentRequest && currentRequest.goal}

                        field={"goal"}
                        message={"Опишите состав поставленного задания"}
                        textAreaLabel={"Цель задания *"} />

                    <DropdownMenu
                        styleName={"important-task"}
                        label={"Источник премирования *"}
                        listOfOptions={fundingSource ? fundingSource : []}
                        stateProperty={"fundingSource"}
                        currentRequest={currentRequest}
                        selectOptionClick={selectDropdown}
                        isValidKey={
                            currentRequest && currentRequest.isValid &&
                            currentRequest.isValid.fundingSource
                                ? currentRequest.isValid.fundingSource
                                : false} />

                    <DropdownMenu
                        styleName={"important-task"}
                        label={"Актив *"}
                        listOfOptions={structTop ? structTop : []}
                        stateProperty={"assetId"}
                        currentRequest={currentRequest}
                        selectOptionClick={selectDropdown}
                        isValidKey={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.assetId
                                ? currentRequest.isValid.assetId
                                : false} />

                    {
                        currentRequest &&
                        currentRequest.fundingSource === 1 &&
                        <DropdownMenu
                            styleName={"important-task"}
                            label={"Маркер функции *"}
                            listOfOptions={functionMarkers ? functionMarkers : []}
                            stateProperty={"functionMarkerId"}
                            currentRequest={currentRequest}
                            selectOptionClick={selectDropdown}
                            isValidKey={
                                currentRequest && currentRequest.isValid &&
                                currentRequest.isValid.functionMarkerId
                                    ? currentRequest.isValid.functionMarkerId
                                    : false} />
                    }

                    <div className={"important-tasks__legend"}>
                        Сумма премирования не должна быть меньше 10% от должностного
                        оклада/месячной тарифной ставки работника с учетом РКиСН/КРРТ
                        и не может превышать шести должностных окладов/месячных тарифных
                        ставок с учетом РКиСН/КРРТ по совокупности премий за ОВЗ в год.
                        В случае нарушения условий методики,
                        премирование может быть не согласовано.
                    </div>

                    <ImportantTasksDropdownTree
                        currentRequest={currentRequest && currentRequest}
                        isValid={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.structId
                        }
                        setWorkersState={setWorkersState}
                    />

                    {
                        workersState &&
                        workersState.map((worker, workerIndex) => {
                            return <ImportantTasksWorkers
                                key={uuid()}
                                worker={worker}
                                workerIndex={workerIndex}
                                pidValid={worker.pidValid}
                                amountValid={worker.amountValid}
                                setWorkerState={setWorkersState}
                            />;
                        })
                    }

                    <div style={{ display: "flex", gap: "20px", height: "48px", alignItems: "center" }}>
                        <div style={{ fontSize: "18px", lineHeight: "42px" }}>
                            Добавить еще одного работника
                        </div>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={addWorkerHandler}
                            src={bluePlus}
                            alt="add" />
                    </div>

                    <div>
                        <div style={{ fontSize: "12px" }}>Итого суммы премии</div>
                        <div style={{
                            width: "587px",
                            borderBottom: "1px solid #eeeeee",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            {
                                workersState &&
                                workersState.reduce((acc, worker) => acc += worker.amount, 0)
                            }
                        </div>
                    </div>


                    <CommentInput
                        maxLength={255}
                        value={currentRequest && currentRequest.employeeComment}
                        changeHandler={commentHandler}
                        label={"Комментарий"}
                        parentStyle={"postpone-vacation"} />

                    <SingleUploaderNewVersion
                        label={"Прикрепить скан (фото) документов "}
                        i={0}
                        fileName={"Отпуск_по_уходу_за_ребенком.pdf"}
                        subTypeNumber={7}
                    />

                    <div style={{ marginTop: "40px" }}></div>
                    {/*<RequestButtons*/}
                    {/*  sendHandler={submitHandler}*/}
                    {/*  // saveHandler={saveHandler}*/}
                    {/*  // backHandler={backHandler}*/}
                    {/*/>*/}
                    <NotificationWindowRequests
                        notificationWindowStatus={requestStatus}
                        setNotificationWindowStatus={setRequestStatus}
                        notificationWindowStyle={notificationWindow}
                        blockingMessages={true}
                    />
                    {Number(params.id) === 16 ? (
                        <RequestButtons
                            sendHandler={submitHandler}
                            saveHandler={saveHandler}
                            backHandler={backHandler}
                            isRunningSaveHandler={isRunningSaveHandler}
                            isRunningSendHandler={isRunningSendHandler}
                        />
                    ) : (
                        <EmptyBody backHandler={backHandler} />
                    )}

                    {/*BACK*/}
                    <Modal shown={modalStatus} close={setModalStatus}>
                        <BackHandlerModal
                            handler={saveHandler}
                            close={setModalStatus}
                        />
                    </Modal>
                    {/*SAVE SUCCESS*/}
                    <Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>
                        <SaveSuccessModal
                            route={"/main/hr-services"}
                            close={setModalSuccessStatus}
                        />
                    </Modal>
                    {/*REQUEST SEND*/}
                    <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                        <RequestVerification
                            onValidation={onValidation}
                            setOnValidation={setOnValidation}
                            setModalError={setModalError}
                            sendButtonLoading={sendButtonLoading}
                            route={"/main/hr-services/"}
                            setModalSentStatus={setModalSentStatus}

                        />
                    </ModalBanOverlay>
                    {modalError.status && (
                        <ModalBanOverlay shown={modalError} close={setModalError}>
                            <Error500Modal
                                modalError={modalError}
                                setModalError={setModalError}
                            />
                        </ModalBanOverlay>
                    )}
                </div>


            </div>
        </div>
    );
};

export default ImportantTasks;
