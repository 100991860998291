import { initialState } from "../../init/initialState";
import {
    APPROVE_RELEASE_UNEP,
    GET_ADDITIONAL_INFO,
    GET_DOCUMENTS, GET_EXPERIENCE,
    GET_FIO,
    GET_GENERAL_DATA, GET_LATEST_UNEP_CERTIFICATE,
    GET_RELATIVES, GET_USER_ADDRESSES, GET_USER_PASSPORT, GET_WORK_SIGN_TYPE, RELEASE_UNEP,
} from "../../enums/Profile/profile";
import { GET_USEFUL_LINKS } from "../../enums/HrServices/UsefulLinks/usefulLinks";
// import {releaseUnepThunkAction} from "../../actions/User/profile/ElectronicSignature/thunk/releaseUnep.thunk.action";
// import electronicSignatureService from "../../../frontend.services/ElectronicSignature.service";

export const personalDataReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_FIO:
        return { ...state, fioContacts: action.payload };
    case GET_RELATIVES:
        return { ...state, relatives: action.payload };
    case GET_GENERAL_DATA:
        return { ...state, serviceData: action.payload };
    case GET_USER_ADDRESSES:
        return { ...state, addresses: action.payload };
    case GET_USER_PASSPORT:
        return { ...state, passport: action.payload };
    case GET_ADDITIONAL_INFO:
        return { ...state, additionalInformation: action.payload };
    case GET_DOCUMENTS:
        return { ...state, documents: action.payload };
    case GET_EXPERIENCE:
        return { ...state, experience: action.payload };
    case RELEASE_UNEP:
        return { ...state, electronicSignature: action.payload };
    case APPROVE_RELEASE_UNEP:
        return { ...state, unepIssueId: action.payload };
    case GET_LATEST_UNEP_CERTIFICATE:
        return { ...state, latestCertificate: action.payload };
    case GET_WORK_SIGN_TYPE:
        return { ...state, workSignType: action.payload };
    case "GET_USER_WIDGETS" :
        return { ...state, widgets: action.payload };
    case GET_USEFUL_LINKS:
        return { ...state, usefulLinks: action.payload };
    default:
        return state;
    }
};
