import {$api} from "../../../../services/request.config";
import {getAllLeaveTypesAction} from "../../LeaveMetadata/getAllLeaveTypes.action";

export const getTypesForRequestThunkAction  = (
    category,
    {
        setTypesListLoader,
    }
) => async (dispatch) => {
    try {
        setTypesListLoader(true);
        const response = await $api(`v2/leaverequest/type/1/category/${category}`);
        // console.log('getTypesForRequestThunkAction: ', response.data)
        dispatch(getAllLeaveTypesAction(response.data));
        setTypesListLoader(false);
    } catch (e) {
        setTypesListLoader(false);
        // console.log('ERROR_WHILE_GETTING_TYPES: ', e?.response)
    }
};
