import React from "react";
import "./HintModal.scss";

const HintModal = ({children, shown, close}) => {
    return shown ? (
        <div
            className="hint__modal"
            onClick={() => {
                // close modal when outside of modal is clicked
                close();
            }}
        >
            <div
                className="hint__modal-content"
                onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
            >
                {/*<button onClick={close}>Close</button>*/}
                {children}
            </div>
        </div>
    ) : null;
};

export default HintModal;