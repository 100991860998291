import { userSuccessRegisterAction } from "../userRegisterAction";
import { wrongAuthRegisterAction } from "../wrongAuthAction";
import { rawHeaders, urlOnUse } from "../../../../app/mode";

import axios from "axios";

export const userRegisterThunkAction = (input) => async (dispatch) => {
    const { socNumber, password, confirmPassword } = input;

    const data = {
        socNumber: socNumber,
        password: password,
        confirmPassword: confirmPassword,
        agreement: true,
    };

    try {
        const response = await axios.post(
            `${urlOnUse()}v1.0/auth/signup`,
            data,
            { headers: rawHeaders },
        );
        // .post(`https://${urlOnUse()}/api/v1.0/auth/signup`, data, {headers: rawHeaders});

        switch (response.status) {
        case 200:
            localStorage.setItem("userId", response.data.userId);
            return dispatch(userSuccessRegisterAction(response.data));
        default:
            return true;
        }
    } catch (e) {
        dispatch(wrongAuthRegisterAction(e.response.data));
    }
};
