import { ButtonTerminal } from "../Button/ButtonTerminal";
import backArrow from "../../../../assets/icons/BackArrow.svg";

import { useNavigate, useLocation } from "react-router-dom";

export const BackArrowButton = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handlerClick = () => {
        const routers = pathname.split("/");
        routers.pop();
        navigate(routers.join("/"));
    };

    return (
        <ButtonTerminal variant="text" onClick={handlerClick}>
            <img src={backArrow} />
        </ButtonTerminal>
    );
};
