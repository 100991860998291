import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import store from "./redux/store/store";

import App from "./app/App";

import { ComponentPreviews, useInitial } from "./dev";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { DevSupport } from "@react-buddy/ide-toolbox";


ReactDOM.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <DevSupport ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
            >
                <App />
            </DevSupport>
        </Provider>
    </BrowserRouter>,
    // </React.StrictMode>,
    document.getElementById("root"),
);

