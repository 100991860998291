import { data } from "./responsibilitiesDelegation.data";

import Header from "../../../../layout/Header/Header";
import Footer from "../../../../layout/Footer/Footer";
import MultiRoute from "../../../../common/MultiRoute/MultiRoute";

import "./ResponsibilitiesDelegation.scss";

const ResponsibilitiesDelegation = () => {
    return (
        <>
            <Header />
            <MultiRoute
                styleParent={"delegation-of-responsibilities"}
                subHeader={"делегирование полномочий"}
                breadCrumbs={true}
                data={data} />
            {/*TODO REFACTOR MULTI-ROUTE! MAX MIN HEIGHT! */}
            <div style={{height: "20vh"}}></div>
            <Footer />
        </>
    );
};

export default ResponsibilitiesDelegation;