import EmployeeDetailInformation from "./EmployeeDetailInformation/EmployeeDetailInformation";

import {
    getAllEmployeesThunkAction,
} from "../../../../../redux/actions/MyDivision/EmployeeList/thunk/getAllEmployees.thunk.action";
import {
    deleteSubstitutionThunkAction,
} from "../../../../../redux/actions/MyDivision/EmployeeList/thunk/deleteSubstitution.thunk.action";
import ModalBanOverlay from "../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import EmployeeListHelper from "../../../../../frontend.services/Divisions/EmployeeList.helper";
import {tableHeader} from "../../../../../frontend.services/Divisions/EmployeeList.data";
import ReplacementModal from "../../../../common/Modal/Replacement/ReplacementModal/ReplacementModal";
import {
    addSubstitutionThunkAction,
} from "../../../../../redux/actions/MyDivision/EmployeeList/thunk/addSubstitution.thunk.action";
import {
    clearErrDescriptionEmployeeListAction,
} from "../../../../../redux/actions/MyDivision/EmployeeList/thunk/clearErrDescriptionEmployeeList.action";
import {
    clearEmployeeDetailInformationAction,
} from "../../../../../redux/actions/MyDivision/EmployeeInformation/clearEmployeeDetailInformation.action";

import Footer from "../../../../layout/Footer/Footer";
import Header from "../../../../layout/Header/Header";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";

import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import "./EmployeeList.scss";

const EmployeeList = () => {

    const dispatch = useDispatch();

    const [employeeLoader, setEmployeeLoader] = useState(false);
    const [modal, setModal] = useState({
        body: null,
        status: false,
        subject: null,
        from: null,
        to: null,
        pid: null,
    });

    const employeeList = useSelector(state => state.myDivision.employeeList);
    const employeeInformation = useSelector(state => state.myDivision.employeeInformation);

    function closeModal () {
        dispatch(clearErrDescriptionEmployeeListAction());
        setModal({
            body: null,
            status: false,
            subject: null,
            from: null,
            to: null,
            pid: null});
        if (employeeInformation) {
            dispatch(clearEmployeeDetailInformationAction());
        }
    }

    function addReplacement () {
        dispatch(addSubstitutionThunkAction(
            {
                pid: modal.pid,
                period: {from: modal.from, to: modal.to},
                setModal,
                modalData: modal,
            }));
    }

    function deleteReplacement ({id,pid}) {
        dispatch(deleteSubstitutionThunkAction({id,pid}));
        return true;
    }

    function getDetailInformation () {
        setModal({
            body: "details",
            status: true,
            subject: "Подробная информация",
        });
    }

    useEffect(() => {
        dispatch(getAllEmployeesThunkAction(setEmployeeLoader));
    }, [dispatch]);

    return (
        <>
            <Header />
            <Breadcrumbs />

            <table className="division-employee-list">

                <thead className="division-employee-list__table-header">
                    <tr>
                        {
                            tableHeader
                                .map(e => {
                                    return EmployeeListHelper.getTableHeader(e, "division-employee-list");
                                })
                        }
                    </tr>
                </thead>
                <tbody  className="division-employee-list__table-body">
                    {
                        employeeList && employeeList.map(
                            employee => EmployeeListHelper
                                .getTableRow(
                                    employee,
                                    "division-employee-list",
                                    deleteReplacement,
                                    getDetailInformation,
                                    {modal,setModal}
                                ))
                    }
                    {
                        employeeLoader
                            ? EmployeeListHelper
                                .getEmptyTableRow("division-employee-list")
                                .map(e => e)
                            : null
                    }

                </tbody>

            </table>
            <Footer />
            <ModalBanOverlay shown={modal.status} close={closeModal} >
                {
                    modal.body === "details"
                        ? <EmployeeDetailInformation
                            close={closeModal}
                        />
                        : <ReplacementModal
                            close={closeModal}
                            proceed={addReplacement}
                            modalData={modal}
                        />
                }
            </ModalBanOverlay>
        </>
    );
};

export default EmployeeList;
