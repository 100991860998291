import { payrollRequest, payrollSuccess, payrollFailure } from "../payroll";

import { $api } from "../../../services/request.config";

export const asyncPayrollAction = (id) => async(dispatch) => {
    try {
        dispatch(payrollRequest());
        const response = await $api(`/v2/pr/all/${id}`);
        dispatch(payrollSuccess(response.data.data));
    } catch (error) {
        dispatch(payrollFailure(error));
    }
};