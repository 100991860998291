import BasicApplication from "./Basic.application";

class UnplannedVacationApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.dateFrom = options.dateFrom;
        this.dateTo = options.dateTo;
        this.isValid = {
            leaveReasonId: true,
            validDate: true,
            dateFrom: true,
            dateTo: true,
            employeeComment: true,
        };
        // this.saveOrUploadValidation = (setRequestState, requestState) => {
        //     if (requestState.leaveReasonId !== null) {
        //         return true
        //     } else {
        //         setRequestState((prev) => {
        //             const temp = {...prev.isValid}
        //             temp.leaveReasonId = false
        //             return {...prev, isValid: temp}
        //         })
        //     }
        // }
        // this.sendToValidation = (requestState, setRequestState) => {
        //     const keysToCheck = ['leaveReasonId', 'dateFrom', 'dateTo']
        //     return this.keyChecker(keysToCheck, requestState, setRequestState)
        // }
    }
}

export default UnplannedVacationApplication;
