import OkButton from "../../../Button/OKButton/OKButton";
import ReplacementStatusModal from "../ReplacementStatusModal/ReplacementStatusModal";

import moment from "moment";

import "./ReplacementModal.scss";


const ReplacementModal = (
    {
        modalData,
        proceed,
        close,
    }
) => {

    const { from, to, subject, body } = modalData;

    const component = {
        "add": (
            <div className={"replacement-modal"}>
                <div className={"replacement-modal__header"}>Установить замещение</div>
                <div className={"replacement-modal__body"}>
                    Вы хотите установить замещение на
                    {" "}
                    <span className={"replacement-modal__body__bold"}>
                        {subject}
                    </span>
                    {" "}
                    на период с
                    {" "}
                    <span className={"replacement-modal__body__bold"}>
                        {moment(from).format("DD.MM.YYYY")}
                    </span>
                    {" "}
                    по
                    {" "}
                    <span className={"replacement-modal__body__bold"}>
                        {moment(to).format("DD.MM.YYYY")}
                    </span>?</div>
                <div className={"replacement-modal__buttons"}>
                    <OkButton handler={close} title={"Отмена"}/>
                    <OkButton handler={proceed} title={"Установить"}/>
                </div>
            </div>
        ),
        "processing": <ReplacementStatusModal close={close}/>,
    };

    return component[body];
};

export default ReplacementModal;