import fileImg from "../../../assets/icons/file_blue.svg";
import deleteImage from "../../../assets/icons/delete_plus.svg";

import {
    deleteAttachmentThunkAction,
} from "../../../redux/actions/Requests/Attachments/thunk/deleteAttachment.thunk.action";
import {
    getRequestAttachmentsURLThunkAction,
} from "../../../redux/actions/Requests/Attachments/thunk/getRequestAttachmentsURL.thunk.action";
import RequestServices from "../../../frontend.services/RequestServices/RequestServices";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import {useSearchParams} from "react-router-dom";

import "./SubTypeRequestAttachments.scss";


const SubTypeRequestAttachments = (
    {
        deleteIcon, subTypeNumber,
    }
) => {
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const requestUid = searchParams.get("id");

    const requestAttachments = useSelector(state => state.requests.requestAttachments);
    const currentRequest = useSelector(state => state.requests.currentRequest);
    const [subDocumentsType, setSubDocumentsType] = useState(null);
    const [deleteStatus, setDeleteStatus] = useState(false);

    function deleteAttachment(e) {
        dispatch(deleteAttachmentThunkAction(
            {attachId: e.target.id, requestId: currentRequest.uid, setDeleteStatus}
        ));
    }

    useEffect(() => {
        dispatch(getRequestAttachmentsURLThunkAction(requestUid));
        if(requestAttachments){
            const temp = requestAttachments.filter(el => el.subDocumentType === subTypeNumber );
            setSubDocumentsType(temp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestAttachments.length]);

    // useEffect(()=>{
    //     // console.log(111)
    //     // if(requestAttachments){
    //     //     console.log(222)
    //     //     const temp = requestAttachments.filter(el => el.subDocumentType == subTypeNumber )
    //     //     setSubDocumentsType(temp)
    //     // }
    // }, [requestAttachments.length]);


    return requestAttachments ? (
        <div>
            {subDocumentsType && subDocumentsType.map(e => {
                return (
                    <div
                        className="custom-uploader__item"
                        key={e.id}>
                        <img
                            className="custom-uploader__item__img"
                            src={fileImg}
                            alt="file"/>
                        <div style={{position: "relative"}} className="custom-uploader__item__link">
                            <a
                                target="_blank"
                                href={e.blobLink}
                                className="custom-uploader__item__name" rel="noreferrer">
                                {
                                    RequestServices.attachmentNameDecode(e.url).shortName
                                        ? RequestServices.attachmentNameDecode(e.url).shortName
                                        : RequestServices.attachmentNameDecode(e.url)
                                }
                                {
                                    RequestServices.attachmentNameDecode(e.url).shortName &&
                                    <div className="custom-uploader__item__name__label">
                                        {RequestServices.attachmentNameDecode(e.url).fullName}
                                    </div>
                                }
                            </a>

                        </div>
                        {
                            deleteIcon ? null :
                                deleteStatus ? <ClipLoader color="#6DCDF6" size={32}/> :
                                    <div
                                        className="custom-uploader__item__delete">
                                        <img
                                            id={e.id}
                                            onClick={deleteAttachment}
                                            src={deleteImage}
                                            alt="delete"/>
                                    </div>
                        }
                    </div>
                );
            })}
        </div>
    ) : <ClipLoader color="#6DCDF6"/>;
};

export default SubTypeRequestAttachments;
