import { $api } from "../../../../../services/request.config";
import { getAvailableSubstitutionTypesAction } from "../getAvailableSubstitutionTypes.action";

export const getAvailableSubstitutionTypesThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v2/manager/substitution-types");
        dispatch(getAvailableSubstitutionTypesAction(response.data));
    } catch (error) {
        // console.log("ERROR WHILE GETTING AVAILABLE SUBSTITUTION TYPES: ", error);
    }
};