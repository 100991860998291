import {approveRequestFailureAction} from "./approveRequestFailure.action";

import $api from "../../../services/axiosService";
import {approveRequestSuccessAction} from "../approveRequestSuccess.action";

const loaderStatus = (setter, key, value) => {
    setter(prev => {
        return {
            ...prev,
            [`${key}`]: value,
        };
    });
    return true;
};

export const declineRequestsThunkAction = (arrayOfRequests, setIsActiveLoader) => async (dispatch) => {
    try {
        loaderStatus(setIsActiveLoader, "modal", "decline");

        for (let i = 0; i < arrayOfRequests.length; i += 1) {
            loaderStatus(setIsActiveLoader, "loader", true);
            const response = await $api.post(
                `v1/leaverequest/linemanager/${arrayOfRequests[i].uid}/request/${arrayOfRequests[i].pid}/reject`
                , {comment: arrayOfRequests[i].comment});
            // v1/leaverequest/linemanager/5c5ed0f9-440d-4a56-8b0c-f0cf9b481570/request/150431/approve
            if (response.data.isSuccess) {
                dispatch(approveRequestSuccessAction(arrayOfRequests[i].uid));
            } else {
                dispatch(approveRequestFailureAction(arrayOfRequests[i].uid));
            }
        }
        loaderStatus(setIsActiveLoader, "loader", false);

    } catch (error) {
        loaderStatus(setIsActiveLoader, "loader", false);
        // console.log("ERROR DECLINE PROCESS: ", error.response)
    }
};
