import BasicApplication from "./Basic.application";

class WithoutPayApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.dateFrom = options.dateFrom;
        this.dateTo = options.dateTo;
        this.isValid = {
            leaveReasonId: true,
            dateFrom: true,
            dateTo: true,
            validDate: true,
        };
    }
}

export default WithoutPayApplication;