export const wrongAuthAunteficationAction =(data)=>{
    return {
        type : "WRONG_CODE",
        payload:data,
    };
};
export const setToNullStateAction =() =>{
    return {
        type:"SET_TO_NULL",
        payload:null,
    };
};
export const  successAuthAunteficationAction = (user) =>{
    return{
        type: "ADD_USER_STATE",
        payload:user,
    };
};
