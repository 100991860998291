import { isTerminal } from "../../../constants";

import { forwardRef } from "react";

isTerminal ? import("./DatepickerCustomFieldTerminal.scss") : import("./DatepickerCustomField.scss");

export const DatepickerCustomField = forwardRef(({ value, onClick, className }, ref) => (
    <div className={[className, "datepicker-custom-field"].join(" ")} onClick={onClick} ref={ref}>
        {value}
    </div>
));

DatepickerCustomField.displayName = "DatepickerCustomField";