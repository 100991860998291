import { footerLinks } from "./footer.links";
import { footerBadges } from "./footer.badges";

import file from "./terms.pdf";

import corpLines from "../../../assets/footer/corpLines.svg";

import { isTerminal } from "../../../constants";
import { Modal } from "../../common/Modal/Modal";

import { useState } from "react";

isTerminal ? import("./FooterTerminal.scss") : import("./Footer.scss");
// import { Link } from "react-router-dom";

const Footer = () => {
    const [isShow, setShow] = useState(false);

    return (
        <>
            <div className="flex-grow-1"></div>
            <footer className="footer-wrapper">
                <img
                    className="corp-lines"
                    src={corpLines}
                    alt="footer-lines" />
                <div>
                    <div className="footer">
                        <div className="footer__left">
                            <div>
                                <p>
                                    Телефон ОЦО:
                                </p>
                                <p>
                                    8 (800) 585-58-85
                                </p>
                            </div>
                            <div className="footer__left__badges">
                                {
                                    footerBadges && footerBadges.map((el) => {
                                        return (
                                            <div
                                                onClick={() => window.open(el.link)}
                                                key={el.id}
                                                className="footer__left__badges__item badge">
                                                <img src={el.img} alt={el.id + el.title} />
                                                <div>
                                                    <p className="badge__head">
                                                        {el.head}
                                                    </p>
                                                    <p className="badge__title">
                                                        {el.title}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="footer__right">
                            {
                                !isTerminal && footerLinks && footerLinks.map((el) => {
                                    return (
                                        <a
                                            // onClick={() => window.open(el.blank ? file : el.path)}
                                            target={"_blank"}
                                            key={el.id}
                                            href={el.blank ? file : el.path}
                                            className="footer__right__link" rel="noreferrer">
                                            {el.title}
                                        </a>
                                    );
                                })
                            }
                            {isTerminal && (
                                <>
                                    <span className="footer__right__link" onClick={() => setShow(true)}>Условия использования</span>
                                    <Modal close={() => setShow(!isShow)} shown={isShow}>
                                        <iframe className="footer__right__iframe" src={file}/>
                                    </Modal>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
