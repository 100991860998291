import uploadImage from "../../../assets/icons/PlusCircle.svg";
import { newUploadFileThunkAction } from "../../../redux/actions/Requests/FileUpload/thunk/newUploadFile.thunk.action";
import SubTypeRequestAttachmentsNew from "../SubTypeRequestAttachmentsNew/SubTypeRequestAttachmentsNew";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

import "./SingleUploaderNewVersion.scss";

function SingleUploaderNewVersion({ label, index, i, subTypeNumber, errorMessage, fileName, field }) {
    const dispatch = useDispatch();
    const filePicker = useRef();

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const requestAttachments = useSelector(
        (state) => state.requests.requestAttachments,
    );

    const [isUploading, setIsUploading] = useState(false);

    function handlePick(e) {
        filePicker.current.click();
    }

    function uploadHandler(e) {
        const formData = new FormData();
        formData.append("file", filePicker.current.files[0]);
        formData.append("fileName", fileName);
        formData.append("name", "file");
        // name of that append file should be "files" otherwise backend can't recognize
        dispatch(
            newUploadFileThunkAction(
                {
                    file: formData,
                    setIsUploading,
                },
                index,
                subTypeNumber,
                i,
                field,
            ),
        );
    }

    const [subDocumentsType, setSubDocumentsType] = useState(null);

    useEffect(() => {
    }, [currentRequest, requestAttachments.length, subDocumentsType]);

    return (
        <div>
            <div className="single-uploader">
                <div className="single-uploader__upload-field">
                    <div>
                        <div className={"single-uploader__upload-field__text"}>
                            {label}
                        </div>
                        <div className={"single-uploader__upload-field__input"}>
                            <div
                                className={errorMessage ? "single-uploader__upload-field__input__line-red" :
                                    "single-uploader__upload-field__input__line"
                                }
                            >
                                {currentRequest && (
                                    <SubTypeRequestAttachmentsNew
                                        subDocumentsType={subDocumentsType}
                                        setSubDocumentsType={setSubDocumentsType}
                                        index={index}
                                        i={i}
                                        subTypeNumber={subTypeNumber}
                                        field={field}
                                    />
                                )}
                            </div>
                            {isUploading ? (
                                <ClipLoader size={32} color="#6DCDF6" />
                            ) : (
                                <img
                                    className="single-uploader__img"
                                    onClick={handlePick}
                                    src={uploadImage}
                                    alt="upload"
                                />
                            )}
                            <input
                                onChange={uploadHandler}
                                ref={filePicker}
                                className="single-uploader__input"
                                type="file"
                                accept=".png,.jpg,.jpeg,.pdf,.docx"
                            />
                        </div>
                        {errorMessage ? (
                            <div className="date-popup-selector__error-message">
                                {errorMessage}
                            </div>
                        ) : (
                            <div className="date-popup-selector__buffer"></div>
                        )}
                        <sub>
              До 50 Мб. форматы: .jpg, .png, .pdf, .docx
                        </sub>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default SingleUploaderNewVersion;
