import {initialState} from "../../init/initialState";

export const authLoginReducer = (state = initialState, action) => {
    switch (action.type) {

    case "WRONG_LOGIN":
        return action.payload;
    case "RESET_ERROR_LOGIN":
        return action.payload;

    default: return state;
    }
};
