import { data } from "./reference.data";

import Header from "../../layout/Header/Header";
import MultiRoute from "../../common/MultiRoute/MultiRoute";
import Footer from "../../layout/Footer/Footer";

import React from "react";

const ReferencePage = () => {
    return (
        <>
            <Header />
            <MultiRoute
                data={data}
                styleParent={"hr-services"}
                breadCrumbs={true}
                subHeader={"Заказ справок"}
            />
            <Footer />

        </>
    );
};

export default ReferencePage;
