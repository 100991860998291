import { $api } from "../../../../../services/request.config";
import { getInternshipsStructsAction } from "../getInternshipsStructs.action";

export const getInternshipsStructsThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/managers/requests/internships/structs");
        if (response.status === 200) {
            const temp = response.data.map((element) => {
                return {
                    id: element.id,
                    name: `${element.name} ${element.path}`,
                };
            });
            dispatch(getInternshipsStructsAction(temp));
        }
    } catch (error) {
        throw new Error(error.response);
    }
};
