import SingleDateCalendarSelectorForStudyLeave from "./SingleDateCalendarSelectorForStudyLeave/SingleDateCalendarSelectorForStudyLeave";

import { isTerminal } from "../../../../constants";

const style = isTerminal
    ? {
        display: "flex",
        columnGap: "20px",
    }
    : {
        display: "flex",
        width: "1160px",
        margin: "auto",
        gap: "20px",
    };

const SinglePairDatesForStudyLeave = ({
    labelFrom,
    labelTo,
    fieldFrom,
    fieldTo,
}) => {
    return (
        <div
            style={style}
        >
            <SingleDateCalendarSelectorForStudyLeave
                label={labelFrom ? labelFrom : <>Дата начала<span className="required_text"> *</span></>}
                field={fieldFrom ? fieldFrom : "dateFrom"}
            />
            <SingleDateCalendarSelectorForStudyLeave
                label={labelTo ? labelTo : <>Дата окончания<span className="required_text"> *</span></>}
                field={fieldTo ? fieldTo : "dateTo"}
            />
        </div>
    );
};

export default SinglePairDatesForStudyLeave;
