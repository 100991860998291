import "./Modal.css";
import union from "../../../assets/icons/modal/Рабочее время/Union.svg";

import { createPortal } from "react-dom";
import { useEffect } from "react";

export function Modal({ children, shown, close, showCloseButton = true }) {

    useEffect(() => {
        const classList = document.body.classList;
        const showModalClass = "show_modal_form";
        const showModal =  classList.contains(showModalClass);

        shown && !showModal && classList.add(showModalClass);
        !shown && showModal && classList.remove(showModalClass);
    }, [shown]);

    return shown ? createPortal((
        <div
            className="modal-backdrop"
            onClick={() => {
                // close modal when outside of modal is clicked
                close();
            }}
        >
            <div
                className="modal-content"
                onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
            >
                {showCloseButton && <img onClick={()=>close()} className="modal-union" src={union} alt="union"/> }

                {/*<button onClick={close}>Close</button>*/}
                {children}
            </div>
        </div>
    ), document.body) : null;
}
