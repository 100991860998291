import {initialState} from "../../init/initialState";
import {
    ADD_CANCEL_COMMENT,
    APPROVE_REQUEST_FAILURE,
    APPROVE_REQUEST_SUCCESS,
    CLEAR_EMPLOYEE_APPLICATION_DETAILS,
    CLEAR_EMPLOYEES_LEAVES, CLEAR_FILTER_SELECTED_PIDS,
    CLEAR_GANT_REPRESENTATION,
    CLEAR_SEARCHABLE_EMPLOYEES,
    CLEAR_YEAR_NOTIFICATION_BUBBLE,
    // FILTER_DELETE_SELECTED_EMPLOYEE,
    FILTER_RESET_SELECTION_EMPLOYEE,
    FILTER_SELECT_EMPLOYEE,
    GET_EMPLOYEE_APPLICATION_DETAILS,
    GET_EMPLOYEES_LEAVES,
    GET_EMPLOYEES_PENDING_APPLICATIONS,
    GET_EMPLOYEES_PLANNED_VACATIONS,
    GET_EMPLOYEES_VACATION_REQUESTS, GET_FILTER_SELECTED_PIDS,
    GET_GANT_REPRESENTATION,
    GET_SEARCHABLE_EMPLOYEES,
    SET_ALL_REQUEST_CHECKBOX_VALUE,
    SET_DAILY_PERCENT,
    SET_REQUEST_CHECKBOX_VALUE,
    SET_YEAR_NOTIFICATION_BUBBLE,
} from "../../enums/Employees/employees.types";

export const employeesReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_YEAR_NOTIFICATION_BUBBLE:
        return {
            ...state,
            yearBubbleNotification: action.payload,
        };
    case CLEAR_YEAR_NOTIFICATION_BUBBLE:
        const checkState = {...state};
        if (checkState.yearBubbleNotification) {
            delete checkState.yearBubbleNotification;
        }
        return checkState;

    case GET_EMPLOYEES_LEAVES:
        return {
            ...state,
            employeesLeaves: {
                leaves: action.payload.items,
                dailyPercent: action.payload.dailyPercent,
            },
        };

    case CLEAR_EMPLOYEES_LEAVES:
        return {
            ...state,
            employeesLeaves: [],
        };
    case GET_SEARCHABLE_EMPLOYEES:
        return {
            ...state,
            searchableEmployees: action.payload.map(employee => {
                return { ...employee, selected: !!(state.selectedPids && state.selectedPids.includes(employee.pid)) };
                // return { ...employee, selected: false };

            }),
        };
    case CLEAR_SEARCHABLE_EMPLOYEES:
        const {searchableEmployees} = state;
        if (searchableEmployees) {
            const temp = state;
            delete temp.searchableEmployees;
            return temp;
        } else {
            return state;
        }
    case FILTER_SELECT_EMPLOYEE:
        return {
            ...state,
            searchableEmployees: state.searchableEmployees.map(employee => {
                if (action.payload === employee.pid) {
                    return {...employee, selected: !employee.selected};
                } else {
                    return employee;
                }
            }),
        };
    case FILTER_RESET_SELECTION_EMPLOYEE:
        return {
            ...state,
            searchableEmployees: state.searchableEmployees.map(employee => {
                return {
                    ...employee, selected: false,
                };
            }),
        };

    case GET_EMPLOYEES_PENDING_APPLICATIONS: {
        if (action.payload.requests) {
            return {
                ...state,
                pendingApplications: {
                    multiSelect: false,
                    applications: action.payload.requests.items.map(e => {
                        return {...e, status: "ожидание", checked: false};
                    }),
                    dailyPercent: action.payload.requests.dailyPercent,
                },
            };
        } else {
            const temp = state;
            if (temp.pendingApplications) {
                delete temp.pendingApplications;
            }
            return temp;
        }
    }
    case GET_FILTER_SELECTED_PIDS:
        return {
            ...state, selectedPids: action.payload,
        };
    case CLEAR_FILTER_SELECTED_PIDS:
        if (state.selectedPids) {
            const temp = state;
            delete temp.selectedPids;
            return temp;
        } else {
            return state;
        }
    case GET_EMPLOYEES_PLANNED_VACATIONS:
        return {...state, cross:
                    {
                        data: action.payload.employees,
                        dailyPercent: action.payload.dailyPercent,
                        percent: action.payload.percent,
                    }};
    case GET_EMPLOYEES_VACATION_REQUESTS:
        if (action.payload.items) {
            return {...state,
                requests: {
                    multiSelect: false,
                    dailyPercent: action.payload.dailyPercent,
                    items: action.payload.items.map(e => {
                        return {...e, status: "ожидание", checked: false};
                    }),
                },
            };
        } else {
            return {...state,
                requests: [],
            };
        }
    case GET_EMPLOYEE_APPLICATION_DETAILS:
        return {
            ...state,
            applicationDetails: action.payload,
        };
    case CLEAR_EMPLOYEE_APPLICATION_DETAILS:
        const stateEmployees = {...state};
        stateEmployees.applicationDetails && delete stateEmployees.applicationDetails;
        return stateEmployees;
    case SET_REQUEST_CHECKBOX_VALUE:
        const temp = state.pendingApplications.applications.map(e => {
            if (e.uid === action.payload.uid) {
                return {...e, checked: !action.payload.checked};
            } else {
                return e;
            }
        });
        const verification = temp.filter(e => e.checked === !action.payload.checked);
        if (verification.length === temp.length) {
            return  {...state, pendingApplications: {
                multiSelect: !action.payload.checked,
                // multiSelect: true,
                dailyPercent: state.pendingApplications.dailyPercent,
                applications: temp,
            }};
        } else {
            return {...state, pendingApplications: {
                multiSelect: false,
                dailyPercent: state.pendingApplications.dailyPercent,
                applications: temp,
            }};
        }
    case SET_ALL_REQUEST_CHECKBOX_VALUE:
        const tempAll = state.pendingApplications.applications.map(e => {
            return {...e, checked: action.payload};
        });
        return {...state, pendingApplications: {
            multiSelect: action.payload,
            dailyPercent: state.pendingApplications.dailyPercent,
            applications: tempAll,
        }};
    case SET_DAILY_PERCENT:
        return {
            ...state, todaysPercent: action.payload,
        };
    case APPROVE_REQUEST_SUCCESS:
        return {
            ...state, pendingApplications: {
                multiSelect: state.pendingApplications.multiSelect,
                dailyPercent: state.pendingApplications.dailyPercent,
                applications: state.pendingApplications.applications.map(e => {
                    if (e.uid === action.payload) {
                        return {
                            ...e,
                            status: "согласовано",
                        };
                    } else {
                        return e;
                    }
                }),
                // todaysPercent: state.pendingApplications.todaysPercent,
            },
        };
    case APPROVE_REQUEST_FAILURE:
        return {
            ...state, pendingApplications: {
                multiSelect: state.pendingApplications.multiSelect,
                dailyPercent: state.pendingApplications.dailyPercent,
                applications: state.pendingApplications.applications.map(e => {
                    if (e.uid === action.payload) {
                        return {
                            ...e,
                            status: "отклонено",
                        };
                    } else {
                        return e;
                    }
                }),
            },
        };
    case GET_GANT_REPRESENTATION:
        return {...state, gant: action.payload};
    case CLEAR_GANT_REPRESENTATION:
        return {...state, gant: []};
    case ADD_CANCEL_COMMENT:
        return {
            ...state,
            requests: {
                ...state.requests,
                items: [...state.requests.items.map(request => {
                    if (action.payload.uid === request.uid) {
                        return {...request, comment: action.payload.comment};
                    } else {
                        return request;
                    }
                })],
            },
        };
    default: return state;
    }
};
