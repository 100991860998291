import { isTerminal } from "../../../../../../constants";
import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import CommentInput from "../../../../../common/Inputs/CommentInput/CommentInput";
import CustomUploader from "../../../../../common/CustomUploader/CustomUploader";
import NotificationWindowRequests from "../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import { clearAttachmentsAction } from "../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";

import DismissalRevocationApplication from "../../../../../../frontend.classes/Requests/DismissalRevocation.application";

import { emptyCurrentRequest } from "../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";

import {
    validationControlCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    getPlannedRevocationThunkAction,
} from "../../../../../../redux/actions/Requests/DismissalRevocation/thunk/getPlannedRevocation.thunk.action";

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

isTerminal ? import("./DismissalRevocationPageTerminal.scss") : import("./DismissalRevocationPage.scss");

const DismissalRevocationPage = ({
    setRequestStatus,
    requestStatus,
}) => {
    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const plannedDismissal = useSelector(
        (state) => state.requests.plannedDismissal,
    );

    const [notificationWindow, setNotificationWindow] = useState(false);

    function commentHandler(event) {
        dispatch(validationControlCurrentRequest("employeeComment", true));
        dispatch(addCommentHandlerCurrentRequest(event.target.value));
    }

    function selectDropdown(event, element, stateProperty) {
        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getPlannedRevocationThunkAction());
        if (!queryId) {
            dispatch(
                emptyCurrentRequest(
                    new DismissalRevocationApplication({
                        leaveTypeId: 14,
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);
    return (
        <section className={"layoff-revocation"}>
            <DropdownMenu
                currentRequest={currentRequest}
                styleName={"unplanned-reason"}
                label={<>Номер и дата первоначальной заявки <span className="required_text"> *</span></>}
                listOfOptions={plannedDismissal}
                stateProperty={"dismissalId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.dismissalId
                        ? currentRequest.isValid.dismissalId
                        : false
                }
                selectOptionClick={selectDropdown}
                helper={
                    "Дата формирования заявки на отзыв не должна превышать дату увольнения из первоначальной заявки"
                }
            />
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                changeHandler={commentHandler}
                label={<>Комментарий <span className="required_text"> *</span></>}
                parentStyle={"extra-vacation-days"}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.employeeComment
                        ? ""
                        : "Необходимо заполнить поле «комментарий к заявке» "
                }
            />

            {!isTerminal && <CustomUploader />}

            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
            />
        </section>
    );
};
export default DismissalRevocationPage;
