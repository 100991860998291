import { usefulLinksAction } from "./usefulLinks.action";

import { $api } from "../../../../services/request.config";

export const usefulLinksThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/knowledge-base/employees/resources");
        if (response.status === 200) {
            dispatch(usefulLinksAction(response.data));
        }
    } catch (e) {
        throw new Error("error with getting usefulLinks");
    }
};
