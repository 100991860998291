import { createContext, useContext } from "react";

const InputCompositionContext = createContext(null);

export const useInputCompositionContext = () => {
    const context = useContext(InputCompositionContext);
    if (!context) {
        throw new Error("IC.* component must be rendered as a child as IC compontent");
    }
    return context;
};

export default InputCompositionContext;