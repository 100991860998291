import {initialState} from "../../init/initialState";

export const resetStatePasswordReducer = (state=initialState,action)=>{
    switch (action.type) {
    case "RESET_PASSWORD":
        return action.payload;
    case "RESET_TO_NULL_PASSWORD":
        return action.payload;
    default:
        return state;
    }
};
