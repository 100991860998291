import { $api } from "../../../../services/request.config";
import { getParentalStatusAction } from "../getParentalStatus.action";

export const getParentalStatusThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/requests/leaves/parental/parents/statuses/alrosa");
        dispatch(getParentalStatusAction(response.data));

    } catch (error) {
        throw new Error(error.response);
    }
};
