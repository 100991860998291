import { isTerminal } from "../../../../constants";

import React from "react";

const CommentInput = ({
    parentStyle,
    label,
    value,
    changeHandler,
    maxLength,
    stateProperty,
    basicInput,
    errorMessage,
    helper,
    type,
}) => {
    return (
        <div className={`${parentStyle}-comment__container`}>
            <label
                htmlFor="comment"
                className={`${parentStyle}-comment__label`}
            >
                {label}
            </label>
            {!isTerminal && <input
                name="comment"
                value={value ? value : ""}
                onChange={(event) => changeHandler(event)}
                maxLength={maxLength ? maxLength : null}
                className={
                    !errorMessage
                        ? `${parentStyle}-comment`
                        : `${parentStyle}-commentError`
                }
                type={"text"}
            />}
            {isTerminal && <textarea
                name="comment"
                placeholder="Введите комментарий"
                rows={4}
                value={value ? value : ""}
                onChange={(event) => changeHandler(event)}
                maxLength={maxLength ? maxLength : null}
                className={
                    !errorMessage
                        ? `${parentStyle}-comment`
                        : `${parentStyle}-commentError`
                }
                type={"text"}
            />}
            {errorMessage ? (
                <div className="date-popup-selector__error-message">
                    {errorMessage}
                </div>
            ) : (
                <div className="date-popup-selector__buffer"></div>
            )}
            {helper && (
                <p className={`${parentStyle}-comment__underline`}>{helper}</p>
            )}
            {!basicInput && (
                <p className={`${parentStyle}-comment__underline`}>
                    <span className="required_text">* </span> 
                    Поля обязательные для заполнения
                </p>
            )}
        </div>
    );
};

export default CommentInput;
