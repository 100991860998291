import { $api } from "../../../services/request.config";

export const sendReferenceThunkAction = (props) => async (dispatch) => {
    try {
        const temp = { ...props.currentRequest };
        delete temp.isValid;
        const response = await $api.post("v2/itsm/hr/create", temp);
        if (response.status === 200) {
            props.closeModal(false);
            props.stateLoader(false);
            props.setErrDescription("Заявка на получение справки успешно отправлена, пожалуйста, ожидайте ее исполнения в разделе Документы");
            props.setErrorModal(true);
        }
        console.log(response);
    } catch (e) {
        props.closeModal(false);
        props.stateLoader(false);
        if (props) {
            props.setErrDescription(e?.response?.detail);
            props.setErrorModal(true);
        }
    // throw new Error("references error");
    }
};
