import BasicApplication from "./Basic.application";

class ExtraVacationDaysApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.periods = options.periods;
        this.bloodDonationData = {
            donationDate: options.bloodDonationData
                ? options.bloodDonationData.donationDate
                : null,
            certificateNumber: options.bloodDonationData
                ? options.bloodDonationData.certificateNumber
                : null,
        };
        // this.bloodDonationData = options.bloodDonationData
        this.isValid = {
            leaveReasonId: true,
            bloodDonationData: {
                donationDate: true,
                certificateNumber: true,
            },
            validDate: true,
        };
    }
}

export default ExtraVacationDaysApplication;
