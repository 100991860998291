import { IMPORTANT_TASK_SAVE_URL, UNPAID_PRACTICE_SAVE_URL } from "../../../../enums/Request/applicationUrls";
import { $api } from "../../../../services/request.config";
import { clearCurrentRequestAction } from "../../CurrentRequest/clearCurrentRequest.action";

import { setCurrentLeaderRequestFromClass } from "../../currentRequestControls/setCurrentLeaderRequestFromClass";

import moment from "moment";

function applicationUrl(option) {
    switch (option) {
    case 15:
        return UNPAID_PRACTICE_SAVE_URL;
    case 16:
        return IMPORTANT_TASK_SAVE_URL;
    default:
        return "v1/leaverequest";
    }
}

export const saveDraftCurrentLeaderRequestThunk =
  (data, modal, option, filePicker, setIsUploading) => async (dispatch) => {
      try {
          setIsUploading && setIsUploading(true);
          if (data.dateFrom)
              data.dateFrom = moment(data.dateFrom).format("YYYY-MM-DD");
          if (data.dateTo)
              data.dateTo = moment(data.dateTo).format("YYYY-MM-DD");
          if (data.periods && data.periods[0].dateFrom === null)
              delete data.periods;
          const temp = applicationUrl(Number(option));
          const response = await $api.post(`${temp}`, data);
          if (response.status === 201) {
              const request = await $api(
                  `v1/leaverequest/${response.data}`,
              );

              const { item } = request.data;

              dispatch(setCurrentLeaderRequestFromClass({ currentRequest: item }));

              filePicker && filePicker.current.click();

              setIsUploading && setIsUploading(false);
              modal && modal(true);

          } else {
              setIsUploading && setIsUploading(false);
              dispatch(clearCurrentRequestAction());
              modal && modal(false);
          }
      } catch (error) {
          setIsUploading && setIsUploading(false);
          modal && modal(false);
      }
  };


