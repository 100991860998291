import $api from "../../../services/axiosService";

import {setDailyPercentAction} from "../setDailyPercent.action";

import moment from "moment";

export const getDailyPercentThunkAction = () => async (dispatch) => {
    try {
        const  today = moment().format("YYYY-MM-DD");
        // const response = await $api(`v1/leaverequest/linemanager/${today}/${today}/crossing/planned`)
        const response = await $api(`v1/manager/subordinates/leaves/overlapped/${today}`);
        // console.log("RESPONSE PERCENT: ", response)
        if (response.status === 200) {
            dispatch(setDailyPercentAction(String(response.data.percent)));
        }
    } catch (error) {
        // console.log("ERROR WHILE GETTING DAILY PERCENT: ", error.response)
    }
};
