import $api from "../../../services/axiosService";
import { leavesWidgetsAction } from "../leavesWidgetsAction";
import LeavesService from "../../../../frontend.services/Leaves.service";

export const leavesWidgetsThunkAction = (year) => async (dispatch) => {
    try {
        const leavesWidgets = await $api("v1/p/po/leaves/stats");
        const response = await $api(`v1/p/po/leaves/${year}`);
        const temp = response.data.map((el) => el.typeName);
        const data = {
            widgets: [
                {
                    title: leavesWidgets.data.allowedDays
                        ? leavesWidgets.data.allowedDays
                        : "нет данных",
                    description: "Положенное количество дней отпуска в год",
                },
                {
                    title: leavesWidgets.data.basicDays
                        ? leavesWidgets.data.basicDays
                        : "нет данных",
                    description: "Резерв отпуска на сегодня (ежегодный)",
                },
                {
                    title: leavesWidgets.data.additionalDays
                        ? leavesWidgets.data.additionalDays
                        : "нет данных",
                    description: "Резерв отпуска на сегодня (дополнительный)",
                },
            ],
            items: temp,
            daysLeave: [
                `${
                    leavesWidgets.data.basicDays
                        ? leavesWidgets.data.basicDays
                        : 0
                }`,
                `${
                    leavesWidgets.data.additionalDays
                        ? leavesWidgets.data.additionalDays
                        : 0
                }`,
                `${
                    leavesWidgets.data.restDays
                        ? leavesWidgets.data.restDays
                        : 0
                }`,
            ],
            leavePeriod: await LeavesService.getItemsPeriod(year),
            leavesPeriodForDayPicker: await LeavesService.getPlanNoPlanPeriod(
                year,
            ),
        };
        return dispatch(leavesWidgetsAction(data));
    } catch (e) {
        // console.log(e?.response?.data)
    }
};
