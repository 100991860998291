import Table from "../../Table/Table";
import TableHead from "../../Table/TableHead";
import TableHeadRow from "../../Table/TableHeadRow";
import TableBody from "../../Table/TableBody";
import TableRowQuart from "../../Table/TableRowQuart";
import "./HolidayTableQuart.scss";

import TableQuartHelper from "../../../../frontend.services/TableServices/TableQuart.helper";

import {
    getGantRepresentationThunkAction,
} from "../../../../redux/actions/Employees/Gant/thunk/getGantRepresentation.thunk.action";
import Portal from "../../Portal/Portal";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const HolidaysTableQuart = ({tableStyle, section}) => {

    const dispatch = useDispatch();

    const gantData = useSelector(state => state.employees.gant);

    const [tooltip, setTooltip] = useState(null);


    useEffect(() => {
        dispatch(getGantRepresentationThunkAction(TableQuartHelper.getPeriod(section)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section]);


    return (
        <div className="table-quart-container">
            <Table tableStyle={tableStyle}>
                <TableHead parentStyle={tableStyle}>
                    <TableHeadRow
                        parentStyle={tableStyle}
                        data={TableQuartHelper.getHeaderRow(TableQuartHelper.getPeriod(section))}/>
                </TableHead>
                <TableBody parentStyle={tableStyle}>
                    <TableRowQuart
                        parentStyle={tableStyle}
                        data={gantData && gantData ? gantData : null}
                        section={TableQuartHelper.getPeriod(section)}
                        setTooltip={setTooltip}
                    />
                </TableBody>
            </Table>
            {
                tooltip &&
                <Portal>
                    {tooltip}
                </Portal>
            }
        </div>
    );
};

export default HolidaysTableQuart;