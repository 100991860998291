import KebabMenu from "../../components/common/KebabMenu/KebabMenu";
import {listOfOptionsData} from "../../components/pages/MyDivision/DivisionStructure/EmployeeList/listOfOptions.data";

import {v4 as uuidv4} from "uuid";
import {Fragment} from "react";
import moment from "moment";

class EmployeeListHelper {
    static getTableHeader (data, parentStyle) {
        return (
            <td
                key={uuidv4()}>
                <div
                    className={`${parentStyle}__${data.cssKey}`}>
                    {data.title}
                </div>
            </td>
        );
    }
    static getTableRow (
        data,
        parentStyle,
        deleteReplacement,
        getDetailInformation,
        {modal, setModal}
    ) {
        return (
            <Fragment key={uuidv4()}>
                <tr
                    className={`
                    ${parentStyle}__table-row
                    ${data.substitutions && data.substitutions.length ? `${parentStyle}__substitution` : ""}`}
                    key={uuidv4()}>
                    <td>
                        <div className={`${parentStyle}__table-body__department`}>
                            {data.department}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__employee`}>
                            {data.name}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__position`}>
                            {data.position}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__grade`}>
                            {data.grade}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__occupationDate`}>
                            {data.occupationDate}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__birthDate`}>
                            {data.birthDate}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__category`}>
                            {data.category}
                        </div>
                    </td>
                    <td>
                        <div className={`${parentStyle}__table-body__agreement`}>
                            <div>{data.digitalApproval}</div>
                            {
                                !data.substitutions.length
                                    ? <KebabMenu
                                        data={data}
                                        pid={data.pid}
                                        modal={modal}
                                        setModal={setModal}
                                        kebabStyle={`${parentStyle}__table-body__agreement__kebab`}
                                        dropdownStyle={`${parentStyle}__table-body__agreement__dropdown`}
                                        listOfOptions={listOfOptionsData}
                                        getDetailInformation={getDetailInformation}
                                    /> : <div></div>
                            }

                        </div>
                    </td>
                </tr>
                {data.substitutions && data.substitutions.length ? data.substitutions.map(sub => {
                    return (
                        <tr
                            style={{
                                background: "rgba(151, 153, 155, 0.1)",
                                height: "30px",
                            }}
                            key={uuidv4()}>
                            <td></td>
                            <td colSpan={7}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                    <div>
                                        Замещающий сотрудник:
                                        с {moment(sub.from).format("DD.MM.YYYY")}
                                        {" "}
                                        по {moment(sub.to).format("DD.MM.YYYY")}
                                    </div>
                                    <div
                                        onClick={() => deleteReplacement({id: sub.id, pid: data.pid})}
                                        className={"el-delete-replacement"}
                                        style={{color: "#a3e0fa", paddingRight: "20px"}} >
                                        Удалить замещение
                                    </div>
                                </div>


                            </td>
                        </tr>);
                })
                    : null
                }
            </Fragment>

        );
    }
    static getEmptyTableRow (parentStyle) {
        return new Array(3).fill(null).map(e => {
            e = (
                <tr
                    className={`${parentStyle}__table-row`}
                    key={uuidv4()}>
                    <td className={"el-department-empty"}>
                        <div className={`${parentStyle}__table-body__department-empty`}>
                        </div>
                    </td>
                    <td className={"el-employee-empty"}>
                        <div className={`${parentStyle}__table-body__employee-empty`}>
                        </div>
                    </td>
                    <td className={"el-position-empty"}>
                        <div className={`${parentStyle}__table-body__position-empty`}>
                        </div>
                    </td>
                    <td className={"el-grade-empty"}>
                        <div className={`${parentStyle}__table-body__grade-empty`}>
                        </div>
                    </td>
                    <td className={"el-occupationDate-empty"}>
                        <div className={`${parentStyle}__table-body__occupationDate-empty`}>
                        </div>
                    </td>
                    <td className={"el-birthDate-empty"}>
                        <div className={`${parentStyle}__table-body__birthDate-empty`}>
                        </div>
                    </td>
                    <td className={"el-category-empty"}>
                        <div className={`${parentStyle}__table-body__category-empty`}>
                        </div>
                    </td>
                    <td className={"el-agreement-empty"}>
                        <div className={`${parentStyle}__table-body__agreement-empty`}>
                        </div>
                    </td>
                </tr>
            );
            return e;
        });
    }
}

export default EmployeeListHelper;
