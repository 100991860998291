import {data} from "./layoffServicePage.js";

import Header from "../../../layout/Header/Header";
import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import Footer from "../../../layout/Footer/Footer";

import React from "react";

const LayoffPage = () => {
    return (
        <>
            <Header/>
            <MultiRoute
                breadCrumbs={true}
                subHeader={"Увольнение"}
                styleParent={"work-time"}
                data={data}
            />
            <Footer/>
        </>
    );
};

export default LayoffPage;
