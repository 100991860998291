import { useInputCompositionContext } from "../../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import React, { Children, cloneElement, isValidElement } from "react";
import {v4 as uuidv4} from "uuid";

import "./SimpleDropdown.scss";

/*
 if you want to pass custom list item
 you should use flag "component" and pass custom component as children
*/



const SimpleDropdown = (
    {
        setState,
        items,
        forceClose,
        field,
        setStateModel,
        injectMethods,
        children,
    }
) => {

    const [, , isError, setIsError] = useInputCompositionContext();

    function itemClickHandler (event, listItem) {
        if (isError) {
            setIsError(prev => {
                if (prev[field]) {
                    const temp = prev;
                    delete temp[field];
                    return temp;
                } else {
                    return prev;
                }
            });
        }
        injectMethods && injectMethods.forEach(method => {
            if (method) {
                if (method.dispatch) {
                    method.dispatch(method.call(listItem.id));
                } else {
                    method.call();
                }
            }
        });
        forceClose();
    }

    return items
        ? (
            <ul className="simple-dropdown-default">
                {
                    items.map((listItem) => {
                        return (
                            <div key={uuidv4()}>
                                {
                                    children
                                        ? (
                                            <div>
                                                {
                                                    Children && Children.map(children, (child)=> {
                                                        if (isValidElement(child)) {
                                                            return cloneElement(
                                                                child,
                                                                {
                                                                    listItem,
                                                                    setState,
                                                                    setStateModel,
                                                                    field,
                                                                    itemClickHandler,
                                                                }
                                                            );
                                                        }
                                                    })
                                                }
                                            </div>
                                        )
                                        : <div>Нет доступных полей для выбора</div>
                                }
                            </div>
                        );
                    })
                }
            </ul>
        )
        : (
            <ul className="simple-dropdown-default">
                <li className="simple-dropdown-default__empty-item"><div></div></li>
                <li className="simple-dropdown-default__empty-item"><div></div></li>
                <li className="simple-dropdown-default__empty-item"><div></div></li>
            </ul>
        );
};

export default SimpleDropdown;