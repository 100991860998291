import DropdownMenu from "../../../Menu/DropdownMenu/DropdownMenu";
import UButton from "../../../Button/UButton/UButton";

import "./ReportAnError.scss";
import {
    getReasonsForReportThunkAction,
} from "../../../../../redux/actions/Documents/EmployeDocuments/thunk/getReasonsForReport.thunk.action";
import CommentInput from "../../../Inputs/CommentInput/CommentInput";
import {
    documentSignigRejectThunkAction,
} from "../../../../../redux/actions/Requests/Hr-documents/thunk/documentSignigReject.thunk.action";

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ClipLoader} from "react-spinners";


const ReportAnError = (
    {
        information,
        close,
    }
) => {

    const dispatch = useDispatch();

    const rejectReasons = useSelector(state => state.documents.rejectReasons);

    const [requestStatus, setRequestStatus] = useState("");
    const [form] = useState({
        packageId: information.hrDocument.packageId,
        documentId: information.hrDocument.docsAttacments[information.index].docId,
        workerId: information.hrDocument.workerId,
    });
    const [input, setInput] = useState({
        text: "",
        validate: false,
    });
    const [selectedOption, setSelectedOption] = useState(false);
    const [loader, setLoader] = useState(false);

    function selectDropdown( event, element, stateProperty) {
        setSelectedOption(element);
    }

    function sendHandler (selectedOption, input) {
        dispatch(documentSignigRejectThunkAction(form, selectedOption, input, {
            setRequestStatus,
            setLoader,
        }));
    }

    useEffect(()=> {
        dispatch(getReasonsForReportThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return loader
        ? (<div className='request-modal-loader'>
            <ClipLoader color="#6DCDF6"/>
        </div>)
        : (<div className={"report-error"}>
            {
                requestStatus ?
                    <div className={"report-error__statusModal"}>
                        <div className={"report-error__statusModal__errorText"}>
                            {requestStatus}
                        </div>
                        <UButton
                            className="report-error__container__button"
                            onClick={close}
                        >назад</UButton>
                    </div> :
                    <>
                        <div className={"report-error__main-header"}>{information.hrDocument.typeText} № 64356-12/22
                        </div>
                        <div className={"report-error__header"}>
                            <span>{information.hrDocument.docsAttacments[information.index].nameDoc}</span>
                        </div>
                        <DropdownMenu
                            styleName={"reason"}
                            label={"Выберите тип ошибки из перечня *"}
                            listOfOptions={rejectReasons}
                            stateProperty={"comment"}
                            selectOptionClick={selectDropdown}
                        />
                        {
                            selectedOption &&
                            selectedOption.isUserCommentRequired === true ?
                                (
                                    <CommentInput
                                        value={input.text}
                                        basicInput={true}
                                        maxLength={100}
                                        changeHandler={((event, prev) => (setInput({
                                            ...prev,
                                            text: event.target.value,
                                            validate: false,
                                        })))}
                                        label={"Опишите ваш случай   *"}
                                        errorMessage={input.validate}
                                        parentStyle={"extra-vacation-days"} />
                                ) : (
                                    <div style={{ minHeight: "60px" }}>

                                    </div>
                                )

                        }
                        <div className={"report-error__container"}>
                            <UButton
                                className="report-error__container__button"
                                onClick={close}
                            >назад</UButton>
                            <UButton
                                className="report-error__container__button"
                                onClick={() => sendHandler(selectedOption, input)}
                                disabled={(selectedOption.id === 3 && input.text.length === 0) || !selectedOption}
                            >направить</UButton>
                        </div>
                    </>
            }


            </div>
        );
};

export default ReportAnError;
