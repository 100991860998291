import OkButton from "../../../Button/OKButton/OKButton";

import {declineRequestsThunkAction} from "../../../../../redux/actions/Employees/thunk/declineRequests.thunk.action";
import {
    getEmployeesVacationRequestsThunkAction,
} from "../../../../../redux/actions/Employees/thunk/getEmployeesVacationRequests.thunk.action";

import TableAcceptRow from "../../../Holidays/TableAcceptRow/TableAcceptRow";

import {
    getEmployeesWithApplicationsThunkAction,
} from "../../../../../redux/actions/Employees/LeavesCrossings/thunk/getEmployeesWithApplications.thunk.action";

import {
    getApplicationDetailThunkAction,
} from "../../../../../redux/actions/Employees/thunk/getApplicationDetail.thunk.action";

import { clearApplicationDetailsAction } from "../../../../../redux/actions/Employees/clearApplicationDetails.action";

import ApplicationDetailsModal from "../../Employees/ApplicationDetailsModal/ApplicationDetailsModal";

import { approveRequestsThunkAction } from "../../../../../redux/actions/Employees/thunk/approveRequests.thunk.action";

import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {ClipLoader} from "react-spinners";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import "./AcceptVacation.scss";


const AcceptVacation = (
    {
        period,
        modal,
        setModal,
    }
) => {

    const dispatch = useDispatch();

    const startOfMonth = moment(period.dateFormat).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(period.dateFormat).endOf("month").format("YYYY-MM-DD");

    const selectedPids = useSelector(state => state.employees.selectedPids);
    const requests = useSelector(state => state.employees?.pendingApplications?.applications);
    const applicationDetails = useSelector(state => state.employees.applicationDetails);
    const UIDs = modal.items && modal.items.map(e => e.uid);

    const [requestsState, setRequestsState] = useState(requests && requests.filter(e => UIDs.includes(e.uid)));


    function closeModal () {
        setModal(prev => {
            return {
                ...prev,
                modal: false,
            };
        });
        dispatch(getEmployeesVacationRequestsThunkAction({
            from: startOfMonth,
            to: endOfMonth,
            subordinatePids: selectedPids ? selectedPids : [],
        }));
    }
    function sendHandler (e) {
        // object with collection of comments
        const temp = {};
        let counter = 0;
        for (let i = 0; i < requestsState.length; i += 1) {
            const input = document.getElementById(`cancel-${requestsState[i].uid}`);
            const value = input.value;
            if (value) {
                temp[`${requestsState[i].uid}`] = value;
                counter += 1;
            } else {
                input.classList.add("empty-cancel-comment");
            }
        }
        if (counter === requestsState.length) {
            setRequestsState(prev => {
                return prev.map((element) => {
                    return element.comment = temp[element.uid];
                });
            });
            dispatch(declineRequestsThunkAction(
                requestsState,
                setModal
            ));
        }
    }

    function declineApplicationHandler () {

        const arrayOfApplications = requests && requests.filter(app => app.uid === applicationDetails.uid);
        setModal( prev => {
            return {
                applicationUIDs: null,
                modal: "decline-comment",
                items: arrayOfApplications,
                loader: false,
            };
        });

    }

    function acceptApplicationHandler () {
        const arrayOfApplications = requests && requests.filter(app => app.uid === applicationDetails.uid);
        setModal( prev => {
            return {
                applicationUIDs: null,
                modal: "accept",
                items: arrayOfApplications,
                loader: false,
            };
        });
        dispatch(approveRequestsThunkAction(arrayOfApplications, setModal));
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";

        modal.modal === "application-details" && dispatch(getApplicationDetailThunkAction(modal.applicationUID));

        return () => {
            document.body.style.overflow = "unset";

            dispatch(getEmployeesWithApplicationsThunkAction({
                from: startOfMonth,
                to: endOfMonth,
                subordinatePids: selectedPids ? selectedPids : [],
            }));
            dispatch(clearApplicationDetailsAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal.items]);



    return modal && modal.modal === "application-details"
        ? applicationDetails
            ? <ApplicationDetailsModal
                details={applicationDetails}
                setModal={setModal}
                declineHandler={declineApplicationHandler}
                acceptHandler={acceptApplicationHandler}
                closeModal={closeModal}
                // from={startOfMonth}
                // to={endOfMonth}
            />
            : <ClipLoader color={"#6DCDF6"} />
        : (
            <div className="accept-holiday__wrapper">
                {modal.modal === "accept" &&
                        <div className='accept-holiday__header'>
                            <h3>
                                процесс согласования
                            </h3>
                            <p>
                                Не закрывайте страницу до завершения процедуры согласования
                            </p>
                        </div>
                }
                {(modal.modal === "decline" || modal.modal === "decline-comment") &&
                        <div className='accept-holiday__header'>
                            <h3>
                                Отклонение заявок
                            </h3>
                        </div>
                }
                <div className='accept-holiday__table-wrapper'>
                    <table>
                        <thead>
                            <tr>
                                <td className="ah-modal-name">
                                    ФИО
                                </td>
                                <td className="ah-modal-dates">
                                    даты отпуска
                                </td>
                                {(modal.modal === "accept" || modal.modal === "accept") &&
                                    <td className="ah-modal-status">
                                        процесс согласования
                                    </td>
                                }
                                {modal.modal === "decline-comment" &&
                                    <td className="ah-modal-status">
                                        Комментарий *
                                    </td>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                requests && requests.map((e, i) => {
                                    if (UIDs.includes(e.uid)) {
                                        return (
                                            <TableAcceptRow
                                                setModal={setModal}
                                                state={modal.modal}
                                                request={e}
                                                key={uuidv4()} />
                                        );
                                    } else {
                                        return null;
                                    }

                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div style={{
                    marginLeft: "98px",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "15px",
                    color: "#97999B"}
                }>* поля обязательные для заполнения</div>

                <div className='accept-holiday__controls'>
                    {
                        modal.modal === "decline-comment"
                            ? <div className="decline-modal__controls__buttons" style={
                                {
                                    display:"flex",
                                    justifyContent: "space-between",
                                    width: "964px",
                                }}>
                                <OkButton handler={closeModal} title={"Вернуться"} />
                                <OkButton handler={sendHandler} title={"Отклонить"} />
                            </div>
                            : modal.loader
                                ? <ClipLoader color={"#6DCDF6"}/>
                                : <OkButton handler={closeModal} title={"назад"}/>
                    }

                </div>
            </div>
        );
};

export default AcceptVacation;
