import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible, { portalRef }) {

    let handleClickOutside;

    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);
    function forceClose () {
        document.removeEventListener("mousedown", handleClickOutside);
        setIsComponentVisible(false);
    }

    useEffect(() => {

        switch (!!portalRef) {
        case false:
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target) && isComponentVisible) {
                    setIsComponentVisible(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);  // options : true

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);  // options : true
            };
        case true:
            handleClickOutside = (event) => {
                if (
                    ref.current &&
                        !ref.current.contains(event.target) &&
                        portalRef.current &&
                        !portalRef.current.contains(event.target) &&
                        isComponentVisible
                ) {
                    setIsComponentVisible(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);  // options : true

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);  // options : true

            };
        default: return true;
        }

    }, [isComponentVisible]);

    return [ ref, isComponentVisible, setIsComponentVisible, forceClose];
}