import $api from "../../../../services/axiosService";
import {deleteCurrentRequestAction} from "../deleteCurrentRequest.action";
import {getLeaderRequestsThunkAction} from "../../LeaderRequests/thunk/getLeaderRequests.thunk.action";

export const deleteCurrentRequestThunkAction = (action,number,uid,props,reason,pid) => async(dispatch)=> {
    try {
        props.setState(true);
        switch (action) {
        case "delete":
            try {
                const response = await $api.delete(`v1/leaverequest/${uid}`);
                if(response.data.isSuccess) {
                    dispatch(deleteCurrentRequestAction(uid));
                    props.setState(false);
                }
                return props.setDeleteState(`Заявка №${number}  успешно удалена`);
            } catch (e) {
                props.setState(false);
                return props.setDeleteState(e.response.data.detail);
            }

        case "cancel":
            try {
                const cancel = await $api.post(`v1/leaverequest/${uid}/cancel`);
                if(cancel.data.isSuccess) {
                    dispatch(deleteCurrentRequestAction(uid));
                    props.setState(false);
                }
                return props.setDeleteState(`Заявка №${number}  отозвана`);
            } catch (e) {
                props.setState(false);
                return props.setDeleteState(e.response.data.detail);
            }

        case "approve":
            try {
                const resp = await $api.post(`v1/leaverequest/linemanager/${uid}/request/${pid}/approve`);
                if(resp.data.isSuccess) {
                    dispatch(deleteCurrentRequestAction(uid));
                    props.setState(false);
                    dispatch(getLeaderRequestsThunkAction());
                }
                return props.setDeleteState(`Заявка №${number} согласована`);
            } catch (e) {
                props.setState(false);
                return props.setDeleteState("Произошла ошибка....Попробуйте позже");
            }

        case "reject":
            try {
                // console.log(reason)
                const respReject = await $api.post(`v1/leaverequest/linemanager/${uid}/request/${pid}/reject`, {comment:reason});
                if(respReject.data.isSuccess) {
                    dispatch(deleteCurrentRequestAction(uid));
                    props.setState(false);
                    dispatch(getLeaderRequestsThunkAction());
                }
                return props.setDeleteState(`Заявка №${number} отклонена`);
            } catch (e) {
                props.setState(false);
                return props.setDeleteState(e.response.data.detail);
            }
        default: return true;
        }
    }catch (e) {
        // console.log('ERROR_IN_CURRENT_REQUEST_CARD: ',e.response.data.detail)
    }
};
