export const data = [
    {
        title: "Справки о заработной плате",
        description:
      "Справки о доходах, о сумме заработной платы, о начисленной и удержанной заработной плате, о надбавках, о среднем заработке",
        path: "/main/references/salary",
    },
    {
        title: "Пенсионные справки\n" +
      "и справки о стаже",
        // eslint-disable-next-line max-len
        description: "Справки для оформления пенсии в ПФР, о подтверждении трудового стажа, для оформления Алмазной осени, о стаже работе в компании и другие",
        path: "#",
    },
    {
        title: "Копия трудовой книжки",
        description: "Заверенная или не заверенная копия трудовой книжки работника",
        path: "#",
    },
    {
        title: "Для получения визы",
        description:
      "Справки для посольств на русском и английском языках",
        path: "#",
    },
    {
        title: "Прочие справки",
        description: "Прочие справки не вошедшие в другие разделы сервиса “Заказ справок”",
        path: "#",
    },
    {
        title: "Рождение и уход за ребенком",
        description:
      "Справка о (не) нахождении в отпуске по уходу за ребенком до 3 лет, о неполучениии пособия для ребенка до 1,5 лет и другие",
        path: "#",
    },
];
