import { INPUT_TEXT_AREA_CONTENT } from "../../../enums/Request/currentRequestControls.types";

export const inputTextAreaContentCurrentRequestAction = (field, value) => {
    return {
        type: INPUT_TEXT_AREA_CONTENT,
        payload: {
            field,
            value,
        },
    };
};