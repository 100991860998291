

const WidgetDiv = (
    {
        title,
        description,
        styleParent,
    }) => {
    return (
        <div
            className={`${styleParent}__item`}
        >
            <div className={`${styleParent}__block-head`}>
                {title}
            </div>
            <div className={`${styleParent}__description`}>
                {description}
            </div>
        </div>
    );
};

export default WidgetDiv;
