import {$api} from "../../../../services/request.config";
import {createSignRequestAction} from "../createSignRequest.action";

export const createSignRequestThunkAction = ({uid, setModalSentStatus}) => async (dispatch) => {
    try {
        const response = await $api.post(`v2/request/${uid}/create-sign-request`);
        if(response.data) {
            dispatch(createSignRequestAction(response.data));
            setModalSentStatus(true);
        }
    } catch (e) {
        // console.log('ERROR_WHILE_CREATE_SIGN_REQUEST: ', e?.response)
    }
};
