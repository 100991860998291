import {approveRequestFailureAction} from "./approveRequestFailure.action";

import $api from "../../../services/axiosService";
import {approveRequestSuccessAction} from "../approveRequestSuccess.action";

const loaderStatus = (setter ,status) => {
    setter(prev => {
        return {
            ...prev,
            loader: status,
        };
    });
    return true;
};

export const approveRequestsThunkAction = (
    arrayOfRequests,
    setModal
) => async (dispatch) => {
    try {
        for (let i = 0; i < arrayOfRequests.length; i += 1) {
            loaderStatus(setModal, true);
            const response = await $api.post(
                `v1/leaverequest/linemanager/${arrayOfRequests[i].uid}/request/${arrayOfRequests[i].pid}/approve`
            );
            if (response.data.isSuccess) {
                dispatch(approveRequestSuccessAction(arrayOfRequests[i].uid));
            } else {
                dispatch(approveRequestFailureAction(arrayOfRequests[i].uid));
            }
        }
        loaderStatus(setModal, false);

    } catch (error) {
        loaderStatus(setModal, false);

        // console.log("ERROR APPROVAL PROCESS: ", error)
    }
};
