import TableService from "../../../../frontend.services/TableServices/Table.service";

export const tableHeadPrefix = [
    {
        id: "1tableHead",
        cssKey: "__department",
        data: {
            upperValue: "структурное",
            lowerValue: "подразделение",
        },
    },
    {
        id: "2tableHead",
        cssKey: "__worker",
        data: "Сотрудник",
    },
    {
        id: "3tableHead",
        cssKey: "__vacation-days",
        data: {
            upperValue: "дни",
            lowerValue: "отпуска",
        },
    },
];

export const tableYear = [
    {
        // TODO USER ID
        uid: "userID1234567890",
        id: "1tableRow",
        cssKey: "__department",
        data: "Ежегодный отпуск",
    },
];

export const tableUsers = [
    {
        // TODO USER ID
        uid: "userID1234567890",
        id: "1tableRow",
        cssKey: "__department",
        data: "Управление проектной деятельностью",
    },
    {
        id: "2tableRow",
        cssKey: "__worker",
        data: "Константинопольский Константин Васильевич",
    },
    {
        id: "3tableRow",
        cssKey: "__vacation-days",
        data: "57 дней",
    },
];

export const tableHeader = TableService.getWeekWithPrefix(tableHeadPrefix);
