export const EMPTY_CURRENT_REQUEST = "EMPTY_CURRENT_REQUEST";
export const SET_CURRENT_REQUEST_FROM_CLASS = "SET_CURRENT_REQUEST_FROM_CLASS";
export const DROPDOWN_SELECT_CURRENT_REQUEST =
  "DROPDOWN_SELECT_CURRENT_REQUEST";
export const DROPDOWN_SELECT_FOR_ARRAY_CURRENT_REQUEST =
  "DROPDOWN_SELECT_FOR_ARRAY_CURRENT_REQUEST";

export const CALENDAR_DATE_PICK = "CALENDAR_DATE_PICK";
export const SINGLE_DATE_PICK = "SINGLE_DATE_PICK";
export const DATEPICKER_SELECT_CURRENT_REQUEST =
  "DATEPICKER_SELECT_CURRENT_REQUEST";
export const ADD_PERIOD_HANDLER_CURRENT_REQUEST =
  "ADD_PERIOD_HANDLER_CURRENT_REQUEST";
export const DELETE_PERIOD_HANDLER_CURRENT_REQUEST =
  "DELETE_PERIOD_HANDLER_CURRENT_REQUEST";

export const ADD_COMMENT_HANDLER_CURRENT_REQUEST =
  "ADD_COMMENT_HANDLER_CURRENT_REQUEST";
export const ADD_BASIC_INPUT_HANDLER_CURRENT_REQUEST =
  "ADD_BASIC_INPUT_HANDLER_CURRENT_REQUEST";

export const VALIDATION_CONTROL_CURRENT_REQUEST =
  "VALIDATION_CONTROL_CURRENT_REQUEST";
export const INPUT_VALIDATION_IN_ARRAY = "INPUT_VALIDATION_IN_ARRAY";
export const ATTACHMENTS_VALIDATION = "ATTACHMENTS_VALIDATION";
export const CHANGE_CHECKED_STATE = "CHANGE_CHECKED_STATE";

export const SET_STRUCT_ID_IMPORTANT_TASK = "SET_STRUCT_ID_IMPORTANT_TASK";

export const INPUT_TEXT_AREA_CONTENT = "INPUT_TEXT_AREA_CONTENT";