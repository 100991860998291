export const unepData = [
    {
        title: "Простая электронная подпись (ПЭП)",
        description: "Информация о простой электронной подписи ",
        path: "#",
    },
    {
        title: "Усиленная неквалифицированная электронная подпись (УНЭП)",
        description: "Получение и информация об усиленной неквалифицированной электронной подписи",
        path: "/profile/eds-release/unep",
        // secured: true,
    },
    {
        title: "Усиленная квалифицированная электронная подпись (УКЭП)",
        description: "Информация об усиленной квалифицированной электронной подписи",
        path: "#",
    },
];
