import {SET_USER_LOGIN, UNSET_USER} from "../../enums/AuthType";

export const userLoginAction = (user) =>{
    return {
        type:SET_USER_LOGIN,
        payload:user,
    };
};
export const unsetUserAction = () => {
    return {
        type:UNSET_USER,
    };
};
