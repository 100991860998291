import $api from "./axiosService";

class AuthService {
    static async getHeaderInformation() {
        try {
            let signType = await $api
                .get("v1/sections-access/general")
                .catch((error) =>
                // {
                //     console.log("EEEERRRRROOOORRR"),
                // return
                    localStorage.setItem(
                        "restricted",
                        "false",
                    ),
                    // }
                );
            const documentSignType = await $api
                .get("v1/p/document/sign/type")
                .catch((error) => localStorage.setItem("documentSignType", 2));

            const resp = await $api
                .get("/v1.0/c/u/0")
                .catch((error) => new Error(error));
            const division = await $api
                .get("/v1.0/c/u/12")
                .catch((error) => new Error(error));
            if (documentSignType &&
                documentSignType.data && documentSignType.data.documentSignType
            ) {
                localStorage.setItem(
                    "documentSignType",
                    documentSignType.data.documentSignType);

            } else {
                localStorage.setItem("documentSignType", 2);
            }
            // const agreement = await $api
            //     .get("/v1/firm/edm-agreement")
            //     .catch((error) => new Error(error));

            // if (signType && signType.data) {
            //     if (signType.data.restricted === false) {
            //         localStorage.setItem("restricted", true);
            //         localStorage.setItem("reason", signType.data.reason);
            //     }
            //     console.log(signType.data.restricted);
            //     console.log(typeof (signType.data.restricted));
            // }
            // if (agreement && agreement.data && agreement.data.agreement > 0) {
            if (!signType) {
                console.log("EEEERRRRROOOORRR");
                signType = {
                    data: {
                        restricted: false,
                    },
                };
            }
            if (
                signType &&
                signType.data &&
                signType.data.restricted === false
            ) {
                // localStorage.setItem(
                //     "documentSignType",
                //     signType.data.documentSignType,
                // );
                localStorage.setItem(
                    "restricted",
                    "false",
                );
                const actualDocuments = await $api
                    .get("v2/directum/documents/actual")
                    .catch((error) =>
                    // {
                        localStorage.setItem("hasActualDocuments", "false"),
                        // throw  new Error(error);
                        // }
                    );
                if (actualDocuments) {
                    localStorage.setItem(
                        "hasActualDocuments",
                        actualDocuments.data.hasActualDocuments,
                        // "false",
                    );
                } else {
                    localStorage.setItem("hasActualDocuments", "false");
                }

            } else {
                localStorage.setItem("restricted", true);
                localStorage.setItem("reason", signType.data.reason);
            }
            localStorage.setItem("agreement", 2);
            const temp = resp.data.data.personal;
            const appointment = division.data.data.appointment[0].position;

            localStorage.setItem("fio", JSON.stringify(temp));
            localStorage.setItem("appointment", appointment);

            return true;
        } catch (e) {
            return false;
        }
    }
}

export default AuthService;
