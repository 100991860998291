import ControlledDatePicker from "./ControlledDatePicker/ControlledDatePicker";

import greyPlus from "./PlusCircle.svg";
import redPlus from "./RedRotatedPlusCircle.svg";
import bluePlus from "./BluePlusCircle.svg";

import {
    addPeriodHandlerCurrentRequest,
} from "../../../../redux/actions/Requests/currentRequestControls/addPeriodHandler.currentRequest";
import {
    deletePeriodHandlerCurrentRequest,
} from "../../../../redux/actions/Requests/currentRequestControls/deletePeriodHandler.currentRequest";

import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import React, { memo } from "react";

const BlueButton = ({ parentStyle, setState }) => {

    const dispatch = useDispatch();

    function addPeriodHandler(e) {
        dispatch(addPeriodHandlerCurrentRequest());
    }

    return (
        <img
            onClick={addPeriodHandler}
            className={`${parentStyle}-date-period__blue-plus`}
            src={bluePlus}
            alt="bluePlus" />
    );
};

const RedButton = ({ parentStyle, setState, index }) => {

    const dispatch = useDispatch();

    function deletePeriodHandler(e) {
        dispatch(deletePeriodHandlerCurrentRequest(index));
    }

    return (
        <img
            onClick={deletePeriodHandler}
            className={`${parentStyle}-date-period__red-plus`}
            src={redPlus}
            alt="redPlus" />
    );
};

const GreyButton = ({ parentStyle }) => {
    return (
        <img
            className={`${parentStyle}-date-period__grey-plus`}
            src={greyPlus}
            alt="greyPlus" />
    );
};


const DatePeriodControlled = (
    {
        requestState,
        // setRequestState,
        parentStyle,
        // setPeriods,
        period,
        index,
        periodsLength,
        label,
        datesForRange,
    },
) => {

    return (
        <div key={uuidv4()} className={`${parentStyle}-date-period`}>
            <div className={`${parentStyle}-date-period__input-wrapper`}>
                <div className={`${parentStyle}-date-period__from`}>
                    <ControlledDatePicker
                        period={period}
                        requestState={requestState}
                        date={period.dateFrom}
                        index={index}
                        field={"dateFrom"}
                        parentStyle={parentStyle}
                        label={label ? label.from : "Начало перенесенного отпуска *"}
                        datesForRange={datesForRange} />
                </div>
                <div className={`${parentStyle}-date-period__to`}>
                    <ControlledDatePicker
                        period={period}
                        requestState={requestState}
                        date={period.dateTo}
                        index={index}
                        field={"dateTo"}
                        parentStyle={parentStyle}
                        label={label ? label.to : "Окончание перенесенного отпуска *"} />
                </div>
            </div>
            <div className={`${parentStyle}-date-period__controllers`}>
                {periodsLength > 1 &&
          <RedButton
              parentStyle={parentStyle}
              index={index}
          />}
                {
                    !period.dateFrom || !period.dateTo
                        ? <GreyButton parentStyle={parentStyle} />
                        : index === periodsLength - 1
                            ? <BlueButton
                                parentStyle={parentStyle}
                            />
                            : null
                }

            </div>
        </div>
    );
};

export default memo(DatePeriodControlled);
