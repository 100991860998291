import stylesTerminal from "./RegFormTerminal.module.css";

import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";
import "./RegForm.scss";
import passwordEye from "../../../../assets/icons/authIcons/eye.svg";
import openEyes from "../../../../assets/icons/authIcons/ic_eyes_open.svg";

import { resetErrorMessageAction } from "../../../../redux/actions/Auth/wrongAuthAction";
import UButton from "../../Button/UButton/UButton";
import file from "../../../pages/RegisterPage/terms.pdf";

import { isTerminal } from "../../../../constants";
import { InputTerminal } from "../../componentsTerminal/Input/InputTerminal";
import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";
import { Modal } from "../../Modal/Modal";
import { ScrollButton } from "../../componentsTerminal/ScrollButton/ScrollButton";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";


const RegForm = ({
    validPassword,
    setValidPassword,
    invalidLength,
    setInvalidLength,
    validSnils,
    setvalidSnils,
    input,
    inputHandler,
    passwordShown,
    togglePasswordVisiblity,
    togglePasswordVisiblityRepeat,
    passwordShownRepeat,
    setChecked,
    checked,
    inputSnils,
    handleChangeSnils,
    submitHandler,
    clickHandlerSudgestions,
    clickHandlerSupport,
    openEye,
    openEyeRepeat,
}) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const authRegister = useSelector((state) => state.authRegister);
    const [isOpenModal, setOpenModal] = useState(false);
    // const user = useSelector((state) => state.user);
    // const auth = useSelector((state) => state.auth);
    // const [invalidRegister,setInvalidRegister] = useState(false)

    const setToValidLabelAndInput = () => {
        setvalidSnils(true);
        dispatch(resetErrorMessageAction());
    };

    useEffect(() => {
        return () => {
            setvalidSnils(true);
            dispatch(resetErrorMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isTerminal) {
        return (
            <>
                <form onSubmit={submitHandler} className="register-form">
                    <img
                        className="register-form__logo"
                        src={logo}
                        alt="reg-logo"
                    />
                    <div className="register-form__wrapper">
                        {authRegister ? (
                            <label
                                className="register-form__wrapper__label-invalid"
                                htmlFor="socNumber">
                                {authRegister}
                            </label>
                        ) : (
                            <label
                                className={
                                    validSnils
                                        ? "register-form__wrapper__label"
                                        : "register-form__wrapper__label-invalid"
                                }
                                htmlFor="socNumber">
                                {validSnils
                                    ? "СНИЛС"
                                    : "Необходимо ввести корректный СНИЛС"}
                            </label>
                        )}
                        <input
                            name="socNumber"
                            onClick={setToValidLabelAndInput}
                            className={
                                (!validSnils &&
                                    "register-form__wrapper__input-invalid") ||
                                (authRegister &&
                                    "register-form__wrapper__input-invalid") ||
                                "register-form__wrapper__input"
                            }
                            type="tel"
                            ref={inputSnils}
                            onChange={handleChangeSnils}
                        />
                    </div>
                    <div className="register-form__wrapper">
                        <label
                            htmlFor="password"
                            className={
                                !invalidLength
                                    ? "register-form__wrapper__label"
                                    : "register-form__wrapper__label-invalid"
                            }>
                            {!invalidLength
                                ? "Пароль"
                                : "Пароль должен содержать не менее 8 символов"}
                        </label>
                        <input
                            name="password"
                            maxLength={32}
                            onChange={inputHandler}
                            onClick={() => setInvalidLength(false)}
                            className={
                                (invalidLength &&
                                    "register-form__wrapper__input-invalid") ||
                                "register-form__wrapper__input"
                            }
                            type={passwordShown ? "text" : "password"}
                        />
                        <i
                            className="register-form__wrapper__icon"
                            onClick={togglePasswordVisiblity}>
                            {openEye ? (
                                <img src={openEyes} alt="openEye" />
                            ) : (
                                <img src={passwordEye} alt="icon" />
                            )}
                        </i>{" "}
                    </div>
                    <div className="register-form__wrapper">
                        <label
                            htmlFor="confirmPassword"
                            className={
                                validPassword
                                    ? "register-form__wrapper__label"
                                    : "register-form__wrapper__label-invalid"
                            }>
                            {validPassword
                                ? "Повторите пароль"
                                : "Пароли должны совпадать"}
                        </label>
                        <input
                            name="confirmPassword"
                            maxLength={32}
                            className={
                                (!validPassword &&
                                    "register-form__wrapper__input-invalid") ||
                                "register-form__wrapper__input"
                            }
                            onChange={inputHandler}
                            onClick={() => setValidPassword(true)}
                            type={passwordShownRepeat ? "text" : "password"}
                        />
                        <i
                            className="register-form__wrapper__icon"
                            onClick={togglePasswordVisiblityRepeat}>
                            {openEyeRepeat ? (
                                <img src={openEyes} alt="openEye" />
                            ) : (
                                <img src={passwordEye} alt="icon" />
                            )}
                        </i>{" "}
                    </div>
                    <UButton
                        disabled={
                            !input.socNumber ||
                            !input.password ||
                            !input.confirmPassword ||
                            !checked
                        }
                    >зарегистрироваться</UButton>
                    <div className="register-form__wrapper">
                        <div className="register-form__wrapper__checkbox-text">
                            <input
                                type="checkbox"
                                className="checkboxInput"
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}
                            />
                            <div>
                                Ознакомление с{" "}
                                <a
                                    href={file}
                                    target="_blank"
                                    className="colored-texts"
                                    rel="noreferrer">
                                    Условиями
                                    <br />
                                    использования приложения
                                </a>
                            </div>
                        </div>
                        <div className="register-form__wrapper__login">
                            Уже есть аккаунт?{" "}
                            <span
                                onClick={() => navigate("/login")}
                                className="colored-texts">
                                Войти
                            </span>
                        </div>
                    </div>
                    <div className="register-form__support">
                        <span
                            className="colored-texts"
                            onClick={clickHandlerSupport}>
                             Запросить в поддержку
                        </span>
                    </div>
                </form>
            </>
        );
    }

    if (isTerminal) {
        return (
            <>
                <form
                    onSubmit={submitHandler}
                    onClick={setToValidLabelAndInput}
                    className={stylesTerminal.regTerminal}>
                    <img src={logo} alt="login-logo" style={{ width: "50%" }} />
                    <InputTerminal
                        name="socNumber"
                        placeholder="Начните вводить значение"
                        label="СНИЛС"
                        type="tel"
                        inputRef={inputSnils}
                        onChange={handleChangeSnils}
                        errorView={authRegister}
                    />
                    <InputTerminal
                        name="password"
                        placeholder="Введите пароль"
                        label="Пароль"
                        type={passwordShown ? "text" : "password"}
                        onChange={inputHandler}
                        onClick={() => setValidPassword(true)}
                        errorView={
                            invalidLength &&
                            "Пароль должен содержать не менее 8 символов"
                        }
                        trailingIcon={
                            <i
                                className={stylesTerminal.wrapperEye}
                                onClick={togglePasswordVisiblity}>
                                {openEye ? (
                                    <img src={openEyes} alt="openEye" />
                                ) : (
                                    <img src={passwordEye} alt="icon" />
                                )}
                            </i>
                        }
                    />
                    <InputTerminal
                        name="confirmPassword"
                        placeholder="Введите пароль"
                        label="Повторите пароль"
                        onClick={() => setValidPassword(true)}
                        type={passwordShownRepeat ? "text" : "password"}
                        errorView={!validPassword && "Пароли должны совпадать"}
                        onChange={inputHandler}
                        trailingIcon={
                            <i
                                className={stylesTerminal.wrapperEye}
                                onClick={togglePasswordVisiblityRepeat}>
                                {openEyeRepeat ? (
                                    <img src={openEyes} alt="openEye" />
                                ) : (
                                    <img src={passwordEye} alt="icon" />
                                )}
                            </i>
                        }
                    />
                    <ButtonTerminal
                        label="Зарегистрироваться"
                        disabled={
                            !input.socNumber ||
                            !input.password ||
                            !input.confirmPassword ||
                            !checked
                        }
                    />
                </form>
                <div className={stylesTerminal.regHelpersWrapper}>
                    <input
                        type="checkbox"
                        className={stylesTerminal.checkbox}
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                    <div>
                        Ознакомление с{" "}
                        <span
                            onClick={() => setOpenModal(true)}
                            className={stylesTerminal.coloredTexts}
                        >
                            Условиями
                            <br />
                            использования Личного кабинета
                        </span>
                    </div>
                    <Modal shown={isOpenModal} close={setOpenModal}>
                        <iframe
                            title="Pdf"
                            className={stylesTerminal.modal}
                            src={require("../../../pages/RegisterPage/terms.pdf")}
                        />
                    </Modal>
                </div>
                <div className={stylesTerminal.regHelpers}>
                    <div className={stylesTerminal.regHelpersWrapper}>
                        <span>Уже есть аккаунт?</span>
                        <ButtonTerminal
                            label="Войти"
                            variant="text"
                            onClick={() => navigate("/login")}
                        />
                    </div>
                    <ButtonTerminal
                        label="Запрос в поддержку"
                        variant="text"
                        textAlign="text-start"
                        onClick={clickHandlerSupport}
                    />
                </div>
            </>
        );
    }
};

export default RegForm;
