import stylesTerminal from "./SecondAunteficationTerminal.module.css";

import ModalSupport from "../../common/Auth/ModalSupport/ModalSupport";
import { authNumberThunkAction } from "../../../redux/actions/AuthNumber/Thunk/authNumberThunkAction";
import { Modal } from "../../common/Modal/Modal";
import { isTerminal } from "../../../constants";
import PhoneVerify from "../../common/Auth/PhoneVerify/PhoneVerify";
import { repeatSmsCodeThunkAction } from "../../../redux/actions/AuthNumber/Thunk/repeatSmsCode.thunk.action";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../LoginPage/LoginPage.scss";

const SecondAuthentication = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authNumber = useSelector((state) => state.authNumber);
    const isAuth = useSelector((state) => state.isAuth);
    const resetPassword = useSelector((state) => state.resetPassword);
    const user = useSelector((state) => state.user);

    const [input, setInput] = useState("");
    const [counter, setCounter] = useState(60);
    const [active, setActive] = useState(false);
    const [modalShownSupport, toggleModalSupport] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);


    const submitHandler = (e) => {
        e.preventDefault();
        if (input.length) {
            setDisableSubmit(true);
            dispatch(
                authNumberThunkAction(
                    {
                        userId: user.userId,
                        code: input,
                        consumer: 1,
                    },
                    { navigate: navigate },
                    resetPassword,
                ),
            )
                .then(()=>setDisableSubmit(false))
                .finally(()=>setDisableSubmit(false));

            if (isAuth) {
                if (resetPassword) {
                    navigate("/reset-password");
                } else {
                    navigate("/main");
                }
            }
        }
    };

    function repeatSmsCode() {
        dispatch(repeatSmsCodeThunkAction(user.userId));
    }

    function clickHandlerSupport(e) {
        toggleModalSupport(!modalShownSupport);
    }

    function closeHandlerSupport(e) {
        toggleModalSupport(false);
    }

    useEffect(() => {
        if (isAuth) {
            if (resetPassword) {
                navigate("/reset-password");
            } else {
                navigate("/main");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    return (
        <div className={ isTerminal ? stylesTerminal.container : "login-container"}>
            <div className={ isTerminal ? stylesTerminal.wrapper : "login-wrapper"}>
                <PhoneVerify
                    input={input}
                    setInput={setInput}
                    submitHandler={submitHandler}
                    counter={counter}
                    setCounter={setCounter}
                    active={active}
                    setActive={setActive}
                    clickHandlerSupport={clickHandlerSupport}
                    phone={user.phoneNumber}
                    styleParent={"phone-verify"}
                    authNumber={authNumber}
                    repeatCode={repeatSmsCode}
                    disableSubmit={disableSubmit}
                />
            </div>
            {/*<img className={'login-img-auth'} src={img} alt="photo"/>*/}
            { !isTerminal &&  <div className={"login-img-auth"}></div> }
            { isTerminal && <img style={{height: "100vh"}} src={require("../../../assets/authPhoto/photo.png")} alt="" /> }
            <Modal shown={modalShownSupport} close={closeHandlerSupport}>
                <ModalSupport close={closeHandlerSupport} />
            </Modal>
        </div>
    );
};

export default SecondAuthentication;
