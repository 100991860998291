import React from "react";

const ICLabel = (
    {
        value,
        groupId,
        require,
    }
) => {


    return (
        <label htmlFor={groupId ? groupId : null}>
            {
                value
            }
            {
                require
                    ? <span className={"input-composition-default__label__star"}>*</span>
                    : null
            }
        </label>
    );
};
export default ICLabel;