import {links, representationLinks, workerLinks} from "../../../pages/MyDivision/WorkTimeWorkersPage/HolidaysPage/holidays.data";

import React  from "react";
import {NavLink, useLocation} from "react-router-dom";
import "./HolidaysTabs.scss";
import {ClipLoader} from "react-spinners";

const HolidaysTabs = (
    {
        representation,
        todayPercent,
    }
) => {

    const location = useLocation();

    return (
        // CROSSING PERCENT
        <div className="hp-header">
            <div className="hp-header__upper">
                <div className="hp-header__upper__left">
                    Отпуска и дни отдыха
                </div>
                <div className="hp-header__upper__right">
                    {todayPercent ? todayPercent : <ClipLoader size={20} color={"FD8E28"}/>} % пересечений
                </div>
            </div>

            {/*TABS*/}
            <div className="hp-header__lower">
                {
                    representation === "split"
                        ?
                        <ul className="hp-header__lower__tabs hp-tabs">
                            {workerLinks && workerLinks.map(e => {
                                return (
                                    <li
                                        key={e.id}
                                        className="hp-tabs__item"
                                    >
                                        <NavLink
                                            id={e.search}
                                            to={
                                                location.search
                                                    ? location.search.replace(/((?<=\?section=).*?(?=&))/gmi,`${e.search}`)
                                                    : e.path
                                            }
                                            className={
                                                ({isActive}) => {
                                                    const sectionRegex = /(?<=section=).+((?=(&|$)))/;
                                                    let section = [];
                                                    if (location.search.match(sectionRegex)){
                                                        section = location.search.match(sectionRegex);
                                                        if (section[0].includes("&")) {
                                                            section = section[0].match(/^.*?(?=&)/);
                                                        }
                                                    }
                                                    if (section.includes(e.search)) {
                                                        return isActive ? "hp-tabs__item__active" : "hp-tabs__item";
                                                    } else {
                                                        return isActive ? "hp-tabs__item" : "hp-tabs__item__active";
                                                    }
                                                }
                                            }>
                                            {e.title}
                                        </NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                        :
                        <ul className="hp-header__lower__tabs hp-tabs">
                            {links && links.map(e => {
                                return (
                                    <li
                                        key={e.id}
                                        className="hp-tabs__item"
                                    // onClick={sectionHandler}
                                    >
                                        <NavLink
                                            id={e.search}
                                            to={
                                                location.search
                                                    ? location.search.replace(/((?<=\?section=).*?(?=&))/gmi,`${e.search}`)
                                                    : e.path
                                            }
                                            className={
                                                ({isActive}) => {
                                                    const sectionRegex = /(?<=section=).+((?=(&|$)))/;
                                                    let section = [];
                                                    if (location.search.match(sectionRegex)){
                                                        section = location.search.match(sectionRegex);
                                                        if (section[0].includes("&")) {
                                                            section = section[0].match(/^.*?(?=&)/);
                                                        }
                                                    }
                                                    if (section.includes(e.search)) {
                                                        return isActive ? "hp-tabs__item__active" : "hp-tabs__item";
                                                    } else {
                                                        return isActive ? "hp-tabs__item" : "hp-tabs__item__active";
                                                    }
                                                }
                                            }>
                                            {e.title}
                                        </NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                }
                <ul className="hp-header__lower__visual-representation hp-visual">
                    {representationLinks && representationLinks.map(e => {
                        return (
                            <li
                                // onClick={representationHandler}
                                key={e.id}
                                className="hp-visual__item">
                                <NavLink
                                    id={e.search}
                                    to={
                                        location.pathname + "?" + e.path
                                        // location.search
                                        //     ? location.search.match(/(?<=&repres=).+((?=(&|$)))/)
                                        //         ? location.pathname + location.search.match(/^.*?(?=&)/) + '&' + e.path
                                        //             : location.pathname + location.search + '&' + e.path
                                        //     : location.pathname + '?' + e.path
                                    }
                                    className={({isActive}) => {
                                        const represRegex = /(?<=&repres=).+((?=(&|$)))/;
                                        let section = [];
                                        if (location.search.match(represRegex)){
                                            section = location.search.match(represRegex);
                                            if (section[0].includes("&")) {
                                                section = section[0].match(/^.*?(?=&)/);
                                            }
                                        }
                                        if (section.includes(e.search)) {
                                            return isActive ? "hp-visual__item__active" : "hp-visual__item";
                                        } else {
                                            return isActive ? "hp-visual__item" : "hp-visual__item__active";
                                        }

                                        // return isActive ? "hp-visual__item__active" : "hp-visual__item"
                                    }}
                                >
                                    {e.title}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>

        </div>
    );
};

export default HolidaysTabs;
