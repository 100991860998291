import React from "react";

import "./DelegationInProgressItem.scss";
import moment from "moment";

const DelegationInProgressItem = (
    {
        listItem,
        setState,
        setStateModel,
        field,
        itemClickHandler,
    }
) => {

    function selectHandler (event, listItem) {
        setState(() => listItem.fullName);
        setStateModel((prev) => {
            return {
                ...prev,
                [field]: listItem.substitutePid,
                substitutionType: listItem.substitutionType,
                delegationType: listItem.delegationType,
            };
        });

        itemClickHandler(event, listItem);
    }


    return (
        <div
            className={"delegation-in-progress-item"}
            onClick={(event) => selectHandler(event, listItem)}>
            <div className={"delegation-in-progress-item__fullname"}>
                {listItem.fullName}
            </div>
            <div className={"delegation-in-progress-item__info"}>
                <div className={"delegation-in-progress-item__info__dates"}>
                    <div>{moment(listItem.from).format("DD.MM.YYYY")}</div>
                    -
                    <div>{moment(listItem.to).format("DD.MM.YYYY")}</div>
                </div>
                <div className={"delegation-in-progress-item__info__comment"}>
                    {listItem.comment}
                </div>
            </div>
        </div>
    );
};

export default DelegationInProgressItem;