import BasicApplication from "./Basic.application";

class LayoffApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.dismissalDate = options.dismissalDate;
        this.dismissalObtainingDocumentId = options.dismissalObtainingDocumentId;
        this.dismissalMotives = options.dismissalMotives;
        this.obtainingDocumentAddress = options.obtainingDocumentAddress;
        this.isValid = {
            leaveReasonId: true,
            dismissalDate: true,
            dismissalObtainingDocumentId: true,
            dismissalMotives: true,
            obtainingDocumentAddress: true,
        };
    }
}

export default LayoffApplication;
