export const data = [
    {
        title: "Оценка условий труда по вредным (опасным) факторам",
        // description: "Оформление и информация об отпусках и днях отдыха",
        path: "/main/hr-services/work-time/sout/harmful-factors",
    },
    {
        title: "Гарантии и компенсации работнику",
        // description: "Оформление и информация о графике работы",
        path: "/main/hr-services/work-time/sout/guarantee-compensation",
    },
    
];
