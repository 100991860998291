import { $api } from "../../../../services/request.config";
import { newDeleteAttachmentAction } from "../newDeleteAttachment.action";

export const newDeleteAttachmentThunkAction = ({
    attach,
    setDeleteStatus,
    personIndex,
    attachArray,
}) => async (dispatch) => {
    try {
        setDeleteStatus(true);
        const response = await $api.delete(`v1/requests/attachments/${attach.uid ? attach.uid : attach.id}`);
        if (response.status === 200) {
            dispatch(newDeleteAttachmentAction({ uid: attach.uid ? attach.uid : attach.id, personIndex, attachArray }));
            setDeleteStatus(false);
        }
    } catch (error) {
        setDeleteStatus(false);
    }
};
