import OkButton from "../../../../Button/OKButton/OKButton";
import BasicInput from "../../../../Inputs/BasicInput/BasicInput";
import { passwordValidationAction } from "../../../../../../redux/actions/Requests/thunk/passwordValidation.action";
import CustomButton from "../../../../Button/CustomButton/CustomButton";
import SentStatusModal from "../SentStatusModal/SentStatusModal";
import ConfirmSignModal from "../../Layoff/ConfirmSignModal";
import {
    cofirmSignRequestThunkAction,
} from "../../../../../../redux/actions/Requests/Layoff/thunk/cofirmSignRequest.thunk.action";
import { isTerminal } from "../../../../../../constants";
import { Modal } from "../../../Modal";

import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useState } from "react";

isTerminal ? import("./RequestVerificationTerminal.scss") : import("./RequestVerification.scss");

const RequestVerification = (
    {
        unep,
        setModalSentStatus,
        route,
        setModalError,
        setOnValidation,
        sendButtonLoading,
        onValidation,
    },
) => {

    const dispatch = useDispatch();

    const currentRequest = useSelector(state => state.requests.currentRequest);
    const requestTypes = useSelector(state => state.requests.types);
    const file = useSelector(state => state.requests.application);
    const createSignRequest = useSelector(state => state.requests.createSignRequest);

    const [password, setPassword] = useState("");
    const [correctPassword, setCorrectPassword] = useState(true);
    const [sentStatus, setSentStatus] = useState(false);
    const [requestNumber, setRequestNumber] = useState(null);
    const [requestTheme, setRequestTheme] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    function buttonHandler(e) {
        if (unep) {
            dispatch(cofirmSignRequestThunkAction({
                operationId: createSignRequest.operationId,
                code: password,
                modal: setModalSentStatus,
                route: route,
                uid: currentRequest.validatedData.uid,
                currentRequest,
                setCorrectPassword,
                setSentStatus,
                setRequestNumber,
                setRequestTheme,
                setModalError: setModalError,
                setOnValidation: setOnValidation,
                setErrorMessage: setErrorMessage,

            }));
        } else {
            dispatch(passwordValidationAction(
                {
                    password,
                    modal: setModalSentStatus,
                    route: route,
                    uid: currentRequest.validatedData.uid,
                    currentRequest,
                    setCorrectPassword,
                    setSentStatus,
                    setRequestNumber,
                    setRequestTheme,
                    setModalError: setModalError,
                    setOnValidation: setOnValidation,
                },
            ));
        }
    }


    return sendButtonLoading ?
        <ClipLoader color="#6DCDF6" /> :
        (
            sentStatus
                ? <SentStatusModal
                    close={setModalSentStatus}
                    route={route}
                    requestNumber={requestNumber}
                    requestTheme={unep ? requestTheme : requestTypes.filter(e => e.id === requestTheme)}
                />
                : <div className="request-verification">
                    <div className="request-verification__content">
                        <div className="request-verification__head">
                            {isTerminal ? "З" : "з"}аявка сформирована
                        </div>
                        
                        <div className="request-verification__description">
              Для отправки на согласование,
              Вам необходимо ознакомиться с вашей
                            {file && (
                                isTerminal 
                                    ? 
                                    <>
                                        &nbsp;<span className="request-verification__link" onClick={() => setIsModalOpen(true)}>заявкой</span>&nbsp;
                                        <Modal shown={isModalOpen} close={() => setIsModalOpen(!isModalOpen)}>
                                            <iframe className="request-verification__iframe" src={file} />
                                        </Modal>
                                    </>
                                    : (
                                        <a target="_blank" href={file} rel="noreferrer">
                                        &nbsp;<span className={"request-verification__link"}>
                                            заявкой
                                            </span>&nbsp;
                                        </a>
                                    ))}
                        и подписать ее
                        </div>

                    </div>
                    <div className="request-verification__input">
                        {
                            !unep ?
                                <BasicInput
                                    eye={true}
                                    setCorrectPassword={setCorrectPassword}
                                    passedState={password}
                                    correctPassword={correctPassword}
                                    stateType={"password"}
                                    stateHandler={setPassword}
                                    type={"password"}
                                    label={"Для подписания введите пароль от вашего личного кабинета"} 
                                    placeholder={isTerminal && "Введите пароль"}
                                /> :


                                <ConfirmSignModal
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    setInput={setPassword}
                                    styleParent={"confirm"}
                                    input={password} />
                        }

                    </div>
                    <div className="request-verification__button">
                        <CustomButton
                            onValidation={onValidation}
                            regular={true}
                            title={"отмена"}
                            modal={setModalSentStatus}
                            route={unep ? "/main/hr-services" : route ? route : "/main/hr-services/work-time/requests"} />
                        <OkButton
                            onValidation={onValidation}
                            handler={buttonHandler}
                            title={"OK"} />
                    </div>
                </div>
        );
};

export default RequestVerification;
