export const setStateToResetPasswordAction = ()=>{
    return{
        type:"RESET_PASSWORD",
        payload:true,
    };
};
export const setStateToNullResetPasswordAction = ()=>{
    return{
        type:"RESET_TO_NULL_PASSWORD",
        payload:null,
    };
};
