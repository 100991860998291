import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

import React, { useState } from "react";

import "./PersonalDocuments.scss";

// TODO REFACTOR
const PersonalDocuments = () => {
    // const dispatch = useDispatch();
    //EmploymentСontractModal
    const [modalEmploymentContact, toggleModal] = useState(false);

    function employmentContactClickHandler(e) {
        toggleModal(!modalEmploymentContact);
    }

    // function closeHandlerEmploymentСontract(e){
    //     toggleModal(false);
    // }
    //instraction,Modal
    const [modalShownInstraction, toggleModalInstraction] = useState(false);

    function clickHandlerInstraction(e) {
        toggleModalInstraction(!modalShownInstraction);
    }

    // function closeHandlerInstraction(e){
    //     toggleModalInstraction(false);
    // }
    return (
        <>
            <Header />
            <div className="main__container--personal-data">
                <Breadcrumbs />
                <h1 className={"sub-header"}> Документы</h1>
                <div className="documents-rows__container">
                    <div
                        onClick={employmentContactClickHandler}
                        className="docs-item">
                        <div className="header-item">
              Трудовой договор
                        </div>
                        <div
                            style={{ color: "#97999B" }}
                            className="description-item">
              Скоро здесь появится информация
                        </div>
                    </div>
                    <div
                        onClick={clickHandlerInstraction}
                        className="docs-item">
                        <div className="header-item">
              Должностная инструкция
                        </div>
                        <div
                            style={{ color: "#97999B" }}
                            className="description-item">
              Скоро здесь появится информация
                        </div>
                    </div>
                </div>
                {/*<Modal*/}
                {/*    shown={modalEmploymentСontract}*/}
                {/*    close={closeHandlerEmploymentСontract}*/}
                {/*>*/}
                {/*    < EmploymentContractModal close = {closeHandlerEmploymentСontract} />*/}
                {/*</Modal>*/}
                {/*<Modal*/}
                {/*    shown={modalShownInstraction}*/}
                {/*    close={closeHandlerInstraction}*/}
                {/*>*/}
                {/*    <InstractionModal close = {closeHandlerInstraction}/>*/}
                {/*</Modal>*/}
            </div>
            <Footer />
        </>
    );
};

export default PersonalDocuments;
