import ICLabel from "./parts/IC.Label";
import ICDescription from "./parts/IC.Description";
import ICInput from "./parts/IC.Input";
import ICDropdown from "./parts/IC.Dropdown";
import ICError from "./parts/IC.Error";
import ICDatepickerSelect from "./parts/IC.DatepickerSelect";
import ICTextAreaVerticalGrow from "./parts/IC.TextAreaVerticalGrow";

import useComponentVisible from "../../../../hooks/useComponentVisible/useComponentVisible";

import { useInputCompositionContext } from "../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import React, { Children, cloneElement, useRef, useState } from "react";
import { usePopper } from "react-popper";

import "./IC.scss";
import moment from "moment";


const IC = (
    {
        children,
        customStyle,
        field,
        require,
    }
) => {
    const [state, setState] = useState("");

    const [, setStateModel, isError, setIsError] = useInputCompositionContext();

    // POPPER & MODAL PROPERTIES
    // START
    const portalRef = useRef(null);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const [ref, isComponentVisible, setIsComponentVisible, forceClose] = useComponentVisible(false, { portalRef });

    const { styles, attributes } = usePopper(referenceElement, popperElement,
        {
            modifiers: {
                name: "offset",
                options: {
                    offset: [0, 7],
                },
            },
            placement: "bottom",
        }
    );
    // END

    function changeHandler (event, item, date) {
        setIsError(prev => {
            const temp = prev;
            if (temp[field]) {
                delete temp[field];
            }
            return temp;
        });
        if (item) {
            setState(() => item.name);
            setStateModel((prev) => {
                return {
                    ...prev, [field]: item.id,
                };
            });
            forceClose();
        } else if (date) {
            setStateModel((prev) => {
                return {
                    ...prev, [field]: moment(date).format("YYYY-MM-DD"),
                };
            });
        } else {
            setStateModel((prev) => {
                return {
                    ...prev, [field]: event.target.value,
                };
            });
        }
    }


    return (
        <div
            onFocus={() => setIsComponentVisible(true)}
            ref={ref}
            className={`input-composition-default${customStyle ? "-" + customStyle : ""}`}>
            {
                children &&
                children.length &&
                children.filter(element => element.type.name === ICDropdown.name).length ?
                    Children && Children.map(children, (child) => {
                        return cloneElement(child, {
                            require,
                            isError,
                            state,
                            setState,
                            setStateModel,
                            changeHandler,
                            field,
                            dropdownProps: {
                                setIsComponentVisible,
                                isComponentVisible,
                                setReferenceElement,
                                setPopperElement,
                                styles,
                                attributes,
                                portalRef,
                                forceClose,
                            },
                        });
                    })
                    : Children && Children.map(children, (child) => {
                        return cloneElement(child, {
                            require,
                            isError,
                            field,
                            changeHandler,
                            dropdownProps: false,
                        });
                    })
            }
        </div>
    );
};

IC.Label = ICLabel;
IC.Description = ICDescription;
IC.Input = ICInput;
IC.Dropdown = ICDropdown;
IC.Error = ICError;
IC.DatepickerSelect = ICDatepickerSelect;
IC.TextAreaVerticalGrow = ICTextAreaVerticalGrow;
// TODO TOOLTIP WITH ADVICE
// TODO UNITPUTABLE INPUT
// TODO DATEPICKER INPUT (NOT SELECT)
// TODO PERIOD INPUT

// console.log("IC: ==========================>", IC);
export default IC;