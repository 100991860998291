import {$api} from "../../../../../../services/request.config";
import {getPendingRequestsInfoAction} from "../getPendingRequestsInfo.action";
import {clearPendingRequestInfoAction} from "../clearPendingRequestInfo.action";

export const getPendingRequestsInfoThunkAction = (year, direction) => async (dispatch) => {
    try {
        // /api/v1/manager/requests/leaves/approvals/pending/{year}
        const response = await $api.head(`v1/manager/requests/leaves/approvals/pending${year ? `/${year}` : ""}`);
        // const response = await $api.head(`v1/manager/requests/leaves/approvals/pending/2023`)
        if (response.status === 200) {
            dispatch(getPendingRequestsInfoAction({status: true, direction}));
        } else if (response.status === 204) {
            dispatch(getPendingRequestsInfoAction({status: false, direction}));
        }
    } catch (error) {
        dispatch(clearPendingRequestInfoAction());
        // console.log("ERROR WHILE GETTING BUBBLE INFO: ", error)
        throw new Error("ERROR WHILE GETTING BUBBLE INFO");
    }
};
