import {workTimeWorkersData} from "./workTimeWorkers.data";

import Header from "../../../layout/Header/Header";
import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import Footer from "../../../layout/Footer/Footer";

import React from "react";

function WorkTimeWorkersPage() {
    return (
        <>
            <Header/>
            <MultiRoute
                breadCrumbs={true}
                subHeader={"Рабочее время сотрудников"}
                styleParent={"work-time-workers"}
                data={workTimeWorkersData}
            />
            <Footer/>
        </>
    );
}

export default WorkTimeWorkersPage;
