import {
    validationControlCurrentRequest,
} from "../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";

class RequestControlServices {
    static saveOrUploadValidation(currentRequest, dispatch) {
        if (currentRequest.leaveReasonId !== null) {
            return true;
        } else {
            dispatch(validationControlCurrentRequest("leaveReasonId", false));
            return false;
        }
    }

    static sendToValidation(currentRequest, dispatch) {
        const { leaveTypeId } = currentRequest;
        let periodsCheck = true;
        let keysToExtraVacation = ["leaveReasonId"];

        if (currentRequest.leaveTypeId === 9) {
            if (currentRequest.leaveReasonId === 33) {
                keysToExtraVacation = [
                    "leaveReasonId",
                    ["bloodDonationData", "donationDate", "certificateNumber"],
                ];
            }
        }

        const keysToCheck = {
            1: ["leaveReasonId", "dateFrom", "dateTo"],
            2: ["leaveReasonId", "dateFrom", "dateTo", "employeeComment"],
            3: ["leaveReasonId", "leaveRequestDwhId"],
            // eslint-disable-next-line max-len
            5: [
                "leaveReasonId",
                "institutionName",
                "educationProgramId",
                "courseNumberId",
                "specialtyName",
                "certificateCallNumber",
                "certificateCallDate",
                "dateTo",
                "dateFrom",
            ],
            6: [
                "dateFrom",
                "dateTo",
                "disabilityFrom",
                "disabilityTo",
                "disabilityDocumentName",
            ],
            9: keysToExtraVacation,
            12: [
                "leaveReasonId",
                "dismissalDate",
                "dismissalObtainingDocumentId",
                "obtainingDocumentAddress",
                "dismissalMotives",
            ],
            13: ["leaveReasonId", "dateFrom", "dateTo", "leaveRequestDwhId"],
            14: ["dismissalId", "employeeComment"],
        };

        currentRequest.periods &&
        currentRequest.periods.forEach((period, index) => {
            if (!period.dateTo || !period.dateFrom) {
                dispatch(
                    validationControlCurrentRequest("validDate", false),
                );
                periodsCheck = false;
            }
        });

        return (
            this.keyChecker(
                keysToCheck[leaveTypeId],
                currentRequest,
                dispatch,
            ) && periodsCheck
        );
    }

    static keyChecker(arrayOfKeys, currentRequest, dispatch) {
        let result = 0;
        arrayOfKeys.forEach((e) => {
            if (
                !Array.isArray(e) &&
                (currentRequest[`${e}`] === null ||
                    currentRequest[`${e}`] === undefined)
            ) {
                result += 1;
                dispatch(validationControlCurrentRequest(e, false));
            } else if (Array.isArray(e)) {
                const key = [...e].shift();
                for (const bloodKey in currentRequest[key]) {
                    if (
                        currentRequest[key][bloodKey] === null ||
                        currentRequest[key][bloodKey] === undefined
                    ) {
                        result += 1;
                        dispatch(
                            validationControlCurrentRequest(
                                { object: key, innerKey: bloodKey },
                                false,
                                "object",
                            ),
                        );
                    }
                }
            }
        });
        return !result;
    }
}

export default RequestControlServices;
