import { getUserHrDocsThunkAction } from "./getUserHrDocs.thunk.action";

import { $api } from "../../../../services/request.config";
// import { wrongAuthAunteficationAction } from "../../../AuthNumber/aunteficationAction";
// import { getUserHrDocsAction } from "../getUserHrDocs.action";

export const unepSmsConfirmationThunkAction =
    ({ smsCode, taskId, setDescription, setLoader }) =>
        async (dispatch) => {
            try {
                setLoader(true);
                const response = await $api.post(
                    `/v2/directum/document/signing/sign/task/${taskId}/confirm/sms`,
                    { smsCode },
                );
                if (response) {
                    setDescription(" Подписание успешно завершено");
                    setLoader(false);
                    // dispatch(getUserHrDocsAction(null));
                    dispatch(getUserHrDocsThunkAction());
                }
            } catch (error) {
                setLoader(false);
                setDescription("Не удалось подписать документ");
            }
        };
