import uploadImage from "../../../assets/icons/PlusCircle.svg";

import RequestControlServices from "../../../frontend.services/RequestServices/RequestControlServices";
import {
    saveDraftCurrentRequestThunk,
} from "../../../redux/actions/Requests/currentRequestControls/thunk/saveDraft.currentRequest.thunk";
import {uploadFileThunkAction} from "../../../redux/actions/Requests/FileUpload/thunk/uploadFile.thunk.action";
import SubTypeRequestAttachments from "../SubTypeRequestAttachments/SubTypeRequestAttachments";

import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";

import "./SingleUploader.scss";


function SingleUploader(props) {

    const dispatch = useDispatch();
    const filePicker = useRef();

    const currentRequest = useSelector(state => state.requests.currentRequest);
    const requestAttachments = useSelector(state => state.requests.requestAttachments);

    const [isUploading, setIsUploading] = useState(false);

    function handlePick(e) {
        // TODO condition isn't consistent
        // TODO NEED CONDITION TO CHECK IS REASON PICKED
        // if (requestState.saveOrUploadValidation(setRequestState, requestState)) {
        if (RequestControlServices.saveOrUploadValidation(currentRequest, dispatch)) {
            if (!currentRequest.uid) dispatch(saveDraftCurrentRequestThunk(currentRequest));
            filePicker.current.click();
        }
    }

    function uploadHandler(e) {
        const formData = new FormData();
        formData.append("files", filePicker.current.files[0]);
        formData.append("SubDocumentTypeName", props.subType );
        // name of that append file should be "files" otherwise backend can't recognize
        dispatch(uploadFileThunkAction({uid: currentRequest.uid, file: formData, setIsUploading}));
    }

    useEffect(() => {

    }, [currentRequest, requestAttachments.length]);



    return (
        <div>
            <div className="single-uploader">
                <div className="single-uploader__upload-field">
                    <div>
                        <div className={"single-uploader__upload-field__text"} >
                            {props.label}
                        </div>
                        <div className={"single-uploader__upload-field__input"}>
                            <div className={"single-uploader__upload-field__input__line"} >
                                {currentRequest  && <SubTypeRequestAttachments subTypeNumber={props.subTypeNumber}/>}
                            </div>
                            {
                                isUploading
                                    ? <ClipLoader size={32} color="#6DCDF6"/>
                                    : <img
                                        className="single-uploader__img"
                                        onClick={handlePick}
                                        src={uploadImage}
                                        alt="upload"/>
                            }
                            <input
                                onChange={uploadHandler}
                                ref={filePicker}
                                className="single-uploader__input"
                                type="file"
                                accept=".png,.jpg,.jpeg,.pdf,.docx"
                            />
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleUploader;
