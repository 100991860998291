import DatePopupSelector from "../../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import SingleUploaderNewVersion from "../../../../../../../common/SingleUploaderNewVersion/SingleUploaderNewVersion";
import CommentInput from "../../../../../../../common/Inputs/CommentInput/CommentInput";
import DropdownMenuTest from "../../../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";
import DropdownMenu from "../../../../../../../common/Menu/DropdownMenu/DropdownMenu";

import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    calendarDatePickCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";

import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";
import {
    addParentAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/controls/addParent.action";

import {
    getParentFullNameThunkAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/thunk/getParentFullName.thunk.action";

import {
    addParentTabNumberAction,
} from "../../../../../../../../redux/actions/Requests/ParentalLeave/controls/addParentTabNumber.action";

import RequestInputInArray from "../../../../../../../common/Inputs/RequestInputInArray/RequestInputInArray";

import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const GeneralInformation = ({ parentStyle, currentRequest, visible }) => {

    const dispatch = useDispatch();
    const claimantStatuse = useSelector((state) => state.requests.claimantStatuses);
    const parentsAlrosaStatusId = useSelector((state) => state.requests.parentsAlrosaStatusId);
    const addresses = useSelector((state) => state.personalData.addresses);
    const passport = useSelector(state => state.personalData.passport);

    function selectDropdown(event, element, stateProperty) {
        if (stateProperty === "parentsAlrosaStatusId") {
            switch (element.id) {
            case 1:
                dispatch(addParentAction([{ fullName: null, tabNumber: null }]));
                break;

            case 3:
                dispatch(addParentAction([{ fullName: null, tabNumber: null }, {
                    fullName: null,
                    tabNumber: null,
                }]));
                break;
            default:
                break;
            }
        }
        dispatch(dropdownSelectionCurrentRequest({ event, element, stateProperty }));
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }


    function inputHandler(e, field) {
        let value = e.target.value;
        if (field === "cardNumber" || field === "accountNumber" || field === "bik") {
            value = value.match(/^\d+$/gm, "");
            value = value.join("");
        }
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field,
                value: value,
            }),
        );
        dispatch(validationControlCurrentRequest(field, true));
    }

    function searchParent(input, index, field) {
        if (input.length === 8) {
            dispatch(getParentFullNameThunkAction({
                index,
                tabNumber: input,
                field: "tabNumber",

            }));
            dispatch(addParentTabNumberAction({
                parentInput: input,
                indexOfParent: index,
                inputfield: field,
                isParent: true,
            }));
        }
    }

    function changeDateHandler(field, date) {
        const value = moment(date).format("YYYY-MM-DD");

        if (field === "dateFrom") {
            dispatch(validationControlCurrentRequest(field, true));
            dispatch(calendarDatePickCurrentRequest({ field: field, date: value }));
            dispatch(validationControlCurrentRequest("dateTo", true));
            dispatch(calendarDatePickCurrentRequest({ field: "dateTo", date: moment(value).add(36, "months") }));
        } else {
            dispatch(validationControlCurrentRequest("dateTo", true));
            dispatch(calendarDatePickCurrentRequest({ field: "dateTo", date: value }));
        }
    }


    return (
        visible ?
            <div className={`${parentStyle}-general-information`}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "1160px",
                        margin: "auto",
                        gap: "5px",
                    }}
                >
                    <DatePopupSelector
                        field={"dateFrom"}
                        label={"Дата начала отпуска *"}
                        errorMessage={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.dateFrom
                                ? ""
                                : "Обязательное поле"
                        }
                        changeHandler={changeDateHandler}
                        propsDate={
                            currentRequest && currentRequest.dateFrom
                                ? new Date(currentRequest.dateFrom)
                                : null
                        }
                        minDate={moment().subtract(36, "months")}
                    />
                    <span className={"extra-vacation-days__notification"}>
                    Оформить получение единовременного федерального пособия возможно, подав заявку через МФЦ или Госуслуги. <br />
                        {/* eslint-disable-next-line max-len */}
                        Для работников АК «АЛРОСА» ПАО в рамках коллективного договора возможно оформить заявку на предоставление материальной помощи в связи с рождением ребенка.<br />
                    Для работников ДЗО – если эта выплата предусмотрена коллективным договором
                    </span>
                </div>
                {
                    currentRequest &&
                    currentRequest.children &&
                    currentRequest.children[0] &&
                    currentRequest.children[0].birthDate
                        ? //birthDate
                        <div style={{ marginTop: "40px" }}>
                            <CommentInput
                                value={currentRequest && currentRequest.dateTo && moment(currentRequest.dateTo).format("DD.MM.YYYY")}
                                basicInput={true}
                                maxLength={255}
                                stateProperty={"dateTo"}
                                label={"Дата окончания отпуска *"}
                                changeHandler={() => console.log("handler")}
                                errorMessage={
                                    currentRequest &&
                                    currentRequest.isValid &&
                                    currentRequest.isValid.dateTo
                                        ? ""
                                        : "Обязательное поле"
                                }
                                parentStyle={"extra-vacation-days"}
                            />
                        </div>
                        :
                        <div style={{ height: "41px" }}></div>
                }
                <DropdownMenuTest
                    currentRequest={currentRequest}
                    styleName={"reason"}
                    label={"Ваш статус *"}
                    listOfOptions={claimantStatuse}
                    stateProperty={"relativeId"}
                    isValidKey={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.relativeId
                            ? currentRequest.isValid.relativeId
                            : false
                    }
                    selectOptionClick={selectDropdown}
                />
                <DropdownMenu
                    helper={"Для подтверждения статуса требуется предоставление оригиналов документов в ОЦО"}
                    currentRequest={currentRequest}
                    styleName={"reason"}
                    label={"Может быть подтвержден статус «мать-одиночка»/ «отец-одиночка»  *"}
                    listOfOptions={[
                        {
                            name: "Нет",
                            id: false,
                        },
                        {
                            name: "Да",
                            id: true,
                        },
                    ]}
                    stateProperty={"isSingleParent"}
                    isValidKey={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.isSingleParent
                            ? currentRequest.isValid.isSingleParent
                            : false
                    }
                    selectOptionClick={selectDropdown}
                />

                <DropdownMenu
                    currentRequest={currentRequest}
                    styleName={"reason"}
                    label={"Второй родитель является работником АК \"АЛРОСА\" ПАО *"}
                    listOfOptions={currentRequest &&
                    currentRequest.relativeId &&
                    currentRequest.relativeId !== 3 ?
                        parentsAlrosaStatusId && parentsAlrosaStatusId.filter(el => el.id !== 3) : currentRequest &&
                        currentRequest.isSingleParent &&
                        currentRequest.isSingleParent === true ? parentsAlrosaStatusId.filter(el => el.id !== 3) :
                            parentsAlrosaStatusId}
                    stateProperty={"parentsAlrosaStatusId"}
                    isValidKey={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.parentsAlrosaStatusId
                            ? currentRequest.isValid.parentsAlrosaStatusId
                            : false
                    }
                    selectOptionClick={selectDropdown}
                />
                {
                    currentRequest &&
                    currentRequest.parentsAlrosaStatusId &&
                    currentRequest.parentsAlrosaStatusId !== 2 &&
                    currentRequest.parents && currentRequest.parents.map((element, index) => {
                        // const inputRef =
                        return (
                            <div key={uuidv4()}>
                                <RequestInputInArray
                                    uploader={true}
                                    value={
                                        element && element.tabNumber && element.tabNumber
                                    }
                                    // ref={inputRef}
                                    basicInput={true}
                                    handler={searchParent}
                                    maxLength={8}
                                    stateProperty={"tabNumber"}
                                    field={"tabNumber"}
                                    index={index}
                                    // changeHandler={searchParent}
                                    label={index === 0 ? "Табельный номер  родителя *" : "Табельный номер второго родителя *"}
                                    errorMessage={
                                        element && !element.tabNumberValid
                                            ? ""
                                            : "Обязательное поле"
                                    }
                                    parentStyle={"unpaid-practice"}
                                    letter={300}
                                />
                                <CommentInput
                                    changeHandler={() => console.log("disable input")}
                                    value={element.fullName}
                                    basicInput={true}
                                    maxLength={255}
                                    stateProperty={"ФИО второго родителя"}
                                    label={index === 0 ? "ФИО  родителя *" : "ФИО второго родителя *"}
                                    errorMessage={
                                        element &&
                                        !element.fullNameValid
                                            ? ""
                                            : "Обязательное поле"
                                    }
                                    parentStyle={"extra-vacation-days"}
                                />
                            </div>
                        );
                    })
                }

                <CommentInput
                    value={currentRequest && currentRequest.cardNumber}
                    basicInput={true}
                    maxLength={16}
                    stateProperty={"cardNumber"}
                    label={"Номер карты платежной системы МИР для перечисления пособия *"}
                    changeHandler={(e) => inputHandler(e, "cardNumber")}
                    errorMessage={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.cardNumber
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"extra-vacation-days"}
                />
                <CommentInput
                    value={currentRequest && currentRequest.accountNumber}
                    basicInput={true}
                    maxLength={255}
                    stateProperty={"accountNumber"}
                    label={"Номер счета для перечисления пособия *"}
                    changeHandler={(e) => inputHandler(e, "accountNumber")}
                    errorMessage={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.accountNumber
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"extra-vacation-days"}
                />
                <CommentInput
                    value={currentRequest && currentRequest.bik}
                    basicInput={true}
                    maxLength={255}
                    stateProperty={"bik"}
                    changeHandler={(e) => inputHandler(e, "bik")}
                    label={"БИК Банка *"}
                    errorMessage={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.bik
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"extra-vacation-days"}
                />
                <CommentInput
                    value={passport ? passport : ""}
                    basicInput={true}
                    maxLength={255}
                    stateProperty={"passportSeries"}
                    label={"Паспортные данные (серия и номер)  *"}
                    errorMessage={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.passportSeries
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"extra-vacation-days"}
                    helper={
                        "Проверьте корректность данных. В случае, если данные не корректны, необходимо обратиться в ОЦО"
                    }
                />
                <CommentInput
                    value={addresses && addresses.registrationAddress ? addresses.registrationAddress : ""}
                    basicInput={true}
                    maxLength={255}
                    label={"Адрес регистрации *"}
                    errorMessage={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.registrationAddress
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"extra-vacation-days"}
                    helper={
                        "Проверьте корректность данных. В случае, если данные не корректны, необходимо обратиться в ОЦО"
                    }
                />
                <CommentInput
                    value={
                        addresses &&
                        addresses.same
                            ? addresses.same
                            : addresses &&
                            addresses.actualAddress
                                ? addresses.actualAddress
                                : ""}
                    basicInput={true}
                    maxLength={255}
                    stateProperty={"actualAddress"}
                    label={"Место фактического проживания (в случае отличия от адреса регистрации) "}
                    errorMessage={
                        currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.actualAddress
                            ? ""
                            : "Обязательное поле"
                    }
                    parentStyle={"extra-vacation-days"}
                    helper={
                        "Проверьте корректность данных. В случае, если данные не корректны, необходимо обратиться в ОЦО"
                    }
                />

                <SingleUploaderNewVersion
                    label={"Копии свидетельств о рождении ранее рожденных детей матерью данного ребенка "}
                    i={0}
                    fileName={"Отпуск_по_уходу_за_ребенком.pdf"}
                    subTypeNumber={7}
                />
                {
                    currentRequest &&
                    currentRequest.parentsAlrosaStatusId &&
                    currentRequest.parentsAlrosaStatusId === 2 &&
                    <SingleUploaderNewVersion
                        label={
                            "Справка с места работы второго родителя ребенка о том," +
                            " что он не использует отпуск по уходу за ребенком и не получает" +
                            " ежемесячное пособие по уходу за ребенком до 1,5 лет. *"
                        }
                        i={1}
                        subTypeNumber={8}
                        errorMessage={
                            currentRequest &&
                            currentRequest.attachments &&
                            currentRequest.attachments[0] &&
                            currentRequest.attachments[0].attachNotValid
                                ? "Пожалуйста, прикрепите документ"
                                : ""
                        }
                    />
                }


                <CommentInput
                    value={currentRequest &&
                        currentRequest.employeeComment}
                    basicInput={false}
                    maxLength={255}
                    stateProperty={"employeeComment"}
                    label={"Комментарий "}
                    changeHandler={commentHandler}
                    parentStyle={"parental-leave"}
                    helper={
                        "Обращаем внимание: заявка не будет исполнена без предоставления в ОЦО оригинала(-ов) подтверждающих документов"
                    }
                />
            </div>
            : null
    );
};

export default GeneralInformation;
