import BasicApplication from "./Basic.application";

class StudyLeaveApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.leaveTypeId = options.leaveTypeId;
        this.institutionName = options.institutionName;
        this.educationProgramId = options.educationProgramId;
        this.courseNumberId = options.courseNumberId;
        this.specialtyName = options.specialtyName;
        this.certificateCallNumber = options.certificateCallNumber;
        this.certificateCallDate = options.certificateCallDate;
        this.dateTo = options.dateTo;
        this.dateFrom = options.dateFrom;
        this.isValid = {
            leaveReasonId: true,
            institutionName: true,
            educationProgramId: true,
            courseNumberId: true,
            specialtyName: true,
            certificateCallNumber: true,
            certificateCallDate: true,
            dateTo: true,
            dateFrom: true,
        };
    }
}

export default StudyLeaveApplication;
