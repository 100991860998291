import BasicApplication from "./Basic.application";

class DismissalRevocationApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.dismissalId = options.dismissalId;
        this.leaveReasonId = 0;
        this.isValid = {
            dismissalId: true,
            employeeComment: true,
        };
    }
}

export default DismissalRevocationApplication;
