import { $api } from "../../../../../services/request.config";
import { getAvailableEmployeesDelegationAction } from "../getAvailableEmployeesDelegation.action";
import { clearAvailableEmployeesDelegationAction } from "../clearAvailableEmployeesDelegation.action";
export const getAvailableEmployeesDelegationThunkAction = (substitutionType) => async (dispatch) => {
    try {
        dispatch(clearAvailableEmployeesDelegationAction());
        const response = await $api(`v2/manager/substitution-types/${substitutionType}/delegate-employees`);

        dispatch(getAvailableEmployeesDelegationAction(response.data));
    } catch (error) {
        // throw new Error("")
        // console.log("ERROR WHILE GETTING AVAILABLE EMPLOYEES: ", error);
    }
};