import React from "react";

const ICError = (
    {
        customStyle,
        message,
        isError,
        field,
    }
) => {

    return  isError &&
            isError[field]
        ? (
            <div className={`input-composition-default__error-message${
                customStyle 
                    ? " " + customStyle 
                    : ""}`}>
                {/*{message ?*/}
                {isError[field]}
                {/*: "Проверьте правильность ввода"}*/}
            </div>
        )
        : <div className={`input-composition-default__error-message-buffer${
            customStyle 
                ? " " + customStyle 
                : ""}`}>
        </div>;
};

export default ICError;