import logos from "../../../../assets/icons/authIcons/alrosaMainLogo/LogoCopy.svg";

import "./InputSearch.css";
import { getLeaderRequestsAction } from "../../../../redux/actions/Requests/LeaderRequests/getLeaderRequests.action";
import { getUserHrDocsAction } from "../../../../redux/actions/Requests/Hr-documents/getUserHrDocs.action";
import { getAllUserRequestsAction } from "../../../../redux/actions/Requests/GetUserRequests/getAllUserRequests.action";
import { getAllUserReferencesAction } from "../../../../redux/actions/Requests/References/getAllUserReferences.action";
import { getAllDocumentsAction } from "../../../../redux/actions/Documents/AllDocuments/getAllDocuments.action";

import { useLogout } from "../../../../hooks/useLogout/useLogaut";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const InputSearch = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = useLogout(navigate);
    const click = (e) => {
        e.preventDefault();
        logout();
    };

    useEffect(() => {
        return () => {
            dispatch(getLeaderRequestsAction(null));
            dispatch(getUserHrDocsAction(null));
            dispatch(getAllUserRequestsAction(null));
            dispatch(getAllUserReferencesAction(null));
            dispatch(getAllDocumentsAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="search-container">
            <img className="colored-logo" src={logos} alt="" />
            <div className="input-container">
                <label htmlFor="search">
                    {/*<input placeholder={'Поиск'} name='search' className='input-header' type="text"/>*/}
                </label>
            </div>
            <div className="logout">
                <span onClick={click}>Выход</span>
            </div>
        </div>
    );
};

export default InputSearch;
