import { urlOnUse } from "../../app/mode";

import axios from "axios";

const token = localStorage.getItem("token");
token && (axios.defaults.headers.common["Authorization"] = "Bearer " + token);

const $api = axios.create({
    baseURL: urlOnUse(),
    headers: {
        "Consumer": 1,
        "Content-Type": "Application/json",
        "Accept": "Application/json",
        "Access-Control-Allow-Origin": urlOnUse(),
    },
});

const $apiMultipart = axios.create({
    baseURL: urlOnUse(),
    headers: {
        "Consumer": 1,
        "Content-Type": "multipart/form-data",
        "Accept": "multipart/form-data",
        "Access-Control-Allow-Origin": `${urlOnUse()}/*`,
    },
});


export { $api , $apiMultipart };
