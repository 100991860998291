import {
    inputTextAreaContentCurrentRequestAction,
} from "../../../redux/actions/Requests/currentRequestControls/inputTextAreaContent.currentRequest.action";

import React, { useEffect, useRef, useState } from "react";
import "./ImportantTasksDescription.scss";
import { useDispatch, useSelector } from "react-redux";

const ImportantTasksDescription = (
    {
        field,
        textAreaLabel,
        message,
        value,
    },
) => {

    const dispatch = useDispatch();

    const ref = useRef(null);
    const [charCounter, setCharCounter] = useState(ref && ref.current ? ref.current.innerText.length : 0);

    const currentRequest = useSelector(state => state.requests.currentRequest);

    function onInputHandler(event) {
    // if (event.target.innerText.length === 0 || event.target.innerText === "\n") {
    //     event.target.innerText = "";
    //     setCharCounter(0);
    // } else {
    //     setCharCounter(ref.current.innerText.length);
    // }
        dispatch(inputTextAreaContentCurrentRequestAction(field, event.target.value));
    }

    const resizeTextArea = () => {
        ref.current.style.height = "auto";
        ref.current.style.height = ref.current.scrollHeight + "px";
    };
    useEffect(resizeTextArea, [ref]);
    return (
        <div className={"important-tasks-description"}>
            <div className={"important-tasks-description__label"}>
                {textAreaLabel && textAreaLabel}
            </div>
            <textarea
                autoResize={true}
                value={value ? value : ""}
                spellCheck={false}
                className={`important-tasks-description__textarea${
                    currentRequest && currentRequest.isValid &&
          currentRequest.isValid[field]
                        ? ""
                        : "-error"
                }`}
                onChange={onInputHandler}
                ref={ref}
                // contentEditable={charCounter >= 1000 ? false : true}
                // onLoad={(event) => {
                //     if (currentRequest && currentRequest[field]) {
                //         event.target.innerText = "";
                //     }
                // }}
            >

            </textarea>
            <div className={"important-tasks-description__char-counter"}>
                {1000 - charCounter}/1000
            </div>
            {
                currentRequest && currentRequest.isValid &&
        currentRequest.isValid[field]
                    ? <div className={"important-tasks-description__error-buffer"}></div>
                    : <div className={"important-tasks-description__error"}
                    >
            Проверьте правильность заполнения
                    </div>
            }
            {
                message ?
                    <div className={"important-tasks-description__message"}>
                        {message}
                    </div> :
                    null
            }
        </div>
    );
};

export default ImportantTasksDescription;
