import TableYearHelper from "../../../frontend.services/TableServices/TableYear.helper";

import {v4 as uuidv4} from "uuid";

const TableRowYear = (
    {
        data,
        parentStyle,
        setTooltip,
    }
) => {
    let output = [];

    if (data) {
        if (data.find(e => e.name)) {
            output = TableYearHelper
                .getCredentials(data, parentStyle);
        } else {
            output = TableYearHelper
                .getMonthsGantRow(data, parentStyle, setTooltip);
        }
    }

    return (
        <>
            {output.map(e => (<tr key={`tableRow${uuidv4()}`}>{e}</tr>))}
        </>
    );
};

export default TableRowYear;