import { $api } from "../../../../services/request.config";
import { isWorkerSubstituteAction } from "../isWorkerSubstitute.action";

export const isWorkerSubstituteThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v2/manager/is-substitute");
        dispatch(isWorkerSubstituteAction(response.data.isSubstitute));
    } catch (e) {
    // console.log('ERROR_WHILE_GETTING_SUSSTITUTE: ', e?.response)
    }
};
