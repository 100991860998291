import blankList from "../../../assets/icons/NavBlockIcons/blank-list.svg";
import calendar from "../../../assets/icons/NavBlockIcons/calendar.svg";

export const dataTerminal = [
    {
        title: "Кадровый сервис",
        description: `Рабочее время, 
        командировки, 
        отпуска, 
        больничные, 
        вознаграждения, 
        льготы, 
        обучение, 
        расчетные листки и другое`,
        icon: blankList,
        path: "/main/hr-services",
        secured: true,
    },
    {
        title: "Запрос справок и информации",
        description: `Задать вопрос, 
        получить: копии кадровых документов, 
        справку 2-НДФЛ, 
        справку с места работы и другие справки`,
        icon: calendar,
        path: "/main/references",
    },
];
