import $api from "../../../../services/axiosService";
import {getAttachmentsAction} from "../getAttachments.action";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";

export const getRequestAttachmentsURLThunkAction = (uid) => async (dispatch) => {
    try {
        if (uid) {
            const request = await $api(`v1/leaverequest/${uid}`);
            // console.log("REQUEST ATTACMENTS: ", request)
            if (request.data.isSuccess) {
                const attachments = await RequestServices.getAttachmentsWithURL(request);
                dispatch(getAttachmentsAction(attachments));
            }
        }
    } catch (error) {
        // console.log("ERROR WHILE GETTING URL ATTACHMENTS: ", error.response)
    }
};
