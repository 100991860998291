import {initialState} from "../../init/initialState";

export const startReducer= (state = initialState,action)=>{
    switch (action.type){
    case "isAuth":
        return true;
    case "noAuth":
        return false;
    default :
        return state;
    }
};
