import $api from "../../../../services/axiosService";
import { getApplicationFileAction } from "../../Application/getApplicationFile.action";

import {
    IMPORTANT_TASK_VALIDATE_URL,
    UNPAID_PRACTICE_VALIDATE_URL,
} from "../../../../enums/Request/applicationUrls";
import { setValidatedApplicationAction } from "../../CurrentRequest/setValidatedApplication.action";

// import moment from "moment";

function applicationUrl(option) {
    switch (option) {
    case 15:
        return UNPAID_PRACTICE_VALIDATE_URL;
    case 16:
        return IMPORTANT_TASK_VALIDATE_URL;
    default:
        return "v1/leaverequest/validate";
    }
}

export const requestValidationForLeaderRequestsThunkAction = (
    {
        setRequestStatus,
        stateLoader,
        closeModal,
        currentRequest,
        setModalError,
        option,
    },
) =>
    async (dispatch) => {
        try {
            stateLoader(true);
            const temp = applicationUrl(option);
            if (option === 15 && currentRequest.contactPhone.length !== 11) {
                setRequestStatus({
                    details: [
                        {
                            message: "Номер телефона должен начинаться с 7 *** *** **** и содержать 11 цифр",
                            block: 1,
                        }],
                });
                closeModal(false);
            } else {

                const response = await $api.post(`${temp}`, currentRequest);
                if (response.data.isSuccess) {
                    const currentRequest = await $api(`v1/leaverequest/${response.data.uid}`);
                    if (currentRequest.data.item.attachments.length) {
                        for (let i = 0; i < currentRequest.data.item.attachments.length; i += 1) {
                            if (currentRequest.data.item.attachments[i].isApplicationDocument) {
                                const file = await $api(
                                    `v1.1/leaverequest/file/
                                    ${response.data.uid}/
                                    ${currentRequest.data.item.attachments[i].id}`,
                                    { responseType: "blob" });
                                const blobLink = URL.createObjectURL(
                                    new Blob([file.data], { type: `${file.headers["content-type"]}` }),
                                );
                                dispatch(getApplicationFileAction(blobLink));
                            }
                        }
                        // dispatch(setCurrentRequestAction(response.data))
                        dispatch(setValidatedApplicationAction(response.data));
                        setRequestStatus(response.data);
                        if (!(response.data.isSuccess)) {
                            closeModal(false);
                        }
                        stateLoader(false);
                    }
                } else {
                    if (!(response.data.isSuccess)) {
                        setRequestStatus(response.data);
                        closeModal(false);
                    }
                }

            }

        } catch (error) {
            if (error.response.status === 500) {
                setModalError(prev => {
                    return {
                        ...prev, status: true, validation: true,
                    };
                });
                closeModal(false);
                stateLoader(false);
            }
            closeModal(false);
            stateLoader(false);
        }
    };
