import { unsetUserRequestAction } from "../../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";
import Header from "../../../layout/Header/Header";
import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import Footer from "../../../layout/Footer/Footer";

import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";

import {
    getAvailableTypesForLeaderThunkAction,
} from "../../../../redux/actions/Requests/RequestsFromLeader/thunk/getAvailableTypesForLeader.thunk.action";

import NavMenuDiv from "../../../common/NavMenuDiv/NavMenuDiv";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const LeaderRequestsPage = () => {
    const dispatch = useDispatch();
    const requestTypes = useSelector(state => state.requests.types);


    useEffect(() => {
        dispatch(getAvailableTypesForLeaderThunkAction(3));

        // dispatch(unsetUserRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <div style={{ minHeight: "35vh" }}>
                {
                    requestTypes &&
                    requestTypes.length !== 0
                        ?
                        <>
                            <MultiRoute
                                breadCrumbs={true}
                                subHeader={"Заявки руководителя"}
                                styleParent={"leader-requests"}
                                data={requestTypes}
                            />
                            {/*path: `/main/hr-services/leader-requests/${el.id}`,*/}
                            {/*title: el.name,*/}
                       

                        </>


                        : <div style={{ margin: "60px auto", width: "1160px" }}>
                            <Breadcrumbs />
                            <div className={"sub-header"}>Заявки руководителя</div>
                            <div style={{ margin: "60px auto", fontSize: "24px", lineHeight: "32px", color: "gray" }}>
                                На данный момент нет доступных заявок руководителя
                            </div>
                        </div>
                }
                {/*    <div style={{ width: "1160px", margin: "0 auto" }}>*/}
                {/*        <NavLink to={"/main/hr-services/leader-requests/important"}>*/}
                {/*Премирование за выполнение ОВЗ (фонд руководителя актива)*/}
                {/*        </NavLink>*/}
                {/*    </div>*/}

            </div>

            <Footer />
        </>
    );
};

export default LeaderRequestsPage;
