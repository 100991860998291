import { rewardRoutes } from "./rewardRoutes.data";

import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

export const RewardPage = () => {
    return (
        <>
            <Header />
            <MultiRoute
                data={rewardRoutes}
                styleParent={"hr-services"}
                breadCrumbs={true}
                subHeader={"Вознаграждение"}
            />
            <Footer />
        </>
    );
};
