import "./ModalBanOverlay.scss";
import { useEffect } from "react";

const ModalBanOverlay = (
    {
        children,
        shown,
        close,
    }
) => {

    useEffect(() => {
        const classList = document.body.classList;
        const showModalClass = "show_modal_form";
        const showModal =  classList.contains(showModalClass);

        shown && !showModal && classList.add(showModalClass);
        !shown && showModal && classList.remove(showModalClass);
    }, [shown]);

    return shown ? (
        <div className="no-overlay__modal-backdrop">
            <div className="no-overlay__modal-content"
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {children}

            </div>
        </div>
    ) : null;
};

export default ModalBanOverlay;
