import CancelButton from "../CancelButton/CancelButton";
import OkButton from "../OKButton/OKButton";

import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

const HolidayButtonsController = (
    {
        declineHandler,
        acceptHandler,
    }
) => {

    // const dispatch = useDispatch();

    const requestsData = useSelector(state => state.employees);
    // const requestsRedux = useSelector(state => state.employees.requests.items);

    const [visibility, setVisibily] = useState(
        requestsData.requests.items && requestsData.requests.items.find(e => e.checked === true)
    );


    useEffect(() => {
        setVisibily(requestsData
            && requestsData.pendingApplications
            && requestsData.pendingApplications.applications
            && requestsData.pendingApplications.applications.find(e => e.checked === true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestsData.pendingApplications]);

    return visibility
        ? (
            <>
                <CancelButton
                    handler={declineHandler}
                    title={"отклонить"}/>
                <OkButton
                    handler={acceptHandler}
                    title={"согласовать"}/>
            </>
        )
        : null;
};

export default HolidayButtonsController;