import threeDots from "./threedots.svg";

import TwoMonthsCalendar from "../TwoMonthsCalendar/TwoMonthsCalendar";
import {
    getEmployeeDetailInformationThunkAction,
} from "../../../redux/actions/MyDivision/EmployeeInformation/thunk/getEmployeeDetailInformation.thunk.action";

import {useDispatch} from "react-redux";
import {v4 as uuidv4} from  "uuid";
import {createPortal} from "react-dom";
import {usePopper} from "react-popper";
import {useState} from "react";

const KebabMenu = (
    {
        data,
        listOfOptions,
        kebabStyle,
        dropdownStyle,
        pid,
        modal,
        setModal,
        getDetailInformation,
    }) => {


    // main kebab popup menu states
    const [visible, setVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    // const [, setEmployeeModal] = useState(false);

    const dispatch = useDispatch();

    const {
        styles,
        attributes,
        // update
    } = usePopper(referenceElement, popperElement, {
        // placement: placement,
        placement: "left",
        modifiers: [
            // { name: 'arrow', options: { element: arrowElement } },
            { name: "offset", options: { offset: [ 0, 3 ] } },
        ],
    });
    const toggleDropdown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setVisible(!visible);
    };

    function addReplacement () {
        return true;
    }

    function kebabMenuClick (option) {
        switch (option) {
        case "add":
            return addReplacement();
        case "details":
            dispatch(getEmployeeDetailInformationThunkAction(pid));
            return getDetailInformation();
        default:
            return null;
        }
    }


    return (
        <>
            <img
                className={kebabStyle}
                src={threeDots}
                onClick={toggleDropdown}
                ref={setReferenceElement}
                alt="kebab"/>

            {
                visible
                    ? createPortal(
                        <div
                            className={dropdownStyle}
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                        >
                            {
                                listOfOptions && listOfOptions.map(option => {
                                    if (option.call === "add" && data.substitutions.length) return null;
                                    return (
                                        <div key={uuidv4()}>
                                            {
                                                option.call === "add"
                                                    ? <TwoMonthsCalendar
                                                        data={data}
                                                        modal={modal}
                                                        setModal={setModal}
                                                        pid={pid}
                                                        setVisible={setVisible}
                                                        inputStyle={`${dropdownStyle}__item`}
                                                    />
                                                    : (
                                                        <div
                                                            onClick={() => kebabMenuClick(option.call) }
                                                            className={`${dropdownStyle}__item`}
                                                        >
                                                            {option.title}
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    );
                                })}
                        </div>,
                        document.querySelector("#popup")
                    )
                    : null
            }
        </>
    );
};

export default KebabMenu;
