export const GET_EMPLOYEES_PLANNED_VACATIONS = "GET_EMPLOYEES_PLANNED_VACATIONS";
export const GET_EMPLOYEES_VACATION_REQUESTS = "GET_EMPLOYEES_VACATION_REQUESTS";
export const GET_EMPLOYEES_PENDING_APPLICATIONS = "GET_EMPLOYEES_PENDING_APPLICATIONS";
export const GET_SEARCHABLE_EMPLOYEES = "GET_SEARCHABLE_EMPLOYEES";
export const CLEAR_SEARCHABLE_EMPLOYEES = "CLEAR_SEARCHABLE_EMPLOYEES";
export const GET_FILTER_SELECTED_PIDS = "GET_FILTER_SELECTED_PIDS";
export const CLEAR_FILTER_SELECTED_PIDS = "CLEAR_FILTER_SELECTED_PIDS";
export const FILTER_SELECT_EMPLOYEE = "FILTER_SELECT_EMPLOYEE";
export const FILTER_RESET_SELECTION_EMPLOYEE = "FILTER_RESET_SELECTION_EMPLOYEE";
export const GET_EMPLOYEES_LEAVES = "GET_EMPLOYEES_LEAVES";
export const CLEAR_EMPLOYEES_LEAVES = "CLEAR_EMPLOYEES_LEAVES";
export const GET_EMPLOYEE_APPLICATION_DETAILS = "GET_EMPLOYEE_APPLICATION_DETAILS";
export const CLEAR_EMPLOYEE_APPLICATION_DETAILS = "CLEAR_EMPLOYEE_APPLICATION_DETAILS";

export const SET_REQUEST_CHECKBOX_VALUE = "SET_REQUEST_CHECKBOX_VALUE";
export const SET_ALL_REQUEST_CHECKBOX_VALUE= "SET_ALL_REQUEST_CHECKBOX_VALUE";

export const SET_DAILY_PERCENT = "SET_DAILY_PERCENT";

export const APPROVE_REQUEST_SUCCESS = "APPROVE_REQUEST_SUCCESS";
export const APPROVE_REQUEST_FAILURE = "APPROVE_REQUEST_FAILURE";

export const GET_GANT_REPRESENTATION = "GET_GANT_REPRESENTATION";
export const CLEAR_GANT_REPRESENTATION = "CLEAR_GANT_REPRESENTATION";

export const ADD_CANCEL_COMMENT = "ADD_CANCEL_COMMENT";

export const SET_YEAR_NOTIFICATION_BUBBLE = "SET_YEAR_NOTIFICATION_BUBBLE";
export const CLEAR_YEAR_NOTIFICATION_BUBBLE = "CLEAR_YEAR_NOTIFICATION_BUBBLE";
