import prev from "./arrowLeft.svg";
import next from "./arrowRight.svg";
import cross from "./cross.svg";

import { openFileBlobThunkAction } from "../../../../../redux/actions/Employees/thunk/openFileBlob.thunk.action";
import {
    getApplicationDetailThunkAction,
} from "../../../../../redux/actions/Employees/thunk/getApplicationDetail.thunk.action";
import OkButton from "../../../Button/OKButton/OKButton";

import React from "react";
import "./ApplicationDetailsModal.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";



const ApplicationDetailsModal = (
    {
        details,
        declineHandler,
        acceptHandler,
        closeModal,
    }
) => {

    const dispatch = useDispatch();

    const pendingApplications = useSelector(state => state.employees.pendingApplications);

    const applicationUIDs = pendingApplications.applications.map(app => app.uid);
    const currentApplicationIndex = details && applicationUIDs.indexOf(details.uid);

    const isFirstItem = currentApplicationIndex === 0;
    const isLastItem = currentApplicationIndex === applicationUIDs.length - 1;

    function openFileBlobHandler (document) {
        dispatch(openFileBlobThunkAction(document));
    }

    function prevApplicationHandler () {
        const prevApplication = pendingApplications.applications[currentApplicationIndex - 1];
        dispatch(getApplicationDetailThunkAction(prevApplication.uid));
    }

    function nextApplicationIndex () {
        const prevApplication = pendingApplications.applications[currentApplicationIndex + 1];
        dispatch(getApplicationDetailThunkAction(prevApplication.uid));
    }


    return details
        ? (
            <div className="application-details__wrapper">
                <img
                    onClick={closeModal}
                    className="application-details__close-cross"
                    src={cross}
                    alt="close" />
                <div className="application-details__header">
                    {details.applicationType}
                </div>
                <div className="application-details__controlls">
                    <img
                        onClick={!isFirstItem ? prevApplicationHandler : null}
                        src={prev}
                        className={`application-details__controlls-prev${isFirstItem ? "-disabled" : ""}`}
                        alt="prev"
                    />
                    <div className="application-details__name">
                        {details.name}
                    </div>
                    <img
                        onClick={!isLastItem ? nextApplicationIndex : null}
                        src={next}
                        className={`application-details__controlls-next${isLastItem ? "-disabled" : ""}`}
                        alt="next"
                    />
                </div>
                <div className="application-details__order-number">
                    Заявка № {details.applicationNumber}
                </div>
                <div className="application-details__table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <td className="application-details__table-col-name">структурное подраделение</td>
                                <td className="application-details__table-col-data">
                                    {details.struct}
                                </td>
                            </tr>
                            <tr>
                                <td className="application-details__table-col-name">должность</td>
                                <td className="application-details__table-col-data">
                                    {details.struct}
                                </td>
                            </tr>
                            <tr>
                                <td className="application-details__table-col-name">причина отпуска</td>
                                <td className="application-details__table-col-data">
                                    {details.applicationReason}
                                </td>
                            </tr>
                            <tr>
                                <td className="application-details__table-col-name">дата начала</td>
                                <td className="application-details__table-col-data">
                                    {details.dateStart ? moment(details.dateStart).format("DD.MM.YYYY") : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="application-details__table-col-name">дата окончания</td>
                                <td className="application-details__table-col-data">
                                    {details.dateEnd ? moment(details.dateEnd).format("DD.MM.YYYY") : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="application-details__table-col-name">комментарий</td>
                                <td className="application-details__table-col-data">
                                    {details.comment}
                                </td>
                            </tr>
                            <tr>
                                <td className="application-details__table-col-name">документы</td>
                                <td className="application-details__table-col-data-files">{
                                    details.documents && details.documents.map((document, index) => {
                                        const documentTitle = document.fileName;
                                        if (document.fileName.length > 40) {
                                            const end = document.fileName.slice(-4);
                                            document.fileName = document.fileName.slice(0, 35) + "..." + end;
                                        }
                                        return <div
                                            onClick={() => openFileBlobHandler(document)}
                                            title={documentTitle}
                                            key={index}>
                                            {document.fileName}
                                        </div>;
                                    }) 
                                }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="application-details__buttons">
                    <OkButton
                        handler={declineHandler}
                        title={"отклонить"} />
                    {/*<div onClick={declineHandler}>*/}
                    {/*    Отклонить*/}
                    {/*</div>*/}
                    <OkButton
                        handler={acceptHandler}
                        title={"согласовать"} />
                    {/*<div*/}
                    {/*    onClick={acceptHandler}>*/}
                    {/*    Согласовать*/}
                    {/*</div>*/}
                </div>
            </div>
        )
        : <div>Загрузка...</div>;
};

export default ApplicationDetailsModal;