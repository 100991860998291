import UButton from "../UButton/UButton";

import React from "react";
import "./CancelButton.scss";

const CancelButton = (props) => {
    function backHandler(e) {
        e.preventDefault();
        props.modal(false);
    }

    return (
        <>
            <UButton
                onClick={props.handler ? props.handler : backHandler}
                className="ui-cancel-button">
                {props.title}
            </UButton>
        </>

    );
};

export default CancelButton;