import { approvalsData } from "./approvals.data";

import { isTerminal } from "../../../../constants";
import { unsetUserRequestAction } from "../../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";
import RequestServices from "../../../../frontend.services/RequestServices/RequestServices";
import CurrentRequestCard from "../ModalAssets/CurrentRequestCard/CurrentRequestCard";
import { Modal } from "../../Modal/Modal";
import {
    getCurrentLeaderRequestThunkAction,
} from "../../../../redux/actions/Requests/LeaderRequests/thunk/getCurrentLeaderRequest.thunk.action";

import { LeftArrow }  from "../../../../assets/icons/LeftArrow";
import { RightArrow }  from "../../../../assets/icons/RightArrow";

import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import ReactPaginate from "react-paginate";

isTerminal ? import("./ApprovalsTerminal.scss") : import("./Approvals.scss");

const Approvals = ({
    requests,
    currentRows,
    currentRowsHandler,
    rowsPerPage,
}) => {

    const dispatch = useDispatch();

    const [modalShownReferences, toggleModalShownReferences] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + rowsPerPage;
    const currentItems = requests && requests.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(requests.length / rowsPerPage);

    function clickHandlerReferences(uid, i) {
        toggleModalShownReferences(!modalShownReferences);
        dispatch(getCurrentLeaderRequestThunkAction(uid, false));
    }

    function closeHandlerReferences(e) {
        toggleModalShownReferences(false);
        dispatch(unsetUserRequestAction());
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * rowsPerPage) % requests.length;
        setItemOffset(newOffset);
    };

    return !requests
        ? <ClipLoader color="#6DCDF6" />
        : (<div>
            <div className="table-container ">
                <table className="leader-table">
                    <thead>
                        <tr className="leader-table__row">
                            {approvalsData &&
                                approvalsData.map((el) => {
                                    return (
                                        <th
                                            key={el.id}
                                            style={{ width: `${el.width}` }}
                                            className="leader-table__row__item"
                                        >
                                            {el.title}
                                            {
                                                el.icon
                                                    ? <img
                                                        src={el.icon}
                                                        alt="triangle"
                                                        className="header-triangle" />
                                                    : null}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems &&
                            currentItems.map((el, i) => (
                                <tr
                                    onClick={() =>
                                        clickHandlerReferences(el.uid, i)
                                    }
                                    key={el.id}
                                    className={
                                        el.status !== 3
                                            ? el.expired && el.status === 3
                                                ? "row-request-leader-table expired"
                                                : "row-request-leader-table"
                                            : el.expired
                                                ? "row-request-leader-table-active expired"
                                                : "row-request-leader-table-active "
                                    }
                                >
                                    <td style={{ width: "98px" }}>
                                        {el.typeText}
                                    </td>
                                    <td style={{ width: "355px" }}>
                                        {el.leaveReasonId}
                                    </td>
                                    <td style={{ width: "160px" }}>
                                        {new Date(
                                            el.expireApprovalDate,
                                        ).toLocaleDateString()}
                                    </td>
                                    <td style={{ width: "177px" }}>
                                        {el.statusText}
                                    </td>
                                    <td style={{ width: "161px" }}>
                                        {el.employeeComment
                                            ? el.employeeComment.length > 15
                                                ? el.employeeComment.slice(
                                                    0,
                                                    15,
                                                ) + "..."
                                                : el.employeeComment
                                            : "..."}
                                    </td>
                                    <td
                                        onClick={(e) => e.stopPropagation()}
                                        style={{ width: "204px" }}
                                    >
                                        {el.fileName ? (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a
                                                // TODO REFACTOR LINK!!!
                                                // href="/#"
                                                target="_blank"
                                                download
                                                onClick={() =>
                                                    RequestServices.downloadRequestFile(
                                                        el.applicationDocumentAttachment.leaveRequestUid,
                                                        el.applicationDocumentAttachment.id,
                                                        el.applicationDocumentAttachment.url,
                                                    )
                                                }
                                                className="request-colored-link"
                                            >
                                                {el.fileName}
                                            </a>
                                        ) : (
                                            "..."
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {!requests.length
                    ? <div></div>
                    : <div className={ isTerminal && "pagination-wrapper"}>
                        <div className="pagination-container">
                            <div className="pagination-container__rows">
                                <div
                                    onClick={() => currentRowsHandler(5)}
                                    className={
                                        rowsPerPage === 5
                                            ? "rowsPerPage-number-active"
                                            : "rowsPerPage-number-inactive"
                                    }
                                >
                                    5
                                </div>
                                <div
                                    onClick={() => currentRowsHandler(10)}
                                    className={
                                        rowsPerPage === 10
                                            ? "rowsPerPage-number-active"
                                            : "rowsPerPage-number-inactive"
                                    }
                                >
                                    10
                                </div>
                                <div
                                    onClick={() => currentRowsHandler(20)}
                                    className={
                                        rowsPerPage === 20
                                            ? "rowsPerPage-number-active"
                                            : "rowsPerPage-number-inactive"
                                    }
                                >
                                    20
                                </div>

                                <div
                                    onClick={() => currentRowsHandler(50)}
                                    className={
                                        rowsPerPage === 50
                                            ? "rowsPerPage-number-active"
                                            : "rowsPerPage-number-inactive"
                                    }
                                >
                                    50
                                </div>
                            </div>
                        </div>
                        <ReactPaginate
                            className={"pagination"}
                            breakLabel=".."
                            nextLabel={isTerminal ? <RightArrow /> : ">"}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            previousLabel={isTerminal ? <LeftArrow /> : "<"}
                            renderOnZeroPageCount={null}
                        />
                    </div>
                }
            </div>
            <Modal shown={modalShownReferences} close={closeHandlerReferences}>
                <CurrentRequestCard
                    isLeader={true}
                    userRequests={requests}
                    close={closeHandlerReferences}
                />
            </Modal>
        </div>
        );
};

export default memo(Approvals);
