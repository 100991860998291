import { $api } from "../../../services/request.config";
import { clearApplicationDetailsAction } from "../clearApplicationDetails.action";
import { getApplicationDetailsAction } from "../getApplicationDetails.action";

export const getApplicationDetailThunkAction = (uid) => async (dispatch) => {
    try {

        dispatch(clearApplicationDetailsAction());

        const response = await $api(`v1/leaverequest/linemanager/${uid}`);
        if (response.data.isSuccess) {
            const {
                attachments,
                initiatorFullname,
                orderNumber,
                dateFrom,
                dateTo,
                leaveTypeName,
                leaveReasonName,
                periods,
                jobTitle,
                employeeComment,
            } = response.data.item;

            const applicationDetails = {
                uid,
                applicationType: leaveTypeName || "тип не определен",
                applicationReason: leaveReasonName || "причина не определена",
                applicationNumber: orderNumber,
                name: initiatorFullname,
                struct: jobTitle,
                dateStart: dateFrom,
                dateEnd: dateTo,
                comment: employeeComment,
                documents: attachments,
                applicationPeriods: periods,
            };

            dispatch(getApplicationDetailsAction(applicationDetails));
        } else {
            dispatch(clearApplicationDetailsAction());
        }
    } catch (error) {
        dispatch(clearApplicationDetailsAction());
        // eslint-disable-next-line no-console
        console.log("ERROR WHILE GETTING APPLICATION DETAILS", error);
    }
};
