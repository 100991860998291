import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

import "./EvaluationInformation.scss";


const EvaluationInformation = () => {
    return (
        <>
            <Header />
            <div className="main__container--personal-data">
                <Breadcrumbs />
                <h1 className={"sub-header"}> Информация об оценке</h1>
                <div className={"height"}></div>
                <label className="profile-label"> Рейтинг по итогам оценки</label>
                <div className="profile-info-container">
          отсутствует
                </div>
                <label className="profile-label">Результаты оценки работника по КПЭ за период</label>
                <div className="profile-info-container">
          2021 г. - 100%
                </div>
            </div>
            <Footer />
        </>

    );
};

export default EvaluationInformation;
