import { setCurrentHrDocsThunkAction } from "./setCurrentHrDocs.thunk.action";
import { getUserHrDocsThunkAction } from "./getUserHrDocs.thunk.action";

import $api from "../../../../services/axiosService";

export const setViewedHrDocumentThuunkAction =
    (packageId, docId, workerId, typeViewTo, props, isViewed) =>
        async (dispatch) => {
            try {
                const file = await $api.get(
                    `v2/directum/document/file/${packageId}/${docId}/${workerId}`,
                    { responseType: "blob" },
                );
                const blobLink = URL.createObjectURL(
                    new Blob([file.data], {
                        type: `${file.headers["content-type"]}`,
                    }),
                );
                if (blobLink) {
                    window.open(blobLink);
                    if (!isViewed) {
                        // const response =
                        await $api.post(
                            `v2/directum/document/mark/asread/${packageId}/${docId}/${workerId}`,
                        );
                    }
                    dispatch(
                        setCurrentHrDocsThunkAction(
                            packageId,
                            workerId,
                            typeViewTo,
                        ),
                    );
                    dispatch(getUserHrDocsThunkAction());
                }
            } catch (e) {
                props.closeHandlerPackages();
                props.setErrDescription(
                    "Что-то пошло не так, повторите попытку позже!",
                );
                props.helper();
            }
        };
