export const data = [
    {
        title: "Персональные данные",
        description: "Персональные данные о работнике, родственниках, дата рождения, контактные данные, ИНН и другие ",
        path: "/profile/personal-data",
    },
    {
        title: "Служебные данные",
        description: "Служебные данные о работнике, должность, место работы. Описание подразделения работника и другие",
        // icon: calendar,
        path: "/profile/service-data",
    },
    {
        title: "Информация об оценке",
        description: "Оценка производственной эффективности работника, КПЭ и другие",
        // icon: blankNote,
        path: "#",
    },
    {
        title: "Документы",
        description: "Ссылки на трудовой договор, должностную инструкцию и другое",
        // icon: users,
        path: "#",
    },
    {
        title: "Заявка на изменение данных",
        description: "Заявка на изменение персональных и иных данных о работнике",
        // icon: calendar,
        path: "#",
    },
    {
        title: "Электронная подпись",
        description: "Информация об ЭЦП и ее выпуск",
        // icon: blankNote,
        path: "/profile/eds-release",
    },
];
