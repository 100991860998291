import { $api } from "../../../../../services/request.config";
import { getInternhipsTypeAction } from "../getInternhipsType.action";

export const getInternhipsTypeThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("/v1/managers/requests/internships/types");
        if (response.status === 200) {
            dispatch(getInternhipsTypeAction(response.data));
        }
    } catch (error) {
        throw new Error(error.response);
    }
};
