import CurrentRequestCard from "../CurrentRequestCard/CurrentRequestCard";

import React from "react";

function UserRequestCard(props) {
    return (
        <>
            <CurrentRequestCard
                description={props.description}
                userRequests={props.userRequests}
                close={props.close}
            />
        </>
    );
}

export default UserRequestCard;
