import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import {
    emptyCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import ShiftAdditionalRestDaysApplication
    from "../../../../../../../frontend.classes/Requests/ShiftAdditionalRestDays.application";
import {
    getRescheduleDaysThunkAction,
} from "../../../../../../../redux/actions/Requests/ShiftAdditionalRestDays/thunk/getRescheduleDays.thunk.action";
import {
    clearShiftDaysAction,
} from "../../../../../../../redux/actions/Requests/ShiftAdditionalRestDays/clearShiftDays.action";
import RequestsHelper from "../../../../../../../frontend.services/RequestServices/Requests.helper";
import DatePeriodControlled from "../../../../../../common/Inputs/DatePeriodControlled/DatePeriodControlled";
import { isTerminal } from "../../../../../../../constants";
import { ScrollButton } from "../../../../../../common/componentsTerminal/ScrollButton/ScrollButton";

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";

isTerminal ? import("./ShiftAdditionalRestDaysTerminal.scss") : import("./ShiftAdditionalRestDays.scss");


const ShiftAdditionalRestDays = ({
    requestOption,
    requestStatus,
    setRequestStatus,
}) => {
    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const dispatch = useDispatch();

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const reasons = useSelector((state) => state.requests.reasons);
    const shiftDays = useSelector((state) => state.requests.shiftDays);

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectReasonClick(event, element, stateProperty) {
        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    function setPeriodClick(event, element, stateProperty) {
        const shiftPeriod = shiftDays.find(
            (period) => period.id === element.id,
        );
        const { from, to } = shiftPeriod;
        element.dateFrom = from;
        element.dateTo = to;
        element.reason = element.id;
        stateProperty = "shift";
        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    useEffect(() => {
        dispatch(getRescheduleDaysThunkAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        if (!queryId) {
            dispatch(
                emptyCurrentRequest(
                    new ShiftAdditionalRestDaysApplication({
                        leaveTypeId: 13,
                        periods: [{ dateFrom: null, dateTo: null }],
                    }),
                ),
            );
        }
        return () => {
            dispatch(clearShiftDaysAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    // console.log(shiftDays);

    return (
        <div className={"shift-additional-rest-days"}>
            {isTerminal && <ScrollButton />}
            <DropdownMenu
                label={<>Выберите причину переноса дополнительных дней отдыха <span className="required_text"> *</span></>}
                styleName={"reason"}
                listOfOptions={reasons}
                stateProperty={"leaveReasonId"}
                currentRequest={currentRequest}
                selectOptionClick={selectReasonClick}
                dropdownDescription={
                    "Перенос дополнительных дней возможен только в рамках месяца, в котором они были ранее запланированы"
                }
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.leaveReasonId
                        ? currentRequest.isValid.leaveReasonId
                        : false
                }
            />
            <DropdownMenu
                label={
                    <>Выберите период дополнительных дней отдыха для переноса<span className="required_text"> *</span></>
                }
                styleName={"reason"}
                listOfOptions={
                    shiftDays &&
                    RequestsHelper.postponeDropdownFabric(shiftDays)
                }
                stateProperty={"leaveRequestDwhId"}
                currentRequest={currentRequest}
                selectOptionClick={setPeriodClick}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.leaveRequestDwhId
                        ? currentRequest.isValid.leaveRequestDwhId
                        : false
                }
            />

            {currentRequest &&
                currentRequest.periods &&
                currentRequest.periods.map((period, index) => {
                    return (
                        <DatePeriodControlled
                            periodsLength={currentRequest.periods.length}
                            key={uuidv4()}
                            parentStyle={"postpone-vacation"}
                            period={period}
                            index={index}
                            label={{
                                from: <>Начало перенесенных дней отдыха<span className="required_text"> *</span></>,
                                to: <>Окончание перенесенных дней отдыха<span className="required_text"> *</span></>,
                            }}
                            datesForRange={{
                                minFrom: new Date(moment().add(1, "day")),
                                // minTo: new Da ,
                            }}
                        />
                    );
                })}

            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                changeHandler={commentHandler}
                label={"Комментарий"}
                parentStyle={"postpone-vacation"}
            />
            {!isTerminal && <CustomUploader />}
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
            />
        </div>
    );
};

export default ShiftAdditionalRestDays;
