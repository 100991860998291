import {initialState} from "../../init/initialState";

export const authNumberReducer = (state =initialState,action)=>{
    const  {type,payload} = action;
    switch (type) {
    case "SET_TO_NULL":
        return null;
    case "WRONG_CODE":
        return payload;
    default:
        return state;
    }
};
