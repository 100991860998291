import BasicLine from "../../components/common/BasicLine/BasicLine";
// import Experience from "../../components/pages/Profile/ServiceData/Expirience/Expirience";
// import { expirienceData } from "../../components/pages/Profile/ServiceData/Expirience/expirience.data";
// import ExperienceControlService from "../Profile/ExperienceControlService";

import { Fragment } from "react";

class EmployeeInformationHelper {
    static getTableRows(data, parentStyle) {
        return (
            <Fragment>
                <BasicLine
                    type={"табельный номер"}
                    description={
                        data.appointment && data.appointment.tabNumber
                            ? data.appointment.tabNumber
                            : "---"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"структурное подразделение"}
                    description={
                        data.appointment && data.appointment.structName
                            ? data.appointment.structName
                            : "---"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"руководитель"}
                    description={
                        data.leader && data.leader.lastName
                            ? `${data.leader.lastName} ${data.leader.firstName} ${data.leader.secondName}`
                            : "---"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"должность"}
                    description={
                        data.appointment && data.appointment.position
                            ? data.appointment.position
                            : "отсутствует"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"дата рождения"}
                    description={
                        data.birthDate
                            ? `${new Date(data.birthDate).toLocaleDateString()}`
                            : "---"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"телефон"}
                    description={data.phone ? `+${data.phone}` : "---"}
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"e-mail"}
                    description={data.eMail ? `${data.eMail}` : "---"}
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"грейд / разряд"}
                    description={data.grade.name ? data.grade.name : "---"}
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"группа грейдов"}
                    description={data.grade.group ? data.grade.group : "---"}
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"даты ближайшей неявки"}
                    description={
                        data.nextMiss && data.nextMiss.from
                            ? `${new Date(
                                data.nextMiss.from,
                            ).toLocaleDateString()} - ${new Date(
                                data.nextMiss.to,
                            ).toLocaleDateString()},  ${
                                data.nextMiss.typeName
                                    ? data.nextMiss.typeName
                                    : " "
                            }`
                            : "---"
                    }
                    styleParent={parentStyle}
                />
                {/*<div className={`${parentStyle}__item`}>*/}
                {/*<div className={`${parentStyle}__item__label`}>*/}
                {/*    {expirienceData &&*/}
                {/*        expirienceData.map((el, i) => {*/}
                {/*            return (*/}
                {/*                <div*/}
                {/*                    key={el.id}*/}
                {/*                    className={"work-exp__item"}*/}
                {/*                >*/}
                {/*                    <div className={"work-exp__item__text"}>*/}
                {/*                        {el.title}*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        className={"work-exp__item__block"}*/}
                {/*                        style={{*/}
                {/*                            background: `${el.color}`,*/}
                {/*                            width: "25px",*/}
                {/*                            height: "14px",*/}
                {/*                        }}*/}
                {/*                    ></div>*/}
                {/*                </div>*/}
                {/*            );*/}
                {/*        })}*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    {data.current.total ? (*/}
                {/*        <div*/}
                {/*            className="profile-info-diagram"*/}
                {/*            style={{*/}
                {/*                height: `${*/}
                {/*                    data.current.percentRks ? 81 : 54*/}
                {/*                } px`,*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <div*/}
                {/*                className="general-expirience"*/}
                {/*                style={{ width: "100%" }}*/}
                {/*            >*/}
                {/*                {data.workExp.total.years}*/}
                {/*                {ExperienceControlService.text(*/}
                {/*                    data.workExp.total.years,*/}
                {/*                )}*/}
                {/*                ,{data.workExp.total.months}м*/}
                {/*            </div>*/}
                {/*            {data.current.percentCompany && (*/}
                {/*                <div*/}
                {/*                    className="part"*/}
                {/*                    style={{*/}
                {/*                        backgroundColor:*/}
                {/*                            "rgba(205, 232, 245, 1)",*/}
                {/*                        width: `${data.current.percentCompany}%`,*/}
                {/*                        fontSize: "9px",*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    {data.current.percentCompany < 7*/}
                {/*                        ? ""*/}
                {/*                        : data.workExp.company.years}*/}
                {/*                    {data.current.percentCompany < 5*/}
                {/*                        ? ""*/}
                {/*                        : ExperienceControlService.text(*/}
                {/*                              data.workExp.company.years,*/}
                {/*                          )}*/}
                {/*                    {data.current.percentCompany < 5*/}
                {/*                        ? ""*/}
                {/*                        : "," + data.workExp.company.months}*/}
                {/*                    {data.current.percentCompany < 5*/}
                {/*                        ? ""*/}
                {/*                        : "м"}*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*            {data.current.percentRks && (*/}
                {/*                <div*/}
                {/*                    className="part"*/}
                {/*                    style={{*/}
                {/*                        backgroundColor:*/}
                {/*                            "rgba(255, 221, 197, 1)",*/}
                {/*                        width: `${data.current.percentRks}%`,*/}
                {/*                        fontSize: "9px",*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    {data.current.percentRks < 5*/}
                {/*                        ? ""*/}
                {/*                        : data.workExp.rks.years}*/}
                {/*                    {data.current.percentRks < 5*/}
                {/*                        ? ""*/}
                {/*                        : ExperienceControlService.text(*/}
                {/*                              data.workExp.rks.years,*/}
                {/*                          )}*/}
                {/*                    {data.current.percentRks < 5*/}
                {/*                        ? ""*/}
                {/*                        : "," + data.workExp.rks.months}*/}
                {/*                    {data.current.percentRks < 5 ? "" : "м"}*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    ) : (*/}
                {/*        <div className="fio__item__description">*/}
                {/*            отсутствует*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<Experience/>*/}
                <BasicLine
                    type={"дата приема"}
                    description={
                        data.workInOut && data.workInOut.in
                            ? `${new Date(
                                data.workInOut.in,
                            ).toLocaleDateString()}`
                            : "---"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"планируемая дата увольнения"}
                    description={
                        data.workInOut && data.workInOut.out
                            ? `${new Date(data.workInOut.out)}`
                            : "отсутствует"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"срок срочного трудового договора"}
                    description={
                        data.fixedTerm
                            ? `${new Date(
                                data.fixedTerm.from,
                            ).toLocaleDateString()} - ${new Date(
                                data.fixedTerm.to,
                            ).toLocaleDateString()}`
                            : "отсутствует"
                    }
                    styleParent={parentStyle}
                />
                <BasicLine
                    type={"мат. ответственный"}
                    description={data.financiallyResponsibleSign ? "да" : "нет"}
                    styleParent={parentStyle}
                />
            </Fragment>
        );
    }
}

export default EmployeeInformationHelper;
