import triangle from "../../DatePeriodControlled/Triangle.svg";
import {
    calendarDatePickCurrentRequest,
} from "../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import { isTerminal } from "../../../../../constants";
import calendarIcon from "../../../../../assets/icons/Claendar/calendar.svg";
import { DatepickerCustomField } from "../../../DatepickerCustomField/DatepickerCustomField";

import moment from "moment";
import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";

// import "./SingleDateCalendarSelector.scss";
// import "../../DatepickerInput/DatepickerInput.scss";
// import "../../DatePickerInputMod/DatePickerInputMod";


const SingleDateCalendarSelector = (
    {
        field,
        label,
    },
) => {
    const datePickerRef = useRef();
    const dispatch = useDispatch();

    const currentRequest = useSelector(state => state.requests.currentRequest);

    const [value, setValue] = useState(null);
    const [arrow, setArrow] = useState(false);

    const handleCalendarClose = () => setArrow(false);
    const handleCalendarOpen = () => setArrow(true);


    function changeHandler(date) {
        dispatch(calendarDatePickCurrentRequest({ field, date: moment(date).format("YYYY-MM-DD") }));
        dispatch(validationControlCurrentRequest(field, true));
        setValue(date);
    }


    return (
        <div className={"single-date-calendar-selector"}>
            <div className={"single-date-calendar-selector__label"}>
                {label}
            </div>
            <DatePicker
                customInput={<DatepickerCustomField />}
                minDate={field === "dateTo" && currentRequest && currentRequest.dateFrom
                    ? currentRequest.dateFrom === "Invalid date"
                        ? new Date()
                        : new Date(currentRequest.dateFrom)
                    : new Date()}
                maxDate={field === "dateFrom" && currentRequest && currentRequest.dateTo
                    ? currentRequest.dateTo === "Invalid date"
                        ? null
                        : new Date(currentRequest.dateTo)
                    : null}
                className={`single-date-calendar-selector__input${
                    currentRequest && currentRequest.isValid && currentRequest.isValid[[`${field}`]]
                        ? "-regular"
                        : "-error"
                }`}
                dateFormat="dd.MM.yyyy"
                locale={"ru"}
                selected={
                    currentRequest && currentRequest[`${field}`]
                        ? new Date(currentRequest[`${field}`]) : value
                }
                onChange={(date) => changeHandler(date)}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={handleCalendarOpen}
                ref={datePickerRef}
            />
            {!isTerminal &&  <img className={`single-date-calendar-selector__${arrow ? "arrow-down" : "arrow"}`} src={triangle}
                alt=">" />}
            {isTerminal && <img src={calendarIcon} onClick={datePickerRef.current?.onInputClick} className="single-date-calendar-selector__calendar"/>}
            {
                currentRequest && currentRequest.isValid && currentRequest.isValid[[`${field}`]]
                    ? null
                    : <div className={"single-date-calendar-selector__error"}>Проверьте правильность заполнения</div>
            }
        </div>
    );
};

export default SingleDateCalendarSelector;
