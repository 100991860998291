
export const dataHeader = [
    "вид отпуска", "дни", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август",
    "Сентябрь","Октябрь", "Ноябрь", "Декабрь",
];
// export const dataColumn = ['Ежегодный отпуск','Ежегодный отпуск','Вредные и (или) опасные условия труда',
//     'Плановые дни отдыха','НРД', 'РКС']
export const dataColumn = [
    {
        name:"Ежегодный отпуск",
        day:28,

    },
    {
        name:"Другие отпуска",
        day:28,

    },
    {
        name:"Плановые дни отдыха",
        day:28,

    },
];
