import OkButton from "../../../../Button/OKButton/OKButton";
import { isTerminal } from "../../../../../../constants";

import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";


isTerminal ? import("./SentStatusModalTerminal.scss") : import("./SentStatusModal.scss");

const SentStatusModal = (props) => {

    const navigate = useNavigate();

    function buttonHandler(e) {
        props.close(false);
        navigate(props.route);
    }

    return props.sendButtonLoading ?
        <ClipLoader color="#6DCDF6" /> :
        (
            <div className="sent-status-modal">
                <div className="sent-status-modal__content">
                    <div className="sent-status-modal__head">
            заявка отправлена
                    </div>
                    <div className="sent-status-modal__description">
            Ваша заявка
            №
                        {
                            props.requestNumber
                        } «
                        {
                            Array.isArray(props.requestTheme)
                                ? props.requestTheme[0].name
                                : props.requestTheme
                        }
            »
            отправлена на согласование.
            Уточнить ее статус вы можете в разделе «Заявки»
                    </div>
                </div>
                <div className="sent-status-modal__button">
                    <OkButton handler={buttonHandler} title={"OK"} />
                </div>
            </div>
        );
};

export default SentStatusModal;
