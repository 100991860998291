import {
    UNSET_CURRENT_REQUEST,
    // UNSET_CURRENT_REQUEST_CARD
} from "../../../enums/Request/request.types";

export const unsetUserRequestAction = () => {
    return {
        type: UNSET_CURRENT_REQUEST,
        payload: false,
    };
};
