import {GET_LEAVES_WIDGETS, RESET_SCHELDER_WIDGETS} from "../../enums/HrServices/WorkTime/workTime";

export const leavesWidgetsAction=(data)=>{
    return {
        type:GET_LEAVES_WIDGETS,
        payload:data,
    };
};
export const resetLeavesWidgetsAction = () => {
    return {
        type:RESET_SCHELDER_WIDGETS,
        payload:null,
    };
};
