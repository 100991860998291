import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import { resetLeavesWidgetsAction } from "../../../redux/actions/LeavesWorkTimeShifts/leavesWidgetsAction";
import { workScheduleThunkAction } from "../../../redux/actions/LeavesWorkTimeShifts/thunk/workSchedule.thunk.action";
import MultiWidget from "../../common/MultiWidget/MultiWidget";
import AnnualCalendar from "../../common/Modal/AnnualCalendar/AnnualCalendar";
import { Modal } from "../../common/Modal/Modal";
import LeavesService from "../../../frontend.services/Leaves.service";
import { isTerminal } from "../../../constants";
import { AnnualCalendarForTerminal } from "../../common/componentsTerminal/Calendar/AnnualCalendarForTerminal";
import { getActualMonthForTerminalCalendar } from "../../../utils/getActualMonthForTerminalCalendar";

import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

isTerminal
    ? import("./WorkScheduleTerminal.scss")
    : import("./WorkSchedule.scss");

const WorkSchedule = () => {
    const dispatch = useDispatch();
    const workSchedule = useSelector((state) => state.workTime.workSchedule);
    const [workScheduleCurrent, setWorkScheduleCurrent] = useState(workSchedule);
    const [schelduleType, setSchelduleType] = useState({ type: null, data: null });
    const [toggleState, setToggleState] = useState(1);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [firstDisplayedMonth, setFirstDisplayedMonth] = useState(1);

    useEffect(() => {
        setFirstDisplayedMonth(getActualMonthForTerminalCalendar(modalPosition.x));
    }, [modalPosition]);

    const toggleTab = (index) => {
        dispatch(resetLeavesWidgetsAction());
        setToggleState(index);
        if (index === 2) {
            if (LeavesService.getCurrentMonth() !== 1) {
                dispatch(
                    workScheduleThunkAction(
                        LeavesService.getCurrentYear(),
                        LeavesService.getCurrentMonth() - 1,
                    ),
                );
            } else if (LeavesService.getCurrentMonth() === 1) {
                dispatch(
                    workScheduleThunkAction(
                        LeavesService.getCurrentYear() - 1,
                        12,
                    ),
                );
            }
        } else {
            dispatch(
                workScheduleThunkAction(
                    LeavesService.getCurrentYear(),
                    LeavesService.getCurrentMonth(),
                ),
            );
        }
    };
    const [modalAnnual, toggleModalAnnual] = useState(false);
    const clickHandlerAnnual = (type, i, e) => {
        const { clientX, clientY } = e;
        setModalPosition({ x: clientX, y: clientY });
        setSchelduleType({
            type: "График работы",
            data: workScheduleCurrent.workPeriodForDayPicker,
        });
        toggleModalAnnual(true);
    };

    const closeHandlerAnnual = (e) => {
        toggleModalAnnual(false);
    };

    useEffect(() => {
        dispatch(
            workScheduleThunkAction(
                LeavesService.getCurrentYear(),
                LeavesService.getCurrentMonth(),
            ),
        );
    }, [dispatch]);

    useEffect(() => {
        setWorkScheduleCurrent(workSchedule);
    }, [workSchedule]);

    return (
        <div className={isTerminal && "work-schedule__content-wrapper"}>
            <div className="work-schedule">
                <Header />
                <Breadcrumbs />
                {!workScheduleCurrent ? (
                    <div style={{ textAlign: "center", width: isTerminal && "1080px" }}>
                        <ClipLoader color="#6DCDF6" />
                    </div>
                ) : (
                    <>
                        <div className={isTerminal && "work-schedule__multi-wdget-wrapper"}>
                            <MultiWidget
                                data={workScheduleCurrent.widgets}
                                styleParent={isTerminal ? "leaves-widgets" : "work-schedule"}
                                subHeader="График работы"
                                isBackButtonEnabled
                            />
                        </div>
                        {!isTerminal && <div style={{
                            fontSize: "16px",
                            lineHeight: " 135%",
                            fontWeight: "450",
                        }}>
                            Графики/режимы работы носят информационный характер и не имеют юридической силы.
                            Руководствоваться необходимо актуальной информацией в утвержденном работодателем графике или
                            подписанном соглашении
                        </div>}
                        <div
                            className={isTerminal ? "work-schedule__nav-menu" : "navMenu"}
                            style={{ marginTop: !isTerminal && "40px" }}>
                            <div
                                onClick={() => toggleTab(2)}
                                className={
                                    toggleState === 2
                                        ? "documents-menu-item"
                                        : "documents-menu-item-active"
                                }>
                                Прошлый месяц
                            </div>
                            <div
                                onClick={() => toggleTab(1)}
                                className={
                                    toggleState === 1
                                        ? "documents-menu-item"
                                        : "documents-menu-item-active"
                                }>
                                Текущий месяц
                            </div>
                        </div>
                        <table className="work-schedule__table-a">
                            <thead>
                                <tr>
                                    <td
                                        style={{
                                            letterSpacing: "0.2em",
                                            fontSize: "12px",
                                            textTransform: "uppercase",
                                            color: "rgba(109, 110, 113, 1)",
                                            fontWeight: "450",
                                        }}>
                                    Тип
                                    </td>
                                    {workScheduleCurrent.workPeriod
                                        .length !== 0 &&
                                    workScheduleCurrent.workPeriod.map(
                                        (el, i) => {
                                            return (
                                                <td
                                                    key={i}
                                                    className={
                                                        el.dayNumber ===
                                                        6 ||
                                                        el.dayNumber ===
                                                        7
                                                            ? "work-schedule-header weekendHeader"
                                                            : "work-schedule-header "
                                                    }>
                                                    <div>
                                                        {
                                                            el.dayShortName
                                                        }
                                                    </div>
                                                    <div>
                                                        {new Date(
                                                            el.date,
                                                        ).getDate()}
                                                    </div>
                                                    {/*{" lk"}*/}
                                                </td>
                                            );
                                        },
                                    )}
                                </tr>
                            </thead>
                            <tbody
                                className={"work-schedule__table-a__item"}>
                                <tr>
                                    <td
                                        style={{
                                            fontSize: "14px",
                                            color: "rgba(109, 110, 113, 1)",
                                            fontWeight: "400",
                                            textAlign: isTerminal ? "left" : "center",
                                            paddingRight: "8px",
                                        }}>
                                    График работы
                                    </td>
                                    {workScheduleCurrent.workPeriod
                                        .length !== 0 &&
                                    workScheduleCurrent.workPeriod.map(
                                        (el, i) => {
                                            return (
                                                <td
                                                    onClick={(e) =>
                                                        clickHandlerAnnual(el.typeName, i, e)
                                                    }
                                                    className={
                                                        el.dayTypeName ===
                                                        "рабочий" ||
                                                        el.dayTypeName ===
                                                        "рабочий сокращенный"
                                                            ? el.dayPass ===
                                                            true
                                                                ? "work-schedule__table pass"
                                                                : "work-schedule__table"
                                                            : "work-schedule__table weekend"
                                                    }
                                                    key={i}></td>
                                            );
                                        },
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
                {isTerminal
                    ? (
                        <AnnualCalendarForTerminal
                            firstDisplayedMonth={firstDisplayedMonth}
                            shown={modalAnnual}
                            onClose={closeHandlerAnnual}
                            type={schelduleType}
                            year={toggleState === 1 ? LeavesService.getCurrentYear() : LeavesService.getCurrentYear() - 1}
                            mausePosition={modalPosition}
                        />
                    )
                    : (
                        <Modal shown={modalAnnual} close={closeHandlerAnnual}>
                            <AnnualCalendar
                                type={schelduleType}
                                year={
                                    toggleState === 1
                                        ? LeavesService.getCurrentYear()
                                        : LeavesService.getCurrentYear() - 1
                                }
                            />
                        </Modal>
                    )}
            </div>
            <Footer />
        </div>
    );
};

export default WorkSchedule;
