import {$api} from "../../../../services/request.config";
import {getUsersAdressAction} from "../getUsersAdress.action";

export const getUsersAdressThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1.0/c/u/11");
        if(response.status) {
            // console.log(response.data)
            const temp = response.data.data.addresses.map((el)=> {
                return {
                    id: el.address,
                    name: el.address,
                    typeId: el.addressTypeId,
                };
            });
            dispatch(getUsersAdressAction(temp));
        }


    } catch (e) {

    }
};
