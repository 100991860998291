import { tableHeaderData } from "./tableHeader.data";

import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../../../layout/Header/Header";
import Footer from "../../../../layout/Footer/Footer";
import BasicTable from "../../../../common/BasicTable/BasicTable";
import UButton from "../../../../common/Button/UButton/UButton";
import BackButton from "../../../../common/Button/BackButton/BackButton";

import {
    getLatesUnepCertificateThunkAction,
} from "../../../../../redux/actions/User/profile/ElectronicSignature/thunk/getLatesUnepCertificate.thunk.action";
import ModalBanOverlay from "../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import ErrorModal from "../../../../common/Modal/ModalConditions/Profile/ErrorModal/ErrorModal";
import { isTerminal } from "../../../../../constants";
import { BackArrowButton } from "../../../../common/componentsTerminal/BackArrowButton/BackArrowButton";

import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

isTerminal ? import("./UnepTerminal.scss") : import("./Unep.scss");

const Unep = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const latestCertificate = useSelector(
        (state) => state.personalData.latestCertificate,
    );
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("");

    useEffect(() => {
        dispatch(
            getLatesUnepCertificateThunkAction({
                setErrorModal: setErrorModal,
                setErrDescription: setErrDescription,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            {localStorage.getItem("hasActualDocuments") !== "true" && (
                <Breadcrumbs />
            )}
            <section className="unep">
                {!isTerminal && <h2> УНЭП </h2>}
                {isTerminal && (
                    <div className="unep__header">
                        <BackArrowButton />
                        <h2> УНЭП </h2>
                    </div>
                )}
                {latestCertificate ? (
                    <>
                        <BasicTable
                            dataHeader={tableHeaderData}
                            dataRow={latestCertificate}
                        />
                        <div className={"unep__container"}>
                            <BackButton route={"/profile/eds-release"} />
                            <UButton onClick={() =>
                                navigate(
                                    "/profile/eds-release/unep/release",
                                )
                            }
                            disabled={latestCertificate.thumbprint || latestCertificate.status === "Подтверждена"}
                            >{!latestCertificate.thumbprint
                                    ? "получить УНЭП"
                                    : "Перевыпустить УНЭП"
                                }</UButton>

                        </div>
                    </>
                ) : (
                    <>
                        <ClipLoader color="#6DCDF6" />
                    </>
                )}
                <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                    <ErrorModal
                        description={errDescription}
                        setErrorModal={setErrorModal}
                        route={"/profile"}
                    />
                </ModalBanOverlay>
            </section>
            <Footer />
        </>
    );
};

export default Unep;
