import { $api } from "../../../../services/request.config";
import { getEmployeesByStructIdAction } from "../getEmployeesByStructId.action";

export const getEmployeesByStructIdThunkAction = (id) => async (dispatch) => {
    try {
        const response = await $api(`v1/structs/employees?id=${id}`);
        dispatch(getEmployeesByStructIdAction(response.data));
    } catch (error) {
        // eslint-disable-next-line
        console.log("ERROR WHILE GETTING EMPLOYEES BY STRUCT ID: ", error.response);
    }
};