import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import UnplannedVacationApplication from "../../../../../../../frontend.classes/Requests/UnplannedVacation.application";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";
import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import SinglePairDates from "../../../../../../common/Inputs/SinglePairDates/SinglePairDates";
import { emptyCurrentRequest } from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import { clearAttachmentsAction } from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import { isTerminal } from "../../../../../../../constants";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

isTerminal ? import("./UnplannedVacationTerminal.scss") : import("./UnplannedVacation.scss");

function UnplannedVacation({ setRequestStatus, requestStatus, requestOption }) {
    const dispatch = useDispatch();

    const reasons = useSelector((state) => state.requests.reasons);
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectDropdown(event, element, stateProperty) {
        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    function commentHandler(event) {
        dispatch(validationControlCurrentRequest("employeeComment", true));
        dispatch(addCommentHandlerCurrentRequest(event.target.value));
    }

    useEffect(() => {
        dispatch(
            emptyCurrentRequest(
                new UnplannedVacationApplication({ leaveTypeId: 2 }),
            ),
        );
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return (
        <section className={"unplanned-vacation"}>
            <DropdownMenu
                currentRequest={currentRequest}
                styleName={"unplanned-reason"}
                label={<>Выберите причину отпуска <span className="required_text"> *</span></>}
                listOfOptions={reasons}
                stateProperty={"leaveReasonId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.leaveReasonId
                        ? currentRequest.isValid.leaveReasonId
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            <SinglePairDates />
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                changeHandler={commentHandler}
                label={<>Комментарий <span className="required_text"> *</span></>}
                parentStyle={"extra-vacation-days"}
                helper={
                    " Пожалуйста, расшифруйте причину необходимости предоставления " +
                    "отпуска. Обращаем внимание: комментарий будет доступен всем " +
                    "согласующим."
                }
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.employeeComment
                        ? ""
                        : "Необходимо заполнить поле «комментарий к заявке» "
                }
            />
            {!isTerminal && <CustomUploader />}
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
            />
        </section>
    );
}

export default UnplannedVacation;
