import {authUserReducer} from "./Auth/authUserReducer";
import {authRegisterReducer} from "./Auth/authRegisterReducer";
import {authNumberReducer} from "./Auth/authNumberReducer";
import {resetPasswordReducer} from "./Auth/resetPasswordReducer";
import {authLoginReducer} from "./Auth/authLoginReducer";
import {startReducer} from "./Auth/startReducer";
import {resetStatePasswordReducer} from "./Auth/resetStatePasswordReducer";
import {requestReducer} from "./Requests/request.reducer";
import {workTimeReducer} from "./WorkTime/workTime.reducer";
import {experienceDiagramReducer} from "./workerProfile/experienceDiagramReducer";
import {personalDataReducer} from "./PersonalData/personalData.reducer";
import {employeesReducer} from "./Employees/employees.reducer";
import {documentsReducer} from "./Documents/Documents.reducer";
import {myDivisionReducer} from "./MyDivision/MyDivision.reducer";
import {payrollReducer} from "./Payroll/payrollReducer";

import {combineReducers} from "redux";

export const rootReducer = combineReducers({
    user: authUserReducer,
    authRegister: authRegisterReducer,
    authLogin: authLoginReducer,
    authNumber: authNumberReducer,
    wrongRequestResetPassword: resetPasswordReducer,
    isAuth: startReducer,
    resetPassword: resetStatePasswordReducer,
    requests: requestReducer,
    workTime:workTimeReducer,
    experienceDiagram:experienceDiagramReducer,
    personalData: personalDataReducer,
    employees: employeesReducer,
    documents:documentsReducer,
    myDivision: myDivisionReducer,
    payroll: payrollReducer,
});
