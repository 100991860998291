export const wrongAuthRegisterAction = (message) => {
    return {
        type: "WRONG_REGISTRATION",
        payload:message,

    };
};
export const wrongAuthLoginAction = (message) =>{
    // console.log(message)
    return{
        type: "WRONG_LOGIN",
        payload:message,
    };
};
export const resetErrorLoginMessageAction = () => {
    return{
        type:"RESET_ERROR_LOGIN",
        payload:null,
    };
};
export const resetErrorMessageAction = () => {
    return{
        type:"RESET_ERROR",
        payload:null,
    };
};

// SUCCESS WRONG_VERIFICATION WRONG_REGISTRATION
