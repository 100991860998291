import React from "react";

import "./DelegationTypesItem.scss";

const DelegationTypesItem = (
    {
        listItem,
        setState,
        setStateModel,
        field,
        itemClickHandler,
    }
) => {

    function selectHandler (event, listItem) {

        setState(() => listItem.name);
        setStateModel((prev) => {
            return {
                ...prev,
                [field]: listItem.id,

            };
        });

        itemClickHandler(event, listItem);
    }

    return (
        <div
            className={"delegation-types-item"}
            onClick={(event) => selectHandler(event, listItem)}>
            {listItem.name}
        </div>
    );
};

export default DelegationTypesItem;