import { isTerminal } from "../../../constants";

import moment from "moment";

isTerminal ? import("./BasicTableTerminal.scss") : import("./BasicTable.scss");

const stylesTerminal = {
    width: "100%",
    wordBreak: "break-word",
};

const BasicTable = (
    {
        dataHeader,
        dataRow,

    },
) => {
    // const now = new Date();
    // const [date, setDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate())) ;
    return (
        <>
            <table className="basic-table">
                <tbody>
                    <tr className="leader-table__row">
                        {dataHeader && dataHeader.map((el) => {
                            return (
                                <th
                                    key={el.id}
                                    style={isTerminal ? stylesTerminal : { width: `${el.width}` }}
                                    className="leader-table__row__item"
                                >
                                    {el.title}
                                    {el.icon ?
                                        <img
                                            src={el.icon}
                                            alt="triangle"
                                            className="header-triangle"
                                        />
                                        : null
                                    }
                                </th>
                            );
                        })

                        }
                    </tr>
                    <tr
                        className={"row-request-leader-table"}
                    >
                        <td style={isTerminal ? stylesTerminal : { width: "180px" }}>
                            {
                                dataRow.issueDate
                                    ? moment(dataRow.issueDate).format("DD.MM.YYYY")
                                // ? new Date(dataRow.issueDate ).toLocaleDateString()
                                    : "Отсутствует"
                            }
                        </td>
                        <td style={isTerminal ? stylesTerminal : { width: "176px" }}>
                            {
                                dataRow.status
                                    ? dataRow.status
                                    : "Отсутствует"
                            }
                        </td>
                        <td
                            className={
                                new Date().getTime() > new Date(dataRow.validDate).getTime()
                                    ? "expiredDate"
                                    : null
                            } style={isTerminal ? stylesTerminal : { width: "272px" }}>
                            {
                                dataRow.validDate
                                    ? moment(dataRow.validDate).format("DD.MM.YYYY")
                                // ? new Date(dataRow.validDate).toLocaleDateString()

                                    : "Отсутствует"
                            }
                        </td>
                        <td style={isTerminal ? stylesTerminal : { width: "407px" }}>
                            {
                                dataRow.thumbprint
                                    ? dataRow.thumbprint
                                    : "Отсутствует"
                            }
                        </td>
                    </tr>
                    {
                        dataRow.valid === "expired"
                            ? <span className={"expiredDate spanError"}>Срок действия вашей ЭЦП истекает
                                {
                                    moment(dataRow.validDate).format("DD.MM.YYYY")
                                }
                            .
                            </span>
                            : null
                    }
                    {
                        dataRow.valid === "completely expired"
                            ? <span className={"expiredDate spanError"}>Срок действия вашей ЭЦП истек
                                {
                                    moment(dataRow.validDate).format("DD.MM.YYYY")
                                }
                            .
                            </span>
                            : null
                    }
                </tbody>
            </table>
        </>
    );
};

export default BasicTable;
