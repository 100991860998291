import { $api } from "../../../../../services/request.config";
import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../Requests/currentRequestControls/addBasicInputHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../Requests/currentRequestControls/validationControl.currentRequest";
import { getUserPassportAction } from "../getUserPassport.action";

export const getUserPassportThunkAction = () => async (dispatch) => {
    try {
        const response = await $api.get("/v1.0/c/u/15");
        if (response.status === 200) {
            const passport = `${response.data.data.passport.seria} ${response.data.data.passport.number}`;
            dispatch(
                addBasicInputHandlerCurrentRequest({
                    field: "passportSeries",
                    value: response.data.data.passport.seria,
                }),
            );
            dispatch(validationControlCurrentRequest("passportSeries", true));
            dispatch(
                addBasicInputHandlerCurrentRequest({
                    field: "passportNumber",
                    value: response.data.data.passport.number,
                }),
            );
            dispatch(validationControlCurrentRequest("passportNumber", true));
            return dispatch(getUserPassportAction(passport));
        }
    } catch (error) {
        dispatch(validationControlCurrentRequest("passportSeries", false));
        dispatch(getUserPassportAction("Получение данных завершилось ошибкой"));
        throw new Error(error.response);
    }
};
