import { getAvailableDelegationTypesAction } from "../getAvailableDelegationTypes.action";
import { $api } from "../../../../../services/request.config";

export const getAvailableDelegationTypesThunkAction = (id) => async (dispatch) => {
    try {
        const response = await $api(`v2/manager/substitution-types/${id}/delegation-types`);
        dispatch(getAvailableDelegationTypesAction(response.data));
    } catch (error) {

    }
};