import React from "react";

const HolidaysApplicationNoContent = (
    {
        noContent,
        setNoContent,
    }
) => {
    return (
        <div className="holidays-application-wrapper__no-content">
            <div className="holidays-application-no-content__message">
                нет заявочек для согласования, зайчик {"<3"}
            </div>
            <div
                onClick={() => setNoContent(!noContent)}
                className="holidays-application-no-content__control">
                закрыть
            </div>
        </div>
    );
};

export default HolidaysApplicationNoContent;