export const addUserWidgetsAction = (data)=>{
    return{
        type:"ADD_NEW_WIDGETS",
        payload:data,
    };

};
export const getUserWidgetsAction = (data)=>{
    return{
        type:"GET_USER_WIDGETS",
        payload:data,
    };
};