import BasicApplication from "./Basic.application";

import {
    validationControlCurrentRequest,
} from "../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import { inputValidationInArray } from "../../redux/actions/Requests/currentRequestControls/inputValidationInArray";
import { attachmentsValidation } from "../../redux/actions/Requests/currentRequestControls/attachmentsValidation";

class ParentalLeaveApplication extends BasicApplication {
    constructor(options) {
        super(options);
        this.category = 1;
        this.tabs = options.tabs;
        this.relativeId = options.relativeId;
        this.isSingleParent = options.isSingleParent;
        this.parentsAlrosaStatusId = options.parentsAlrosaStatusId;
        this.cardNumber = options.cardNumber;
        this.accountNumber = options.accountNumber;
        this.bik = options.bik;
        this.passportSeries = options.passportSeries;
        this.passportNumber = options.passportNumber;
        this.parents = options.parents;
        this.children = options.children;
        this.employeeComment = options.employeeComment;
        this.dateTo = options.dateTo;
        this.dateFrom = options.dateFrom;
        this.actualAddress = options.actualAddress;
        this.registrationAddress = options.registrationAddress;
        this.isValid = {
            parents: [],
            children: [],
            actualAddress: true,
            registrationAddress: true,
            relativeId: true,
            isSingleParent: true,
            parentsAlrosaStatusId: true,
            cardNumber: true,
            accountNumber: true,
            bik: true,
            pasportSeria: true,
            pasportNumber: true,
            dateTo: true,
            dateFrom: true,
        };
    }

    static sendToValidation(currentRequest, dispatch) {
        const periodsCheck = true;
        const keysToCheck = {
            // eslint-disable-next-line max-len
            1: ["relativeId", "isSingleParent", "parentsAlrosaStatusId", "cardNumber", "accountNumber", "bik",
                "dateTo", "dateFrom"],

        };

        currentRequest.parents &&
    currentRequest.parents.forEach((element, index) => {
        if (currentRequest.parentsAlrosaStatusId !== 2) {
            if (!element.fullName) {

                dispatch(
                    inputValidationInArray({
                        fieldElement: "fullNameValid",
                        bool: true,
                        indexArray: index,
                        arrayField: "parents",
                    }),
                );

            }
            if (!element.tabNumber) {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "tabNumberValid",
                        bool: true,
                        indexArray: index,
                        arrayField: "parents",
                    }),
                );
            }
        }


    });


        if (currentRequest &&
      currentRequest.attachments &&
      !currentRequest.attachments[1]) {
            if (currentRequest.parentsAlrosaStatusId === 2 || currentRequest.isSingleParent === false) {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "attachNotValid",
                        bool: true,
                        indexArray: 1,
                        arrayField: "attachments",
                    }),
                );
            }

        }

        currentRequest.children &&
    currentRequest.children.forEach((element, index) => {
        if (!element.birthDate) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "birthDateValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.firstName) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "firstNameValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.lastName) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "lastNameValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.middleName) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "middleNameValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.genderId) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "genderIdValid",
                    bool: false,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.birthCertificateNumber) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "birthCertificateNumberValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.birthCertificateDate) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "birthCertificateDateValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.snils) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "snilsValid",
                    bool: true,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (!element.orderId) {
            dispatch(
                inputValidationInArray({
                    fieldElement: "orderIdValid",
                    bool: false,
                    indexArray: index,
                    arrayField: "children",
                }),
            );
        }
        if (element.attachments && !element.attachments[0] || !element.attachments[0].uid) {
            dispatch(attachmentsValidation({ arrIndex: index, attachIndex: 0, falsyAttach: true, arrayName: "children" }));
        }
    });


        return (
            this.keyChecker(
                keysToCheck[1],
                currentRequest,
                dispatch,
            ) && periodsCheck
        );
    }

    static keyChecker(arrayOfKeys, currentRequest, dispatch) {
        let result = 0;
        arrayOfKeys.forEach((e) => {
            if (
                !Array.isArray(e) &&
        (currentRequest[`${e}`] === null ||
          currentRequest[`${e}`] === undefined)
            ) {
                result += 1;
                dispatch(validationControlCurrentRequest(e, false));
            } else if (Array.isArray(e)) {
                const key = [...e].shift();
                for (const bloodKey in currentRequest[key]) {
                    if (
                        currentRequest[key][bloodKey] === null ||
            currentRequest[key][bloodKey] === undefined
                    ) {
                        result += 1;
                        dispatch(
                            validationControlCurrentRequest(
                                { object: key, innerKey: bloodKey },
                                false,
                                "object",
                            ),
                        );
                    }
                }
            }
        });
        return !result;
    }
}

export default ParentalLeaveApplication;
