import {initialState} from "../../init/initialState";

export const experienceDiagramReducer = (state=initialState,action)=>{
    const {type,payload}= action;
    switch (type) {
    case"GET_DATA":
        return payload;
    default:
        return state;
    }
};
