import { mode } from "../../../app/mode";

import { useNavigate } from "react-router-dom";

const NavMenuDiv = (
    {
        title,
        description,
        icon,
        styleParent,
        path,
        secured,
        setErrorModal,
    },
) => {

    const navigate = useNavigate();

    const regExp = /\.svg$/;

    function navHandler(e) {
        // TODO MODE SELECTOR
        if (mode === "PROD") {
            return secured
                ?
                localStorage.getItem("restricted") === "false"
                    ? navigate(path)
                    : setErrorModal(true)
                : navigate(path);
        } else {
            return path ? navigate(path) : null;
        }
    }

    return (
        <div
            className={`${styleParent}__item${path !== "#" ? "" : "-disabled"}`}
            onClick={navHandler}
        >
            <div
                className={`${styleParent}__block-head${
                    path !== "#" ? "" : "-disabled"
                }`}
            >
                {icon ? (
                    regExp.test(icon) ? (
                        <img src={icon} alt="nav-menu-icon" />
                    ) : (
                        <span style={{ background: `${icon}` }}>3</span>
                    )
                ) : null}
                <div>{title}</div>
            </div>
            <div
                className={`${styleParent}__description${
                    path !== "#" ? "" : "-disabled"
                }`}
            >
                {description}
            </div>
        </div>
    );
};

export default NavMenuDiv;
