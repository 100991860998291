// noinspection JSXUnresolvedComponent
import { validationRules } from "./validation.rules";

import Header from "../../../../../layout/Header/Header";
import Footer from "../../../../../layout/Footer/Footer";
import Breadcrumbs from "../../../../../common/Breadcrumbs/Breadcrumbs";
import IC from "../../../../../common/compositions/InputComposition/IC";

import "./AuthorityDelegation.scss";
import "../Delegation.modal.scss";

import {
    getAvailableDelegationTypesThunkAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityDelegation/thunk/getAvailableDelegationTypes.thunk.action";

import SimpleDropdown from "../../../../../common/compositions/InputComposition/dropdown.types/SimpleDropdown/SimpleDropdown";

import SearchDropdown from "../../../../../common/compositions/InputComposition/dropdown.types/SearchDropdown/SearchDropdown";
import {
    getAvailableEmployeesDelegationThunkAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityDelegation/thunk/getAvailableEmployeesDelegation.thunk.action";
import {
    getAvailableSubstitutionTypesThunkAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityDelegation/thunk/getAvailableSubstitutionTypes.thunk.action";
import {
    sendDelegationEmployeeThunkAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityDelegation/thunk/sendDelegationEmployee.thunk.action";

import InputCompositionContext from "../../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import FrontendFormValidationService from "../../../../../../frontend.services/FrontendFormValidation.service";

import OkButton from "../../../../../common/Button/OKButton/OKButton";

import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";

import {
    clearErrorNotificationContentAction,
} from "../../../../../../redux/actions/MyDivision/Delegation/AuthorityDelegation/clearErrorNotificationContent.action";

import DelegationTypesItem
    from "../../../../../common/compositions/InputComposition/dropdownItems.types/DelegationTypesItem/DelegationTypesItem";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";



const AuthorityDelegation = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const availableSubstitutionTypes = useSelector(state => state.myDivision.availableSubstitutionTypes);
    const availableDelegationTypes = useSelector(state => state.myDivision.availableDelegationTypes);
    const availableEmployees = useSelector(state => state.myDivision.availableEmployees);
    const errorNotificationContent = useSelector(state => state.myDivision.errorNotificationContent);

    const [delegationState, setDelegationState] = useState({});
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [notification, setNotification] = useState(false);


    const context = [delegationState, setDelegationState, isError, setIsError];

    function backButtonHandler () {
        navigate("/divisions/structure/delegation");
    }

    function submitHandler () {
        const validation = new FrontendFormValidationService(delegationState, isError, setIsError, validationRules);
        if (validation.validate()) {
            dispatch(sendDelegationEmployeeThunkAction(delegationState, setIsLoading, setModalSuccess, setNotification));
        }
    }

    function closeNotificationHandler () {
        dispatch(clearErrorNotificationContentAction());
        setNotification(false);
    }

    useEffect(() => {
        dispatch(getAvailableSubstitutionTypesThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

    }, [errorNotificationContent]);



    return (
        <>
            <Header />
            <Breadcrumbs />
            <main
                className={"delegation-transfer"}
                style={{minHeight: "35vh", width: "1160px", margin: "0 auto", position: "relative"}}>

                <h3 className={"sub-header"} style={{alignSelf:"flex-start", marginBottom: "44px"}}>
                    передача полномочий руководителя
                </h3>

                <InputCompositionContext.Provider value={context}>

                    <IC field={"substitutionType"} require>
                        <IC.Label
                            value={"Тип замещения"}/>
                        <IC.Input />
                        <IC.Error />
                        <IC.Dropdown>
                            <SimpleDropdown
                                items={availableSubstitutionTypes}
                                // TODO classify injected methods [add interface]
                                injectMethods={[
                                    {
                                        name: "getDelegationEmployees",
                                        call: getAvailableEmployeesDelegationThunkAction,
                                        dispatch, // optional
                                    },
                                    {
                                        name: "getDelegationTypes",
                                        call: getAvailableDelegationTypesThunkAction,
                                        dispatch,
                                    },
                                ]}
                            >
                                <DelegationTypesItem />
                            </SimpleDropdown>
                        </IC.Dropdown>
                    </IC>

                    <IC field={"substitutePid"} require>
                        <IC.Label value={"ФИО замещающего сотрудника"} />
                        <IC.Input />
                        <IC.Error />
                        <IC.Dropdown>
                            <SearchDropdown items={availableEmployees}/>
                        </IC.Dropdown>
                    </IC>

                    <IC field={"delegationType"} require>
                        <IC.Label
                            value={"Тип делегирования"}/>
                        <IC.Input />
                        <IC.Error />
                        <IC.Dropdown>
                            <SimpleDropdown
                                items={availableDelegationTypes}
                            >
                                <DelegationTypesItem />
                            </SimpleDropdown>
                        </IC.Dropdown>
                    </IC>

                    <div style={{display:"flex", gap: "30px"}}>

                        <IC field={"from"}
                            customStyle={"datepicker"}
                            require
                        >
                            <IC.Label value={"Дата начала замещения"} />
                            <IC.DatepickerSelect
                                minDate={new Date()}
                                maxDate={delegationState.to ? new Date(delegationState.to) : null}
                            />
                            <IC.Error />
                            <IC.Description
                                value={"Отметьте справа, в случае бессрочного замещения"}/>
                        </IC>

                        <IC field={"to"}>
                            <IC.Label value={"Дата окончания замещения"} />
                            <IC.DatepickerSelect
                                minDate={delegationState.from ? new Date(delegationState.from) : new Date()}
                                checkbox={true}
                            />
                        </IC>
                    </div>

                    <IC field={"comment"}>
                        <IC.Label value={"Комментарий"}/>
                        <IC.Input/>
                        <IC.Description value={"* - поля обязательные для заполнения"}/>
                    </IC>
                </InputCompositionContext.Provider>

                <div style={{width: "1160px", margin: "60px auto", display: "flex", justifyContent: "space-between"}}>
                    <OkButton title={"назад"} handler={backButtonHandler}/>
                    <OkButton onValidation={isLoading} title={"передать полномочия"} handler={submitHandler}/>
                </div>
                {
                    notification &&
                    errorNotificationContent &&
                    errorNotificationContent.length
                        ? <div className={"delegation-transfer__notification"}>
                            <div onClick={closeNotificationHandler} className={"delegation-transfer__notification__cross"}></div>
                            {errorNotificationContent.map((e, i) => {
                                return (<div key={i}><p>{e}</p> <br/></div>);
                            })}
                        </div>
                        : null
                }

            </main>
            <Footer />
            {/*TODO ADD MODAL VIEW COMPONENT*/}
            <ModalBanOverlay shown={modalSuccess}>
                <div className={"delegation-modal-status"}>
                    <div className={"delegation-modal-status__header sub-header"}>Замещение установлено</div>
                    <div className={"delegation-modal-status__content"}>для
                        <span className={"delegation-modal-status__content__highlight"}>{" "}
                            {
                                availableEmployees &&
                                availableEmployees.find(e => e.pid === delegationState.substitutePid) &&
                                availableEmployees.find(e => e.pid === delegationState.substitutePid).fullName
                            }
                        </span>{" "}
                        <br/>
                        {
                            delegationState &&
                            delegationState.to
                                ? ( <div>
                                    в период
                                    с <span className={"delegation-modal-status__content__highlight"}>
                                        {moment(delegationState.from).format("DD.MM.YYYY")}
                                    </span>
                                    {" "}
                                    по <span className={"delegation-modal-status__content__highlight"}>
                                        {moment(delegationState.to).format("DD.MM.YYYY")}
                                    </span>

                                </div>)
                                : `в период с ${moment(delegationState.from).format("DD.MM.YYYY")}`
                        }
                    </div>
                    <div className={"delegation-modal-status__buttons"}>
                        <OkButton title={"ок"} handler={backButtonHandler} />
                    </div>
                </div>
            </ModalBanOverlay>
        </>
    );
};

export default AuthorityDelegation;

// TODO GET RID FROM UPPER LEVEL STATE