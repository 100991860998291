import { $api } from "../../../../services/request.config";
import { getEmployeeDetailInformationAction } from "../getEmployeeDetailInformation.action";
import ExperienceControlService from "../../../../../frontend.services/Profile/ExperienceControlService";

export const getEmployeeDetailInformationThunkAction =
    (pid) => async (dispatch) => {
        try {
            const response = await $api(`v1/manager/subordinates/${pid}`);
            if (response.status) {
                const temp = { ...response.data };
                if (temp.workExp) {
                    temp.current = ExperienceControlService.calc(temp.workExp);
                }
                dispatch(getEmployeeDetailInformationAction(temp));
            }
        } catch (e) {
            return dispatch(
                getEmployeeDetailInformationAction({
                    error: "Произошла ошибка, повторите попытку позже",
                }),
            );

            // if(e.response.status == 403) {
            //     console.log(e.response.detail)
            //   return   dispatch(getEmployeeDetailInformationAction({error:e.response.detail}))
            // }
            // if(e.response.status == 404) {
            //   return   dispatch(getEmployeeDetailInformationAction({error:"Произошла ошибка, сотрудник не найден"}))
            // }
            // if(e.response.status == 500) {
            //   return    dispatch(getEmployeeDetailInformationAction({error:'Произошла ошибка, повторите попытку позже'}))
            // }
        }
    };
