import {getExpirienceInformationAction} from "../getExpirienceInformationAction";
import $api from "../../../../../services/axiosService";
// import {loginFormData} from "../../../../../../components/common/Auth/LoginForm/loginForm.data";

import moment from "moment";

export const getExpirienceInformationThunkAction = ()=>async(dispatch)=>{

    try {
        //  const res= await $api.get('/v1/u/p/work/exp')
        //  // localStorage.setItem('expirience',JSON.stringify(res.data) )
        //  console.log('expirience',JSON.stringify(res.data))
        //  // const current = res.data
        //  const data = {}
        // const current =  {
        //      "total": {
        //      "years": 0,
        //          "months": 0
        //  },
        //      "rks": {
        //      "years": 0,
        //          "months": 0
        //  },
        //      "company": {
        //      "years": 0,
        //          "months": 0
        //  }
        //  }
        //  function calk (current) {
        //      if (current.total){
        //          data.total = 12
        //          const totalMonth = current.total.years * 12 + current.total.months
        //          if (current.company) {
        //              console.log(current)
        //              const companyMonth = current.company.years * 12 + current.company.months
        //              const companyPercents = companyMonth * 100 / totalMonth
        //              // setPercentCompany(Math.floor(companyPercents))
        //              if (current.rks) {
        //                  const rksMonth = current.rks.years * 12 + current.rks.months
        //                  const rcsPercents = (rksMonth / companyMonth) * companyPercents
        //                  // setPercentRks(Math.floor(rcsPercents))
        //              }
        //
        //          }
        //      }
        //  }




        // const response =  await  $api.get("/v1/u/p/work/exp");


        const work = await $api.get("/v1/u/p/work/in-out");
        const userWork = {
            type:"Дата приема",
            description:`${(work.data.in) ? moment(work.data.in).format("DD.MM.YYYY") : "Отсутствует"}`,
        };
        const userUnWork = {
            type:"Планируемая дата увольнения",
            description: `${(work.data.out) ? moment(work.data.out).format("DD.MM.YYYY") : "Отсутствует"}`,
        };



        dispatch(getExpirienceInformationAction([userWork,userUnWork]));
    }catch (e) {
        // console.log(e.response.data)
    }
};
