import stylesTerminal from "./LoginFormTerminal.module.css";

import { InputTerminal } from "../../componentsTerminal/Input/InputTerminal";
import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";

import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";
import passwordEye from "../../../../assets/icons/authIcons/eye.svg";
import openEyes from "../../../../assets/icons/authIcons/ic_eyes_open.svg";
import InputSelect from "../../Select/InputSelect/InputSelect";
import UButton from "../../Button/UButton/UButton";
import { mode } from "../../../../app/mode";
import { resetErrorLoginMessageAction } from "../../../../redux/actions/Auth/wrongAuthAction";
import { signInWithESIAThunkAction } from "../../../../redux/actions/Auth/Thunk/signInWithESIA.thunk.action";

import { isTerminal } from "../../../../constants";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

import "./LoginForm.scss";

const LoginForm = ({
    email,
    setEmail,
    phone,
    setPhone,
    snils,
    password,
    setPassword,
    setSnils,
    togglePasswordVisiblity,
    passwordShown,
    valid,
    setValid,
    inputSnils,
    handleChangeSnils,
    inputEmail,
    handleChangePhone,
    submitHandler,
    inputPhone,
    clickHandlerForgotPassword,
    clickHandlerSupport,
    openEye,
    changedSnils,
    onChangeValue,
}) => {
    const ref = useRef();
    // const formRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const user = useSelector(state => state.user);
    const authLogin = useSelector((state) => state.authLogin);

    /* TODO: authorization for the PC (desktop) version: feat/add_new_login_input_from_login_page
    const [selectedOption, setSelectedOption] = useState("Выберите ID");
    const [visible, setVisible] = useState(false);
    const [, setSelectedOptionTriangle] = useState(true);

    function visibleSelect() {
        setVisible(!visible);
        setEmail("");
    }
    function optionHandler(e) {
        setVisible(false);
        setSelectedOptionTriangle(true);
        setSelectedOption(e.target.id);
        setSnils("");
        setPhone("");
        setEmail("");
    }
    function clickOutside(e) {
        if (!ref.current?.contains(e.target)) {
            setVisible(false);
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, [selectedOption]);
    */

    const setToValidLabelAndInput = () => {
        setValid(true);
        dispatch(resetErrorLoginMessageAction());
    };


    function navigateToEsia() {
        dispatch(signInWithESIAThunkAction());
    }


    if (!isTerminal) {
        return (
            <>
                <form
                    onSubmit={submitHandler}
                    onClick={setToValidLabelAndInput}
                    className="login">
                    <img className="login__logo" src={logo} alt="login-logo" />
                    <div>
                        <div ref={ref} className="login__wrapper">
                            {
                            //TODO: authorization for the PC (desktop) version: feat/add_new_login_input_from_login_page
                            //authLogin ? (
                            //     <>
                            //         <label
                            //             className="login__wrapper__label-invalid"
                            //             htmlFor="socNumber">
                            //             {authLogin}
                            //         </label>
                            //     </>
                            // ) : (
                            //     <label
                            //         className={
                            //             valid
                            //                 ? "login__wrapper__label"
                            //                 : "login__wrapper__label-invalid"
                            //         }
                            //         htmlFor="socNumber">
                            //         {valid
                            //             ? selectedOption
                            //             : `Введите корректный ${selectedOption}`}
                            //         {/*{selectedOption}*/}
                            //     </label>
                            // )
                            }
                            {/* {
                                //TODO: authorization for the PC (desktop) version: feat/add_new_login_input_from_login_page
                                selectedOption === "Телефон" ? (
                                    <InputMask
                                        className={
                                            (!valid &&
                                                "login__wrapper__input-invalid") ||
                                            (authLogin &&
                                                "login__wrapper__input-invalid") ||
                                            "login__wrapper__input"
                                        }
                                        onClick={setToValidLabelAndInput}
                                        mask="+7\ 999 999 99 99"
                                        ref={inputPhone}
                                        maskplaceholder={"+7 (***) *** - ** - **"}
                                        alwaysShowMask={true}
                                        onChange={handleChangePhone}
                                    />
                                ) : (
                                    <input
                                        className={
                                            (!valid &&
                                                "login__wrapper__input-invalid") ||
                                            (authLogin &&
                                                "login__wrapper__input-invalid") ||
                                            "login__wrapper__input"
                                        }
                                        value={
                                            selectedOption === "E-mail"
                                                ? email
                                                : changedSnils
                                        }
                                        placeholder={
                                            selectedOption === "Выберите ID"
                                                ? "Выберите значение из списка"
                                                : ""
                                        }
                                        disabled={selectedOption === "Выберите ID"}
                                        name={
                                            selectedOption === "СНИЛС"
                                                ? "socNumber"
                                                : "email"
                                        }
                                        onClick={setToValidLabelAndInput}
                                        type={
                                            selectedOption === "СНИЛС"
                                                ? "tel"
                                                : "text"
                                        }
                                        ref={
                                            selectedOption === "СНИЛС"
                                                ? inputSnils
                                                : inputEmail
                                        }
                                        onChange={
                                            selectedOption === "СНИЛС"
                                                ? handleChangeSnils
                                                : (e) => setEmail(e.target.value)
                                        }
                                    />
                                )
                            }
                            <>
                            <img
                                className={
                                    !visible
                                        ? "login__wrapper__img"
                                        : "login__wrapper__img-reverse"
                                }
                                onClick={visibleSelect}
                                src={triangle}
                                alt="triangle"
                            />
                        </>

                            <InputSelect
                            selectRef={ref}
                            visible={visible}
                            styleParent={"login__wrapper__options"}
                            visibleSelect={visibleSelect}
                            selectedOption={selectedOption}
                            choose={optionHandler}
                        /> */}
                            <InputTerminal
                                placeholder="Начните вводить значение"
                                onChange={(e) => setEmail(e.target.value)}
                                label="Телефон/Email/СНИЛС"
                                errorView={!valid && "Некорректные значения"}
                            />
                        </div>
                    </div>
                    <div className="login__wrapper">
                        <label
                            className={
                                authLogin
                                    ? "login__wrapper__label-invalid"
                                    : "login__wrapper__label"
                            }
                            htmlFor="password">
                            Пароль
                            <input
                                className={
                                    (authLogin &&
                                        "login__wrapper__input-invalid") ||
                                    "login__wrapper__input"
                                }
                                maxLength={32}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={passwordShown ? "text" : "password"}
                            />
                            <i
                                className={"login__wrapper__icon"}
                                onClick={togglePasswordVisiblity}>
                                {openEye ? (
                                    <img src={openEyes} alt="openEye" />
                                ) : (
                                    <img src={passwordEye} alt="icon" />
                                )}
                            </i>{" "}
                        </label>
                    </div>
                    <UButton disabled={!password || (!snils && !email && !phone)}>Войти</UButton>
                    <div className="login__wrapper">
                        <div className="login__wrapper__checkbox-text">
                            <div>
                                Еще нет аккаунта?{" "}
                                <span
                                    onClick={() => navigate("/registration")}
                                    className="colored-texts">
                                    Регистрация
                                </span>
                            </div>
                        </div>
                        <div className="login__wrapper__login">
                            <span
                                onClick={clickHandlerForgotPassword}
                                className="colored-texts">
                                Забыли пароль?
                            </span>
                        </div>
                    </div>
                    {/*TODO MODE SELECTOR*/}
                    {mode === "TEST" ? (
                        <div className="login__wrapper">
                            <div className="login__wrapper__checkbox-text">
                                <div>
                                    Войти через{" "}
                                    <span
                                        onClick={navigateToEsia}
                                        className="colored-texts">
                                        Госуслуги(ЕСИА)
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </form>
                <div className="login__support">
                    <span
                        className="colored-texts"
                        onClick={clickHandlerSupport}>
                        Запрос в поддержку
                    </span>
                </div>
            </>
        );
    }

    if (isTerminal) {
        return (
            <>
                <form
                    onSubmit={submitHandler}
                    onClick={setToValidLabelAndInput}
                    className={stylesTerminal.loginTerminal}>
                    <img src={logo} alt="login-logo" style={{width: "50%"}} />
                    <InputTerminal
                        placeholder="Начните вводить значение"
                        onChange={(e) => setEmail(e.target.value)}
                        label="Телефон/Email/СНИЛС"
                        errorView={!valid && "Некорректные значения"}
                    />
                    <InputTerminal
                        placeholder="Введите пароль"
                        onChange={(e) => setPassword(e.target.value)}
                        label="Пароль"
                        type={passwordShown ? "text" : "password"}
                        trailingIcon={
                            <i
                                className={stylesTerminal.wrapperEye}
                                onClick={togglePasswordVisiblity}>
                                {openEye ? (
                                    <img src={openEyes} alt="openEye" />
                                ) : (
                                    <img src={passwordEye} alt="icon" />
                                )}
                            </i>
                        }
                        errorView={(!valid || authLogin) && "Неверный пароль"}
                    />
                    <ButtonTerminal
                        label="Забыли пароль?"
                        variant="text"
                        textAlign="text-start"
                        type="button"
                        onClick={clickHandlerForgotPassword}
                    />
                    <ButtonTerminal
                        label="Войти"
                        disabled={!password || !email}
                    />
                </form>
                <div className={stylesTerminal.loginContainerHelpers}>
                    <div className={stylesTerminal.loginHelpersWrapper}>
                        <span>Еще нет аккаунта?</span>
                        <ButtonTerminal 
                            label="Регистрация"
                            onClick={() => navigate("/registration")}
                            variant="text"
                        />
                    </div>
                    <ButtonTerminal 
                        label="Запрос в поддержку"
                        onClick={clickHandlerSupport}
                        variant="text"
                        textAlign="text-start"
                    />
                </div>
            </>
        );
    }
};

export default LoginForm;
