import { $api } from "../../../../../services/request.config";
import { getInternshipsStructsEmployeesAction } from "../getInternshipsStructsEmployees.action";

export const getInternshipsStructsEmployeesThunkAction =
  (id) => async (dispatch) => {
      try {
          const response = await $api.get(
              `v1/managers/requests/internships/structs/${id}/heads`,
              {
                  params: { onDate: "2023-07-1" },
              },
          );
          if (response.status === 200) {
              const temp = response.data.map((element) => {
                  return {
                      id: element.pid,
                      name: `${element.lastName} ${element.firstName} ${element.secondName} ${element.tabNumber} `,
                  };
              });
              dispatch(getInternshipsStructsEmployeesAction(temp));
          }
      } catch (error) {
          throw new Error(error.response);
      }
  };
