import { $api } from "../../../../services/request.config";
import { getStructureTopAction } from "../getStructureTop.action";

export const getStructureTopThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/structs/top");
        dispatch(getStructureTopAction(response.data));
    } catch (error) {
        // eslint-disable-next-line
        console.log("ERROR WHILE GETTING TOP STRUCTS: ", error.response);
    }
};