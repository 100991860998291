import emptyFileImage from "../../../assets/icons/file.svg";
import uploadImage from "../../../assets/icons/PlusCircle.svg";
import { uploadFileThunkAction } from "../../../redux/actions/Requests/FileUpload/thunk/uploadFile.thunk.action";

import RequestAttachments from "../RequestAttachments/RequestAttachments";

import RequestControlServices from "../../../frontend.services/RequestServices/RequestControlServices";
import {
    saveDraftCurrentRequestThunk,
} from "../../../redux/actions/Requests/currentRequestControls/thunk/saveDraft.currentRequest.thunk";
import { isTerminal } from "../../../constants";

import { ClipLoader } from "react-spinners";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

isTerminal ? import("./CustomUploaderTerminal.scss") : import("./CustomUploader.scss");

const CustomUploader = (
    {
        accept,
    },
) => {

    const dispatch = useDispatch();
    const filePicker = useRef();

    const currentRequest = useSelector(state => state.requests.currentRequest);
    const requestAttachments = useSelector(state => state.requests.requestAttachments);

    const [isUploading, setIsUploading] = useState(false);

    function handlePick(e) {
        // TODO condition isn't consistent
        // TODO NEED CONDITION TO CHECK IS REASON PICKED
        // if (requestState.saveOrUploadValidation(setRequestState, requestState)) {
        if (
            RequestControlServices.saveOrUploadValidation(
                currentRequest,
                dispatch,
            )
        ) {

            if (!currentRequest.uid) {
                dispatch(
                    saveDraftCurrentRequestThunk(
                        currentRequest,
                        null,
                        filePicker,
                        setIsUploading,
                    ),
                );
            } else {
                filePicker.current.click();
            }
        }
    }

    function uploadHandler(e) {
        const formData = new FormData();
        formData.append("files", filePicker.current.files[0]);
        // name of that append file should be "files" otherwise backend can't recognize
        dispatch(
            uploadFileThunkAction({
                uid: currentRequest.uid,
                file: formData,
                setIsUploading,
            }),
        );
    }

    useEffect(() => {
    }, [currentRequest, requestAttachments.length]);

    return (
        <div className="custom-uploader">
            <div className="custom-uploader__upload-field">
                <img src={emptyFileImage} alt="emptyFile" />
                <div>
                    Прикрепить скан (фото) документов <br />
                    <sub>
                        До 50 Мб. форматы:
                        {accept ? accept : " .jpg, .png, .pdf, .docx"}
                    </sub>
                </div>
                <div>
                    {isUploading ? (
                        <ClipLoader size={32} color="#6DCDF6" />
                    ) : (
                        <img
                            className="custom-uploader__img"
                            onClick={handlePick}
                            src={uploadImage}
                            alt="upload"
                        />
                    )}
                    <input
                        onChange={uploadHandler}
                        ref={filePicker}
                        className="custom-uploader__input"
                        type="file"
                        accept={accept ? accept : ".png,.jpg,.jpeg,.pdf,.docx"}
                    />
                </div>
                <div></div>
            </div>

            {currentRequest && <RequestAttachments />}
        </div>
    );
};

export default CustomUploader;
