import $api from "../../../../services/axiosService";
import { getAllReasonTypesAction } from "../getAllReasonTypes.action";

export const getReasonBelongsTypeThunkAction =
    (requestTypeId) => async (dispatch) => {
        try {
            const response = await $api(
                `v1/leaverequest/reason/${requestTypeId}`,
            );
            dispatch(getAllReasonTypesAction(response.data));
        } catch (error) {
            throw new Error(error.response);
        }
    };
