import SingleDateCalendarSelector from "./SingleDateCalendarSelector/SingleDateCalendarSelector";

import { isTerminal } from "../../../../constants";

isTerminal ? import("./SinglePairDatesTerminal.scss") : import("./SinglePairDates.scss");

const SinglePairDates = (
    {
        labelFrom,
        labelTo,
        fieldFrom,
        fieldTo,
    }
) => {

    const container_fix = {
        display:"flex",
        width: "1160px",
        margin: "auto",
        gap:"20px",
    };

    return (

        <div className={isTerminal ? "container": ""} style={!isTerminal ? container_fix : {}}>
            <SingleDateCalendarSelector
                label={ labelFrom ? labelFrom : <>Дата начала <span className="required_text"> *</span></>}
                field={fieldFrom ? fieldFrom :"dateFrom"}/>
            <SingleDateCalendarSelector
                label={labelTo ? labelTo : <>Дата окончания <span className="required_text"> *</span></>}
                field={fieldTo ? fieldTo :"dateTo"}/>
        </div>
    );
};

export default SinglePairDates;
