import {deleteSubstitutionAction} from "../deleteSubstitution.action";
import {$api} from "../../../../services/request.config";

export const deleteSubstitutionThunkAction = ({id, pid}) => async (dispatch) => {
    try {
        const response = await $api.delete(`v1/manager/substitutions/${id}`);
        // console.log("RESPONSE DELETE: ", response)
        if (response.status === 200) dispatch(deleteSubstitutionAction(pid));
    } catch (error) {
        // console.log("ERROR WHILE DELETING REPLACEMENT: ", error.response)
    }
};
