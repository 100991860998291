export const validationSnils = (value) => {
    const uuid = value.slice(0, 9).split("").reverse();
    const controlSumCheck = +value.slice(9);

    const sum = uuid.reduce((acc, current, index) => {
        return (acc += +current * (index + 1));
    }, 0);

    if (sum < 100) return sum === controlSumCheck;
    if (sum === 100 || sum === 101) return !controlSumCheck;
    if (sum > 101) {
        const remainder = sum % 101;
        if (remainder === 100 || remainder === 101) return !controlSumCheck;
        if (remainder < 100) return sum === controlSumCheck;
    }
};
