import $api from "../../../services/axiosService";
import LeavesService from "../../../../frontend.services/Leaves.service";
import {workScheduleAction} from "../workSchedule.action";

export const workScheduleThunkAction = (year,month) => async (dispatch) => {
    try {
        const responseWorkSchedule= await $api(`v1/p/po/work-calendar/${year}/${month}`);
        const responseWorkScheduleForDayPicker= await $api(`v1/p/po/work-calendar/${year}`);
        const responseWorkWidget = await $api("v1/u/p/fixed-term");
        const responseNrd = await $api("v1/u/p/work/irregular");
        function setYears (obj){
            return `${obj.years} ${obj.years<6?"г.":"л. "} ${obj.months<10?"0":""}${obj.months} м.`;
        }
        const data = {
            widgets: [
                {
                    title:responseWorkWidget.data.term ? setYears(responseWorkWidget.data.term) : "Бессрочный",
                    description: "Общий срок контракта",
                },
                {
                    title:`${(responseWorkWidget.data.to) ? new Date(responseWorkWidget.data.to).toLocaleDateString() : "Отсутствует"}`,
                    description: "Дата окончания срочного трудового договора",
                },
                {
                    title:responseNrd.data.isIrregularWork === true ? "Есть" : "Нет",
                    description: "Наличие НРД",
                },
            ],
            workPeriodForDayPicker: await LeavesService.getWorkPeriodForDayPicker(responseWorkScheduleForDayPicker.data)  ,
            workPeriod: await LeavesService.getDayNames(responseWorkSchedule.data),
        };
        return dispatch(workScheduleAction(data));
    } catch (e) {
        // console.log('ERROR WHILE GETTING WORK SCHEDULE',e?.data,e?.data,e?.data)
    }
};
