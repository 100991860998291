import { $api } from "../../../../services/request.config";
import { getPlannedRevocationAction } from "../getPlannedRevocation.action";

export const getPlannedRevocationThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/request/dismissal/rev/planned");
        if (response) {
            dispatch(getPlannedRevocationAction(response.data));
        }
    } catch (error) {
        throw new Error(error.response);
    }
};
