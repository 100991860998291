import {
    getPendingRequestsInfoThunkAction,
} from "../../../../redux/actions/Requests/Division/EmployeesWorkTime/CrossTable/thunk/getPendingRequestsInfo.thunk.action";
import {
    clearPendingRequestInfoAction,
} from "../../../../redux/actions/Requests/Division/EmployeesWorkTime/CrossTable/clearPendingRequestInfo.action";

import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import "./HolidayTableController.scss";

/*
    ==============
    Component that assets selected period to display
    in table with crossings
    Selected periods passing to dispatch that will request data
    ==============
    period format:
    {
      "displayPeriod": "2022",
      "dateFormat": "2022-06-30T16:00:00.000Z"
    }
    ==============
 */


const HolidayTableController = (
    {
        period,
        setPeriod,
    }
) => {
    const dispatch = useDispatch();

    // Array of month indexes
    const months = new Array(12).fill(null).map((e, i) => i);

    const [monthCounter, setMonthCounter] = useState(moment().month());
    const [yearCounter, setYearCounter] = useState(0);

    const notificationBubble = useSelector(state => state.employees.yearBubbleNotification);

    const dataAttributes = {
        past: {
            "list-item": "past",
        },
        cross: {
            "list-item": "cross",
        },
        regular: {
            "list-item": "regular",
        },
        over: {
            "list-item": "over",
        },
    };

    function yearSelection (action) {
        switch (action) {
        case "ADD":
            if (yearCounter !== 1) setYearCounter(prev => prev + 1);
            return true;
        case "SUB":
            if (yearCounter !== -1) setYearCounter(prev => prev - 1);
            return true;
        default: return true;
        }
    }
    function monthSelection (monthIndex) {
        setMonthCounter(monthIndex);
    } // values: -1 0 1
    const getCurrentYear = () => {
        return moment( // PRINT OUT FORMATTED DATE
            moment( // ADD NUMBER OF MONTHS TO CORRECT YEAR
                moment( // GET START OF CORRECT YEAR
                    moment() // GET CORRECT YEAR
                        .add(yearCounter, "year")
                ).startOf("year")
            ).add(monthCounter, "month").toDate()
        )
            .format("YYYY");
    }; // format: "YYYY"

    const getFormattedDate = () => {
        return moment(moment(moment().add(yearCounter, "year")).startOf("year")).add(monthCounter, "month").toDate();
    }; // format: Date()

    useEffect(() => {
        setPeriod({
            displayPeriod: getCurrentYear(),
            dateFormat: getFormattedDate(),
        });
        // CHECK DO WE HAVE NOT SUBMITTED APPLICATIONS
        // IF PREVIOUS OR CURRENT YEAR -> CHECK NEXT YEAR
        // IF NEXT YEAR -> CHECK CURRENT YEAR
        if (yearCounter === -1 || yearCounter === 0) {
            const year = Number(getCurrentYear()) + 1;
            dispatch(getPendingRequestsInfoThunkAction(year, "next"));
        } else if (yearCounter === 1) {
            const year = Number(getCurrentYear()) - 1;
            dispatch(getPendingRequestsInfoThunkAction(year, "prev"));
        }
        return () => {
            dispatch(clearPendingRequestInfoAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthCounter, yearCounter]);



    return (
        <>
            {/*LEGEND*/}
            <div className={"cross-table-controller"}>
                <ul className={"cross-table-controller__legend"}>
                    <li {...dataAttributes["past"]}>
                        Использованные дни
                    </li>
                    <li {...dataAttributes["cross"]}>
                        Пересечения отпусков
                    </li>
                    <li {...dataAttributes["regular"]}>
                        Пересечений нет
                    </li>
                    <li {...dataAttributes["over"]}>
                        Превышения % пересечений
                    </li>
                </ul>
            </div>
            {/*NAVIGATION CONTROLS*/}
            <div
                className={"cross-table-controller__period-control-wrapper"}>
                {/*YEAR CONTROLS*/}
                <div className={"cross-table-controller__year-with-control"}>
                    {/*BUBBLE PREV*/}
                    {
                        notificationBubble
                        && notificationBubble.direction === "prev"
                        && notificationBubble.status &&
                        <div className={"cross-table-controller__year-with-control__bubble__left"}><span></span></div>
                    }
                    <div
                        onClick={() => yearSelection("SUB")}
                        // MINIMUM YEAR RANGE
                        className={yearCounter === -1
                            ? "cross-table-controller__left-arrow-disabled"
                            : "cross-table-controller__left-arrow"}>
                    </div>

                    <span className={"cross-table-controller__year-with-control__selected-year"}>
                        {period.displayPeriod}
                        {/*FORMAT: "YYYY"*/}
                    </span>

                    <div
                        onClick={() => yearSelection("ADD")}
                        // MAXIMUM YEAR RANGE
                        className={yearCounter === 1
                            ? "cross-table-controller__right-arrow-disabled"
                            : "cross-table-controller__right-arrow"}>
                    </div>
                    {/*BUBBLE NEXT*/}
                    {
                        notificationBubble
                        && notificationBubble.direction === "next"
                        && notificationBubble.status &&
                        <div className={"cross-table-controller__year-with-control__bubble__right"}><span></span></div>
                    }
                </div>
                {/*MONTH CONTROLS*/}
                {months.map((monthIndex) => {
                    return (
                        // TODO ADD PAST MONTHS STYLES
                        <div
                            key={`cross-table-controller${monthIndex}`}
                            className={moment(period.dateFormat).month() === monthIndex
                                ? "cross-table-controller__selected-month"
                                : `cross-table-controller__${
                                    moment().year() > moment(period.dateFormat).year() 
                                        ? "past-"
                                        : ""
                                // moment(period.dateFormat).isSameOrAfter(moment().startOf("month"))
                                }month`}
                            onClick={() => monthSelection(monthIndex)}>{
                                moment()
                                    .startOf("year").add(monthIndex, "month")
                                    .format("MMMM")}
                        </div>
                    );
                })}
            </div>
        </>

    );
};

export default HolidayTableController;
