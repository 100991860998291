import {$api} from "../../../../services/request.config";
import {getEmployeeListAction} from "../getEmployeeList.action";

import moment from "moment";

export const getAllEmployeesThunkAction = (setListLoader) => async (dispatch) => {
    try {
        setListLoader(true);
        const response = await $api("v1/manager/subordinates");
        if (response.status === 200) {
            const listData = response.data.map(employee => {
                return {
                    pid: employee.pid && employee.pid,
                    department: employee.structName && employee.structName,
                    name: employee.name && employee.name,
                    position: employee.jobTitle && employee.jobTitle,
                    grade: employee.grade && employee.grade,
                    occupationDate: employee.inDate && moment(employee.inDate).format("DD.MM.YYYY"),
                    birthDate: employee.dateOfBirth && moment(employee.dateOfBirth).format("DD.MM.YYYY"),
                    category: employee.category && employee.category,
                    digitalApproval: employee.documentSignHold
                        ? "Приостановлено"
                        : employee.documentSignType > 0
                            ? "Да" : "Нет",
                    substitutions: employee.substitutions ? [...employee.substitutions] : [],
                };
            });
            dispatch(getEmployeeListAction(listData));
        }
        setListLoader(false);
    } catch (error) {
        setListLoader(false);
        // console.log("ERROR WHILE GET EMPLOYEE LIST: ", error.response)
    }
};
