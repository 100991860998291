import { clearCurrentRequestAction } from "../../CurrentRequest/clearCurrentRequest.action";
import { $api } from "../../../../services/request.config";
import { setCurrentRequestFromClass } from "../setCurrentRequestFromClass";

import {
    DISMISSAL_REVOCATION_SAVE_DRAFT,
    MATERNITY_SAVE_DRAFT, PARENTAL_LEAVE_SAVE_URL,
    STUDY_LEAVE_SAVE_DRAFT,
} from "../../../../enums/Request/applicationUrls";

import moment from "moment";

function applicationUrl(option) {
    switch (option) {
    case 5:
        return STUDY_LEAVE_SAVE_DRAFT;
    case 6:
        return MATERNITY_SAVE_DRAFT;
    case 7:
        return PARENTAL_LEAVE_SAVE_URL;
    case 14:
        return DISMISSAL_REVOCATION_SAVE_DRAFT;
    default:
        return "v1/leaverequest";
    }
}

export const saveDraftCurrentRequestThunk =
  (data, modal, filePicker, setIsUploading) => async (dispatch) => {
      try {
          setIsUploading && setIsUploading(true);
          if (data.dateFrom)
              data.dateFrom = moment(data.dateFrom).format("YYYY-MM-DD");
          if (data.dateTo)
              data.dateTo = moment(data.dateTo).format("YYYY-MM-DD");
          if (data.periods && data.periods[0].dateFrom === null)
              delete data.periods;
          const temp = applicationUrl(data.leaveTypeId);
          const response = await $api.post(`${temp}`, data);


          if (response.data.isSuccess) {

              const request = await $api(
                  `v1/leaverequest/${response.data.uid}`,
              );
              const { item } = request.data;
              dispatch(setCurrentRequestFromClass({ currentRequest: item }));
              filePicker && filePicker.current.click();
              setIsUploading && setIsUploading(false);
              modal && modal(true);
          } else {
              setIsUploading && setIsUploading(false);
              dispatch(clearCurrentRequestAction());
              modal && modal(false);
          }
      } catch (error) {
          setIsUploading && setIsUploading(false);
          modal && modal(false);
          // console.log("ERROR WHILE SAVING DRAFT: ", error)
      }
  };
