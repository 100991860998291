import {
    IMPORTANT_TASK_SAVE_URL, IMPORTANT_TASK_UPDATE_URL,
    UNPAID_PRACTICE_UPDATE_URL,
} from "../../../../enums/Request/applicationUrls";

import { $api } from "../../../../services/request.config";


function applicationUrl(option) {
    switch (Number(option)) {
    case 15:
        return UNPAID_PRACTICE_UPDATE_URL;
    case 16:
        return IMPORTANT_TASK_UPDATE_URL;
    default:
        return "v1/leaverequest";
    }
}

export const updateCurrentLeaderRequestThunkAction =
  (request, modal, option) => async (dispatch) => {
      try {
          const temp = applicationUrl(option);
          const response = await $api.put(`${temp}`, {
              ...request,
              uid: request.uid,
          });
          if (response.status === 200) modal(true);
      } catch (error) {
      }
  };
