import {$api} from "../../../../services/request.config";
// import {getAllReasonTypesAction} from "../../LeaveMetadata/getAllReasonTypes.action";
import {layoffDismissalDocumentObtainingAction} from "../layoffDismissalDocumentObtaining.action";

export const layoffDismissalDocumentObtainingThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/dismissal/document/obtaining");
        if (response.status) {
            dispatch(layoffDismissalDocumentObtainingAction(response.data));
        }

    } catch (e) {
        // console.log('ERROR WHILE GETTING DOCUMENT OBTAINING: ', e?.response)
    }
};
