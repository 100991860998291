import { SET_CURRENT_REQUEST_FROM_CLASS } from "../../../enums/Request/currentRequestControls.types";
import UnplannedVacationApplication from "../../../../frontend.classes/Requests/UnplannedVacation.application";
import WithoutPayApplication from "../../../../frontend.classes/Requests/WithoutPay.application";
import PostponeVacationApplication from "../../../../frontend.classes/Requests/PostponeVacation.application";
import ExtraVacationDaysApplication from "../../../../frontend.classes/Requests/ExtraVacationDays.application";
import LayoffApplication from "../../../../frontend.classes/Requests/Layoff.application";
import ShiftAdditionalRestDaysApplication
    from "../../../../frontend.classes/Requests/ShiftAdditionalRestDays.application";
import MaternityLeaveApplication from "../../../../frontend.classes/Requests/MaternityLeave.application";
import StudyLeaveApplication from "../../../../frontend.classes/Requests/StudyLeave.application";
import DismissalRevocationApplication from "../../../../frontend.classes/Requests/DismissalRevocation.application";

import ParentalLeaveApplication from "../../../../frontend.classes/Requests/ParentalLeave.application";
import ImportantTasksApplication from "../../../../frontend.classes/Requests/nonBasic/ImportantTasks.application";
import UnpaidPracticeApplication from "../../../../frontend.classes/Requests/UnpaidPractice.application";


const dict = {
    1: function(item) {
        return new WithoutPayApplication(item);
    },
    2: function(item) {
        return new UnplannedVacationApplication(item);
    },
    3: function(item) {
        return new PostponeVacationApplication(item);
    },
    5: function(item) {
        return new StudyLeaveApplication(item);
    },
    6: function(item) {
        return new MaternityLeaveApplication(item);
    },
    7: function(item) {
        return new ParentalLeaveApplication(item);
    },
    9: function(item) {
        return new ExtraVacationDaysApplication(item);
    },
    12: function(item) {
        return new LayoffApplication(item);
    },
    14: function(item) {
        return new DismissalRevocationApplication(item);
    },
    13: function(item) {
        return new ShiftAdditionalRestDaysApplication(item);
    },
    15: function(item) {
        return new ImportantTasksApplication(item);
    },
    16: function(item) {
        return new UnpaidPracticeApplication(item);
    },
};


export const setCurrentRequestFromClass = ({ currentRequest }) => {
    const { leaveTypeId } = currentRequest;

    return {
        type: SET_CURRENT_REQUEST_FROM_CLASS,
        payload: dict[leaveTypeId](currentRequest),
    };
};
