import blankList from "../../../assets/icons/NavBlockIcons/blank-list.svg";
import calendar from "../../../assets/icons/NavBlockIcons/calendar.svg";

export const data = [
    {
        title: "Кадровый сервис",
        description:
            "Рабочее время," +
            " командировки," +
            " отпуска," +
            " больничные," +
            " вознаграждения," +
            " льготы," +
            " обучение," +
            " расчетные листки и другое",
        icon: blankList,
        path: "/main/hr-services",
        // secured: true,
    },
    {
        title: "Запрос справок и информации",
        description:
            "Задать вопрос," +
            " получить:" +

            " копии кадровых документов," +
            " справку 2-НДФЛ," +
            " справку с места работы и другие справки",
        icon: calendar,
        path: "/main/references",
    },
    {
        title: "Полезные ссылки",
        description:
            "Корпоративный университет, Карьерный портал, Рейтинг молодых специалистов",
        path: "/main/useful-links",
    },
    {
        title: "Календарь событий",
        description:
            "Предстоящие события:" +
            " обучение, командировки," +
            " отпуска, медицинский осмотр," +
            " замена СИЗ и другие",
        path: "#",
    },
    {
        title: "Общие заявки",
        description:
            "Заказ визитных карточек," +
            " заказ канцтоваров," +
            " заказ пропуска," +
            " заказ транспорта," +
            " заявка на фитнес и другие",
        path: "#",
    },
];
