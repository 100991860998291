class EmployeesService {
    static getStyleBitwise (value) {
        /*
        *   Method that receives bitwise value,
        *   converts it to the 8 bit string representation.
        *   Follows left assignment logic,
        *   returns style name depends on which bit is in place.
        *   Otherwise returns "day-regular" - not styled cell.
        */
        const _bit = (value >>> 0)
            .toString(2)
            .padStart(8, "0")
            .split("")
            .reverse()
            .join("");
        // console.log("BIT: ", _bit);
        const map = {
            0: "day-planned",
            1: "day-planned",
            5: "day-cross",
            6: "day-over",
            7: "day-past",
        };

        for (let flag = _bit.length - 1; flag >= 0; flag -= 1) {
            if (Number(_bit[flag])) {
                return map[flag];
            }
        }
        return "day-regular";
    }
}

export default EmployeesService;