import $api from "../../../../../services/axiosService";

export const createCertificateWhithEsiaThunkAction = ( props,issueId,confirmInformation) => async (dispatch) => {
    // console.log('CONFIRM_INFO: ', props,issueId,confirmInformation)
    try{
        const response = await $api.post(`v1/c/certificates/issues/${issueId}/sign/esia`, {
            snilsNumber: confirmInformation.snils,
            birthDate: confirmInformation.birthDate,
        });

        if(response.status === 200) {
            // const resp = await $api.post(`v1/c/certificates/issues/${issueId}/confirm/esia`)
            // if (resp.status === 200) {
            props.setLoader(false);
            window.open("https://www.gosuslugi.ru/", "_blank");
            return  props.setDescription("Подтвердите выпуск сертификата УНЭП на Госуслугах.");
            // }
        }
    } catch (e) {
        if(e.status === 500) {
            // console.log('createCertificateWhithEsiaThunkActionERROR: ', e.response.data)
            props.setLoader(false);
            return  props.setDescription("Что-то пошло не так, попробуйте подтвердить заявку через СМС",);
        } else if(e.response.data.status === 415) {
            // console.log('createCertificateWhithEsiaThunkActionERROR: ', e.response.data.detail)
            props.setLoader(false);
            return  props.setDescription(e.response.data.detail);
        }
        else  {
            props.setLoader(false);
            return props.setDescription(e.response.data.detail );
            //'Ошибка соединения с удостоверяющим центром.
            // Оформите заявку в службу поддержки пользователей '
        }
    }
};
