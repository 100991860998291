import React from "react";

const ICDescription = (
    {
        value,
        customStyle,
    }
) => {
    return (
        <div className={`input-composition-default__description${
            customStyle
                ? " " + customStyle
                : ""}`}>
            {value}
        </div>
    );
};

export default ICDescription;