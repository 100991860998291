import LayOffRequestPage from "./LayOffRequestPage/LayOffRequestPage";

import DismissalRevocationPage from "./DismissalRevocationPage/DismissalRevocationPage";

import { isTerminal } from "../../../../../constants";
import { layoffCheckValidationThunkAction } from "../../../../../redux/actions/Requests/Layoff/thunk/layoffCheckValidation.thunk.action";
import { clearAttachmentsAction } from "../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import { getCurrentRequestThunkAction } from "../../../../../redux/actions/Requests/CurrentRequest/thunk/getCurrentRequest.thunk.action";
import DropdownMenu from "../../../../common/Menu/DropdownMenu/DropdownMenu";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../../../layout/Header/Header";
import { getTypesForRequestThunkAction } from "../../../../../redux/actions/Requests/Layoff/thunk/getTypesForRequest.thunk.action";
import Footer from "../../../../layout/Footer/Footer";
import RequestButtons from "../../../../common/Button/RequestButtons/RequestButtons";
import EmptyBody from "../../../../common/EmptyBody/EmptyBody";
import { Modal } from "../../../../common/Modal/Modal";
import BackHandlerModal from "../../../../common/Modal/ModalConditions/Requests/BackHandlerModal/BackHandlerModal";
import SaveSuccessModal from "../../../../common/Modal/ModalConditions/Requests/SaveSuccessModal/SaveSuccessModal";
import WarningModal from "../../../../common/Modal/ModalConditions/Layoff/WarningModal/WarningModal";
import ModalBanOverlay from "../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import RequestVerification from "../../../../common/Modal/ModalConditions/Requests/RequestVerification/RequestVerification";
import Error500Modal from "../../../../common/Modal/ModalConditions/Requests/Error500Modal/Error500Modal";
import {
    saveDraftCurrentRequestThunk,
} from "../../../../../redux/actions/Requests/currentRequestControls/thunk/saveDraft.currentRequest.thunk";
import { updateCurrentRequestThunk } from "../../../../../redux/actions/Requests/currentRequestControls/thunk/update.currentRequest.thunk";
import RequestControlServices from "../../../../../frontend.services/RequestServices/RequestControlServices";

import {
    getLatesUnepCertificateThunkAction,
} from "../../../../../redux/actions/User/profile/ElectronicSignature/thunk/getLatesUnepCertificate.thunk.action";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import React, { useEffect, useState } from "react";


const WtLayOffPage = () => {

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const requestTypes = useSelector((state) => state.requests.types);
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const latestCertificate = useSelector(
        (state) => state.personalData.latestCertificate,
    );

    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [onValidation, setOnValidation] = useState(false);

    const [typesListLoader, setTypesListLoader] = useState(false);

    const [requestOption, setRequestOption] = useState(null);

    const [requestStatus, setRequestStatus] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
    const [modalSentStatus, setModalSentStatus] = useState(false);
    const [modalError, setModalError] = useState({
        sign: false,
        validation: false,
        status: false,
    });
    const [modalWarning, setModalWarning] = useState(false);
    const [warningText, setWarningText] = useState("");

    const [isRunningSaveHandler, setIsRunningSaveHandler] = useState(false);
    const [isRunningSendHandler, setIsRunningSendHandler] = useState(false);


    function backHandler(e) {
        currentRequest && currentRequest.leaveReasonId
            ? setModalStatus(true)
            : navigate("/main/hr-services");
    }

    function saveHandler(e) {
        let result = false;
        // check condition that empty request contains required fields
        if (
            RequestControlServices.saveOrUploadValidation(
                currentRequest,
                dispatch,
            )
        ) {
            setModalSuccessStatus(true);
            setIsRunningSaveHandler(true);
            if (!currentRequest.uid) {
                result = dispatch(
                    saveDraftCurrentRequestThunk(
                        currentRequest,
                        setModalSuccessStatus,
                    ),
                );
            } else {
                result = dispatch(
                    updateCurrentRequestThunk(
                        currentRequest,
                        setModalSuccessStatus,
                    ),
                );
            }
        }

        if (result)
            result.then(()=>{
                setIsRunningSaveHandler(false);
            })
        else setIsRunningSaveHandler(false);

    }

    function sendHandler(e) {
        let result = false;
        if (RequestControlServices.sendToValidation(currentRequest, dispatch)) {
            setModalSentStatus(true);
            setIsRunningSendHandler(true);
            result = dispatch(
                layoffCheckValidationThunkAction({
                    currentRequest,
                    requestData: currentRequest,
                    setRequestStatus,
                    stateLoader: setSendButtonLoading,
                    closeModal: setModalSentStatus,
                    setModalError,
                    setModalWarning,
                    setWarningText,
                    setModalSentStatus,
                    latestCertificate,
                }),
            );
        }
        if (result)
            result.then(()=>{
                setIsRunningSendHandler(false);
            })
        else setIsRunningSendHandler(false);
    }

    function selectOptionClick(event, element, stateProperty) {
        setRequestOption(element.id);
    }

    function closeWarningModal() {
        setModalWarning(false);
        // dispatch()
    }

    useEffect(() => {
        dispatch(clearAttachmentsAction());
        dispatch(getTypesForRequestThunkAction(2, { setTypesListLoader }));
        dispatch(getLatesUnepCertificateThunkAction());
        if (queryId)
            dispatch(getCurrentRequestThunkAction(queryId, setRequestOption));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    // console.log('REQUEST_OPTION: ', requestOption)
    return (
        <>
            <Header />
            <Breadcrumbs />
            <DropdownMenu
                optionsLoader={typesListLoader}
                styleName={"types"}
                label={"Заявка на увольнение"}
                initialMenuMessage={"Выберите категорию"}
                listOfOptions={requestTypes}
                stateProperty={"leaveTypeId"}
                currentRequest={currentRequest}
                selectOptionClick={selectOptionClick}
                showBackButton={isTerminal}
            />
            {requestOption === 12 && (
                <LayOffRequestPage
                    setRequestStatus={setRequestStatus}
                    requestStatus={requestStatus}
                    requestOption={requestOption}
                />
            )}
            {requestOption === 14 && (
                <DismissalRevocationPage
                    setRequestStatus={setRequestStatus}
                    requestStatus={requestStatus}
                    requestOption={requestOption}
                />
            )}
            {requestOption === 12 || requestOption === 14 ? (
                <RequestButtons
                    sendHandler={sendHandler}
                    saveHandler={saveHandler}
                    backHandler={backHandler}
                    isRunningSaveHandler={isRunningSaveHandler}
                    isRunningSendHandler={isRunningSendHandler}

                />
            ) : (
                <EmptyBody backHandler={backHandler} />
            )}

            {/*BACK*/}
            <Modal shown={modalStatus} close={setModalStatus}>
                <BackHandlerModal
                    // requestData={requestState}
                    handler={saveHandler}
                    close={setModalStatus}
                />
            </Modal>
            {/*SAVE SUCCESS*/}
            <Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>
                <SaveSuccessModal
                    route={"/main/hr-services"}
                    close={setModalSuccessStatus}
                />
            </Modal>
            {/*REQUEST SEND*/}
            <Modal shown={modalWarning} close={closeWarningModal}>
                <WarningModal
                    close={closeWarningModal}
                    description={warningText}
                />
            </Modal>
            <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                <RequestVerification
                    onValidation={onValidation}
                    unep={true}
                    setOnValidation={setOnValidation}
                    setModalError={setModalError}
                    requestStatus={requestStatus}
                    sendButtonLoading={sendButtonLoading}
                    route={"/main/hr-services"}
                    setModalSentStatus={setModalSentStatus}
                />
            </ModalBanOverlay>
            {modalError.status && (
                <ModalBanOverlay shown={modalError} close={setModalError}>
                    <Error500Modal
                        modalError={modalError}
                        setModalError={setModalError}
                    />
                </ModalBanOverlay>
            )}
            <Footer />
        </>
    );
};

export default WtLayOffPage;
