import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

import React from "react";

const DataModification = () => {
    return (
        <>
            <Header/>
            <div>
                DataModification

            </div>
            <Footer/>
        </>
    );
};

export default DataModification;
