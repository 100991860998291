import OKButton from "../../../../Button/OKButton/OKButton";

import { isTerminal } from "../../../../../../constants";

import React from "react";
isTerminal ? import("./DeleteRequestTerminal.scss") : import("./DeleteRequest.scss");

const DeleteRequest = (props) => {
    return (
        <div className="deleteRequest">
            <div className={"sub-header"}>
                {/*<h1 className={"sub-header"}>*/}
                {props.deleteRequest}
                {/*</h1>*/}
            </div>
            <div>
                <OKButton
                    title={"ок"}
                    handler={props.close} />
            </div>
        </div>
    );
};

export default DeleteRequest;
