import $api from "../../../../services/axiosService";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";
import {setCurrentHrDocsAction} from "../setCurrenrRequest.action";

export const setCurrentHrDocsThunkAction = (packageId, workerId, typeViewTo,props)=>async (dispatch) =>{
    try {
        const response = await $api(`v2/directum/documents/${packageId}/${workerId}`);
        if (response.status === 200){
            // console.log('SET_CURRENT_REQUEST: ',response)
            const dataDocuments = await RequestServices.getOneHrDocument(response.data,typeViewTo,workerId,props);
            dispatch(setCurrentHrDocsAction(dataDocuments));
        }
    }catch (e) {
        props.closeHandlerPackages();
        props.setErrDescription("Что-то пошло не так, повторите попытку позже!");
        props.setErrorModal(true);
        // console.log('setCurrentHrDocsThunkAction ERROR: ', e.response)
    }
};

