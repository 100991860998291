import $apiMultipart from "../../../../services/axiosMultipartService";
import { getAttachmentsAction } from "../../Attachments/getAttachments.action";
import $api from "../../../../services/axiosService";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";

export const uploadFileThunkAction =
  ({ uid, file, setIsUploading }) =>
      async (dispatch) => {
          try {
              setIsUploading && setIsUploading(true);
              const response = await $apiMultipart.post(
                  `v1/leaverequest/upload?&uid=${uid}`,
                  file,
              );
              if (response.data.isSuccess) {
                  const request = await $api(`v1/leaverequest/${uid}`);
                  const attachments = await RequestServices.getAttachmentsWithURL(
                      request,
                  );
                  dispatch(getAttachmentsAction(attachments));
                  setIsUploading && setIsUploading(false);
              }
          } catch (error) {
              setIsUploading && setIsUploading(false);
          }
      };
