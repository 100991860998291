import {
    ADD_ERR_DESCRIPTION_EMPLOYEE_LIST,
} from "../../../enums/MyDivision/EmployeeList/EmployeeListTypes";

export const addErrorDecriptionEmployeeListAction = (message) => {
    return {
        type: ADD_ERR_DESCRIPTION_EMPLOYEE_LIST,
        payload: message,
    };
};
