import
SingleDateCalendarSelectorForUnpaidPractise
    from "./SingleDateCalendarSelectorForUnpaidPractise/SingleDateCalendarSelectorForUnpaidPractise";

import React from "react";

const SinglePairDatesForUnpaidPractise = ({
    labelFrom,
    labelTo,
    fieldFrom,
    fieldTo,
}) => {
    return (
        <div
            style={{
                display: "flex",
                width: "1160px",
                margin: "auto",
                gap: "20px",
            }}
        >
            <SingleDateCalendarSelectorForUnpaidPractise
                label={labelFrom ? labelFrom : "Дата начала *"}
                field={fieldFrom ? fieldFrom : "dateFrom"}
            />
            <SingleDateCalendarSelectorForUnpaidPractise
                label={labelTo ? labelTo : "Дата окончания *"}
                field={fieldTo ? fieldTo : "dateTo"}
            />
        </div>
    );
};

export default SinglePairDatesForUnpaidPractise;
