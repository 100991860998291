import styles from "./ScrollButton.module.css";
import leftArrowIcon from "./LeftArrow.svg";

import React from "react";

const step = 100;
const scrollByY = window.scrollBy.bind(null, 0);

export const ScrollButton = ({ propsClassName, isIframe, iframeRef }) => {
    const isNotPropsNull = !!propsClassName;
    const container = isNotPropsNull ? document.querySelector(propsClassName) : null;
    
    const handlerPressUp = () => {
        scrollByY(-step);
        container && (container.scrollTop -= step);
        isIframe && iframeRef.current && (iframeRef.current.contentWindow.document.documentElement.scrollTop -= step); 
    };

    const handlerPressDown = () => {
        scrollByY(step);
        container && (container.scrollTop += step);
        isIframe && iframeRef.current && (iframeRef.current.contentWindow.document.documentElement.scrollTop += step);
    };

    return (
        <div className={styles.container}>
            <button onClick={handlerPressUp} className={`${styles.button} ${styles["button-up"]}`}>
                <img src={leftArrowIcon} />
            </button>
            <button onClick={handlerPressDown} className={`${styles.button} ${styles["button-down"]}`}>
                <img src={leftArrowIcon} />
            </button>
        </div>
    );
};
