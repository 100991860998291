import { getGeneralDataAction } from "../getGeneralDataAction";
import $api from "../../../../../services/axiosService";

import moment from "moment";

export const getGeneralDataThunkAction = () => async (dispatch) => {
    try {

        const division = await $api.get("/v1.0/c/u/12");
        const appointment = await $api.get("/v1.0/c/u/6");

        const userDivision = {
            type: "Подразделение",
            description: `${division.data.data.appointment[0].structName}`,
        };
        const userAppointment = {
            type: "Должность (по основному месту работы)",
            description: `${division.data.data.appointment[0].position}`,
        };

        const userDopAppointment = {
            type: "Подразделение и должность по совместительству",
            description: `${appointment.data.data.appointment.length === 1 ?
                division.data.data.appointment[0].structName
                : (division.data.data.appointment
                    .map((el) => `${el.structName}: ${el.position}  </br> `).join(""))
            }`,
        };
        const responseEmail = await $api.get("/v1/c/u/2");
        const userEmail = {
            type: "Рабочая эл. почта",
            description: `${responseEmail.data.data.contacts.email}`,
        };
        const userPhone = {
            type: "Рабочий телефон",
            description: responseEmail.data.data.contacts.phoneNumber
                ? `+${responseEmail.data.data.contacts.phoneNumber}`
                : "Отсутствует",
        };
        const category = await $api.get("/v1/u/p/category");//категория
        const grade = await $api.get("/v1/u/p/grade");//грейд
        const userCategory = {
            type: "Категория (РСС, рабочий и другие), грейд / разряд ",
            description: `${category.data.name} ${grade.data.name ? ", " + grade.data.name : ""}`,
        };
        const userTabel = {
            type: "Табельный номер",
            description: `${division.data.data.appointment[0].tabNumber}`,
        };
        const fixedTerm = await $api.get("/v1/u/p/fixed-term");
        const userTerm = {
            type: "Срок срочного трудового договора",
            description: `${(fixedTerm.data.to)
                ? moment(fixedTerm.data.to).format("DD.MM.YYYY")
                : "Отсутствует"}`,
        };
        const probation = await $api.get("/v1/u/p/probation");//испытательный срок
        const userProbation = {
            type: "Дата окончания испытательного срока",
            description: `${probation.data.endTest
                ? moment(probation.data.endTest).format("DD.MM.YYYY")
                : "Отсутствует"}`,
        };
        const lead = await $api.get("/v1/u/p/lead/line");//руководит
        const teamLead = {
            type: "Непосредственный руководитель ",
            description: lead.data && lead.data.firstName
                ? ` ${lead.data.lastName} ${lead.data.firstName}  ${lead.data.secondName} `
                : "Отсутствует",
        };
        // const leadTemp = await $api.get('/v1/u/p/lead/func')
        // const funcLead = {
        //     type: 'Функциональный руководитель ',
        //     description: leadTemp.data.firstName
        //     ?` ${ leadTemp.data.firstName}  ${leadTemp.data.secondName} ${leadTemp.data.lastName} `
        //     : 'Отсутствует'
        // }
        dispatch(getGeneralDataAction([
            userDivision,
            userAppointment,
            userDopAppointment,
            userEmail,
            userPhone,
            userCategory,
            userTabel,
            userTerm,
            userProbation,
            teamLead,
        ]));
    } catch (e) {
        // console.log(e)
    }
};

