import TableQuartHelper from "../../../frontend.services/TableServices/TableQuart.helper";

import React, {useEffect} from "react";

const TableRowQuart = ({parentStyle, data, section, setTooltip}) => {
    // TODO HERE IS SOME BAG WITH WEEKS
    // BECAUSE OF CORRECTNESS OF OUR DESIGN AND THE LIMIT OF TIME DISPLAYED DATA IN CELLS MAY BE WRONG

    // console.log("============>RENDER HOLIDAY ROW QUART<=================")
    // console.log("ROW QUART DATA: ", data)
    // console.log("SECTION: ", section)
    // console.log("NUMBER OF WEEK: ", moment(section.to).format("WW"))

    useEffect(() => {
    }, [data]);


    return (
        <>
            {TableQuartHelper.getFilledRow(data, parentStyle, section, setTooltip)}
        </>
    );
};

export default TableRowQuart;