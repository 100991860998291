import BackButton from "../../../../Button/BackButton/BackButton";
import SaveButton from "../../../../Button/SaveButton/SaveButton";
import { isTerminal } from "../../../../../../constants";

import React from "react";

isTerminal ? import("./BackHandlerModalTerminal.scss") : import("./BackHandlerModal.scss");

const BackHandlerModal = (props) => {
    return (
        <div className="back-handler-modal">
            <div className="back-handler-modal__content">
                <div className="back-handler-modal__content__head">
          Сохранить введенные данные?
                </div>
                <div className="back-handler-modal__content__description">
          В противном случае все несохраненные данные будут утеряны
                </div>
            </div>
            <div className="back-handler-modal__buttons">
                <BackButton route={"/main/hr-services/"} />
                <SaveButton
                    requestData={props.requestData}
                    handler={props.handler}
                    close={props.close}
                    route={"/main/hr-services/"} />
            </div>
        </div>
    );
};

export default BackHandlerModal;
