import React from "react";

const GosUslugi = () => {
    return (
        <div>
            <h1 className={"sub-header"}>GosUslugi</h1>
        </div>
    );
};

export default GosUslugi;
