export const initialState = () => {

    return {
        isAuth: false,
        resetPassword: false,   //true
        user: null,             //true null {userId:6,phoneNumber:'+7926*****12}
        authRegister: null,     //null,//"message", //"message"
        authLogin: null,        //'message'
        authNumber: null,       //false
        wrongRequestResetPassword: null,    //"errorMessage"//errorMessage
        personalData:{},                    // these fields are required for workerProfile
        requests: {
            // these fields are required for request
            userRequests: [],
            requestAttachments: [],
        },
        // leaves: [],
        experienceDiagram: {},
        employees: {
            cross: {
                period: null,
                data: [],
            },
            requests: [],
        },
        documents:{},
        myDivision: {
            employeeList: [],
        },
    };


};
