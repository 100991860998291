export const releaseUnepData = [
    {
        title:"Вид ЭЦП",
        type:"",

    },
    {
        title:"Хранение ЭЦП",
        type:"",

    },
    {
        title:"ФИО",
        type:"",

    },
    {
        title:"Паспортные данные  (серия и номер)",
        type:"",

    },
    {
        title:"Паспортные данные  (дата выдачи)",
        type:"",

    },
    {
        title:"Паспортные данные  (код подразделения)",
        type:"issueOrganizationId",

    },
    {
        title:"Паспортные данные  (орган выдачи)",
        type:"",

    },
    {
        title:"Номер телефона",
        type:"phone",
    },
    {
        title:"ИНН",
        type:"",

    },
    {
        title:"СНИЛС",
        type:"",

    },
    {
        title:"E-mail",
        type:"issueOrganizationId",

    },
    {
        title:"Дата Рождения",
        type:"phone",
    },
];

