export default class ImportantTasksApplication {
    constructor({
        dateFrom,
        dateTo,
        uid,
        consumer = 1,
        description,
        goal,
        assetId,
        structId,
        fundingSource,
        functionMarkerId,
        workers = [],
        attachments = [],
        structName,
    }) {
        this.dateFrom = dateFrom || null;
        this.structName = structName || null;
        this.dateTo = dateTo || null;
        this.uid = uid || null;
        this.consumer = consumer;
        this.description = description || null;
        this.goal = goal || null;
        this.assetId = assetId || null;
        this.structId = structId || null;
        this.fundingSource = fundingSource || null;
        this.functionMarkerId = functionMarkerId || null;
        this.workers = workers;
        this.attachments = attachments;
        this.isValid = {
            dateFrom: true,
            dateTo: true,
            uid: true,
            description: true,
            goal: true,
            assetId: true,
            structId: true,
            fundingSource: true,
            functionMarkerId: true,
            workers: true,
        };
    }

}
