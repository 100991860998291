import filterArrow from "./filterArrow.svg";

import HolidaysFilterWindow from "./HolidaysFilterWindow/HolidaysFilterWindow";

import useComponentVisible from "../../../../hooks/useComponentVisible/useComponentVisible";

import { useRef, useState} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import "./HolidayEmployeeFilter.scss";


export const HolidaysEmployeeFilter = (
    {
        startOfMonth,
        endOfMonth,
    }
) => {

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const portalRef = useRef(null);
    const [
        ref,
        isComponentVisible,
        setIsComponentVisible,
        // forceClose,
    ] = useComponentVisible(false, { portalRef });

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: {
            name: "offset",
            options: {
                offset: [230, 15],
            },
        },
        placement: "bottom",
    });



    return (
        <div ref={ref}>
            <div>
                {/*<button onClick={() => forceClose()}>CLOSE</button>*/}
                <div
                    onClick={() => setIsComponentVisible(prev => !prev)}
                    ref={setReferenceElement}
                >
                    <span
                        style={isComponentVisible ? {fontWeight:"500"} : {}}
                    >
                        сотрудник
                    </span>
                    <img src={filterArrow} alt="filter" />
                </div>
            </div>
            <div>
                {
                    isComponentVisible
                    && createPortal(
                        <div
                            ref={setPopperElement}
                            style={{...styles.popper}}
                            {...attributes.popper}
                        >
                            <HolidaysFilterWindow
                                startOfMonth={startOfMonth}
                                endOfMonth={endOfMonth}
                                setIsComponentVisible={setIsComponentVisible}
                                ref={portalRef}/>
                        </div>,
                        document.querySelector("#popup"))
                }
            </div>
        </div>
    );
};
