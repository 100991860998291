import $api from "../../../../../services/axiosService";

export const approveCertificateBySmsThunkAction = (code,issueId,props) => async (dispatch) => {
    try {
        props.setLoader(true);
        const response = await $api.post(`v1/c/certificates/issues/${issueId.issueId}/confirm/sms/${code}`);
        switch (response.status) {
        case 200:
            props.setPhoneToggle(false);
            props.setLoader(false);
            return  props.setDescription("Сертификат УНЭП будет выпущен в течении минуты");
        default: return true;
        }
    } catch (e) {
        // console.log('approveCertificateBySmsThunkActionERROR: ', e.response)
        props.setLoader(false);
        return props.setDescription(e.response.data.detail);
    }
};
