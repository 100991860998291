import React  from "react";

const RequestInput = (
    {
        parentStyle,
        label,
        value,
        changeHandler,
        maxLength,
        stateProperty,
        basicInput,
        errorMessage,
        helper,
        // type,
        letter,
        // index,
        // field,
        // uploader,
    },
) => {
    // const dispatch = useDispatch();
    const area = document.getElementById(`area-${stateProperty}`);
    const count = document.querySelector(`.count-${stateProperty}`);

    function countLetters() {
        const textlength = area?.value.length; // #2
        count.innerText = `${textlength}`; // #3
        const myElement = document.getElementById(`count-${stateProperty}`);
        if (count && count.innerText > 250) {
            myElement.style.color = "red";
        } else {
            myElement.style.color = "black";
        }
    }

    // const [input, setInput] = useState(value ? value : "");


    return (
        <div className={`${parentStyle}-comment__container`}>
            <label
                htmlFor="comment"
                className={`${parentStyle}-comment__label`}
            >
                {label}
            </label>
            <input
                name="comment"
                value={value ? value : ""}
                id={`area-${stateProperty}`}
                onChange={changeHandler}
                maxLength={maxLength ? maxLength : null}
                onKeyUp={countLetters}
                className={
                    !errorMessage
                        ? `${parentStyle}-comment`
                        : `${parentStyle}-commentError`
                }
                type={"text"}
            />
            {errorMessage ? (
                <div className="date-popup-selector__error-message">
                    {errorMessage}
                </div>
            ) : (
                <div className="date-popup-selector__buffer"></div>
            )}
            {helper && (
                <p className={`${parentStyle}-comment__underline`}>{helper}</p>
            )}
            {!basicInput && (
                <p className={`${parentStyle}-comment__underline`}>
          * Поля обязательные для заполнения
                </p>
            )}
            <div className={`${parentStyle}-comment__container__counter`}>
                <span
                    id={`count-${stateProperty}`}
                    className={`count-${stateProperty}`}
                >
                    0
                </span>{" "}
        / {/*<span className="count">*/}
                {letter}
                {/*</span>*/}
                {/*{letterCount} / {letter}*/}
            </div>
        </div>
    );
};

export default RequestInput;
