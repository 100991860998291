import {initialState} from "../../init/initialState";
import {ADD_USER_STATE, SET_USER_LOGIN, UNSET_USER} from "../../enums/AuthType";

export const authUserReducer = (state =initialState,action)=>{
    const {type,payload} = action;
    switch (type) {
    case ADD_USER_STATE:
        return payload;
    case SET_USER_LOGIN:
        return payload;
    case UNSET_USER:
        return null;
    case "IS_LEADER":
        return {...state, isLeader:payload};
    case "WORK_SIGN_TYPE":
        return {...state, workSignType:payload};
    default:
        return state;
    }
};
