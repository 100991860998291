import { $api } from "../../../../services/request.config";

export const getActualDocumentsThunkAction = () => async (dispatch) => {
    try {
        if (localStorage.getItem("agreement") > 0) {
            if (localStorage.getItem("documentSignType") > 0) {
                const actualDocuments = await $api.get(
                    "v2/directum/documents/actual",
                );
                localStorage.setItem(
                    "hasActualDocuments",
                    actualDocuments.data.hasActualDocuments,
                );
            }
        }
    } catch (error) {
        localStorage.setItem("hasActualDocuments", "false");
        throw new Error(error.response);
    }
};
