import {initialState} from "../../init/initialState";

export const authRegisterReducer = (state = initialState, action) => {
    switch (action.type) {

    case "WRONG_REGISTRATION":
        return action.payload;
    case "RESET_ERROR":
        return action.payload;

    default: return state;
    }
};
