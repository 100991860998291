import UButton from "../UButton/UButton";

import { isTerminal } from "../../../../constants";

import React from "react";
import {useNavigate} from "react-router-dom";

isTerminal ? import("./CustomButtonTerminal.scss") : import("./CustomButton.scss");

const CustomButton = (props) => {
    const navigate = useNavigate();

    function clickHandler(e) {
        e.preventDefault();
        props.errorModal && props.errorModal(prev => {
            return {status: false, sign: false, validation: false};
        });
        props.modal && props.modal(false);
        props.route && navigate(props.route);
    }

    return (
        <UButton
            onClick={props.onValidation
                ? null
                : clickHandler}
            className={` 
            ${
        props.danger && "ui-custom-button__danger " ||
                props.regular && "ui-custom-button__regular "
        }
            ${
        props.onValidation && " ui-custom-button__disabled"
        }`
            }>
            {props.title}
        </UButton>

    );
};

export default CustomButton;