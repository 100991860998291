import $api from "../../redux/services/axiosService";
import { statusData } from "../../components/common/Documents/Applications/status.data";

import moment from "moment";

// import moment from "moment";

// or, with the number of recurrences instead of an end date:
// period = createPeriod({ start, duration, recurrence: 3 });

// or, with a string formatted as an ISO 8601 repeating interval:
// period = createPeriod({ iso: 'R3/2014-01-01T00:00:00Z/P1D' })
const FileSaver = require("file-saver");

class RequestServices {
    static async getAttachmentsWithURL(request) {

        const filesArray = request.data.item.attachments;
        const attacments = [];
        for (let i = 0; i < filesArray.length; i += 1) {
            const file = await $api(
                `v1.1/leaverequest/file/${filesArray[i].leaveRequestUid}/${filesArray[i].id}`,
                { responseType: "blob" },
            );
            const blobLink = URL.createObjectURL(new Blob([file.data], { type: `${file.headers["content-type"]}` }));
            attacments.push({ ...filesArray[i], blobLink });
        }
        return attacments;
    }

    static async getAttachmentsWithURLCARD(att) {

        const filesArray = att;
        const attacments = [];
        for (let i = 0; i < filesArray.length; i += 1) {
            const file = await $api(
                `v1.1/leaverequest/file/${filesArray[i].leaveRequestUid}/${filesArray[i].id}`,
                { responseType: "blob" },
            );
            const blobLink = URL.createObjectURL(new Blob([file.data], { type: `${file.headers["content-type"]}` }));
            attacments.push({ ...filesArray[i], blobLink });
        }
        return attacments;
    }

    static attachmentNameDecode(url) {
        const regex = "(?<=(fileName%3D))(.*)";
        const fileName = decodeURI(url.match(regex)[2].replaceAll("+", " "));
        if (fileName.length > 40) {
            return {
                shortName: fileName.slice(0, 30) + "..." + fileName.slice(-8),
                fullName: fileName,
            };
        }
        return fileName;
    }

    static async getUserRequestsWithFile(requests) {
        for (let i = 0; i < requests.length; i += 1) {
            requests[i].typeText = "Заявка";

            requests[i].leaveReasonId = requests[i].leaveTypeName || "...";
            requests[i].statusText =
        statusData
            .find(e => e.id === requests[i].status) ? statusData.find(e => e.id === requests[i].status).name
            : "...";
            requests[i].typeDate = new Date(requests[i].createDate).getTime();
            requests[i].typeName = requests[i].leaveReasonId;
            requests[i].typeViewTo = "...";
            requests[i].typeSignTo = "...";
            requests[i].typeStatus = requests[i].statusText;
            if (requests[i].applicationDocumentAttachment) {
                requests[i].fileName = this.attachmentNameDecode(requests[i].applicationDocumentAttachment.url);
            }
        }
        // console.log("REQUESTS: ", requests[0])
        return requests;
    }

    static async getHrRequestsWithFile(requests) {
        const date = new Date();
        const nowUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
            date.getUTCDate(), date.getUTCHours(),
            date.getUTCMinutes(), date.getUTCSeconds());
        for (let i = 0; i < requests.length; i += 1) {
            requests[i].typeText = "Кадровый документ";
            requests[i].isSignRequired
                ? (!requests[i].isSigned
                    ? requests[i].statusText = "Не подписан"
                    : requests[i].statusText = "Подписан")
                : (requests[i].isViewed
                    ? requests[i].statusText = "Ознакомлено"
                    : requests[i].statusText = "Не ознакомлено");
            requests[i].typeName = requests[i].name;
            requests[i].typeDate = new Date(requests[i].eventStartDate).getTime();
            // requests[i].typeViewTo = new Date(requests[i].eventStartDate).toLocaleDateString();
            requests[i].typeSignTo = requests[i].extra.signDateUtc
                ? new Date(requests[i].extra.signDateUtc).toLocaleDateString()
                : "...";
            requests[i].fileName = "Document file.docx";
            requests[i].typeStatus = requests[i].statusText;
            if (requests[i].extra.signDateUtc == null || !requests[i].extra.signDateUtc) {
                if (requests[i].extra.isOldPackage === true) {
                    requests[i].typeViewTo = new Date(requests[i].eventStartDate).toLocaleDateString();
                    if (new Date(requests[i].eventStartDate).getTime() < new Date(nowUtc).getTime()) {
                        if (requests[i].isViewed === false) {
                            requests[i].typeColor = "expired";
                        }
                        if (requests[i].isSignRequired) {
                            if (requests[i].isSigned === false) {
                                requests[i].typeColor = "expired";
                            }
                        }
                    }
                } else {
                    requests[i].typeViewTo = "Рассчитывается";
                }
            } else {
                requests[i].typeViewTo = new Date(requests[i].extra.signDateUtc).toLocaleDateString();
                if (requests[i].extra.isSignDateExpired === true) {
                    if (requests[i].isViewed === false) {
                        requests[i].typeColor = "expired";
                    }
                    if (requests[i].isSignRequired) {
                        if (requests[i].isSigned === false) {
                            requests[i].typeColor = "expired";
                        }
                    }
                }
            }
            if (
                requests[i].statusText === "Подписать в ОЦО" ||
        requests[i].statusText === "Не подписан" ||
        requests[i].statusText === "Не ознакомлено"
            ) {
                requests[i].typeWeight = "active";
            }
            if (!requests[i].typeViewTo) {
                requests[i].typeViewTo = " ";
            }
        }
        return requests;
    }

    static async getHrReferencesWithFile(requests) {
        for (let i = 0; i < requests.length; i += 1) {
            requests[i].typeText = "Справка";
            requests[i].typeDate = new Date(requests[i].submittedDate).getTime();
            requests[i].typeName = requests[i].type;
            requests[i].typeViewTo = "...";
            requests[i].typeSignTo = "...";
            requests[i].hasFile === true
                ? (requests[i].fileName = "Document file.docx")
                : (requests[i].fileName = "...");
            requests[i].typeStatus = requests[i].status;
        }
        return requests;
    }

    static async getLeaderRequestsWithFile(requests) {
        for (let i = 0; i < requests.length; i += 1) {
            requests[i].typeText = "Заявка";
            requests[i].typeDate = new Date(requests[i].createDate).getTime();
            requests[i].leaveReasonId = requests[i].leaveTypeName || "...";
            requests[i].statusText =
        statusData
            .find(e => e.id === requests[i].status)
            ? statusData.find(e => e.id === requests[i].status).name
            : "...";
            requests[i].typeName = requests[i].leaveReasonId;
            requests[i].typeDate = new Date(requests[i].createDate).getTime();
            requests[i].typeViewTo = new Date(requests[i].expireApprovalDate).toLocaleDateString();
            requests[i].typeSignTo = "...";
            requests[i].typeStatus = requests[i].statusText;
            if (requests[i].applicationDocumentAttachment) {
                requests[i].fileName = this.attachmentNameDecode(requests[i].applicationDocumentAttachment.url);
            }
            if (new Date(requests[i].expireApprovalDate) < new Date()) {

                requests[i].expired = requests[i].status === 5 || requests[i].status === 12 ? null : true;
            }
        }
        return requests;
    }

    static async downloadRequestFile(element, uid, id, url) {
        if (element.uid) {
            const newFile = await $api(`v1/requests/attachments/${uid}`);
            const blobLink = URL.createObjectURL(
                new Blob([newFile.data], { type: `${newFile.headers["content-type"]}` }),
            );
            FileSaver.saveAs(blobLink, element.fileName);
        } else {
            const file = await $api(`v1.1/leaverequest/file/${uid}/${id}`, { responseType: "blob" });
            const blobLink = URL.createObjectURL(
                new Blob([file.data], { type: `${file.headers["content-type"]}` }),
            );
            FileSaver.saveAs(blobLink, this.attachmentNameDecode(url));
        }
    }

    // TODO DELETE ASAP
    static async appFileDownloadTEMPORARYmethod(uid, id, url) {
        const file = await $api(`v1.1/leaverequest/file/${uid}/${id}`, { responseType: "blob" });
        const blobLink = URL.createObjectURL(
            new Blob([file.data], { type: `${file.headers["content-type"]}` }),
        );
        FileSaver.saveAs(blobLink, this.attachmentNameDecode(url));

    }

    static async downloadReferenceFile(url, name) {
        try {
            const file = await $api(`v1/itsm/hr/get/${url}/file`, { responseType: "blob" });
            const blobLink = URL.createObjectURL(
                new Blob([file.data], { type: `${file.headers["content-type"]}` }),
            );
            // console.log("BLOB LINK: ", blobLink);
            window.open(blobLink, "_blank");
            // FileSaver.saveAs(blobLink, name);
        } catch (e) {
            // console.log('EEROR_WHITH_GETTING_FILE: ', e.response)
        }
    }

    static async getOneRequest(requests, worker) {
    // console.log(requests)
        const request = {};
        request["дата и время заявки"] = new Date(requests.createDate).toLocaleDateString();
        request["структурное подразделение"] = requests.departmentName;
        request["должность"] = requests.jobTitle;
        request["дата начала"] = requests.dateFrom ? new Date(requests.dateFrom).toLocaleDateString() : "...";
        request["дата окончания"] = requests.dateTo ? new Date(requests.dateTo).toLocaleDateString() : "...";
        request["комментарий"] = requests.employeeComment ? requests.employeeComment : "...";
        request["Наименование"] = requests.leaveTypeName || "...";
        request["причина"] = requests.leaveReasonName || "...";
        if (worker) {
            request["статус рассмотрения"] = statusData
                .find(e => e.id === requests.status)
                ? statusData.find(e => e.id === requests.status).name
                : "...";
        }
        if (requests.leaveTypeId === 12) {
            request["Дата увольнения"] = requests.dismissalDate
                ? moment(requests.dismissalDate).format("DD.MM.YYYY")
            // new Date(requests.dismissalDate).toLocaleDateString()
                : "...";
            request["Причина принятого решения"] = requests.dismissalMotives
                ? requests.dismissalMotives.map(el => el.name).join(", ")
                : "...";

        }
        if (requests.internshipTypeId) {
            request["Полное наименование ВУЗа"] = requests.university
                ? requests.university
                : "...";
            request["Наименование факультета"] = requests.faculty
                ? requests.faculty
                : "...";
            request["Наименование кафедры"] = requests.chair
                ? requests.chair
                : "...";
            request["Номер курса"] = requests.courseNumber
                ? requests.courseNumber
                : "...";
            request["ФИО студента"] = requests.students.length
                ? requests.students.map(el => el.fullName).join(", ")
                : "...";
            request["Руководитель практики в подразделении"] = requests.head
                ? requests.head
                : "...";
            request["ФИО руководителя практики от ВУЗа"] = requests.contactPerson
                ? requests.contactPerson
                : "...";
        }
        return request;
    }

    static getOneReference(requests, workorderid) {
        const oneReferense = requests.find(el => el.workorderid === workorderid);
        const request = {};
        if (oneReferense) {
            request["дата и время заявки"] = oneReferense.date ? new Date(oneReferense.date).toLocaleString() : "...";
            request["Наименование"] = oneReferense.type;
            request["Номер заявки"] = `${workorderid}`;
            request["статус рассмотрения"] = oneReferense.status;
            request["комментарий"] = oneReferense.submittedComment ? oneReferense.submittedComment : "...";
            if (oneReferense.hasFile === true) {
                request["документы"] = "Document file.docx";
            }
            return request;
        }
    }

    static async downloadAttachmentsfilesInCard(request) {
        const arr = [];
        if (request.attachments.length !== 0) {
            if (request.internshipTypeId) {
                const arrayOfAttachments = request.attachments;
                request.students.map((el) =>
                    el.attachments.map((element) => arrayOfAttachments.push(element)),
                );
            } else {
                for (let i = 0; i < request.attachments.length; i += 1) {
                    // console.log(request.attachments[i].leaveRequestUid, request.attachments[i].id)
                    const file = await $api(
                        `v1.1/leaverequest/file/${request.attachments[i].leaveRequestUid}/${request.attachments[i].id}`,
                        { responseType: "blob" },
                    );
                    const blobLink = URL.createObjectURL(
                        new Blob([file.data], { type: `${file.headers["content-type"]}` }),
                    );
                    const fileName = this.attachmentNameDecode(request.attachments[i].url);
                    arr.push({ ...request.attachments[i], blobLink, fileName });
                }
                request.attachmentsArray = arr;
            }
        } else {
            request.attachmentsArray = [];
        }
        return request;
    }

    static async getOneHrDocument(request, typeViewTo, workerId, props) {
        request.workerId = workerId;
        request.typeText = "Кадровый документ";
        request.docsAttacments = [];
        if (request.documents.length) {
            for (let i = 0; i < request.documents.length; i += 1) {
                request.docsAttacments.push({
                    nameDoc: request.documents[i].name,
                    docId: request.documents[i].docId,
                    // blobLink:blobLink,
                });
                request.isSignRequired
                    ? (localStorage.getItem("workerSignType")
                        ? request.statusText = "Подписать в ОЦО"
                        : request.statusText = "Не подписан")
                    : (request.isViewed
                        ? request.statusText = "Ознакомлено"
                        : request.statusText = "Не ознакомлено");

                // request.statusText = "Ознакомлен"
                // if (request.documents[i].isSignRequired) {
                //     if (!request.documents[i].isSigned) {
                //         request.statusText = "Не подписан"
                //
                //         if (request.documents[i].signatureType?.toLowerCase() === 'NotEndorsing'.toLowerCase()) {
                //             request.statusText = "На доработке"
                //         } else {
                //             if (request.documents[i].edsType.toLowerCase() === "SES".toLowerCase()) {
                //                 if (request.documents[i].isSignedByCompany || !request.documents[i].isCompanySignRequired ){
                //                     if (Number(localStorage.getItem('signType') ?? 0) < 1 ){
                //                         request.documents[i].descriptionLabel = "Необходимо подойти в опер.отдел ОЦО для подписи"
                //                     }
                //                 } else {
                //                     request.documents[i].descriptionLabel= "Необходимо подойти в опер.отдел ОЦО для подписи"
                //                 }
                //             } else {
                //                 request.documents[i].descriptionLabel = "Необходимо подойти в опер.отдел ОЦО для подписи"
                //             }
                //         }
                //     } else {
                //         if (request.documents[i].signatureType?.toLowerCase() === 'Endorsing'.toLowerCase()) {
                //             if (request.documents[i].signatoryId < 0) {
                //                 request.statusText = "Подписан ЭЦП (Автоподписание)"
                //             } else {
                //                 request.statusText = "Подписан ЭЦП"
                //             }
                //         } else {
                //             request.statusText = "Подписан"
                //         }
                //     }
                // } else {
                //     if (!request.documents[i].isViewed ){
                //         request.statusText = "Не ознакомлен"
                //     }
                // }
            }
            // return {request:request}
        }
        const obj = {};
        obj["тип"] = request.documents[0].kind;
        obj["Комментарий ОЦО"] = request.comment ? request.comment : "";
        obj["вид"] = request.packageKind;
        obj["Наименование"] = request.packageName;
        obj["статус"] = request.statusText;
        obj["дата поступления"] = new Date(request.documents[0].eventStartDate).toLocaleDateString();
        obj["ознакомиться до"] = typeViewTo;
        // obj[ 'исходная заявка'] = '---'
        // obj[ 'Документ основание'] = '---'


        return { request: request, requestCard: obj };
    }


}


export default RequestServices;
