import $api from "../../../../services/axiosService";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";
import { getLeaderRequestsAction } from "../getLeaderRequests.action";

export const getLeaderRequestsThunkAction = (props) => async (dispatch) => {
    try {
        const response = await $api("v1/leaverequest/linemanager/all");
        if (response.data.isSuccess) {
            const data = await RequestServices.getLeaderRequestsWithFile(
                response.data.items,
            );
            dispatch(getLeaderRequestsAction(data));
        }
    } catch (e) {
        props.setErrDescription(
            "Что-то пошло не так, повторите попытку позже.",
        );
        props.setErrorModal(true);
        // console.log(e.response)
    }
};
