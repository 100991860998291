import webStyles from "./ChangeMonths.module.css";
import terminalStyles from "./ChangeMonthsTerminal.module.css";

import backArrow from "../../../../assets/icons/BackArrow.svg";
import { isTerminal } from "../../../../constants";

import { useState, useEffect } from "react";
import moment from "moment";

export const ChangeMonths = ({ minDate = null, maxDate = null , date, onChange, disabled }) => {
    const styles = isTerminal ? terminalStyles : webStyles;
    
    const [currentDate, setCurrentDate] = useState(date);
    const isMaxDate = maxDate && moment(maxDate.endOf("week")).add(-1, "week").isSameOrBefore(currentDate);
    const isMinDate =  minDate && moment(minDate.startOf("week")).add(1, "week").isSameOrAfter(currentDate);

    const handlerBack = () => {
        setCurrentDate(moment(currentDate.add(-1, "months")));
    };

    const handlerForth = () => {
        setCurrentDate(moment(currentDate.add(1, "months")));
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onChange?.(currentDate);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [currentDate]);

    return (
        <div className={styles.container}>
            <button disabled={disabled || isMinDate} className={styles.button} onClick={handlerBack}>
                <img src={backArrow} />
            </button>
            <span className={styles.title}>{currentDate.format("MMMM YYYY")}</span>
            <button className={styles.button} disabled={disabled || isMaxDate} onClick={handlerForth}>
                <img className={styles.arrowForth} src={backArrow} />
            </button>
        </div>
    );
};