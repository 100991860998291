import ResetNewPassword from "../../common/Auth/ResetNewPassword/ResetNewPassword";
import {Modal} from "../../common/Modal/Modal";
import ModalSupport from "../../common/Auth/ModalSupport/ModalSupport";
import { isTerminal } from "../../../constants";


import React, {useState} from "react";

// import styles from "../LoginPage/style.module.css";
// TODO: import terminal version styles
import "../LoginPage/LoginPage.scss";
import "./ResetNewPasswordPage.css";
import "../LoginPage/LoginPage.scss";


const ResetNewPasswordPage = () => {
    const [modalShownSupport, toggleModalSupport] = useState(false);

    function clickHandlerSupport(e) {
        toggleModalSupport(!modalShownSupport);
    }
    function closeHandlerSupport(e){
        toggleModalSupport(false);
    }

    return (
        <div className={isTerminal ? "login-container-terminal" :"login-container"}>
            <div className={isTerminal ? "login-wrapper-terminal" :"login-wrapper"}>
                <ResetNewPassword
                    clickHandlerSupport={clickHandlerSupport}

                />
            </div>
            {!isTerminal && <div className={"login-img-auth"}></div>}
            {isTerminal && <img style={{height: "100vh"}} src={require("../../../assets/authPhoto/photo.png")} alt="" />}


            <Modal
                shown={modalShownSupport}
                close={closeHandlerSupport}
            >
                <ModalSupport close = {closeHandlerSupport}/>
            </Modal>
        </div>
    );
};

export default ResetNewPasswordPage;
