import { useInputCompositionContext } from "../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import React, { useRef, useState } from "react";

const ICTextAreaVerticalGrow = (
    {
        field,
    }
) => {

    const [,setDelegationState,isError] = useInputCompositionContext();

    const ref = useRef(null);
    const [charCounter, setCharCounter] = useState(ref && ref.current ? ref.current.innerText.length : 0);

    // console.log("REF: ", ref && ref.current && ref.current.innerText.length > 0 ? ref.current.innerText.length : 0);
    // console.log("INNER TEXT: ", ref && ref.current && ref.current.innerText === "");

    return (

        <div style={
            {
                position: "relative",
            }
        }>
            <div
                // onChange={(event) => setCharCounter(event.target.value)}
                onInput={(event) => {
                    // console.log(event.target.innerText === "");
                    setDelegationState(prev => {
                        return {
                            ...prev,
                            [field]: event.target.innerText,
                        };
                    });
                    if (event.target.innerText.length === 0 || event.target.innerText === "\n") {
                        event.target.innerText = "";
                        setCharCounter(0);
                    } else {
                        setCharCounter(ref.current.innerText.length);
                    }
                }}
                ref={ref}
                style={
                    {
                        marginTop: "15px",
                        minHeight: "32px",
                        width:"587px",
                        resize: "none",
                        outline: "none",
                        borderBottom: "1px solid lavender",
                        fontSize: "16px",
                        lineHeight: "22px",
                        fontWeight: "450",
                        overflow: "auto",
                        wordBreak: "break-all",
                        paddingRight: "60px",
                    }
                }
                contentEditable={true}
            >

            </div>
            <div style={
                {
                    position: "absolute",
                    top: "calc(100% - 25px)",
                    left: "calc(100% - 60px)",
                    fontSize: "12px",
                    lineHeight: "14px",
                }
            }
            >
                {1000 - charCounter}/1000
            </div>
        </div>

    );
};

export default ICTextAreaVerticalGrow;