import doneImg from "../../../../assets/icons/hr-services/done.svg";
import exclamationMark from "../../../../assets/icons/hr-services/exclamationMark.svg";
import errorImg from "../../../../assets/icons/hr-services/redCross.svg";

import moment from "moment";
import React, {memo, useState} from "react";

const TableAcceptRow = (
    {
        request,
        state,
        setModal,
    }
) => {
    const [comment, setComment] = useState("");

    const changeHandler = (event) => {
        if (event.target.classList.contains("empty-cancel-comment")) {
            event.target.classList.remove("empty-cancel-comment");
        }
        setComment(event.target.value);
        // console.log(comment)
    };

    const status = {
        "согласовано": <p>подтверждено  <img src={doneImg} alt="done"/></p>,
        "ожидание": <p>не завершено <img src={exclamationMark} alt="pending"/></p>,
        "отклонено": <p>ошибка <img src={errorImg} alt="error"/></p>,
    };
    const statusStyle = {
        "согласовано": "approved",
        "ожидание": "pending",
        "отклонено": "rejected",
    };

    return (
        <tr key={request.uid}
            className="accept-modal__request-list__item accept-request-item"
        >
            <td className="accept-request-item__name">
                {request.name}
            </td>
            <td className="accept-request-item__date-range">
                <span>{moment(request.from).format("DD")}</span>
                {" - "}
                <span>{moment(request.to).format("DD")}</span>
            </td>
            {state === "decline-comment"
                ?
                <td className={"accept-request-item__status-comment"}>
                    <input
                        className={"basic-input"}
                        onChange={changeHandler}
                        id={`cancel-${request.uid}`}
                        type="text"
                        placeholder="Введите комментарий..."
                        value={comment}
                    />
                </td>
                :
                <td className={`accept-request-item__status-${statusStyle[request.status]}`}>
                    {status[request.status]}
                </td>
            }
        </tr>
    );
};

export default memo(TableAcceptRow);
