import LoginForm from "../../common/Auth/LoginForm/LoginForm";
import ForgotPasswordModal from "../../common/Auth/ForgotPasswordModal/ForgotPasswordModal";

import ModalSupport from "../../common/Auth/ModalSupport/ModalSupport";
import { userLoginThunkAction } from "../../../redux/actions/Auth/Thunk/userLoginThunkAction";

import { Modal } from "../../common/Modal/Modal";
import { resetStateNullAction } from "../../../redux/actions/Auth/resetPasswordAction";

import { isTerminal } from "../../../constants";

import { validationSnils } from "../../../utils/validationSnils";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import "./LoginPage.scss";
import "./LoginPageTerminal.scss";

const LoginPage = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const inputSnils = useRef();
    const inputEmail = useRef();
    const inputPhone = useRef();
    
    const user = useSelector((state) => state.user);
    const authLogin = useSelector((state) => state.authLogin);

    // const auth = useSelector((state) => state.auth);
    // const [input, setInput] = useState({userId:'',password:''})

    const [snils, setSnils] = useState("");
    const [changedSnils, setChangedSnils] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [valid, setValid] = useState(true);
    const [openEye, setOpenEye] = useState(false);
    const [openEyeRepeat] = useState(false);

    const togglePasswordVisiblity = () => {
        setOpenEye(!openEye);
        setPasswordShown(!passwordShown);
    };

    const handleChangePhone = (e) => {
        // const cardValue = inputPhone.current?.value;
        const numbers = inputPhone.current?.value.replace(/(\D)/g, "");
        setPhone(numbers);
    };

    const handleChangeSnils = (e) => {
        const cardValue = inputSnils.current?.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        inputSnils.current.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ""
            }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ""}`}`;
        setChangedSnils(inputSnils.current.value);
        const numbers = inputSnils.current?.value.replace(/(\D)/g, "");
        setSnils(numbers);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setValid(true);
        if (email) {
            const validE = inputEmail.current?.value.match(
                /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                // /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            );
            if (!validE && inputEmail.current?.value.length > 0) {
                setValid(false);
            } else if (valid) {
                dispatch(
                    userLoginThunkAction({ userId: email, password: password }),
                );
                if (user) {
                    navigate("/authentication");
                }
            }
        }
        if (snils) {
            if (snils.length !== 11) {
                setValid(false);
            } else if (snils.length === 11) {
                dispatch(
                    userLoginThunkAction({ userId: snils, password: password }),
                );
                if (user) {
                    navigate("/authentication");
                }
            }
        }
        if (phone) {
            if (phone.length) {
                dispatch(
                    userLoginThunkAction({
                        userId: "+" + phone,
                        password: password,
                    }),
                );
                if (user) {
                    navigate("/authentication");
                }
                if (!authLogin) {
                }
            }
        }
    };
    const [modalShown, toggleModal] = useState(false);

    function clickHandlerForgotPassword(e) {
        toggleModal(!modalShown);
    }

    function closeHandlerForgotPassword(e) {
        dispatch(resetStateNullAction());
        toggleModal(false);
    }

    //supportModal
    const [modalShownSupport, toggleModalSupport] = useState(false);

    function clickHandlerSupport(e) {
        toggleModalSupport(!modalShownSupport);
    }

    function closeHandlerSupport(e) {
        toggleModalSupport(false);
    }

    useEffect(() => {
        // localStorage.removeItem('userId')
        localStorage.removeItem("resetPassword");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        if (user) {
            navigate("/authentication");
        }

        setValid(authLogin !== "Пользователь не найден");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, authLogin]);

    const handlerInput = (event) => {
        event.preventDefault();
        if (user) {
            navigate("/authentication");
        }

        const value = email;
        const isSnils = validationSnils(value);

        if (value.length === 11 && isSnils) {
            dispatch(
                userLoginThunkAction({ userId: value, password: password }),
            );
            return;
        } 
        if ((value.length === 12 && value.includes("+")) || (value.length === 11 && !isSnils)) {
            dispatch(
                userLoginThunkAction({ userId: value, password: password }),
            );
            return;
        }
        const validE = inputEmail.current?.value.match(
            /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            // /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        );
        if (!validE && inputEmail.current?.value.length > 0) {
            setValid(false);
        } else if (valid) {
            dispatch(
                userLoginThunkAction({ userId: value, password: password }),
            );
            return;
        }
        setValid(false);
    };

    return (
        <div className={isTerminal ? "login-container-terminal" : "login-container"}>
            <div className={isTerminal ? "login-wrapper-terminal" : "login-wrapper"}>
                <LoginForm
                    email={email}
                    setEmail={setEmail}
                    snils={snils}
                    setSnils={setSnils}
                    phone={phone}
                    setPhone={setPhone}
                    password={password}
                    setPassword={setPassword}
                    passwordShown={passwordShown}
                    togglePasswordVisiblity={togglePasswordVisiblity}
                    inputEmail={inputEmail}
                    valid={valid}
                    setValid={setValid}
                    inputSnils={inputSnils}
                    handleChangeSnils={handleChangeSnils}
                    handleChangePhone={handleChangePhone}
                    inputPhone={inputPhone}
                    submitHandler={isTerminal ? handlerInput : submitHandler}
                    clickHandlerForgotPassword={clickHandlerForgotPassword}
                    clickHandlerSupport={clickHandlerSupport}
                    openEye={openEye}
                    openEyeRepeat={openEyeRepeat}
                    changedSnils={changedSnils}
                />
            </div>
            { !isTerminal && <div className={"login-img-auth"}></div> }
            { isTerminal && <img style={{height: "100vh"}} src={require("../../../assets/authPhoto/photo.png")} alt="" /> }
            <Modal showCloseButton={!isTerminal} shown={modalShown} close={closeHandlerForgotPassword}>
                <ForgotPasswordModal close={closeHandlerForgotPassword} />
            </Modal>
            <Modal showCloseButton={!isTerminal} shown={modalShownSupport} close={closeHandlerSupport}>
                <ModalSupport close={closeHandlerSupport} />
            </Modal>
        </div>
    );
};

export default LoginPage;
