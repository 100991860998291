import SwitchRequests from "../../../SwitchRequests/SwitchRequests";
import UButton from "../../../Button/UButton/UButton";

import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";

import {useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import React from "react";

function HrReferencesCard(props) {

    const currentRequest = useSelector(state => state.requests.currentRequest);

    return !currentRequest ?
        <div className='request-modal-loader'>
            <ClipLoader color="#6DCDF6"/>
        </div>
        :
        (
            <div className='request-modal'>
                <>
                    <SwitchRequests
                        isReferense={true}
                        userRequests={props.userRequests}
                        currentRequest={ currentRequest }
                        typeText = { currentRequest["Наименование"] }
                        number={ currentRequest["Номер заявки"] }
                    />
                    <div className="request-modal__table">
                        {
                            currentRequest &&
                                Object.keys(currentRequest).map((el,index)=>{
                                    return (
                                        <div key={index} className="request-modal__table__line">
                                            <div className='left-column'>
                                                {el}
                                            </div>
                                            <div className='right-column'>
                                                {currentRequest[el]}
                                            </div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                    <div className={"buttons-wrapper"}>
                        <UButton
                            className="request-modal__button"
                            onClick={
                                () => RequestServices
                                    .downloadReferenceFile(currentRequest["Номер заявки"], currentRequest["Наименование"])
                            }
                            disabled = { !currentRequest["документы"] }
                        >скачать документ</UButton>
                    </div>
                </>
            </div>
        );
}

export default HrReferencesCard;
