import {
    calendarDatePickCurrentRequest,
} from "../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";
import triangle from "../../DatePeriodControlled/Triangle.svg";
import {
    validationControlCurrentRequest,
} from "../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import { isTerminal } from "../../../../../constants";

import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";

isTerminal ? 
    import("./SingleDateCalendarSelectorForUnpaidPractiseTerminal.scss") 
    : import("./SingleDateCalendarSelectorForUnpaidPractise.scss");

const SingleDateCalendarSelectorForUnpaidPractise = ({ field, label }) => {
    const dispatch = useDispatch();

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );

    const [value, setValue] = useState(null);
    const [arrow, setArrow] = useState(false);

    const handleCalendarClose = () => setArrow(false);
    const handleCalendarOpen = () => setArrow(true);

    function changeHandler(date) {
        dispatch(
            calendarDatePickCurrentRequest({
                field,
                date: moment(date).format("YYYY-MM-DD"),
            }),
        );
        dispatch(validationControlCurrentRequest(field, true));
        // console.log(date.constructor.UTC)
        setValue(date);
    }

    return (
        <div className={"single-date-calendar-selector"}>
            <div className={"single-date-calendar-selector__label"}>
                {label}
            </div>
            <DatePicker
                minDate={
                    field === "dateTo" &&
                    currentRequest &&
                    currentRequest.dateFrom
                        ? currentRequest.dateFrom === "Invalid date"
                            ? new Date()
                            : new Date(currentRequest.dateFrom)
                        : new Date(moment().add(10, "days").toDate())
                }
                maxDate={
                    field === "dateFrom" &&
                    currentRequest &&
                    currentRequest.dateTo
                        ? currentRequest.dateTo === "Invalid date"
                            ? null
                            : new Date(currentRequest.dateTo)
                        : null
                }
                className={`single-date-calendar-selector__input${
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid[[`${field}`]]
                        ? "-regular"
                        : "-error"
                }`}
                dateFormat="dd.MM.yyyy"
                locale={"ru"}
                selected={
                    currentRequest && currentRequest[`${field}`]
                        ? new Date(currentRequest[`${field}`])
                        : value
                }
                onChange={(date) => changeHandler(date)}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={handleCalendarOpen}
                wrapperClassName="react-datepicker-wrapper"
            />
            {!isTerminal && <img
                className={`single-date-calendar-selector__${
                    arrow ? "arrow-down" : "arrow"
                }`}
                src={triangle}
                alt=">"
            />}
            {currentRequest &&
            currentRequest.isValid &&
            currentRequest.isValid[[`${field}`]] ? null : (
                    <div className={"single-date-calendar-selector__error"}>
                    Проверьте правильность заполнения
                    </div>
                )}
        </div>
    );
};

export default SingleDateCalendarSelectorForUnpaidPractise;
