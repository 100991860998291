import { getLatesUnepCertificateAction } from "../getLatesUnepCertificate.action";
import $api from "../../../../../services/axiosService";

import moment from "moment";

export const getLatesUnepCertificateThunkAction =
    (props) => async (dispatch) => {
        try {
            const response = await $api("v1/c/certificates/latest");
            if (response.status) {
                if (response.data !== {}) {
                    const temp = { ...response.data };
                    if (temp.validDate) {
                        const timeToNote = moment(temp.validDate).add(-21, "d");
                        if (new Date().getTime() > new Date(temp.validDate).getTime()) {
                            temp.valid = "completely expired";
                        } else if (
                            new Date().getTime() >
                            new Date(timeToNote).getTime()
                        ) {
                            temp.valid = "expired";
                        }
                    }
                    const resp = await $api("/v1/c/certificates/issues/latest");
                    if (resp.status) {
                        switch (resp.data.status) {
                        case 80:
                            if (temp.valid === "expired") {
                                temp.status = "Срок действия ЭЦП истекает";
                            } else {
                                temp.status = "Готов";
                            }
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 60:
                            temp.status = "В процессе выпуска";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 50:
                            temp.status = "Ошибка";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 10:
                            temp.status = "Требует подтверждения через смс";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 20:
                            temp.status =
                                    "Требует подтверждения через ГосУслуги";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 30:
                            temp.status = "Подтверждена";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 40:
                            temp.status = "Ошибка подтверждения";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 70:
                            temp.status = "Ошибка валидации";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 90:
                            temp.status = "Ошибка выпуска";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 100:
                            temp.status = "Ошибка ";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );
                        case 0:
                            temp.status = "Новая ";
                            return dispatch(
                                getLatesUnepCertificateAction(temp),
                            );

                        default:
                            return temp;
                        }
                    }
                }
                dispatch(getLatesUnepCertificateAction(response.data));
            }
        } catch (e) {
            if (props) {
                props.setErrDescription(e?.response?.detail);
                props.setErrorModal(true);
            }
        }
    };
