import TooltipText from "../../components/common/TooltipText/TooltipText";

import {v4 as uuidv4} from "uuid";
import moment from "moment";

class TableYearHelper {
    static getCredentials(data, parentStyle) {
        const output = [];
        const style = {
            struct: "__department",
            name: "__worker",
            totalDays: "__vacation-days",
        };
        data.forEach((e, i) => {
            const temp = [];
            for (const key in e) {
                if (key === "struct" || key === "name" || key === "totalDays") {
                    temp.push(
                        <td
                            key={uuidv4()}
                            className={`${parentStyle}__body${style[key]}`}>
                            <div><div>{e[key]}</div></div>
                        </td>
                    );
                }
            }
            output.push(temp);
        });
        return output;
    }

    static getMonthsGantRow(data, parentStyle, setTooltip) {
        const output = [];
        data.forEach((element, employee) => {
            // check if this employee has leaves
            // temp is row of months
            const temp = new Array(12)
                .fill(null);
            if (element.planned.length) {
                output.push(temp.map((month, monthIndex) => {
                    element.planned.forEach(plannedVacation => {
                        const plannedVacationFrom = moment(plannedVacation.from).month();
                        const plannedVacationTo = moment(plannedVacation.to).month();
                        if (plannedVacationTo - plannedVacationFrom > 0) {
                            const period = plannedVacationTo - plannedVacationFrom;
                            for (let i = 0; i < period; i += 1) {
                                if (plannedVacationFrom + i === monthIndex) {
                                    month = this.filledCell(parentStyle, monthIndex, setTooltip, plannedVacation);
                                }
                            }
                        }
                        if (plannedVacationFrom === monthIndex
                            || plannedVacationTo === monthIndex) {
                            month = this.filledCell(parentStyle, monthIndex, setTooltip, plannedVacation);
                        }
                        if (!month) {
                            month = this.emptyCell(parentStyle);
                        }
                    });
                    return month;
                }));
            } else {
                output.push(temp.map(e => {
                    return this.emptyCell(parentStyle);
                }));
            }
        });
        return output;
    }

    static filledCell(parentStyle, monthIndex, setTooltip, plannedVacation) {
        return <td key={`filled${uuidv4()}`}
            className={`${parentStyle}__body__month`}>
            <div
                name={"filled"}
                id={uuidv4()}
                className={
                    moment().month() > monthIndex
                        ? "month-past"
                        : "month-regular"
                }
                onMouseLeave={(e) => {
                    setTooltip(null);
                }}
                onMouseEnter={(e) => {
                    const elem = document.getElementById(e.target.id);
                    if (elem) {
                        const top = Number(elem.getBoundingClientRect().y);
                        const left = Number(elem.getBoundingClientRect().x);
                        setTooltip(this.tooltipComponent(plannedVacation, Math.ceil(top), Math.ceil(left)));
                    }
                }}
            >
            </div>
        </td>;
    }

    static emptyCell(parentStyle) {
        return <td
            key={uuidv4()}
            className={`${parentStyle}__body__month`}>
            <div></div>
        </td>;
    }

    static tooltipComponent(plannedMonth, top, left) {
        const data = {
            from: moment(plannedMonth.from).format("DD.MM"),
            to: moment(plannedMonth.to).format("DD.MM"),
            days: plannedMonth.days,
        };
        return (
            <div
                key={uuidv4()}
                className={"month-regular__pop-up"}
                style={
                    {
                        top: top,
                        left: left,
                        display: "block",
                    }
                }>
                <ul key={uuidv4()}
                    className={"month-regular__pop-up__list"}>
                    <TooltipText data={data} parentStyle={"month-regular"}/>
                </ul>
            </div>
        );
    }
}

export default TableYearHelper;