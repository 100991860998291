export const LineRightArrow = () => (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.363701 0.363213C0.715173 0.0117414 1.28502 0.0117414 1.63649 0.363213L8.63649 7.36322C8.98796 7.71469 8.98796 8.28454 8.63649 8.63601L1.63649 15.636C1.28502 15.9875 0.715173 15.9875 0.363701 15.636C0.0122297 15.2845 0.0122297 14.7147 0.363701 14.3632L6.7273 7.99961L0.363701 1.63601C0.0122297 1.28453 0.0122297 0.714685 0.363701 0.363213Z"
            fill="#818C99"
        />
    </svg>
);
