import $api from "../../../../services/axiosService";
import { getAllLeaveTypesAction } from "../getAllLeaveTypes.action";
import { mode } from "../../../../../app/mode";

export const getLeaveTypeThunkAction =
  ({ setTypesListLoader }) =>
      async (dispatch) => {
          try {
              setTypesListLoader(true);
              let temp;
              const response = await $api.get("v1/leaverequest/leavetype");
              if (mode === "PROD") {
                  temp = response.data.items.filter(
                      (el) =>
                          el.id === 1 ||
              el.id === 2 ||
              el.id === 3 ||
              el.id === 5 ||
              el.id === 9 ||
              el.id === 6 ||
              el.id === 7 ||
              el.id === 13,
                  );
              } else if (mode === "TEST" || mode === "UNSTABLE") {
                  temp = response.data.items.filter(
                      (el) =>
                          el.id === 1 ||
              el.id === 2 ||
              el.id === 3 ||
              el.id === 5 ||
              el.id === 6 ||
              el.id === 7 ||
              el.id === 9 ||
              el.id === 13,
                  );
              }

              // dispatch(getAllLeaveTypesAction(response.data.items)) //вернем когда будут всее заявки
              dispatch(getAllLeaveTypesAction(temp));
              setTypesListLoader(false);
          } catch (error) {
              setTypesListLoader(false);
              // console.log("ERROR WHILE LOADING TYPES: ", error.response)
          }
      };
