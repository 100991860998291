import { $api } from "../../../../services/request.config";
import { getMaterinityDisDocsAction } from "../getMaterinityDisDocs.action";

export const getMaterinityDisDocsThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/request/leave/maternity/dis-docs");
        if (response) {
            // const temp =
            dispatch(getMaterinityDisDocsAction(response.data));
        }
    } catch (error) {
        throw new Error(error.response);
    }
};
