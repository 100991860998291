import { useInputCompositionContext } from "../../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import HelperService from "../../../../../../frontend.services/Helper.service";

import { useState } from "react";

import "./SearchDropdown.scss";

const SearchDropdown = (
    {
        items,
        setStateModel,
        setState,
        field,
        forceClose,
        message,
    }
) => {

    const [, , isError, setIsError] = useInputCompositionContext();


    const [inputState, setInputState] = useState("");

    function selectHandler (event, listItem) {
        if (isError) {
            setIsError(prev => {
                if (prev[field]) {
                    const temp = prev;
                    delete temp[field];
                    return temp;
                } else {
                    return prev;
                }
            });
        }
        setState(listItem.fullName);
        setStateModel((prev) => {
            return {
                ...prev, [field]: listItem.pid,
            };
        });
        forceClose();
    }

    return items && items.length
        ? <div className={"popup-dropdown-search"}>
            <input
                onChange={(event) => setInputState(event.target.value)}
                className={"popup-dropdown-search__input"}
                value={inputState}
                type="text" />
            <ul className={"popup-dropdown-search__list"}>
                {items.length && items
                    .filter(elem => elem.fullName.toLowerCase().includes(inputState.toLowerCase()))
                    .map((e, i) => {
                        return <li
                            className={"popup-dropdown-search__list__item"}
                            onClick={(event) => selectHandler(event, e)}
                            key={i}>
                            <div>{HelperService.getHighlightedText(e.fullName, inputState)}</div>
                            <div>{e.appointment}</div>
                            <div>{e.structName}</div>
                        </li>;
                    })}
            </ul>
        </div>
        : <div className={"popup-dropdown-search__no-items"}>
            <span className={"popup-dropdown-search__no-items__text"}>
                {
                    message
                        ? message
                        : "для выбранного типа замещения нет доступных сотрудников"
                }
            </span>
        </div>;

};

export default SearchDropdown;