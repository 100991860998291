import LeaveWithoutPay from "./RequestPage/LeaveWithoutPay/LeaveWithoutPay";
import MaternityLeave from "./RequestPage/MaternityLeave/MaternityLeave";
import StudyLeave from "./RequestPage/StudyLeave/StudyLeave";
import PostponeVacation from "./RequestPage/PostponeVacation/PostponeVacation";
import UnplannedVacation from "./RequestPage/UnplannedVacation/UnplannedVacation";
import ExtraVacationDays from "./RequestPage/ExtraVacationDays/ExtraVacationDays";
import ShiftAdditionalRestDays from "./RequestPage/ShiftAdditionalRestDays/ShiftAdditionalRestDays";
import ParentalLeave from "./RequestPage/ParentalLeave/ParentalLeave";

import { isTerminal } from "../../../../../constants";
import RequestVerification
    from "../../../../common/Modal/ModalConditions/Requests/RequestVerification/RequestVerification";
import {
    requestValidationThunkAction,
} from "../../../../../redux/actions/Requests/RequestValidation/thunk/requestValidation.thunk.action";
import {
    getLeaveTypeThunkAction,
} from "../../../../../redux/actions/Requests/LeaveMetadata/thunk/getLeaveType.thunk.action";
import Error500Modal from "../../../../common/Modal/ModalConditions/Requests/Error500Modal/Error500Modal";
import {
    getCurrentRequestThunkAction,
} from "../../../../../redux/actions/Requests/CurrentRequest/thunk/getCurrentRequest.thunk.action";

import DropdownMenu from "../../../../common/Menu/DropdownMenu/DropdownMenu";

import ParentalLeaveApplication from "../../../../../frontend.classes/Requests/ParentalLeave.application";
import RequestControlServices from "../../../../../frontend.services/RequestServices/RequestControlServices";
import {
    saveDraftCurrentRequestThunk,
} from "../../../../../redux/actions/Requests/currentRequestControls/thunk/saveDraft.currentRequest.thunk";
import {
    updateCurrentRequestThunk,
} from "../../../../../redux/actions/Requests/currentRequestControls/thunk/update.currentRequest.thunk";

import {
    clearCurrentRequestAction,
} from "../../../../../redux/actions/Requests/CurrentRequest/clearCurrentRequest.action";

import { clearAttachmentsAction } from "../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import ModalBanOverlay from "../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import SaveSuccessModal from "../../../../common/Modal/ModalConditions/Requests/SaveSuccessModal/SaveSuccessModal";
import BackHandlerModal from "../../../../common/Modal/ModalConditions/Requests/BackHandlerModal/BackHandlerModal";
import { Modal } from "../../../../common/Modal/Modal";
import RequestButtons from "../../../../common/Button/RequestButtons/RequestButtons";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import EmptyBody from "../../../../common/EmptyBody/EmptyBody";

import Header from "../../../../layout/Header/Header";
import Footer from "../../../../layout/Footer/Footer";


import { useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

isTerminal ? import("./stylesTerminal.scss") : import("./styles.scss");

const WTRequestsPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const requestTypes = useSelector((state) =>
        isTerminal
            ? state.requests.types?.filter(
                ({id}) => ![6,7].includes(id),
            )
            : state.requests.types,
    );
    const currentRequest = useSelector(state => state.requests.currentRequest);


    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [onValidation, setOnValidation] = useState(false);

    const [typesListLoader, setTypesListLoader] = useState(false);

    const [requestOption, setRequestOption] = useState(null);

    // NOTIFICATION IN CONTROL PROCEDURES CONTENT
    const [requestStatus, setRequestStatus] = useState(false);

    const [modalStatus, setModalStatus] = useState(false);
    const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
    const [modalSentStatus, setModalSentStatus] = useState(false);
    const [modalError, setModalError] = useState({
        sign: false,
        validation: false,
        status: false,
    });

    const [isRunningSaveHandler, setIsRunningSaveHandler] = useState(false);
    const [isRunningSendHandler, setIsRunningSendHandler] = useState(false);

    function backHandler(e) {
        currentRequest && currentRequest.leaveReasonId ? setModalStatus(true) : navigate("/main/hr-services/work-time");
    }

    function saveHandler(e) {
        let result = false;
        // check condition that empty request contains required fields
        if (RequestControlServices.saveOrUploadValidation(currentRequest, dispatch)) {
            setModalSuccessStatus(true);
            setIsRunningSaveHandler(true);
            if (!currentRequest.uid) {
                if (currentRequest.leaveTypeId === 7) {
                    const requestAttachments = currentRequest.attachments.filter(el => el.uid);
                    // const requestParents = currentRequest.attachments.filter(el => el.fullName && el.tabNumber);
                    const request = {
                        ...currentRequest,
                        attachments: requestAttachments,
                        // parents: requestParents,
                    };
                    result = dispatch(saveDraftCurrentRequestThunk(request, setModalSuccessStatus));
                } else {
                    result = dispatch(saveDraftCurrentRequestThunk(currentRequest, setModalSuccessStatus));
                }
            } else {
                if (currentRequest.leaveTypeId === 7) {
                    const requestAttachments = currentRequest.attachments.filter(el => el.uid);
                    // const requestParents = currentRequest.attachments.filter(el => el.fullName && el.tabNumber);
                    const request = {
                        ...currentRequest,
                        attachments: requestAttachments,
                        // parents: requestParents,
                    };
                    result = dispatch(updateCurrentRequestThunk(request, setModalSuccessStatus));

                } else {
                    result = dispatch(updateCurrentRequestThunk(currentRequest, setModalSuccessStatus));
                }
            }
        }
        if (result)
            result.then(()=>{
                setIsRunningSaveHandler(false);
            })
        else setIsRunningSaveHandler(false);
    }

    function sendHandler(e) {
        let result = false;
        if (currentRequest.leaveTypeId === 7) {
            if (ParentalLeaveApplication.sendToValidation(currentRequest, dispatch)) {
                setModalSentStatus(true);
                setIsRunningSendHandler(true);
                const requestAttachments = currentRequest.attachments.filter(el => el.uid);
                const request = {
                    ...currentRequest,
                    attachments: requestAttachments,
                };
                result = dispatch(requestValidationThunkAction(
                    {
                        currentRequest: request,
                        setRequestStatus,
                        stateLoader: setSendButtonLoading,
                        closeModal: setModalSentStatus,
                        setModalError,
                    },
                ));
            }
        } else {
            if (RequestControlServices.sendToValidation(currentRequest, dispatch)) {
                setModalSentStatus(true);
                setIsRunningSendHandler(true);

                result = dispatch(requestValidationThunkAction(
                    {
                        currentRequest,
                        setRequestStatus,
                        stateLoader: setSendButtonLoading,
                        closeModal: setModalSentStatus,
                        setModalError,
                    },
                ));
            }
        }
        if (result)
            result.then(()=>{
                setIsRunningSendHandler(false);
            })
        else setIsRunningSendHandler(false);
    }

    function selectOptionClick(event, element, stateProperty) {
        setRequestOption(element.id);
    }

    useEffect(() => {
        dispatch(getLeaveTypeThunkAction({ setTypesListLoader }));
        dispatch(clearAttachmentsAction());
        // if there's uid in query string set current request by uid
        if (queryId) dispatch(getCurrentRequestThunkAction(queryId, setRequestOption));

        return () => {
            dispatch(clearCurrentRequestAction());
        };

    }, [dispatch, queryId]);


    return (
        <>

            <Header />
            <Breadcrumbs />
            <DropdownMenu
                optionsLoader={typesListLoader}
                styleName={"types"}
                label={"заявки по рабочему времени"}
                initialMenuMessage={"Выберите категорию"}
                listOfOptions={requestTypes}
                stateProperty={"leaveTypeId"}
                currentRequest={currentRequest}
                selectOptionClick={selectOptionClick} 
                showBackButton={isTerminal}
            />
            {
                requestOption === 1 &&
        <LeaveWithoutPay
            requestStatus={requestStatus}
            setRequestStatus={setRequestStatus}
            requestOption={requestOption} />
            }
            {
                requestOption === 2 &&
        <UnplannedVacation
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            requestOption={requestOption} />
            }
            {
                requestOption === 3 &&
        <PostponeVacation
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            requestOption={requestOption} />
            }
            {
                requestOption === 5 &&
        <StudyLeave
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            requestOption={requestOption} />
            }
            {
                requestOption === 6 &&
        <MaternityLeave
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            requestOption={requestOption} />
            }
            {requestOption === 7 &&
        <ParentalLeave
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            requestOption={requestOption} />
            }
            {
                requestOption === 9 &&
        <ExtraVacationDays
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            requestOption={requestOption} />
            }
            {
                requestOption === 13 &&
        <ShiftAdditionalRestDays
            requestOption={requestOption}
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus} />
            }
            {
                requestOption === 1 ||
        requestOption === 2 ||
        requestOption === 3 ||
        requestOption === 5 ||
        requestOption === 6 ||
        requestOption === 7 ||
        requestOption === 9 ||
        requestOption === 13
                    ? <RequestButtons
                        sendHandler={sendHandler}
                        saveHandler={saveHandler}
                        backHandler={backHandler}
                        isRunningSaveHandler={isRunningSaveHandler}
                        isRunningSendHandler={isRunningSendHandler}
                    />
                    : <EmptyBody backHandler={backHandler} />
            }


            {/*BACK*/}
            <Modal shown={modalStatus} close={setModalStatus}>
                <BackHandlerModal
                    handler={saveHandler}
                    close={setModalStatus} />
            </Modal>
            {/*SAVE SUCCESS*/}
            <Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>
                <SaveSuccessModal
                    route={"/main/hr-services/work-time"}
                    close={setModalSuccessStatus} />
            </Modal>
            {/*REQUEST SEND*/}
            <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                <RequestVerification
                    onValidation={onValidation}
                    setOnValidation={setOnValidation}
                    setModalError={setModalError}
                    sendButtonLoading={sendButtonLoading}
                    route={"/main/hr-services/work-time"}
                    setModalSentStatus={setModalSentStatus
                    } />
            </ModalBanOverlay>
            {modalError.status &&
        <ModalBanOverlay shown={modalError} close={setModalError}>
            <Error500Modal
                modalError={modalError}
                setModalError={setModalError} />
        </ModalBanOverlay>}

            <Footer />
        </>
    );
};

export default memo(WTRequestsPage);
