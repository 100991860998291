import {$api} from "../../../../services/request.config";
import {getReasonsForReportAction} from "../getReasonsForReport.action";

export const getReasonsForReportThunkAction = () => async(dispatch) => {
    try {
        const response = await $api("v2/directum/document/signing/reject/commentTypes");
        if(response.data) {
            const temp = response.data.map(el =>  {
                return {...el, name: el.comment};
            });
            dispatch(getReasonsForReportAction(temp));
        }
    } catch (e) {
        // console.log('ERROR_WHILE_getReasonsForReportThunkAction: ', e?.response)
    }
};
