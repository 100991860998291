import {initialState} from "../../init/initialState";
import { GET_REASONS_FOR_REJECT, IS_WORKER_SUBSTITUTE} from "../../enums/Documents/DocumentsType";

export const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
    case "GET_ALL_DOCUMENTS":
        return {...state, allDocuments: action.payload};
    case IS_WORKER_SUBSTITUTE:
        return {...state, isSubstitute: action.payload};
    case GET_REASONS_FOR_REJECT:
        return {...state, rejectReasons: action.payload};

    default:
        return state;
    }
};
