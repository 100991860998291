import $api from "../../../../services/axiosService";
import { getUserHrDocsAction } from "../getUserHrDocs.action";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";

export const getUserHrDocsThunkAction = (props) => async (dispatch) => {
    try {
        const response = await $api("v2/directum/packages/0");
        if (response.status === 200) {
            const result = await RequestServices.getHrRequestsWithFile(response.data);
            dispatch(getUserHrDocsAction(result.reverse()));
        }

    } catch (e) {
        if (props) {
            if (e?.response?.status === 500) {
                props.setErrDescription(e?.response.data.detail);
                props.setErrorModal(true);
            } else {
                props.setErrDescription("Что-то пошло не так, повторите попытку позже.");
                props.setErrorModal(true);
            }
        } else {
            throw new Error(e.response?.data?.detail);
        }
    }
};
