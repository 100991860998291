import $api from "../../../../services/axiosService";
import { deleteAttachmentAction } from "../deleteAttachment.action";

export const deleteAttachmentThunkAction = ({ attachId, requestId, setDeleteStatus }) => async (dispatch) => {
    try {
        setDeleteStatus(true);
        await $api.delete(`v1/leaverequest/${requestId}/upload/${attachId}`);
        dispatch(deleteAttachmentAction(attachId));
        setDeleteStatus(false);
    } catch (error) {
        setDeleteStatus(false);
    }

};
