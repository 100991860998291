import arrowRight from "../assets/nextMonthArrow.svg";
import arrowLeft from "../assets/prevMonthArrow.svg";

import ApplicationHelperService from "../applicationHelper.service";

import EmployeesService from "../../../../../frontend.services/Employees/Employees.service";

import React from "react";
import { v4 as uuidv4 } from "uuid";

const HolidaysApplicationRow = ({
    application,
    applicationIndex,
    pendingUserRequests,
    selectClickHandler,
    multipleSelection,
    setModal,
}) => {

    return (
        <tr
            key={application.uid + applicationIndex}
            className="holidays-application-body__row"
        >
            <td>
                <div className="holidays-application-body__selector">
                    <input
                        name={"checkbox"}
                        checked={application.checked}
                        onChange={selectClickHandler}
                        id={application.uid}
                        className="holidays-application-body__checkbox"
                        type="checkbox"
                    />
                    {/*<span className="holidays-application-body__checkbox__checkmark"></span>*/}
                    <div
                        onClick={() => setModal(prev => {
                            return {
                                ...prev,
                                applicationUID: application.uid,
                                modal: "application-details",
                            };
                        })}
                        className="holidays-application-body__application-name">
                        {application.name}
                    </div>
                </div>
            </td>
            <td>
                <div className="holidays-application-body__periods-wrapper">
                    {ApplicationHelperService.periodToListMapper(
                        application.periods,
                    ).map((listElement) => {
                        return (
                            <div
                                className="holidays-application-body__periods"
                                key={uuidv4()}
                            >
                                {listElement}
                            </div>
                        );
                    })}
                </div>
            </td>
            {
                pendingUserRequests && application.periods
                    ? ApplicationHelperService.periodMapper(
                        application.periods,
                        pendingUserRequests.dailyPercent.length,
                    ).map.map((day, appDayIndex, array) => {
                        return (
                            <td
                                className={`holidays-application-body__${
                                    EmployeesService.getStyleBitwise(day)
                                }
                                `}
                                key={uuidv4()}
                            >
                                <div>
                                    {appDayIndex === 0 &&
                                          ApplicationHelperService.isClipped(
                                              appDayIndex,
                                              application.periods,
                                              pendingUserRequests.dailyPercent
                                                  .length,
                                          ) && (
                                        <img
                                            src={arrowLeft}
                                            alt="left"
                                        />
                                    )}
                                    {appDayIndex === array.length - 1 &&
                                          ApplicationHelperService.isClipped(
                                              appDayIndex,
                                              application.periods,
                                              pendingUserRequests.dailyPercent
                                                  .length,
                                          ) && (
                                        <img
                                            src={arrowRight}
                                            alt="right"
                                        />
                                    )}
                                </div>
                            </td>
                        );
                    })
                    : null
            }
        </tr>
    );
};

export default HolidaysApplicationRow;
