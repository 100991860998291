import {v4 as uuidv4} from "uuid";

export const tableHeader = [
    {
        key: uuidv4(),
        title: "структурное подразделение",
        cssKey: "header-department",
    },
    {
        key: uuidv4(),
        title: "cотрудник",
        cssKey: "header-employee",
    },
    {
        key: uuidv4(),
        title: "должность",
        cssKey: "header-position",
    },
    {
        key: uuidv4(),
        title: "грейд / разряд",
        cssKey: "header-grade",
    },
    {
        key: uuidv4(),
        title: "дата приема",
        cssKey: "header-occupationDate",
    },
    {
        key: uuidv4(),
        title: "дата рождения",
        cssKey: "header-birthDate",
    },
    {
        key: uuidv4(),
        title: "категория должности",
        cssKey: "header-category",
    },
    {
        key: uuidv4(),
        title: "cогласие на эдто",
        cssKey: "header-agreement",
    },
];