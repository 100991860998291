import FioContacts from "./FioContacts/FioContacts";
import Relatives from "./Relatives/Relatives";
import DataDocuments from "./DataDocuments/DataDocuments";

import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import { isTerminal } from "../../../../constants";
import { BackArrowButton } from "../../../common/componentsTerminal/BackArrowButton/BackArrowButton";

import React, { useState } from "react";

isTerminal ? import("./PersonalDataTerminal.scss") : import("./PersonalData.scss");

const PersonalData = () => {
    // const dispatch = useDispatch();
    const [toggleStateData, setToggleStateData] = useState(1);

    // const toggleTabData = (index) => {
    //     setToggleStateData(index);
    //     // console.log(toggleStateData)
    // };

    function handlerToResetData(index) {
        setToggleStateData(index);
        // dispatch(setTypesDocumentsAction(type))
    }

    function allDataHandler() {
        setToggleStateData(1);
        // dispatch(getAllDocumentsThunkAction(1))
    }

    return (
        <>
            <Header />
            <div className="main__container--personal-data">
                <Breadcrumbs />
                <div className="block-sub-header">
                    {!isTerminal && "Персональные данные"}
                    {isTerminal && (
                        <>
                            <BackArrowButton />
                            <span>Персональные данные</span>
                        </>
                    )}
                </div>
                <div className="data-bread-crumbs">
                    <div
                        onClick={() => allDataHandler()}
                        className={
                            toggleStateData === 1
                                ? "data-menu-item"
                                : "data-menu-item-active"
                        }
                    >
                        ФИО / Контакты
                    </div>
                    <div
                        onClick={() => handlerToResetData(2, "соглашение")}
                        className={
                            toggleStateData === 2
                                ? "data-menu-item"
                                : "data-menu-item-active"
                        }
                    >
                        Родственники
                    </div>
                    <div
                        onClick={() => handlerToResetData(3, "Справка")}
                        className={
                            toggleStateData === 3
                                ? "data-menu-item"
                                : "data-menu-item-active"
                        }
                    >
                        Документы
                    </div>
                </div>
                <div className="content-tabs">
                    <div
                        className={
                            toggleStateData === 1 ? "active-content" : "content"
                        }
                    >
                        <FioContacts />
                    </div>
                    <div
                        className={
                            toggleStateData === 2 ? "active-content" : "content"
                        }
                    >
                        <Relatives />
                    </div>
                    <div
                        className={
                            toggleStateData === 3 ? "active-content" : "content"
                        }
                    >
                        <DataDocuments />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PersonalData;
