import React from "react";

const TableHeadRow = ({parentStyle, data}) => {
    return (
        <tr>
            {data && data.map(e => {
                return (
                    <td
                        colSpan={e.colspan && e.colspan}
                        key={e.id}
                        className={`${parentStyle}__head${e.cssKey}`}>
                        <div>
                            {e.data.upperValue && e.data.upperValue}
                            <br/>
                            {e.data.lowerValue && e.data.lowerValue}
                            {e.data.lowerValue ? null : e.data}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
};

export default TableHeadRow;