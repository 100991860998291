import { createSignRequestThunkAction } from "./createSignRequest.thunk.action";

import { $api } from "../../../../services/request.config";
import { getApplicationFileAction } from "../../Application/getApplicationFile.action";
// import { setCurrentRequestAction } from "../../CurrentRequest/setCurrentRequest.action";
import { DISMISSAL_REVOCATION_VALIDATE_URL } from "../../../../enums/Request/applicationUrls";
import { setValidatedApplicationAction } from "../../CurrentRequest/setValidatedApplication.action";

function applicationUrl(option) {
    switch (option) {
    case 14:
        return DISMISSAL_REVOCATION_VALIDATE_URL;
    default:
        return "v2/request/template/create";
    }
}

export const layoffCheckValidationThunkAction =
    ({
        requestData,
        setRequestStatus,
        stateLoader,
        closeModal,
        currentRequest,
        setModalError,
        setModalWarning,
        setWarningText,
        setModalSentStatus,
        latestCertificate,
    }) =>
        async (dispatch) => {
            try {
                stateLoader(true);
                if (
                    !latestCertificate ||
                !latestCertificate.thumbprint ||
                !new Date(latestCertificate.issueDate) ||
                new Date(latestCertificate.validDate).getTime() <
                    new Date().getTime()
                ) {
                    stateLoader(false);
                    setRequestStatus((prev) => ({
                        details: [
                            {
                                block: true,
                                message:
                                // eslint-disable-next-line max-len
                                "Для оформления заявки необходимо активировать " +
                                    "Усиленную неквалифицированную электронную подпись (УНЭП). " +
                                    "Получить ее можно в разделе Профиль работника",
                            },
                        ],
                    }));
                    closeModal(false);
                } else {
                    let fullRequest;
                    stateLoader(true);
                    const temp = applicationUrl(requestData.leaveTypeId);
                    const response = await $api.post(`${temp}`, requestData);
                    if (response.data.uid) {
                        fullRequest = await $api(
                            `v1/leaverequest/${response.data.uid}`,
                        );
                        if (fullRequest.data) {
                            dispatch(
                                setValidatedApplicationAction(
                                    fullRequest.data.item,
                                ),
                            );
                        }
                    }
                    if (!response.data.isSuccess) {
                        setRequestStatus(response.data);
                        closeModal(false);
                    } else if (response.data.isSuccess) {
                        if (fullRequest.data.item.attachments.length) {
                            for (
                                let i = 0;
                                i < fullRequest.data.item.attachments.length;
                                i += 1
                            ) {
                                if (
                                    fullRequest.data.item.attachments[i]
                                        .isApplicationDocument
                                ) {
                                    const file = await $api(
                                        `v1.1/leaverequest/file/
                                    ${fullRequest.data.item.attachments[i].leaveRequestUid}/
                                    ${fullRequest.data.item.attachments[i].id}`,
                                        { responseType: "blob" },
                                    );
                                    const blobLink = URL.createObjectURL(
                                        new Blob([file.data], {
                                            type: `${file.headers["content-type"]}`,
                                        }),
                                    );
                                    dispatch(getApplicationFileAction(blobLink));
                                }
                            }
                        }
                        dispatch(
                            createSignRequestThunkAction({
                                uid: response.data.uid,
                                setModalSentStatus,
                            }),
                        );
                    }
                    stateLoader(false);
                }
            } catch (e) {
                if (e.response.status === 500) {
                    setModalError((prev) => {
                        return {
                            ...prev,
                            status: true,
                            validation: true,
                        };
                    });
                    closeModal(false);
                    stateLoader(false);
                }
                closeModal(false);
                stateLoader(false);
            }
        };
