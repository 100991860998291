class BasicApplication {
    constructor({
        uid = null,
        consumer = 1,
        leaveTypeId,
        leaveReasonId = null,
        employeeComment = null,
        attachments = [],
    })
    {
        this.uid = uid;
        this.consumer = consumer;
        this.leaveTypeId = leaveTypeId;
        this.leaveReasonId = leaveReasonId;
        this.employeeComment = employeeComment;
        this.attachments = attachments;
        this.keyChecker = (arrayOfKeys, requestState, setRequestState) => {
            let result = 0;
            arrayOfKeys.forEach(e => {
                if (requestState[`${e}`] === null || requestState[`${e}`] === undefined) {
                    result += 1;
                    setRequestState(prev => {
                        const newValid = {...prev.isValid};
                        newValid[`${e}`] = false;
                        return {...prev, isValid: newValid};
                    });
                }
            });
            return !result;
        };
    }

}

export default BasicApplication;