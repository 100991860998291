import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const RequestTab = (
    {
        currentRequest,
        field,
        parentStyle,
        img,
        handler,
        index,
        del,
        func,
        tittle,
    }
) => {
    // const currentRequest = useSelector(state => state.requests.currentRequest);
    const [name, setName] = useState("Выберите ребенка ниже");


    useEffect(() => {
        setName(`${
            currentRequest && 
            currentRequest[field][index].lastName
                ? currentRequest[field][index].lastName 
                : ""
        } ${
            currentRequest &&
            currentRequest[field][index].firstName 
                ? currentRequest[field][index].firstName 
                : "Выберите ребенка ниже"
        } ${
            currentRequest &&
            currentRequest[field][index].middleName 
                ? currentRequest[field][index].middleName 
                : ""
        }`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [

        // TODO ?????
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currentRequest &&
        currentRequest[field][index].lastName,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currentRequest &&
        currentRequest[field][index].firstName,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currentRequest &&
        currentRequest[field][index].middleName,
    ]);


    return (
        <div
            onClick={func}
            key={uuidv4()}
            className={`${parentStyle}`}>
            <div className={`${parentStyle}__tittle`}>
                {tittle ? tittle : name}
            </div>
            {
                img ?
                    <img
                        onClick={del ? () => handler(index) : handler}
                        className={`${parentStyle}__button__blue-plus`}
                        src={img ? img : null}
                        alt="bluePlus"
                    /> :
                    null
            }

        </div>
    );
};

export default RequestTab;
