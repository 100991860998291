import { addSubstitutionAction } from "../addSubstitution.action";

import { $api } from "../../../../services/request.config";
import { addErrorDecriptionEmployeeListAction } from "../addErrorDecriptionEmployeeList.action";

import moment from "moment";

export const addSubstitutionThunkAction =
    ({ data, pid, period, setModal, modalData }) =>
        async (dispatch) => {
            try {
            // console.log("DATA: ", data)
            // console.log("MODAL INSIDE: ", modal)
                setModal((prev) => {
                    return {
                        ...prev,
                        body: "processing",
                    };
                });
                const { from, to } = period;
                const dateFrom = moment(from).format("YYYY-MM-DD");
                const dateTo = moment(to).format("YYYY-MM-DD");

                const toFrontDateFrom = moment(from).format("DD.MM.YYYY");
                const toFrontDateTo = moment(to).format("DD.MM.YYYY");

                const response = await $api.post("v1/manager/substitutions", {
                // id,
                    pid,
                    from: dateFrom,
                    to: dateTo,
                });

                // console.log("RESPONSE ADD REPLACE: ", response)

                if (response.status === 201) {
                    dispatch(
                        addSubstitutionAction({
                            id: response.data.id,
                            pid,
                            from: dateFrom,
                            to: dateTo,
                        }),
                    );
                    dispatch(
                        addErrorDecriptionEmployeeListAction({
                            head: "Замещение успешно установлено",
                            message: `${modalData.subject} с ${toFrontDateFrom} по ${toFrontDateTo}`,
                        }),
                    );
                }
            } catch (error) {
                error.response.data.detail &&
                dispatch(
                    addErrorDecriptionEmployeeListAction({
                        head: "Не удалось установить замещение",
                        message: error.response.data.detail,
                    }),
                );
            // console.log("ERROR WHILE ADDING SUBSTITUTION: ", error.response)
            }
        };
