import { isTerminal } from "../../../../../../constants";
import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import DatePopupSelector from "../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";
import CommentInput from "../../../../../common/Inputs/CommentInput/CommentInput";
import LayoffApplication from "../../../../../../frontend.classes/Requests/Layoff.application";
import CustomUploader from "../../../../../common/CustomUploader/CustomUploader";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import {
    layoffDismissalDocumentObtainingThunkAction,
} from "../../../../../../redux/actions/Requests/Layoff/thunk/layoffDismissalDocumentObtaining.thunk.action";
import {
    layoffDismissalReasonsThunkAction,
} from "../../../../../../redux/actions/Requests/Layoff/thunk/layoffDismissalReasons.thunk.action";
import {
    getUsersAdressThunkAction,
} from "../../../../../../redux/actions/Requests/Layoff/thunk/getUsersAdress.thunk.action";
import NotificationWindowRequests from "../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import {
    emptyCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    calendarDatePickCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";
import DropdownMenuWithCheckbox from "../../../../../common/Menu/DropdownMenuWithCheckbox/DropdownMenuWithCheckbox";
import {
    dropdownSelectionForArrayCurrenRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/thunk/dropdownSelectionForArray.currenRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    addDimmisialAdressCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addDimmisialAdress.currentRequest";
import { clearAttachmentsAction } from "../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import { ScrollButton } from "../../../../../common/componentsTerminal/ScrollButton/ScrollButton";

import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";

// import {
//     addBasicInputHandlerCurrentRequest,
// } from "../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

isTerminal
    ? import("./LayoffRequestPageTerminal.scss")
    : import("./LayoffRequestPage.scss");

const LayOffRequestPage = ({
    setRequestStatus,
    requestStatus,
    requestOption,
}) => {
    // const [searchParams] = useSearchParams();
    // const queryId = searchParams.get("id");
    //
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const reasons = useSelector((state) => state.requests.reasons);
    const documentObtaining = useSelector(
        (state) => state.requests.documentObtaining,
    );
    const dismissalReasons = useSelector(
        (state) => state.requests.dismissalReasons,
    );
    const dismissalAdress = useSelector(
        (state) => state.requests.dismissalAdress,
    );
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectDropdown(event, element, stateProperty) {
        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    function selectDropdownForAddress(event, element, stateProperty) {
        if (element.id === 1) {
            dispatch(addDimmisialAdressCurrentRequest());
        }

        if (element.id === 3) {
            let addr = dismissalAdress.filter((i) => i.typeId === 2);
            addr = addr[0] ? addr[0].name : dismissalAdress[0]?.name;
            addr &&
            dispatch(
                addBasicInputHandlerCurrentRequest({
                    field: "obtainingDocumentAddress",
                    value: addr,
                }),
            );
        }

        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    function selectDropdownForArray(event, element, stateProperty) {
        dispatch(
            dropdownSelectionForArrayCurrenRequest(
                event,
                element,
                stateProperty,
            ),
        );
    }

    function changeDateHandler(field, date) {
        dispatch(validationControlCurrentRequest(field, true));
        dispatch(
            calendarDatePickCurrentRequest({
                field,
                date: moment(date).format("YYYY-MM-DD"),
            }),
        );
    }

    function commentHandler(event) {
        dispatch(addCommentHandlerCurrentRequest(event.target.value));
    }

    function addressHandler(event) {
        const field = "obtainingDocumentAddress";
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field,
                value: event.target.value ? event.target.value : null,
            }),
        );
        dispatch(validationControlCurrentRequest(field, true));
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        dispatch(layoffDismissalDocumentObtainingThunkAction());
        dispatch(layoffDismissalReasonsThunkAction());
        dispatch(getUsersAdressThunkAction());
        // if (!queryId) {
        dispatch(
            emptyCurrentRequest(
                new LayoffApplication({
                    leaveTypeId: 12,
                    dismissalDate: null,
                    dismissalObtainingDocumentId: null,
                    dismissalMotives: null,
                    obtainingDocumentAddress: null,
                    employeeReason: null,
                }),
            ),
        );
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return  isTerminal 
        ? (
            <div className="wrapper">
                <ScrollButton />
                <section className={"layoff"}>
                    <DropdownMenu
                        currentRequest={currentRequest}
                        styleName={"unplanned-reason"}
                        label={
                            <>
                                Выберите причину увольнения
                                <span className="required_text"> *</span>
                            </>
                        }
                        listOfOptions={reasons}
                        stateProperty={"leaveReasonId"}
                        isValidKey={
                            currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.leaveReasonId
                                ? currentRequest.isValid.leaveReasonId
                                : false
                        }
                        selectOptionClick={selectDropdown}
                    />
                    <div className={"layoff__wrapper"}>
                        <DatePopupSelector
                            currentRequest={currentRequest}
                            field={"dismissalDate"}
                            label={<>Дата увольнения<span className="required_text"> *</span></>}
                            errorMessage={
                                currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.dismissalDate
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={
                                currentRequest && currentRequest.dismissalDate
                            }
                            minDate={new Date()}
                        />
                        <span className={"layoff__wrapper__text"}>
                        По общему правилу работник имеет право расторгнуть
                        трудовой договор, предупредив об этом работодателя не
                        позднее, чем за две недели. По соглашению между
                        работником и работодателем трудовой договор может быть
                        расторгнут и до истечения срока предупреждения об
                        увольнении
                        </span>
                    </div>
                    <div className={"layoff__wrapper"}>
                        <DropdownMenu
                            currentRequest={currentRequest}
                            styleName={"unplanned-reason"}
                            label={<>Способ получения документов <span className="required_text"> *</span></>}
                            listOfOptions={documentObtaining}
                            stateProperty={"dismissalObtainingDocumentId"}
                            isValidKey={
                                currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.dismissalObtainingDocumentId
                                    ? currentRequest.isValid
                                        .dismissalObtainingDocumentId
                                    : false
                            }
                            selectOptionClick={selectDropdownForAddress}
                        />
                        <span className={"layoff__wrapper__message"}>
                        Обязательный пакет документов, выдаваемых при
                        увольнении: трудовая книжка или сведения о трудовой
                        деятельности, если трудовая книжка ведется в электронном
                        формате, справки формы СТД-Р, 182-Н, 2-НДФЛ, СЗВ-СТАЖ,
                        СЗВ-М
                        </span>
                    </div>

                    {currentRequest &&
            currentRequest.dismissalObtainingDocumentId === 3 ? (
                            <div className={"layoff__wrapper"}>
                                <CommentInput
                                    maxLength={255}
                                    value={currentRequest && currentRequest.obtainingDocumentAddress}
                                    changeHandler={addressHandler}
                                    label={<>Укажите ваш почтовый адрес<span className="required_text"> *</span></>}
                                    errorMessage={
                                        currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.obtainingDocumentAddress
                                            ? ""
                                            : "Необходимо заполнить поле «Укажите ваш почтовый адрес» "
                                    }
                                    parentStyle={"unplanned-vacation"}
                                    basicInput={true}
                                />
                            </div>
                        ) : null}

                    <div className={"layoff__wrapper"}>
                        <DropdownMenuWithCheckbox
                            currentRequest={currentRequest}
                            styleName={"dimissial-motives"}
                            label={<>Причина принятого решения об увольнении <span className="required_text"> *</span></>}
                            listOfOptions={dismissalReasons}
                            stateProperty={"dismissalMotives"}
                            isValidKey={
                                currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.dismissalMotives
                                    ? currentRequest.isValid.dismissalMotives
                                    : false
                            }
                            selectOptionClick={selectDropdownForArray}
                            isArr={true}
                            option={"dismissalMotives"}
                        />
                    </div>

                    <CommentInput
                        maxLength={255}
                        value={currentRequest && currentRequest.employeeComment}
                        changeHandler={commentHandler}
                        label={"Комментарий"}
                        parentStyle={"unplanned-vacation"}
                    />
                    <NotificationWindowRequests
                        notificationWindowStatus={requestStatus}
                        setNotificationWindowStatus={setRequestStatus}
                        notificationWindowStyle={notificationWindow}
                    />
                </section>
            </div>
        ) : (
            <section className={"layoff"}>
                <DropdownMenu
                    currentRequest={currentRequest}
                    styleName={"unplanned-reason"}
                    label="Выберите причину увольнения *"
                    listOfOptions={reasons}
                    stateProperty={"leaveReasonId"}
                    isValidKey={
                        currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.leaveReasonId
                            ? currentRequest.isValid.leaveReasonId
                            : false
                    }
                    selectOptionClick={selectDropdown}
                />
                <div className={"layoff__wrapper"}>
                    <DatePopupSelector
                        currentRequest={currentRequest}
                        field={"dismissalDate"}
                        label={<>Дата увольнения<span className="required_text"> *</span></>}
                        errorMessage={
                            currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.dismissalDate
                                ? ""
                                : "Обязательное поле"
                        }
                        changeHandler={changeDateHandler}
                        propsDate={
                            currentRequest && currentRequest.dismissalDate
                        }
                        minDate={new Date()}
                    />
                    <span className={"layoff__wrapper__text"}>
                        По общему правилу работник имеет право расторгнуть
                        трудовой договор, предупредив об этом работодателя не
                        позднее, чем за две недели. По соглашению между
                        работником и работодателем трудовой договор может быть
                        расторгнут и до истечения срока предупреждения об
                        увольнении
                    </span>
                </div>
                <div className={"layoff__wrapper"}>
                    <DropdownMenu
                        currentRequest={currentRequest}
                        styleName={"unplanned-reason"}
                        label={<>Способ получения документов <span className="required_text"> *</span></>}
                        listOfOptions={documentObtaining}
                        stateProperty={"dismissalObtainingDocumentId"}
                        isValidKey={
                            currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.dismissalObtainingDocumentId
                                ? currentRequest.isValid
                                    .dismissalObtainingDocumentId
                                : false
                        }
                        selectOptionClick={selectDropdownForAddress}
                    />
                    <span className={"layoff__wrapper__message"}>
                        Обязательный пакет документов, выдаваемых при
                        увольнении: трудовая книжка или сведения о трудовой
                        деятельности, если трудовая книжка ведется в электронном
                        формате, справки формы СТД-Р, 182-Н, 2-НДФЛ, СЗВ-СТАЖ,
                        СЗВ-М
                    </span>
                    {currentRequest &&
                currentRequest.dismissalObtainingDocumentId === 3 ? (
                            <CommentInput
                                maxLength={255}
                                value={currentRequest && currentRequest.obtainingDocumentAddress}
                                changeHandler={addressHandler}
                                label={"Укажите ваш почтовый адрес *"}
                                errorMessage={
                                    currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.obtainingDocumentAddress
                                        ? ""
                                        : "Необходимо заполнить поле «Укажите ваш почтовый адрес» "
                                }
                                parentStyle={"unplanned-vacation"}
                                basicInput={true}
                            />
                        ) : null}
                </div>
                <div className={"layoff__wrapper"}>
                    <DropdownMenuWithCheckbox
                        currentRequest={currentRequest}
                        styleName={"dimissial-motives"}
                        label={<>Причина принятого решения об увольнении <span className="required_text"> *</span></>}
                        listOfOptions={dismissalReasons}
                        stateProperty={"dismissalMotives"}
                        isValidKey={
                            currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.dismissalMotives
                                ? currentRequest.isValid.dismissalMotives
                                : false
                        }
                        selectOptionClick={selectDropdownForArray}
                        isArr={true}
                        option={"dismissalMotives"}
                    />
                </div>

                <CommentInput
                    maxLength={255}
                    value={currentRequest && currentRequest.employeeComment}
                    changeHandler={commentHandler}
                    label={"Комментарий"}
                    parentStyle={"unplanned-vacation"}
                />
                <CustomUploader />
                <NotificationWindowRequests
                    notificationWindowStatus={requestStatus}
                    setNotificationWindowStatus={setRequestStatus}
                    notificationWindowStyle={notificationWindow}
                />
            </section>
        );
};

export default LayOffRequestPage;
