import { useInputCompositionContext } from "../../../../../hooks/useInputCompositionContext/useInputCompositionContext";

import { useState } from "react";

const ICInput = (
    {
        dropdownProps,
        groupId,
        state,
        changeHandler,
        field,
        disabled,
    }
) => {
    
    const [,,isError] = useInputCompositionContext();

    const [inputState, setInputState] = useState("");


    const [stylesFocus] = useState("input-composition-default__no-arrow");
    const [stylesArrowUpFocus, setStylesArrowUpFocus] = useState("input-composition-default__arrow-up");

    switch (dropdownProps) {
    // IF THERES NO DROPDOWN
    case false:
        return (
            <span
                className={`${stylesFocus}`}
                style={isError && isError[field] ? {borderBottom: "1px solid red"} : {  }}>
                <input
                    disabled={disabled ? true : false}
                    onChange={(event) => {
                        setInputState(event.target.value);
                        changeHandler(event);
                    }}
                    maxLength={255}
                    value={inputState}
                    id={groupId ? groupId : null}/>
            </span>
        );
    // IF DROPDOWN HAS PASSED AS A COMPOUND
    default:
        function onFocusDropdownHandler () {
            setStylesArrowUpFocus("input-composition-default__arrow-up");
            if (!dropdownProps.isComponentVisible) {
                dropdownProps.setIsComponentVisible(true);
            }
        }
        function onClickDropdownHandler () {
            if (!dropdownProps.isComponentVisible) {
                dropdownProps.setIsComponentVisible(true);
            }
        }

        return (
            <span
                onClick={onClickDropdownHandler}
                onFocus={onFocusDropdownHandler}
                className={
                    dropdownProps.isComponentVisible
                        ? stylesArrowUpFocus
                        : "input-composition-default__arrow-down"
                }
                style={isError && isError[field] ? {borderBottom: "1px solid red"} : {  }}>
                <div
                    style={{minHeight: "40px", display: "flex", alignItems: "center", margin: "6px 0"}}
                    ref={dropdownProps && dropdownProps.setReferenceElement}>
                    {state}
                </div>
            </span>
        );
    }
};


// TODO INPUT SHOULD REACT TO STATE
export default ICInput;