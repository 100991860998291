import {wrongAuthAunteficationAction} from "../aunteficationAction";
import {$api} from "../../../services/request.config";
import {userLoginAction} from "../../Auth/userLoginAction";

export const repeatSmsCodeThunkAction = (id) => async(dispatch) => {
    try {
        const response = await $api.post("/v1/auth/sms", {userId:id});
        if (response.data) {
            localStorage.setItem("userId", response.data.userId);
            return dispatch(userLoginAction(response.data));
        }
    } catch (e) {
        dispatch(wrongAuthAunteficationAction("Что-то пошло не так, повторите попытку позже"));
    }
};
