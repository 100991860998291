import questionImage from "../../../assets/icons/question.svg";
import HintModal from "../Modal/HintModal/HintModal";

import React, {useState} from "react";

import "./QuestionMarkHint.scss";

const QuestionMarkHint = (props) => {
    const [questModal, setQuestModal] = useState(false);

    return (
        <div className="hint">
            <img
                onClick={() => setQuestModal(!questModal)}
                className="question-hint"
                style={{maxHeight: "35px", alignSelf: "center"}}
                src={questionImage}
                alt="question"/>
            <HintModal
                shown={questModal}
                close={() => setQuestModal(false)}>
                <p content="hint__p-content">
                    {props.content}
                </p>
                <div
                    onClick={() => setQuestModal(false)}
                    className="hint__cross">
                </div>
            </HintModal>
        </div>
    );
};

export default QuestionMarkHint;