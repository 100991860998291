class ExperienceControlService {
    constructor(percentCompany,percentRks){
        this.percentCompany = percentCompany;
        this.percentRks = percentRks;
    }

    static calc (current) {
        const result = {};
        if (current.total){
            const totalMonth = current.total.years * 12 + current.total.months;
            result.total = 100;
            if (current.company) {
                const companyMonth = current.company.years * 12 + current.company.months;
                const companyPercents = companyMonth * 100 / totalMonth;
                result.percentCompany = Math.floor(companyPercents);
                if (current.rks) {
                    const rksMonth = current.rks.years * 12 + current.rks.months;
                    const rcsPercents = (rksMonth / companyMonth) * companyPercents;
                    result.percentRks =Math.floor(rcsPercents);
                }
            }
            else if (current.rks) {
                const rksMonth = current.rks.years * 12 + current.rks.months;
                const rcsPercents = rksMonth * 100 / totalMonth;
                result.percentRks =Math.floor(rcsPercents);
            }
        }
        return result;
    }


    static  text(age) {
        let years;
        let count = age % 100;
        if (count >= 5 && count <= 20) {
            years = "л";
        } else {
            count = count % 10;
            if (count === 1) {
                years = "г";
            } else if (count >= 2 && count <= 4) {
                years = "г";
            } else {
                years = "л";
            }
        }
        return years;
    }
}

export default  ExperienceControlService;
