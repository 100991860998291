

export const data = [
    {
        title: "Передача полномочий руководителя",
        // description:
        //     "Описание структуры подразделения, подчиненность, положения, документы подразделения, штатное расписание",
        // icon: blankList,
        path: "/divisions/structure/delegation/transfer",
    },
    {
        title: "Отзыв полномочий руководителя",
        // description:
        //     "Описание структуры подразделения, подчиненность, положения, документы подразделения, штатное расписание",
        // icon: blankList,
        // path: "/divisions/structure/delegation/revoke",
        path: "/divisions/structure/delegation/revoke",
    },
];
