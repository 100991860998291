import { $api } from "../../../../services/request.config";

export const cofirmSignRequestThunkAction =
    ({
        operationId,
        code,
        modal,
        route,
        uid,
        setCorrectPassword,
        setSentStatus,
        setRequestNumber,
        setRequestTheme,
        setModalError,
        setOnValidation,
        setErrorMessage,
        currentRequest,
    }) =>
        async (dispatch) => {
            try {
                setOnValidation(true);
                setCorrectPassword(true);
                // console.log("password validation")
                const response = await $api.post(
                    `v2/request/confirm-sign-request/operation/${operationId}/${code}`,
                );
                // console.log(response.data)
                if (response.data.isSuccess) {
                    if (currentRequest) {
                        setRequestNumber(currentRequest.validatedData.orderNumber);
                        setRequestTheme(currentRequest.validatedData.leaveTypeName);
                        setSentStatus(true);
                    }
                    setOnValidation(false);
                }
            // setOnValidation(false)
            } catch (e) {
                setErrorMessage(
                    "При отправке кода произошла ошибка, пожалуйста повторите попытку позже",
                );
                // console.log('ERROR_WHILE_CONFIRM_SIGN', e.response)
                setOnValidation(false);
            }
        };
