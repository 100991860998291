import magnifyLens from "./MagnifyingGlass.svg";
import cross from "./cross.svg";

import {
    filterSelectEmployeeAction,
} from "../../../../../redux/actions/Employees/LeavesCrossings/filterActions/filterSelectEmployee.action";
import {
    getEmployeesWithApplicationsThunkAction,
} from "../../../../../redux/actions/Employees/LeavesCrossings/thunk/getEmployeesWithApplications.thunk.action";
import HelperService from "../../../../../frontend.services/Helper.service";

import {
    filterResetSelectionEmployeeAction,
} from "../../../../../redux/actions/Employees/LeavesCrossings/filterActions/filterResetSelectionEmployee.action";

import {
    clearFilterSelectedPidsAction,
} from "../../../../../redux/actions/Employees/LeavesCrossings/clearFilterSelectedPids.action";

import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {v4 as uuidv4} from "uuid";


const HolidaysFilterWindow = forwardRef(
    (
        {
            setIsComponentVisible,
            startOfMonth,
            endOfMonth,
        },
        ref
    ) => {

        const dispatch = useDispatch();

        const [searchInput, setSearchInput] = useState("");

        const employeeList = useSelector(state => state.employees.searchableEmployees);

        const [magnifyLensShow, setMagnifyLensShow] = useState(true);

        function selectHandler (pid) {
            dispatch(filterSelectEmployeeAction(pid));
        }

        function searchInputHandler (event) {
            setSearchInput(event.target.value);
        }

        function resetSelectionHandler () {
            dispatch(filterResetSelectionEmployeeAction());
            dispatch(clearFilterSelectedPidsAction());
            // dispatch(getEmployeesWithApplicationsThunkAction({from: "2023-07-01", to: "2023-07-31", subordinatePids: []}));
            dispatch(getEmployeesWithApplicationsThunkAction({from: startOfMonth, to: endOfMonth, subordinatePids: []}));

        }

        function declineHandler () {
            setIsComponentVisible(false);
            dispatch(filterResetSelectionEmployeeAction());
            dispatch(clearFilterSelectedPidsAction());
            // dispatch(getEmployeesWithApplicationsThunkAction({from: "2023-07-01", to: "2023-07-31", subordinatePids: []}));
            dispatch(getEmployeesWithApplicationsThunkAction({from: startOfMonth, to: endOfMonth, subordinatePids: []}));

        }

        function acceptSearchHandler () {
            const pids = employeeList && employeeList.filter(employee => employee.selected === true).map(employee => employee.pid);
            dispatch(getEmployeesWithApplicationsThunkAction({from: startOfMonth, to: endOfMonth, subordinatePids: pids}));

            // dispatch(getEmployeesWithApplicationsThunkAction({from: "2023-07-01", to: "2023-07-31", subordinatePids: pids}));
        }



        return (
            <div ref={ref}
                className="application-filter">
                {/*SEARCH INPUT*/}
                <div className="application-filter__input">
                    {magnifyLensShow && !searchInput && <img src={magnifyLens} alt="search" />}
                    <input
                        onChange={searchInputHandler}
                        value={searchInput}
                        onFocus={() => setMagnifyLensShow(false)}
                        type="text"
                        onMouseUp={() => {
                            setMagnifyLensShow(false);
                        }}
                        onMouseOut={() => {
                            if (!searchInput.length) {
                                setMagnifyLensShow(true);
                            } else {
                                setMagnifyLensShow(false);
                            }
                        }}/>
                </div>
                {/*SELECTED EMPLOYEES*/}
                {   employeeList &&
                employeeList.filter(employee => employee.selected === true).length
                    ? <div className="application-filter__selected-employees">
                        <div
                            onClick={resetSelectionHandler}
                            className="application-filter__selected-employees__reset">
                        Сбросить поиск
                        </div>
                        <ul className="application-filter__selected-employees__list">
                            {
                                employeeList &&
                                employeeList.filter(employee => employee.selected === true).map(selectedEmployee => {
                                    return (
                                        <li
                                            className="application-filter__selected-employees__list__item"
                                            key={uuidv4()}>
                                            <div>{HelperService.getLastNameWithInitials(selectedEmployee.name)}</div>
                                            <img
                                                onClick={() => selectHandler(selectedEmployee.pid)}
                                                src={cross}
                                                alt="delete" />
                                        </li>

                                    );
                                })
                            }
                        </ul>
                    </div>
                    : null
                }
                {/*EMPLOYEE LIST*/}
                <ul className="application-filter__employee-list">
                    {
                        employeeList && employeeList.map(employee => {
                            if (searchInput.length) {
                                let isDisplayable = false;

                                // const regex = new RegExp("^[а-яА-Я0-9_]+( [а-яА-Я0-9_]+)*$", "gmi");

                                const splittedSearch = searchInput.toLowerCase().split(" ");
                                let countKeys = 0;
                                splittedSearch.forEach((word, index, array) => {
                                    if (employee.name.toLowerCase().includes(word)) {
                                        countKeys += 1;
                                    }
                                });
                                if (countKeys === splittedSearch.length) {
                                    isDisplayable = true;
                                }
                                if (isDisplayable) {
                                    return (
                                        <li
                                            key={uuidv4()}
                                            className="application-filter__employee-list__item"
                                            onClick={() => selectHandler(employee.pid)}>
                                            <input
                                                onChange={() => setMagnifyLensShow(prev => !prev)}
                                                type="checkbox" checked={employee.selected}
                                                id={employee.pid}/>
                                            <div>
                                                {HelperService.getHighlightedText(employee.name, searchInput)}
                                                {/*{employee.name}*/}
                                            </div>
                                        </li>
                                    );
                                } else {
                                    return null;
                                }
                            } else {
                                return (
                                    <li
                                        key={uuidv4()}
                                        className="application-filter__employee-list__item"
                                        onClick={() => selectHandler(employee.pid)}>
                                        <input
                                            onChange={() => setMagnifyLensShow(prev => !prev)}
                                            type="checkbox" checked={employee.selected}
                                            id={employee.pid}/>
                                        <div>
                                            {HelperService.getHighlightedText(employee.name, searchInput)}
                                            {/*{employee.name}*/}
                                        </div>
                                    </li>
                                );
                            }
                        })
                    }
                </ul>
                <div className="application-filter__controll">
                    <div onClick={declineHandler}>
                    отмена
                    </div>
                    <div onClick={acceptSearchHandler}>
                    применить
                    </div>
                </div>
            </div>
        );
    });

export default HolidaysFilterWindow;