import UButton from "../UButton/UButton";

import { isTerminal } from "../../../../constants";

import React from "react";


const RequestButtons = (props) => {

    return (
        <div className="wt-request-buttons">
            <UButton
                onClick={props.backHandler}
                className="wt-request-buttons__default">
                {!isTerminal && "назад"}
                {isTerminal && "Назад"}
            </UButton>
            <div className="wt-request-buttons__two-buttons">
                {
                    !props.references &&
          <UButton
              onClick={props.saveHandler}
              className="wt-request-buttons__default"
              loading={props.isRunningSaveHandler}
          >
              {!isTerminal && "cохранить"}
              {isTerminal && "Cохранить"}
          </UButton>
                }

                <UButton
                    disabled={props.disabled}
                    onClick={props.sendHandler}
                    className="wt-request-buttons__default"
                    loading={props.isRunningSendHandler}
                >
                    {!isTerminal && "направить заявку"}
                    {isTerminal && "Направить заявку"}
                </UButton>
            </div>
        </div>
    );
};

export default RequestButtons;
