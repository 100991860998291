import ModalBanOverlay from "../../common/Modal/ModalBanOverlay/ModalBanOverlay";

import WorkerSignType from "../../common/Modal/ModalConditions/UserSignType/WorkerSignType/WorkerSignType";
import { Modal } from "../../common/Modal/Modal";
import { isTerminal } from "../../../constants";

import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import React from "react";

const ProtectedAuth = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("work");


    function closeModal() {
        setErrorModal(false);
    }

    useEffect(() => {
        if (localStorage.getItem("restricted") === "true") {
            setErrorModal(true);
            setErrDescription(
                localStorage.getItem("reason"),
            );
        }
    }, [localStorage.getItem("restricted")]);

    function check(e) {
        if (localStorage.getItem("restricted") === "true") {
            setErrorModal(true);
            setErrDescription(
                localStorage.getItem("reason"),
            );
        }
    }

    useEffect(() => {
        if (localStorage.getItem("restricted") === "true") {
            setErrorModal(true);
            setErrDescription(
                localStorage.getItem("reason"),
            );
            navigate("/main");
        }
    }, [window.location.pathname]);

    return token
        ?
        <div
            onClick={(e) => check(e)}
            style={{ 
                display: isTerminal && "flex",
                height: isTerminal && "100%",
                flexDirection: isTerminal && "column",
                justifyContent: isTerminal && "space-between",
            }}>
            {/*{*/}
            {/*localStorage.getItem("restricted") === "false" ?*/}
            <Outlet />
            {/*:*/}
            {/*<Navigate to="/main" />*/}
            {/*}*/}

            <Modal shown={errorModal} close={setErrorModal}>
                <WorkerSignType
                    closeButton={true}
                    close={closeModal}
                    path={"/main"}
                    description={errDescription}
                    setErrorModal={setErrorModal}
                    // block={true}
                />
            </Modal>
        </div>
        : <Navigate to="/start" />;
};

export default ProtectedAuth;
