import AuthService from "../../../services/AuthService";

export const getHeaderInformationThunkAction = () => async (dispatch) => {
    try {
        await AuthService.getHeaderInformation();
        // const data = await AuthService.getHeaderInformation();
    } catch (e) {
        throw new Error(e.response);
    }
};
