import {
    getDocumentsInformationThunkAction,
} from "../../../../../redux/actions/User/profile/GeneralData/thunk/getDocumentsInformationThunkAction";
import { isTerminal } from "../../../../../constants";

import BasicLine from "../../../../common/BasicLine/BasicLine";
import { ScrollButton } from "../../../../common/componentsTerminal/ScrollButton/ScrollButton";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const DataDocuments = () => {
    const dispatch = useDispatch();


    const dataDocuments = useSelector(state => state.personalData.documents);
    const [infoDocuments, setInfoDocuments] = useState(dataDocuments);

    useEffect(() => {
        dispatch(getDocumentsInformationThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInfoDocuments(dataDocuments);
    }, [dataDocuments]);

    return (
        <div className='fio'>
            {isTerminal && <ScrollButton />}
            {infoDocuments && infoDocuments?.map((el, index) => {
                return (
                    <BasicLine
                        key={index}
                        type={el.type}
                        description={el.description}
                        styleParent={"fio"}
                    />
                );
            })}
            {/*{dataDocuments&&*/}
            {/*<button*/}
            {/*    className='single-button-data'>*/}
            {/*    Направить заявку*/}
            {/*</button>*/}
            {/*}*/}
        </div>
    );
};

export default DataDocuments;
