import $api from "../../../../../services/axiosService";
import { getRelativesInformationAction } from "../getRelativesInformationAction";

import moment from "moment";

export const getRelativesInformationThunkAction = () => async (dispatch) => {
    try {
        const response = await $api.get("/v1.0/c/relatives");
        const relatives = response.data.data.map((el) => (
            {
                type: `${el.relativesTypeName[0].toUpperCase()}${el.relativesTypeName.slice(1)}`,
                description: `${el.name && el.name}, ${el.birthDate && moment(el.birthDate).format("DD.MM.YYYY")}`,
            }
        ));
        return dispatch(getRelativesInformationAction(relatives));
    } catch (e) {
    // console.log(e?.response?.data)
    }
};
