import { getCourseNumbersAction } from "./getCourseNumbers.action";

import { $api } from "../../../../services/request.config";

export const getCourseNumbersThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/request/leave/study/course-numbers");
        dispatch(getCourseNumbersAction(response.data));
    } catch (error) {
        throw new Error(error.response);
    }
};
