import EmployeeInformationHelper from "../../../../../../frontend.services/Divisions/EmployeeInformation.helper";
import union from "../../../../../../assets/icons/modal/Рабочее время/Union.svg";
import UButton from "../../../../../common/Button/UButton/UButton";

import {useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";

import "./EmployeeDetailInformation.scss";


function EmployeeDetailInformation({
    close,
}) {
    const employeeInformation = useSelector(state => state.myDivision.employeeInformation);

    return !employeeInformation
        ?
        (
            <div className='request-modal-loader'>
                <ClipLoader color="#6DCDF6"/>
            </div>
        ):
        (
            <div className={"information-modal"}>
                <img onClick={close} className="information-modal__modal-union" src={union} alt="union"/>
                {
                    employeeInformation.error ?
                        <div className={"information-modal__error-message"}>
                            <div className={"information-modal__error-message__error"}>{employeeInformation.error}</div>
                            <UButton onClick={close}>закрыть</UButton>
                        </div>
                        :
                        <div className={"information"}>
                            <div className={"information__header"}>
                                {employeeInformation.personal.lastNameRu}

                                {/*{employeeInformation.personal.secondNameRu}*/}
                            </div>
                            <div className={"information__header"}>
                                {employeeInformation.personal.firstNameRu}
                                {" "}
                                {/*{employeeInformation.personal.lastNameRu}*/}
                                {employeeInformation.personal.secondNameRu}

                            </div>
                            <div className={"information__lines"}>
                                {
                                    employeeInformation &&
                                    EmployeeInformationHelper.getTableRows(
                                        employeeInformation,"information"
                                    )
                                }
                            </div>

                        </div>
                }
            </div>
        );
}

export default EmployeeDetailInformation;
