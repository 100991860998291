import { data } from "./hrServicePage.data";
import { routerTerminal } from "./hrServicePageTerminal.data";

import { isTerminal } from "../../../constants";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import MultiRoute from "../../common/MultiRoute/MultiRoute";

import {
    getAvailableTypesForLeaderThunkAction,
} from "../../../redux/actions/Requests/RequestsFromLeader/thunk/getAvailableTypesForLeader.thunk.action";

import blank from "../../../assets/icons/NavBlockIcons/blank-list.svg";

import NavMenuDiv from "../../common/NavMenuDiv/NavMenuDiv";

import {
    isWorkerSubstituteThunkAction,
} from "../../../redux/actions/Documents/Leader/thunk/isWorkerSubstitute.thunk.action";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const style = isTerminal ? null : { margin: "20px auto", width: "1160px" };

const router = isTerminal ? routerTerminal : data;

const HrServicesPage = () => {
    // eslint-disable-next-line no-console
    const dispatch = useDispatch();
    const isSubstitute = useSelector((state) => state.documents.isSubstitute);

    useEffect(() => {
        // dispatch(getAvailableTypesForLeaderThunkAction(3));

        dispatch(isWorkerSubstituteThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Header />
            <MultiRoute
                data={router}
                styleParent={"hr-services"}
                breadCrumbs={true}
                subHeader={"Кадровый сервис"}
            />
            {(isSubstitute || localStorage.getItem("isLeader") === "true") &&
                <div style={style}>
                    <NavMenuDiv
                        path={"/main/hr-services/leader-requests"}
                        title={"Заявки руководителя"}
                        description={"Отпуска, делегирование, служебные записки и другие"}
                        icon={blank}
                        styleParent={"hr-services"}
                    />
                </div>
            }


            <Footer />
        </>
    );
};

export default HrServicesPage;
