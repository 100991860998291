import $api from "../../../../services/axiosService";
import { clearCurrentRequestAction } from "../clearCurrentRequest.action";
import { getCurrentRequestAction } from "../getCurrentRequest.action";
import WithoutPayApplication from "../../../../../frontend.classes/Requests/WithoutPay.application";
import UnplannedVacationApplication from "../../../../../frontend.classes/Requests/UnplannedVacation.application";
import PostponeVacationApplication from "../../../../../frontend.classes/Requests/PostponeVacation.application";
import ExtraVacationDaysApplication from "../../../../../frontend.classes/Requests/ExtraVacationDays.application";
import LayoffApplication from "../../../../../frontend.classes/Requests/Layoff.application";
import ShiftAdditionalRestDaysApplication
    from "../../../../../frontend.classes/Requests/ShiftAdditionalRestDays.application";
import { dropdownSelectionCurrentRequest } from "../../currentRequestControls/dropdownSelection.currentRequest";
import MaternityLeaveApplication from "../../../../../frontend.classes/Requests/MaternityLeave.application";
import StudyLeaveApplication from "../../../../../frontend.classes/Requests/StudyLeave.application";
import DismissalRevocationApplication from "../../../../../frontend.classes/Requests/DismissalRevocation.application";
import UnpaidPracticeApplication from "../../../../../frontend.classes/Requests/UnpaidPractice.application";
import ParentalLeaveApplication from "../../../../../frontend.classes/Requests/ParentalLeave.application";
import ImportantTasksApplication from "../../../../../frontend.classes/Requests/nonBasic/ImportantTasks.application";

export const getCurrentRequestThunkAction =
  (uid, setRequestOption) => async (dispatch) => {
      // TODO REFACTOR TECHNICALLY IS THE SAME BEHAVIOUR WITH SETCURRENTREQUEST.THUNK.ACTION
      try {
          const response = uid ? await $api(`v1/leaverequest/${uid}`) : null;
          if (response.data.isSuccess) {
              setRequestOption(response.data.item.leaveTypeId);
              const { leaveTypeId } = response.data.item;
              if (Number(leaveTypeId) === 1) {
                  dispatch(
                      getCurrentRequestAction(
                          new WithoutPayApplication(response.data.item),
                      ),
                  );
              } else if (Number(leaveTypeId) === 2) {
                  dispatch(
                      getCurrentRequestAction(
                          new UnplannedVacationApplication(
                              response.data.item,
                          ),
                      ),
                  );
              } else if (Number(leaveTypeId) === 3) {
                  dispatch(
                      getCurrentRequestAction(
                          new PostponeVacationApplication(response.data.item),
                      ),
                  );
                  dispatch(
                      dropdownSelectionCurrentRequest({
                          event: {},
                          element: {
                              dateFrom: response.data.item.dateFrom,
                              dateTo: response.data.item.dateTo,
                          },
                      }),
                  );
              } else if (Number(leaveTypeId) === 5) {
                  dispatch(
                      getCurrentRequestAction(
                          new StudyLeaveApplication(response.data.item),
                      ),
                  );
              } else if (Number(leaveTypeId) === 6) {
                  dispatch(
                      getCurrentRequestAction(
                          new MaternityLeaveApplication(response.data.item),
                      ),
                  );
              } else if (Number(leaveTypeId) === 7) {
                  dispatch(
                      getCurrentRequestAction(
                          new ParentalLeaveApplication(response.data.item),
                      ),
                  );
              } else if (Number(leaveTypeId) === 9) {
                  dispatch(
                      getCurrentRequestAction(
                          new ExtraVacationDaysApplication(
                              response.data.item,
                          ),
                      ),
                  );
              } else if (Number(leaveTypeId) === 12) {
                  dispatch(
                      getCurrentRequestAction(
                          new LayoffApplication(response.data.item),
                      ),
                  );
              } else if (Number(leaveTypeId) === 13) {
                  dispatch(
                      getCurrentRequestAction(
                          new ShiftAdditionalRestDaysApplication(
                              response.data.item,
                          ),
                      ),
                  );
              } else if (Number(leaveTypeId) === 14) {
                  dispatch(
                      getCurrentRequestAction(
                          new DismissalRevocationApplication(
                              response.data.item,
                          ),
                      ),
                  );
              } else if (Number(leaveTypeId) === 15) {
                  dispatch(
                      getCurrentRequestAction(
                          new UnpaidPracticeApplication(
                              response.data.item,
                          ),
                      ),
                  );
              } else if (Number(leaveTypeId) === 16) {
                  dispatch(
                      getCurrentRequestAction(
                          new ImportantTasksApplication(
                              response.data.item,
                          ),
                      ),
                  );
              } else {
                  dispatch(clearCurrentRequestAction());
              }
          }
      } catch (error) {
          dispatch(clearCurrentRequestAction());
      // console.log("ERROR WHILE GETTING CURRENT REQUEST: ", error.response)
      }
  };
