export const terminalData = [
    {
        title: "Отпуска и дни отдыха",
        description: "Оформление и информация об отпусках и днях отдыха",
        path: "/main/hr-services/work-time/holidays",
    },
    {
        title: "График работы",
        description: "Оформление и информация о графике работы",
        path: "/main/hr-services/work-time/work-schedule",
    },
    {
        title: "Заявки по рабочему времени и отпускам",
        description: "Заявки на отпуск, изменение рабочего времени и графика работы",
        path: "/main/hr-services/work-time/requests",
        // path: "#",
        // secured: true,
    },
];