import UButton from "../../Button/UButton/UButton";

import React, {useState} from "react";

import "./WidgetModal.scss";


const WidgetModal = (
    {
        active,
        handleClick,
        setActive,
        close,
    }
) => {


    const widgets = [
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
        "Сумма по сдельной оплате",
    ];

    const [checkedState, setCheckedState] = useState(new Array(widgets.length).fill(false));
    // const [total, setTotal] = useState([]);

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
    };

    const checkedCounter = checkedState.filter((el)=>el===true);

    // const checkBoxHandler = (checkedState) =>{
    //     const arr = [];
    //     checkedState?.map((el,index)=> {
    //         if(el){
    //             arr.push(widgets[index]);
    //         }
    //         return arr;
    //     });
    //     setTotal(arr);
    //     // console.log(total,'==========')
    //     dispatch(addUserWidgetsAction(total));
    //     dispatch(addUserWidgetsAction(total));
    //     setActive(false);
    // };

    return (
        <div className='modal-widgets'>
            <div className='modal-widgets__modal-dialog'>
                <h1>Настройка виджетов главной страницы</h1>
                <div className='scroll-bar'>
                    <div className='check-box-container' >
                        {
                            widgets && widgets.map((el,index)=>{
                                return (
                                    <div className='check-box-wrapper'>
                                        <input
                                            type="checkbox"
                                            id={`custom-checkbox-${index}`}
                                            name={el}
                                            value={el}
                                            checked={checkedState[index]}
                                            onChange={() => handleOnChange(index)}

                                        />
                                        <span id={`custom-checkbox-${index}`}>{el}</span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={"big-button2"}>
                    <UButton
                        className= "modal-widgets__button"
                        onClick={close}
                    >сбросить</UButton>
                    <UButton
                        className= "modal-widgets__button"
                        onClick={close}
                        disabled={checkedCounter.length !== 3}
                    >сохранить</UButton>

                </div>

            </div>
        </div>
    );
};

export default WidgetModal;
