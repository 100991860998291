import BasicApplication from "./Basic.application";

class PostponeVacationApplication extends BasicApplication {
    constructor(options, periods = []) {
        super(options);
        this.dateFrom = options.dateFrom;
        this.dateTo = options.dateTo;
        this.periods = options.periods;
        this.leaveRequestDwhId = options.leaveRequestDwhId;
        this.isValid = {
            leaveReasonId: true,
            leaveRequestDwhId: true,
            validDate: true,
        };
    }
}

export default PostponeVacationApplication;