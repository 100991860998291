import {getAllDocumentsAction} from "./getAllDocuments.action";

import RequestServices from "../../../../frontend.services/RequestServices/RequestServices";
// import {getUserHrDocsAction} from "../../Requests/Hr-documents/getUserHrDocs.action";
import {$api} from "../../../services/request.config";
// import {getLeaderRequestsAction} from "../../Requests/LeaderRequests/getLeaderRequests.action";
// import {getAllUserReferencesAction} from "../../Requests/References/getAllUserReferences.action";
// import {getAllUserRequestsAction} from "../../Requests/GetUserRequests/getAllUserRequests.action";
//
// import {element} from "prop-types";

export const getAllDocumentsThunkAction = () => async(dispatch) => {
    try {
        const arr = [];

        const responseHr = await $api("v2/directum/packages/0");
        if (responseHr.status === 200){
            const result = await RequestServices.getHrRequestsWithFile(responseHr.data);
            if(result.length > 0) {
                result.forEach((el) => arr.push({
                    el: el,
                    flag: "hrDocument",
                }));
            }
        }
        const responseLeader = await $api("v1/leaverequest/linemanager/all");
        if (responseLeader.data.isSuccess) {
            const data = await RequestServices.getLeaderRequestsWithFile(responseLeader.data.items);
            if(data.length > 0) {
                data.forEach((el) => arr.push({
                    el: el,
                    flag: "linemanager",
                }));
            }
        }

        const responseRef = await $api("v1/itsm/hr/get/all");
        if (responseRef.status === 200) {
            const data = await RequestServices.getHrReferencesWithFile(responseRef.data.data);
            if (data.length > 0) {
                data.forEach((el) => arr.push({
                    el: el,
                    flag: "references",
                }));
            }
        }

        const responseRequests = await $api("v2/request/all/for/po");
        if (responseRequests.status === 200) {
            const data = await RequestServices.getUserRequestsWithFile(responseRequests.data.items);
            if(data.length > 0) {
                data.forEach((el) => arr.push({
                    el: el,
                    flag: "requests",
                }));
            }
        }
        dispatch(getAllDocumentsAction(arr));
    }catch (e) {
        // console.log(e?.response)
    }
};
