import { $api } from "../../../../services/request.config";
import { addParentTabNumberAction } from "../controls/addParentTabNumber.action";

export const getParentFullNameThunkAction = (props) => async (dispatch) => {
    const { index, tabNumber } = props;
    try {
        const response = await $api(`v1/employees/${tabNumber}`);
        if (response.status === 200) {
            dispatch(addParentTabNumberAction({
                parentInput: response.data.fullName,
                indexOfParent: index,
                inputfield: "fullName",
                isParent: true,
            }));
        }

    } catch (error) {
        dispatch(addParentTabNumberAction({
            parentInput: "По данному табельному номеру не найдено ни одного сотрудника",
            indexOfParent: index,
            inputfield: "fullName",
            isParent: true,
        }));
    // throw new Error(error && error.response && error.response.detail);
    }
};
