export const data = [
    {
        title: "ВНД по вопросам увольнения сотрудников",
        description: "Содержит 6 документов",
        path: "#",
    },
    {
        title: "Материалы для увольняющихся ",
        description: "Содержит 9 документов",
        path: "#",
    },
    {
        title: "Заявка на увольнение",
        description: "Заявка на увольнение по собственному желанию",
        // path: "#",
        path: "/main/hr-services/layoff/request",
        // secured: true,
        // unep: true,
    },
];
