
// DO NOT DELETE
// START *****
import { HolidaysEmployeeFilter } from "../HolidaysEmployeeFilter/HolidaysEmployeeFilter";

export const headData = [
    {
        colspan: 2,
        id: "01tableHead",
        cssKey: "__worker",
        // data: "Сотрудник",
        data: <HolidaysEmployeeFilter />,
    },
    {
        colspan: 1,
        id: "02tableHead",
        cssKey: "__position",
        data: "Должность",
    },
];
export const regularHeaderData = [
    {
        colspan: 2,
        id: "01subHeader",
        cssKey: "__sub-header-regular",
        data: "Отпуска по графику",
    },
    {
        colspan: 1,
        id: "02subHeader",
        cssKey: "__sub-header-regular",
        data: "%",
    },
];
export const acceptHeaderData = [
    {
        input: "checkbox",
        id: "00subHeader",
        cssKey: "__sub-header-accept-checkbox",
    },
    {
        id: "01subHeader",
        cssKey: "__sub-header-accept-regular",
        data: "Согласование отпусков",
    },
    {
        id: "02subHeader",
        cssKey: "__sub-header-accept-regular",
        data: "рассчетный %",
    },
];
// ***** END
// TODO TO TABLE SERVICE
export const subHeaderPercents = (data) => {
    const percents = data.map((e, i) => {
        return {
            id: "cell" + i + e,
            cssKey: "__sub-header-regular",
            data: e,
        };
    });
    return [
        {
            id: "01subHeader",
            cssKey: "__sub-header-regular",
            data: "Отпуска по графику",
        },
        {
            id: "02subHeader",
            cssKey: "__sub-header-regular",
            data: "%",
        },
    ].concat(percents);
};
